import React from 'react'
import "./CreateRole.css"
import Header from '../Header/Header'
import { useState } from 'react'
import { permissions } from '../../helpers/permissions'
import { useEffect } from 'react'
import Cookies from "universal-cookie";
import axiosInstance from '../../helpers/request'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import SubmitLoader from '../common/SubmitLoader'
import { useAppState } from '../../state'

function CreateRole({ title }) {
    const cookies = new Cookies();
    const history = useHistory()
    const { user, userPermissions } = useAppState();
    const location = useLocation()
    const { id } = useParams()
    const [role, setRole] = useState("")
    const isEdit = location.pathname.includes("edit-role")
    const userToken = localStorage.getItem("cs_token");
    const [data, setData] = useState({
        name: "",
        description: "",
        permissions: permissions.map(v => v.children).flat().map(v => ({ name: v.name, value: false, children: v.children.filter((l3) => !l3.adminOnly).map(c => ({ name: c.name, value: false })) })),
        addedByInCompany: ""
    })
    const [loading, setLoading] = useState(false)
    const [companyId, setUserID] = useState("")

    useEffect(() => {
        userDetail()
        if (isEdit && id) {
            getRole()
        }
    }, []);

    const [allPermissions, setPermissions] = useState([]);

    useEffect(() => {
        console.log('comes in to handle disabled');
        
        const disabledPermissions = user.disabledPermissions ? user.disabledPermissions.split(",") : [];

        setPermissions(
        permissions
            .filter(
            (v) =>
                v.children.filter((c) => userPermissions.includes(c.name)).length
            )
            .map((v) => {
            return {
                ...v,
                children: v.children
                .filter(
                    (p) => 
                        ( userPermissions.includes(p.name) &&  
                        ! disabledPermissions.includes(p.name) &&
                        ( ! p.requiresSuperAdmin || user.role == 'superadmin' ) )
                    )
                .map((c) => {
                    return {
                    ...c,
                    children: c.children.filter((gc) =>
                        (
                            (
                                userPermissions.includes(gc.name) ||
                                (gc.adminOnly&&((user.role=="admin")||(user.role=="superadmin")))
                            )
                        )
                    ),
                    };
                }),
            };
            })
        );
    }, [userPermissions, user]);

    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setUserID(((res.data[0].role !== "admin") && res.data[0].added_by) ? res.data[0].added_by : res.data[0].id);
                    setRole(res.data[0].role);

                    // Check if the user is admin
                    if ( res.data[0].role !== 'superadmin' && res.data[0].role !== 'admin' && ! userPermissions.includes('createRole') ) {
                        history.push('/');
                    }

                    if (!isEdit) {
                        setData({
                            ...data,
                            addedByInCompany: res.data[0].id
                        })
                    }
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    function getRole() {
        axiosInstance
            .get(`${process.env.REACT_APP_API_BASEURL}/role/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.id) {
                    const allPermit = res.data.permissions ? res.data.permissions.split(",") : []
                    // setData({ ...res.data, permissions: permissions.map(v => v.children).flat().map(v => ({ name: v.name, value: allPermit.includes(v.name) })) });
                    setData({ ...res.data, permissions: permissions.map(v => v.children).flat().map(v => ({ name: v.name, value: allPermit.includes(v.name), children: v.children.filter((l3) => !l3.adminOnly).map(c => ({ name: c.name, value: allPermit.includes(c.name) })) })) });

                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }
    const handleSubmit = () => {
        if (data.name == "admin" || data.name == "superadmin") {
            toast.error("Reserved keywords for role not allowed")
        } else {
            if (data.name && data.permissions.map(v => v.children).flat().filter(v => v.value).length) {
                setLoading(true)
                if (isEdit) {
                    axiosInstance.put(`/role/${id}`, {
                        ...data,
                        name: data.name.split(" ").join(""),
                        permissions: data.permissions.map(v => v.value ? [...v.children, { name: v.name, value: true }] : v.children).flat().filter(v => v.value).map(v => v.name).join(",")
                    }, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    }).then(async (response) => {
                        if (response.status === 200) {
                            // setData(response.data)
                            toast.success("Successfully Updated role")
                            history.push("/roles")
                        } else {
                            throw new Error(response?.error);
                        }
                        setLoading(false)
                    }).catch(err => {
                        console.log(err)
                        setLoading(false)
                        toast.error("Failed to Update role")
                    });
                } else {
                    axiosInstance.post('/add-role', {
                        ...data,
                        companyId,
                        name: data.name.split(" ").join(""),
                        permissions: data.permissions.map(v => v.value ? [...v.children, { name: v.name, value: true }] : v.children).flat().filter(v => v.value).map(v => v.name).join(",")
                    }, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    }).then(async (response) => {
                        if (response.status === 200) {
                            // setData(response.data)
                            toast.success("Successfully Added role")
                            history.push("/roles")
                        } else {
                            throw new Error(response?.error);
                        }
                        setLoading(false)
                    }).catch(err => {
                        console.log(err)
                        setLoading(false)
                        toast.error("Failed to add role")
                    });
                }
            } else {
                toast.error("Please fill mandatory fields")
            }
        }
    }

    console.log(data.permissions)


    return (
        <div class="middle_page">
            <Header title={title} steps={[{ name: "Home", path: "/" }, { name: "Roles List", path: "/roles" }, { name: "Add Role", path: "/create-role" }]} />
            <div class="main_container">
                <div>
                    <div class="accident">
                        <div class="accident_text">
                            <h5>{!isEdit ? "Add Role" : "Edit Role"}</h5>
                        </div>
                    </div>
                    <form>
                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div class="form_inner p-2">
                                    <label for="">Name <span className='text-danger'>*</span> </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={data.name} name="name" onChange={handleChange} placeholder="Name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Description</label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={data.description} name="description" onChange={handleChange} placeholder="Descriptions" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <div className='row w-100'>
                                        {/* <div className='d-flex justify-content-between'> */}
                                        <div className='row mt-4'>
                                            <div className='col-10 mb-4'>
                                                <div className='d-flex justify-content-between'>
                                                    <label className='p-2' for="">Permissions  <span className='text-danger'>*</span>  </label>
                                                </div>
                                            </div>
                                            <div className='col-2 mb-4'>
                                                <div className='d-flex justify-content-end'>
                                                    <label className='p-2' for="">Select All </label>

                                                    <div class="form-check form-switch mt-1">
                                                        <input
                                                            class="sub-permission-check form-check-input form-check-input-color d-block"
                                                            style={{ width: "50px" }}
                                                            type="checkbox"
                                                            role={`switch`}
                                                            id={`flexSwitchCheckChecked`}
                                                            checked={data.permissions.reduce((aggregate, p) => aggregate && p.value && p.children.reduce((a2, c) => a2 && c.value, true), true)}
                                                            onChange={e => {
                                                                setData(d => ({
                                                                    ...d,
                                                                    permissions: d.permissions.map(p => ({ ...p, value: e.target.checked, children: p.children.map(c => ({ ...c, value: e.target.checked })) }))
                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {allPermissions.map((v, i) => (
                                            <div className='row mt-4'>
                                                <h5 className='mb-4'>{v.name}</h5>
                                                {v.children.map(v=>(<div className='col-md-6 col-xl-4 col-sm-6  mb-4'>
                                                    <div className='d-flex justify-content-between'>
                                                        <label for={`flexSwitchCheckChecked-${i}`}>{v.label} </label>
                                                        {console.log("permission", v.name, data.permissions, data.permissions.filter(p => p.name === v.name))}
                                                        <div class="form-check form-switch">
                                                            <input
                                                                class="sub-permission-check form-check-input form-check-input-color d-block"
                                                                style={{ width: "50px" }}
                                                                type="checkbox"
                                                                role={`switch-${i}`}
                                                                id={`flexSwitchCheckChecked-${i}`}
                                                                checked={data.permissions.filter(p => p.name === v.name)[0].value}
                                                                onChange={e => {
                                                                    setData(d => ({
                                                                        ...d,
                                                                        permissions: d.permissions.map(p => {
                                                                            if (p.name === v.name) {
                                                                                return { ...p, value: e.target.checked, children: p.children.map(c => ({ ...c, value: e.target.checked })) }
                                                                            } else {
                                                                                return p
                                                                            }
                                                                        })
                                                                    }))
                                                                }} />
                                                        </div>

                                                    </div>

                                                    {data.permissions.filter(p => p.name === v.name)[0].value ? <div className='mt-2 ps-2' >
                                                        {v.children.filter((l3) => !l3.adminOnly).map((gc, i) => (
                                                            <div className='d-flex justify-content-between'>
                                                                <label style={{ fontSize: "14px" }} for={`flexSwitchCheckCheckedInner-${i}`}>{gc.label} </label>
                                                                <div class="form-check form-switch">
                                                                    <input
                                                                        class="form-check-input form-check-input-color  d-block"
                                                                        style={{ width: "50px" }}
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id={`flexSwitchCheckCheckedInner-${i}`}
                                                                        checked={data.permissions.filter(p => p.name === v.name)[0].children.filter(c => c.name === gc.name)[0].value}
                                                                        onChange={e => {
                                                                            setData(d => ({
                                                                                ...d,
                                                                                permissions: d.permissions.map(p => {
                                                                                    if (p.name === v.name) {
                                                                                        return {
                                                                                            ...p, children: p.children.map(c => {
                                                                                                if (c.name === gc.name) {
                                                                                                    return { ...c, value: e.target.checked }
                                                                                                } else {
                                                                                                    return c
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    } else {
                                                                                        return p
                                                                                    }
                                                                                })
                                                                            }))
                                                                        }} />
                                                                </div>

                                                            </div>))}
                                                    </div> : ""}

                                                </div>))}
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="signature">
                        <div class="sign_btns">
                            <div>
                                {!loading ? <button class="submit" disabled={loading} onClick={handleSubmit}>{!isEdit ? "Create" : "Update"}</button> : <SubmitLoader />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateRole