import { toast } from "react-toastify";

export function onError(error) {
  console.log(error);
  if (error.message && !error.response?.data?.message) {
    toast.error(error.message);
  } else {
    toast.error(error.response?.data?.message);
  }
}
