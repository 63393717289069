import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import { commonService } from "../common/common.service";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useHistory } from "react-router-dom";

export default function ViewCourse({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  
  const fetchCourse = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/courses/details`,
        {
          courseId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
        console.log("errr", err);
      });
  };

  useEffect(() => {
    if (id) {
      fetchCourse();
    }
  }, [id]);

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Courses", path: "/courses/list" },
          { name: "Course", path: `/courses/${id}` },
        ]}
      />
   
      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">
              <div className="row">
                <div className="col-8 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Course Name : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.course_name ? data.course_name : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Days to Retrain : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.days_to_retrain ? data.days_to_retrain : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Submitted By :</div>
                            <div className="col-6 dd-access">{data.submittedBy ? data.submittedBy : "-"}</div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Department(s) :</div>
                            <div className="col-6 dd-access">{data.departments ? data.departments.map(v => v.name).join(", ") : "-"}</div>
                          </div>
                 
                        </div>
                      </div>
                      

                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
