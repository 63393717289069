import axios from "./../libs/axios";

class AccidentService {
    getAccidentReport(id) {
        return axios.get(`/accident-reports/${id}`);
    }

    getAccidentNotifications(payload) {
        return axios.post(`/accidentnotifications/notifications`, payload);
    }

    markNotificationAsRead(id) {
        return axios.put(`/accidentnotifications/notifications/${id}/read`);
    }
}

export default new AccidentService();
