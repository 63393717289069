import React from 'react'
import { Link } from "react-router-dom";

import Shape1 from '../../images/graphic2.svg'
import Shape2 from '../../images/graphic1.svg'
import graphic from "../../images/bannergraphic.svg";
import videoicon from "../../images/playericon.svg";
import logos from "../../images/logos.svg";
import Icon from '../../images/icon.svg'
import logo from "../../images/foresighthublogo.svg";
import { monthlyPaymentArray } from "../../helpers/paymentArray";
import { useAppState } from "../../state";
import { PaymentCard } from "../paymentCard/PaymentCard";


// brands //
import BrandLogo2 from '../../images/brand-logo-2.png'
import BrandLogo3 from '../../images/brand-logo-3.png'
import BrandLogo4 from '../../images/brand-logo-4.png'
import BrandLogo5 from '../../images/brand-logo-5.png'
import BrandLogo6 from '../../images/brand-logo-6.png'
import BrandLogo7 from '../../images/brand-logo-7.png'
import BrandLogo8 from '../../images/brand-logo-8.png'
import BrandLogo9 from '../../images/brand-logo-9.png'

import BrandLine1 from '../../images/brand-line-1.png'
import BrandLine2 from '../../images/brand-line-2.png'
import BrandLine3 from '../../images/brand-line-3.png'
import BrandLine4 from '../../images/brand-line-4.png'
import BrandLine5 from '../../images/brand-line-5.png'
import BrandLine6 from '../../images/brand-line-6.png'
import BrandLine7 from '../../images/brand-line-7.png'
import BrandLine8 from '../../images/brand-line-8.png'


// social //
import Facebook from '../../images/fb.svg'
import Insta from '../../images/insta.svg'
import Linkedin from '../../images/linkeding.svg'



export default function Contact() {

  const { isLoggedIn } = useAppState();
  const [active, setActive] = React.useState(false);
  const currentPage = window.location.pathname.split("/")[1];



  return (
    <div className='homepage home-styles'>

      <header className="site-header site-header--menu-left dynamic-sticky-bg mt-2 mt-lg-0 menu-block-4 mb-7 mb-lg-0 site-header--absolute site-header--sticky">
        <div className="containerWS">
          <nav className="navbar site-navbar">

            <div className="brand-logo">
              <a href="/">

                <img src={logo} alt="" className='logomain' />

              </a>
            </div>
            <div className="menu-block-wrapper ">
              <div className="menu-overlay"></div>
              <nav className="menu-block" id="append-menu-header">
                <div className="mobile-menu-head">
                  <div className="go-back">
                    <i className="fa fa-angle-left"></i>
                  </div>
                  <div className="current-menu-title"></div>
                  <div className="mobile-menu-close">&times;</div>
                </div>
                <ul className="site-menu-main">
                  <li className="nav-item">

                    <Link to={"/"}>
                      <button className="actionElementButton">

                        <span className="navactionButtonText active">Home</span>
                      </button>
                    </Link>








                  </li>
                  <li className="nav-item">

                    <Link to={"/plans/"}>
                      <button className="actionElementButton">

                        <span className="navactionButtonText">Pricing</span>
                      </button>
                    </Link>


                  </li>
                  <li className="nav-item">





                    <Link to={"/contact/"}>
                      <button className="actionElementButton">

                        <span className="navactionButtonText">Contact</span>
                      </button>
                    </Link>



                  </li>
                </ul>
              </nav>
            </div>
            <div className="header-btns  ms-auto ms-lg-0 d-none d-sm-flex align-items-center heading-default-color">
              <Link to={"/login/"}>
                <button className="actionElementButton">

                  <span className="navactionButtonText">Login</span>
                </button>
              </Link>


              <Link to={"/plans/"}>
                <button className="actionElementButton">

                  <span className="btn btn--xxl h-45 btn-primary text-white rounded-55 trialBTN">Start Free Trial</span>

                </button>
              </Link>


            </div>

            <div className="mobile-menu-trigger">
              <span></span>
            </div>

          </nav>
        </div>
      </header>

      <div className="welcome-area welcome-area--l2 bg-default">
        <div className="containerWS">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6">
              <div className="welcome-content welcome-content--l2 text-center position-relative">
                <h1 className="welcome-content--l2__heading" data-aos="fade-up" data-aos-duration="500" data-aos-once="true">Easy To Use Online<br />Health & Safety Software</h1>
                <p className="welcome-content--l2__descriptions" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true">Have Complete control of your Health & Safety system.
                </p>
                <div className='row'>
                  <div className="col-xl-3 col-lg-3 btn-align">
                    <Link to={"/plans/"}>
                      <button className='btn btn--xxxl white'>

                        <span> Start Free Trial</span>

                      </button>
                    </Link>
                  </div>


                  <div className="col-lg-9 col-md-9 btn-align">
                    <Link to={"/plans/"}>
                      <img className="w-100 marginbottom" src={videoicon} alt="" />
                    </Link>
                  </div>
                </div>

              </div>

              {/* <div className="welcome-content--l2__newsletter" data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true">
                  <div className="newsletter-form mx-auto newsletter-form--l2">
                    <form action="/.">
                      <div className="d-flex align-items-center justify-content-center flex-column flex-xs-row">
                        <input className="form-control  rounded-55" type="email" placeholder="@ Enter your email" required/>
                        <button className=" btn btn-primary text-white btn--medium rounded-55" type="submit">Start Now!</button>
                      </div>
                    </form>
                  </div>
                </div> */}

            </div>
            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true">
              <div className="">
                <img className="w-100 marginbottom" src={graphic} alt="" />
              </div>
            </div>
          </div>
        </div>








        <div className="content-section content-section--l2-2 position-relative bg-white-bottom">
          <div className="containerWS">
            <div className="content-section--l2-2__wrapper border-top border-default-color">
              <div className="row justify-content-center">
                <h2 className="section-title--2__heading" data-aos="fade-up" data-aos-duration="500" data-aos-once="true">Trusted By Companies</h2>
                <div className="col-xl-8 col-lg-12 col-md-8 col-xs-8">
                  <div className="content-image-group--l2-2">
                    <img className="" src={logos} alt="" data-aos="fade-left" data-aos-duration="500" data-aos-once="true" />

                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>














        <div className="content-section content-section--l2-2 position-relative bg-white-bottom">
          <div className="containerWS">
            <div className="content-section--l2-2__wrapper border-top border-default-color">
              <h2 className="section-title--3__heading" data-aos="fade-up" data-aos-duration="500" data-aos-once="true">Key Benefits and Features</h2>
              <div className="row align-items-center justify-content-center justify-content-lg-start">
                <div className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
                  <div className="content-image-group--l2-2">
                    <img className="w-100" src={Shape2} alt="" data-aos="fade-left" data-aos-duration="500" data-aos-once="true" />

                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
                  <div className="content-texts content-texts--l2-1 text-center text-lg-start">
                    <h2 className="content-texts--l2-1__heading" data-aos="fade-left" data-aos-duration="500" data-aos-once="true">Simple User <br className="d-none d-lg-block" />Dashboard.</h2>
                    <p className="content-texts--l2-1__content" data-aos="fade-left" data-aos-duration="500" data-aos-delay="300" data-aos-once="true">At a glance, you can see if your Health & Safety programme<br />needs action. At a glance, you can see if your Health & Safety<br />programme needs action..</p>
                    <div className="content-texts--l2-1__button" data-aos="fade-left" data-aos-duration="500" data-aos-delay="500" data-aos-once="true">
                      <Link to={"/plans/"}>
                        <button className='btn btn--xxxl'>

                          <span>Get Started Now</span>

                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>











        <div className="content-section content-section--l2-1 bg-white-top position-relative">
          <div className="content-section--l2-1__shape">
            <img className="w-100" src="./image/home-2/l2-content-1-shape.png" alt="" />
          </div>
          <div className="containerWS">
            <div className="row align-items-center justify-content-center justify-content-lg-start">
              <div className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1">
                <div className="content-texts content-texts--l2-1 text-center text-lg-start">
                  <h2 className="content-texts--l2-1__heading" data-aos="fade-right" data-aos-duration="500" data-aos-once="true">Risk<br className="d-none d-lg-block" />Assessments</h2>
                  <p className="content-texts--l2-1__content" data-aos="fade-right" data-aos-duration="500" data-aos-delay="300" data-aos-once="true">Foresight has an easy to use Risk Assessment tool which will<br /> ease the burden of completing same on organisations.<br />Foresight has an easy to use Risk Assessment.</p>
                  <div className="content-texts--l2-1__button">
                    <Link to={"/plans/"}>
                      <button className='btn btn--xxxl'>

                        <span>Get Started Now</span>

                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2">
                <div className="content-image-group content-image-group--l2-1">
                  <img className="w-100" src={Shape1} alt="" data-aos="fade-left" data-aos-duration="500" data-aos-once="true" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-section content-section--l2-2 position-relative bg-white-bottom">
          <div className="containerWS">
            <div className="content-section--l2-2__wrapper border-top border-default-color">
              <div className="row align-items-center justify-content-center justify-content-lg-start">
                <div className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
                  <div className="content-image-group--l2-2">
                    <img className="w-100" src={Shape2} alt="" data-aos="fade-left" data-aos-duration="500" data-aos-once="true" />

                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
                  <div className="content-texts content-texts--l2-1 text-center text-lg-start">
                    <h2 className="content-texts--l2-1__heading" data-aos="fade-left" data-aos-duration="500" data-aos-once="true">COVID-19 <br className="d-none d-lg-block" />compliance.</h2>
                    <p className="content-texts--l2-1__content" data-aos="fade-left" data-aos-duration="500" data-aos-delay="300" data-aos-once="true">Covid-19 is the priority health & safety issue of our time by<br /> using Foresight you can track staff declarations, Contact<br /> tracing and much more</p>
                    <div className="content-texts--l2-1__button" data-aos="fade-left" data-aos-duration="500" data-aos-delay="500" data-aos-once="true">
                      <Link to={"/plans/"}>
                        <button className='btn btn--xxxl'>

                          <span>Get Started Now</span>

                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>











        <div className="brand-area brand-area--l2 bg-default-5">
          <div className="containerBrands">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="section-title--2 text-center">
                  <h2 className="section-title--2__heading" data-aos="fade-up" data-aos-duration="500" data-aos-once="true">Software Integrations Available</h2>
                  <p className="section-title--2__description" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true">

                    Use Foresight Hub for quick, compliant deployment of <br className="d-none d-sm-block" />personalised bulk SMS messaging</p>
                  <div className="section-title--2__button">
                    <Link to={"/plans/"}>
                      <button className='btn btn--xxxl'>

                        <span>Get Started Now</span>

                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-6 col-md-9" data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-once="true">
                <div className="brand-image-group brand-image-group--l2">
                  <div className="brand-image-group--l2__image--main circle-126">
                    <div className="img-0">
                      <img className="w-200" src={Icon} alt="" />
                    </div>
                  </div>

                  <div className="brand-image-group--l2__single brand-image-group--l2__img-1 circle-91">
                    <div className="brand-logo-1">
                      <img className="w-100" src={BrandLogo2} alt="" />
                    </div>
                  </div>
                  <div className="brand-image-group--l2__single brand-image-group--l2__img-2 circle-103">
                    <div className="brand-logo-2">
                      <img className="w-100" src={BrandLogo3} alt="" />
                    </div>
                  </div>
                  <div className="brand-image-group--l2__single brand-image-group--l2__img-3 circle-98">
                    <div className="brand-logo-3">
                      <img className="w-100" src={BrandLogo4} alt="" />
                    </div>
                  </div>
                  <div className="brand-image-group--l2__single brand-image-group--l2__img-4 circle-99">
                    <div className="brand-logo-4">
                      <img className="w-100" src={BrandLogo5} alt="" />
                    </div>
                  </div>
                  <div className="brand-image-group--l2__single brand-image-group--l2__img-5 circle-95">
                    <div className="brand-logo-5">
                      <img className="w-100" src={BrandLogo6} alt="" />
                    </div>
                  </div>
                  <div className="brand-image-group--l2__single brand-image-group--l2__img-6 circle-113">
                    <div className="brand-logo-6">
                      <img className="w-100" src={BrandLogo7} alt="" />
                    </div>
                  </div>
                  <div className="brand-image-group--l2__single brand-image-group--l2__img-7 circle-88">
                    <div className="brand-logo-7">
                      <img className="w-100" src={BrandLogo8} alt="" />
                    </div>
                  </div>
                  <div className="brand-image-group--l2__single brand-image-group--l2__img-8 circle-108">
                    <div className="brand-logo-8">
                      <img className="w-100" src={BrandLogo9} alt="" />
                    </div>
                  </div>

                  <div className="brand-image-group--l2__line-1">
                    <img className="w-100" src={BrandLine1} alt="" />
                  </div>
                  <div className="brand-image-group--l2__line-2">
                    <img className="w-100" src={BrandLine2} alt="" />
                  </div>
                  <div className="brand-image-group--l2__line-3">
                    <img className="w-100" src={BrandLine3} alt="" />
                  </div>
                  <div className="brand-image-group--l2__line-4">
                    <img className="w-100" src={BrandLine4} alt="" />
                  </div>
                  <div className="brand-image-group--l2__line-5">
                    <img className="w-100" src={BrandLine5} alt="" />
                  </div>
                  <div className="brand-image-group--l2__line-6">
                    <img className="w-100" src={BrandLine6} alt="" />
                  </div>
                  <div className="brand-image-group--l2__line-7">
                    <img className="w-100" src={BrandLine7} alt="" />
                  </div>
                  <div className="brand-image-group--l2__line-8">
                    <img className="w-100" src={BrandLine8} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className="table-section bg-default-3white">
          <div className="containerWS">
            <div className="table-section--l5 border-bottom border-default-color-3 bg-default-3white">

              <div className="row justify-content-center" id="table-price-value" data-pricing-dynamic data-value-active="monthly">

                <div className="container">
                  <div className="row justify-content-center">

                    <div
                      className="paymentBody"
                      style={{
                        width: currentPage === "plans" && isLoggedIn && `calc(100% - 65px)`,
                        marginLeft: currentPage === "plans" && isLoggedIn && "65px",
                        marginTop: currentPage === "plans" && isLoggedIn && "65px",
                      }}>
                      <div className="header">
                        <h2 className="content-texts--l2-1__heading" data-aos="fade-left" data-aos-duration="500" data-aos-once="true">Choose a Plan to try Foresight Hub risk free.</h2>

                        <p className="section-title--2__description" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true">

                          Free 30 days trial, cancel anytime. Low monthly subscription with savings on Annual Plans.</p>
                        <p className="section-title--2__description" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true">

                          Contact us for customized solutions</p>


                        <div className="paybuttons">
                          <span className="paybutton">
                            <p
                              className={!active ? "pays" : "pays1"}
                              onClick={() => setActive(false)}>
                              Pay Monthly
                            </p>
                            <p
                              className={active ? "pays" : "pays1"}
                              onClick={() => setActive(true)}>
                              Pay Annually
                            </p>
                          </span>
                        </div>
                      </div>
                      <div className="items">
                        {!active
                          ? monthlyPaymentArray.slice(0, 4).map((item) => {
                            return <PaymentCard key={item.id} item={item} />;
                          })
                          : monthlyPaymentArray.slice(4, 8).map((item) => {
                            return <PaymentCard key={item.id} item={item} />;
                          })}
                      </div>
                    </div>



                  </div>
                </div>






              </div>
            </div>
          </div>
        </div>

















        <footer className="footer-section footer-section--l4 position-relative bg-default">
          <div className="containerWS">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="footer-top border-bottom border-default-color-3 pb-5">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-6 col-xxs-4 col-lg-4 col-md-5 col-xs-4">
                      <div className="footer-brand-block footer-brand-block--l4 mb-md-0">

                        <div className="brand-logo mb-0 text-center text-md-start mx-auto mx-md-0">
                          <a href="#">

                            <img src={logo} alt="" className="footerlogo" />

                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7">
                      <div className="footer-menu text-center text-md-end">
                        <ul className="list-unstyled">
                          <li className="nav-item">

                            <Link to={"/"}>
                              <button className="actionElementButton">

                                <span className="navactionButtonText active">Home</span>
                              </button>
                            </Link>








                          </li>
                          <li className="nav-item">

                            <Link to={"/plans/"}>
                              <button className="actionElementButton">

                                <span className="navactionButtonText">Pricing</span>
                              </button>
                            </Link>


                          </li>
                          <li className="nav-item">





                            <Link to={"/contact/"}>
                              <button className="actionElementButton">

                                <span className="navactionButtonText">Contact</span>
                              </button>
                            </Link>



                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="copyright-block copyright-block--l3">
                  <div className="row  text-center align-items-center">
                    <div className="col-xs-6 text-sm-start">
                      <p className="copyright-text--l3 ">© 2023 by Foresight Hub</p>
                    </div>
                    <div className="col-xs-6 text-sm-end">
                      <ul className="footer-social-share footer-social-share--rounded">

                        <li>
                          <a href="#">
                            <img src={Facebook} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Insta} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Linkedin} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>









      </div>


    </div>
  )
}
