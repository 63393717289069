import React from "react";
import "./list.css";
import Header from "../../Header/Header";
import { useAppState } from "../../../state";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../helpers/request";
import { useEffect, useState } from "react";
import UpDownArrow from "../../../images/up-down-arrow.svg";
import Edit2 from "../../../images/edit-2.svg";
import Delete from "../../../images/circle-cross.svg";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import NoRecordFound from "../../common/NoRecordFound";
import TableLoading from "../../common/TableLoading";
import { commonService } from "../../common/common.service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import CustomPopup from "../../Popup/CustomPopup";

import SortableList, { SortableItem } from 'react-easy-sort'
import arrayMove from 'array-move'


export default function GA2CompanyQuestions({title}) {

    const { user, selectedSite } = useAppState();
    const cookies = new Cookies();
    const history = useHistory();
    const userToken = localStorage.getItem("cs_token");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [companyId, setUserID] = useState("");
    const [search, setSearch] = useState("");
    const [pageNumber, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);

    const [newQuestionPopup, setNewQuestionPopup] = useState(true);
    const [newQuestionData, setNewQuestionData] = useState({
        question: '',
        updateId: null
    });
    

    useEffect(() => {
        if (companyId) {
          getGA2CompanyQuestions();
        }
      }, [companyId, selectedSite]);
    
      useEffect(() => {
        userDetail();
      }, []);
      
      useEffect(() => {
          // set the questionPriority in each item as per their current index
          if (data.length > 0) {
              data.forEach((item, index) => {
                  item.questionPriority = index + 1;
              });

              // update priority in database
              updateGA2CompanyQuestionsOrder();
          }
      }, [data])

    function updateGA2CompanyQuestionsOrder() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/ga2/update-questions-priority`, {
                questions: data,
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
            })
            .catch((err) => {
            });
    }

    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setUserID(
                    res.data[0].added_by ? res.data[0].added_by : res.data[0].id
                    );
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const setDefaultQuestions = () => {
        setLoading(true);
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/ga2/set-default-questions`, {
                siteId: parseInt( selectedSite ),
                companyId: companyId,
                userId: user.id,
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                getGA2CompanyQuestions();
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const handleNewQuestionSubmit = () => {
        if ( newQuestionData.question === "") {
            toast.error("Please enter question");
            return;
        } else {
            // save the question...
            setLoading(true);

            axiosInstance
                .post(
                    `${process.env.REACT_APP_API_BASEURL}/ga2/add-company-questions`,
                    {
                        siteId: selectedSite,
                        companyId: companyId,
                        userId: user.id,
                        question: newQuestionData.question,
                        questionOrder: data.length,

                        updateId: ( newQuestionData.updateId ? newQuestionData.updateId : null ),
                    }
                )
                .then( (res) => {
                    setNewQuestionPopup(true);
                    getGA2CompanyQuestions();
                } )
                .catch( (err) => {
                    
                });
        }
    }

    const getGA2CompanyQuestions = () => {
        setLoading(true);
        axiosInstance
          .post(
            `${process.env.REACT_APP_API_BASEURL}/ga2/company-questions`,
            {
              companyId: companyId,
              userId: user.id,
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          .then((res) => {
            // if (res.data.length > 0) {
            setData(
              res.data.filter(
                (site, index, arr) =>
                  res.data.findIndex((item) => item.id == site.id) == index
              )
            );
            setTotal(
              res.data.filter(
                (site, index, arr) =>
                  res.data.findIndex((item) => item.id == site.id) == index
              ).length
            );
            setLoading(false);
            // }
          })
          .catch((err) => {
            setLoading(false);
          });
    };

    const onSortEnd = (oldIndex, newIndex) => {
        setData( (array) => arrayMove(array, oldIndex, newIndex) );
    }

    const handleDelete = async (id, name) => {
        const result = await Swal.fire({
          title: `Are you sure you want to delete this question`,
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });
    
        if (result.isConfirmed) {
          axiosInstance
            .delete(process.env.REACT_APP_API_BASEURL + "/ga2/question/" + id)
            .then(function (response) {
              getGA2CompanyQuestions();
              toast.success("Question has been deleted");
            })
            .catch(function (error) {
              toast.error(
                error?.response?.data?.message || "Unable to delete question"
              );
            });
        }
      };

    return user.role.includes('admin') ? (
        <div className="middle_page">
            <Header
                title={title}
                steps={[
                { name: "Home", path: "/" },
                { name: "GA2 Permit List", path: "/ga2-permits" },
                { name: "Manage Questions", path: "/ga2/manage-company-questions" },
                ]}
            />

            <CustomPopup
                trigger={!newQuestionPopup}
                setTrigger={() => {
                    setNewQuestionPopup(true)
                    setNewQuestionData({
                        question: '',
                        updateId: null
                    });
                }}
            >
                <div className="col-12 form_inner p-2 location_access">
                    <label for="new-question-title">Provide Title for the Question</label>
                    <div className="input_icons">
                        <div className="input_field">
                            <input
                                name="question"
                                id="new-question-title"
                                value={(newQuestionData.question ? newQuestionData.question : "")}
                                onChange={(e) => {
                                        setNewQuestionData({
                                            ...newQuestionData,
                                            question: e.target.value,
                                        });
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="sign_save_reset_btn">
                <button
                    className="btn btn-success"
                    onClick={() => {
                        handleNewQuestionSubmit()
                    }}
                >
                    Submit
                </button>
                </div>
            </CustomPopup>

            { ! loading && <>
                <div className="ga2-manage-questions-btn">
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setNewQuestionData({ question: '', updateId: null });
                            setNewQuestionPopup(false);
                        } }
                    >
                        + Add a Question
                    </button>

                    { data.length == 0 && <>
                        <button
                            className="btn btn-primary"
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                                setDefaultQuestions();
                            } }
                        >
                            Load Default Questions
                        </button>
                    </> }
                </div>
            </> }

            <div className="main_container">
                { ! loading ? <>
                    {data.length > 0 ? <>
                        
                        {/* Company does use, some customized questions, show list with some actions. */}

                        <div className="ga2-questions">
                            <SortableList
                                onSortEnd={onSortEnd}
                                draggedItemClassName="dragged"
                            >
                                {data.map((item, index) => (
                                    <SortableItem
                                    key={index}>
                                        <div className="item ga2-questions-item d-flex flex-spaced">
                                            <div className="ga2-questions-item-title">
                                               {item.question}
                                            </div>
                                            <div className="ga2-questions-item-actions">
                                            {<img
                                                src={Edit2}
                                                alt=""
                                                className="ms-2"
                                                onClick={() => {
                                                    setNewQuestionData({ question: item.question, updateId: item.id });
                                                    setNewQuestionPopup(false);
                                                }}
                                            />}
                                            {!item.isDeleted && (
                                                <img
                                                src={Delete}
                                                alt=""
                                                onClick={() => {
                                                    handleDelete(item.id, item.question);
                                                }}
                                                />
                                                )}
                                            </div>
                                        </div>
                                    </SortableItem>
                                )
                                )}
                            </SortableList>

                            <p><em>You can drag/drop the questions above to sort them in a desired order.</em></p>
                        </div>
                        

                    </> : <>
                        <div>You are not using custom questions for GA2. You can add questions by clicking on the button above.<br /><em>When there are no customized questions, then default questions will be used in GA2 Form</em></div>
                    </>}
                </> : <>
                    <div>Loading...</div>
                </> }
            </div>
        </div>
    ) : (
        <div className="middle_page">
            <Header
                title="Insufficient Priveleges"
                steps={[
                { name: "Home", path: "/" },
                { name: "GA2 Permit List", path: "/ga2-permits" },
                { name: "Manage Questions", path: "/ga2/manage-company-questions" },
                ]}
            />
            <div>You do not have permission to view this page.</div>
        </div>
    );
};