export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    console.log(file.type)
    if(file.type === "application/pdf"){
      resolve(require("../../images/pdfIcon.png"))
    }
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};
