import React, { useEffect } from 'react'
import Select from 'react-select'
import { useState } from 'react'
import { useAppState } from '../../state';
import { useHistory } from 'react-router-dom';
import UserIcon from '../../images/user.svg';
import NotifyNewIcon from '../../images/notification-new.svg';
import NoNotifyNewIcon from '../../images/no-notification-new.svg';
import ProfileIcon from '../../images/ProfileImg.svg';
import LogoutIcon from '../../images/logout.svg';
import CirclediIcon from '../../images/circled-i.svg';
import CheckMarkIcon from '../../images/check-mark.svg';
import Logo2 from '../../images/logo-2.svg';
import Logo from '../../images/logo.svg'
import axiosInstance from '../../helpers/request';
import { toast } from "react-toastify";

function Header({ steps = [], title }) {
    const history = useHistory();
    const { signOut, user, refreshUserDetails, selectedCompany, setCompany, selectedSite, setSite, userSites, setUserSites, companies, setCompanies } = useAppState();
    const [profile, setProfile] = useState(false)
    const [loading, setLoading] = useState(false)
    const [notification, setNotification] = useState(false)
    const [notificationList, setNotificationList] = useState([])
    const userToken = localStorage.getItem("cs_token");

    useEffect(() => {
        if (userSites.length && !localStorage.getItem(`${user.id}-currentSite`) && (user.role != "superadmin") && (user.role != 'admin' ) ) {
            localStorage.setItem(`${user.id}-currentSite`, userSites[0].id)
            setSite(userSites[0].id)
        }
    }, [userSites])

    useEffect(() => {
        if (companies.length && !localStorage.getItem(`${user.id}-currentCompany`) && (user.role == "superadmin") ) {
            localStorage.setItem(`${user.id}-currentCompany`, companies[0].id)
            setCompany(companies[0].id)
        }
    }, [companies])

    useEffect(() => {
        getUsersSites()
        getNotifications()
    }, [])
    
    const getNotifications = ()=>{
        axiosInstance
            .get(`${process.env.REACT_APP_API_BASEURL}/notifications-unread/${user.id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (Array.isArray(res.data)) {
                    setNotificationList(res.data)
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const markRead = (notificationId) => {
        setLoading(true)
        axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/mark-read/${notificationId}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                setLoading(false)
                toast.success(res.data?.message);
                getNotifications()
            })
            .catch((err) => {
                setLoading(false)
                toast.error(err?.response?.data?.message || "Unable to process this request");
                console.log("errr", err);
            });
    }

    const getUsersSites = () => {
        if (!user.role.includes('admin')) {
            axiosInstance
                .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${user.id}`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                })
                .then((res) => {
                    if (res.data && res.data.length) {
                        setUserSites(res.data)
                    }
                })
                .catch((err) => {
                    console.log("errr", err);
                });
        } else {
            if (user.role == 'superadmin') {
                axiosInstance
                    .get(`${process.env.REACT_APP_API_BASEURL}/companyadmins`)
                    .then((res) => {
                        if (res.data && res.data.length) {
                            setCompanies(res.data)
                        }
                    })
                    .catch((err) => {
                        console.log("errr", err);
                    });
            }
            axiosInstance
                .post(`${process.env.REACT_APP_API_BASEURL}/${((user.role == "superadmin")) ? "every-sites" : "all-sites"}`, {
                    companyId: user.id,
                    status: "",
                }, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                })
                .then((res) => {
                    setUserSites(res.data.filter((site, index, arr) => res.data.findIndex(item => item.id == site.id) == index));
                })
                .catch((err) => {
                    console.log("errr", err);
                });
        }
    }

    const selectStyle = {
        container: s => ({
            ...s,
            width: "250px",
            marginTop: "-15px",
            marginRight: "15px",
        }),
        control: s => ({
            ...s,
            minHeight: "50px"
        })
    }

    return (
        <>
            {(notification || profile) && <div className="overlay-popup" onClick={() => {
                setProfile(false)
                setNotification(false)
            }}></div>}
            <header className="header-left">
                <div className='d-flex w-25 ms-4 ps-3' style={{ flexGrow: 1 }}>
                    {(((userSites.length > 1) && !user.role.includes('admin')) || user.role.includes('admin')) ?
                        <>
                            {(user.role === "superadmin")
                                &&
                                <Select
                                    styles={selectStyle}
                                    options={companies.map(d => ({ label: d.companyName, value: d.id }))}
                                    value={companies.filter(v => (v.id == selectedCompany)).map(v => ({ label: v.companyName, value: v.id }))[0]}
                                    placeholder="Select Company"
                                    onChange={v => {
                                        if (v && v.value) {
                                            setCompany(v.value)
                                            localStorage.setItem(`${user.id}-currentCompany`, v.value)
                                        } else {
                                            setCompany(null)
                                            setSite(null)
                                            localStorage.removeItem(`${user.id}-currentCompany`)
                                            localStorage.removeItem(`${user.id}-currentSite`)
                                        }
                                    }}
                                />
                            }
                            <Select
                                styles={selectStyle}
                                options={userSites.filter(v => (user.role == "superadmin") ? (v.companyId == selectedCompany) : true).map(d => ({ label: d.name, value: d.id }))}
                                value={userSites.filter(v => (v.id == selectedSite)).map(v => ({ label: v.name, value: v.id }))[0]}
                                placeholder={user.role.includes('admin') ? "All Sites" : "Select Site"}
                                isClearable={user.role.includes('admin')}
                                onChange={v => {
                                    if (v && v.value) {
                                        setSite(v.value)
                                        localStorage.setItem(`${user.id}-currentSite`, v.value)
                                    } else {
                                        setSite(null)
                                        localStorage.removeItem(`${user.id}-currentSite`)
                                    }
                                }}
                            />
                        </>
                        :
                        <span className="me-3 safety_span cursor-pointer" style={{ marginTop: "-15px" }}>{userSites.length ? userSites[0].name : ""}</span>
                    }
                </div>
                <section className="header">
                    <section className="right_nav">
                        {/* <section class="notify_div"> */}
                        <img src={(notificationList.length==0)?NoNotifyNewIcon:NotifyNewIcon} className='notification mx-3 cursor-pointer' alt="" onClick={() => {
                            setProfile(false)
                            setNotification(n => !n)
                        }} />
                        {notification && <section className="notify_popup">
                            <h4>You have {notificationList.length} new notifications</h4>
                            <hr />
                            {/* <p><img src={CirclediIcon} alt="" width="15px" />Hot Works Permit Submitted</p>
                            <p><img src={CheckMarkIcon} alt="" width="15px" />Safety Checklist Action Required</p>
                            <p><img src={CheckMarkIcon} alt="" width="15px" />Safety Checklist Started</p> */}
                            {notificationList.reverse().map(n => (<p><img src={CheckMarkIcon} alt="" width="20px" />{n.notificationBody}<button onClick={() => {
                                if (!loading) {
                                    markRead(n.id)
                                }
                            }} style={{fontSize:"14px",height:30,minWidth:60}} class="btn btn-primary px-2 py-1">Mark as Read</button></p>))}
                        </section>}
                        {/* </section>
                        <section class="header-saftey"> */}
                        <img src={UserIcon} className='ms-3 cursor-pointer safty-icon' alt="" onClick={() => {
                            setProfile(p => !p)
                            setNotification(false)
                        }} />
                        <span className="me-3 safety_span cursor-pointer" style={{ color: '#005CB9' }} onClick={() => {
                            setProfile(p => !p)
                            setNotification(false)
                        }} >{user.fullName}</span>
                        {profile && <section className="safaty_popup justify-content-evenly">
                            <img src={UserIcon} alt="" width="50px" height="50px" style={{ alignSelf: "center" }} />
                            <span style={{ color: 'black' }}>{user.fullName}</span>
                            {/* <span>{user.role}</span> */}
                            <button className="profile" onClick={() => {
                                history.push("/profile")
                            }}><img src={ProfileIcon} alt="" width="14px" height="14px" />Profile</button>
                            <button className="logout" onClick={() => {
                                signOut()
                            }}><img src={LogoutIcon} alt="" width="12px" height="12px" />Logout</button>
                        </section>}
                        {/* </section> */}
                    </section>
                    <section className="second_logo ms-2">
                        <img className='obj-fit-cover' src={(user.companyLogo || user.contractorLogo) ? (process.env.REACT_APP_API_BASEURL + (user.contractorLogo ? user.contractorLogo : user.companyLogo)) : Logo} alt="" />
                    </section>
                </section>
            </header>
            <div className="submit_accident">
                <h6>{title}</h6>
                <nav style={{ "--bs-breadcrumb-divider": "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;)" }}
                    aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {steps.map((v, i, arr) => (i != (arr.length - 1)) ? (
                            <li key={i} className="breadcrumb-item"><a href="#" onClick={e => {
                                e.preventDefault()
                                if (v.path) {
                                    history.push(v.path)
                                }
                            }}>{v.name}</a></li>
                        ) : (
                            <li className="breadcrumb-item active" aria-current="page">{v.name}</li>
                        ))}
                    </ol>
                </nav>
            </div>
        </>
    )
}

export default Header