import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import "./SafetyObservations.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import CustomPopup from "../Popup/CustomPopup";

const AddSafetyObservation = ({ title }) => {
  const { id } = useParams();
  const { user, selectedCompany } = useAppState();
  const history = useHistory();
  const location = useLocation();
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [newCategoryData, setNewCategoryData] = useState( { category: '' } );
  const [newCategoryPopup, setNewCategoryPopup] = useState(true);

  const [newTypeData, setNewTypeData] = useState( { category: '' } );
  const [newTypePopup, setNewTypePopup] = useState(true);

  const fieldValues = {
    companyId: companyId,
  };



  const [fieldData, setFieldData] = useState(fieldValues);

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(
            userCompanyId
          );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          });
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const fetchSafetyObservationCategories = () => {
    setLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/categories/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setCategoryOptions(res.data.categories);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
      });
  };

  const fetchSafetyObservationTypes = () => {
    setLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/types/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setTypeOptions(res.data.types);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
      if ( companyId ) {
          fetchSafetyObservationCategories();
          fetchSafetyObservationTypes();
      }
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const handleCategoryDelete = (index) => {
    const newCategoryOptions = [...categoryOptions];
    newCategoryOptions.splice(index, 1);
    setCategoryOptions(newCategoryOptions);
  }

  const handleTypeDelete = (index) => {
    const newTypeOptions = [...typeOptions];
    newTypeOptions.splice(index, 1);
    setTypeOptions(newTypeOptions);
  }

  const handleNewCategory = () => {
    const newCategoryOptions = [...categoryOptions, newCategoryData.category];

    // sort the newCategoryOptions
    setCategoryOptions(newCategoryOptions.sort());

    // close the popup
    setNewCategoryPopup(true);
    setNewCategoryData({ category: '' });
  }

  const handleNewType = () => {
    const newTypeOptions = [...typeOptions, newTypeData.category];

    // sort the newTypeOptions
    setTypeOptions(newTypeOptions);

    // close the popup
    setNewTypePopup(true);
    setNewTypeData({ category: '' });
  }

  const handleSubmit = () => {

      const form = new FormData();

      form.append("categories", categoryOptions.join(','));
      form.append( "companyId", companyId );

      setLoading(true);

      const apiCall = axiosInstance.put(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/categories`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )

      apiCall
        .then((res) => {
          toast.success(res.data.message);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
  };

  const handleTypeSubmit = () => {

      const form = new FormData();

      form.append("types", typeOptions.join(','));
      form.append( "companyId", companyId );

      setLoading(true);

      const apiCall = axiosInstance.put(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/types`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )

      apiCall
        .then((res) => {
          toast.success(res.data.message);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
  };

  return (
    <div className="middle_page">
        <Header
            title={title}
            steps={[
              { name: "Home", path: "/" },
              { name: "Safety Observations List", path: "/safety-observations/" },
              { name: "Configure", path: `/manage/safety-observations/` },
            ]}
          />

        <CustomPopup
            trigger={!newCategoryPopup}
            setTrigger={() => {
                setNewCategoryPopup(true)
                setNewCategoryData({
                    category: ''
                });
            }}
        >
            <div className="col-12 form_inner p-2 location_access">
                <label for="new-question-title">Provide name for category</label>
                <div className="input_icons">
                    <div className="input_field">
                        <input
                            name="question"
                            id="new-question-title"
                            value={(newCategoryData.category ? newCategoryData.category : "")}
                            onChange={(e) => {
                                    setNewCategoryData({
                                        ...newCategoryData,
                                        category: e.target.value,
                                    });
                                }
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="sign_save_reset_btn">
            <button
                className="btn btn-success"
                onClick={() => {
                    handleNewCategory()
                }}
            >
                Add
            </button>
            </div>
        </CustomPopup>

        <CustomPopup
            trigger={!newTypePopup}
            setTrigger={() => {
                setNewTypePopup(true)
                setNewTypeData({
                    category: ''
                });
            }}
        >
            <div className="col-12 form_inner p-2 location_access">
                <label for="new-question-title">Provide name for type</label>
                <div className="input_icons">
                    <div className="input_field">
                        <input
                            name="question"
                            id="new-question-title"
                            value={(newTypeData.category ? newTypeData.category : "")}
                            onChange={(e) => {
                                    setNewTypeData({
                                        ...newTypeData,
                                        category: e.target.value,
                                    });
                                }
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="sign_save_reset_btn">
            <button
                className="btn btn-success"
                onClick={() => {
                    handleNewType()
                }}
            >
                Add
            </button>
            </div>
        </CustomPopup>

      <div className="main_container">
        <div className="addaccess_first_container">
          <div className="general_details">Manage Categories</div>

            <div className="list-add-btn">
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        setNewCategoryData({ category: '' });
                        setNewCategoryPopup(false);
                    } }
                >
                    + Add Category
                </button>
            </div>

          <div className="row">
              {categoryOptions.length > 0 && <>
                  <div className="manageable-list">
                      {categoryOptions.map((item, index) => (
                          <div className="manageable-list-item d-flex flex-spaced align-items-center">
                            <div className="manageable-list-item-title">{item}</div>
                            <div className="manangeable-list-item-delete" onClick={() => handleCategoryDelete(index)}>Delete</div>
                          </div>
                    ))}
                  </div>
              </> }
          </div>
        </div>

        <div className="mt-3 mb-20">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
                Save Categories
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>

        <div className="addaccess_first_container">
          <div className="general_details">Manage Types</div>

            <div className="list-add-btn">
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        setNewTypeData({ category: '' });
                        setNewTypePopup(false);
                    } }
                >
                    + Add Type
                </button>
            </div>

          <div className="row">
              {typeOptions.length > 0 && <>
                  <div className="manageable-list">
                      {typeOptions.map((item, index) => (
                          <div className="manageable-list-item d-flex flex-spaced align-items-center">
                            <div className="manageable-list-item-title">{item}</div>
                            <div className="manangeable-list-item-delete" onClick={() => handleTypeDelete(index)}>Delete</div>
                          </div>
                    ))}
                  </div>
              </> }
          </div>
        </div>

        <div className="mt-3 mb-20">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleTypeSubmit()}
            >
                Save Types
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>


      </div>
    </div>
  );
};

export default AddSafetyObservation;
