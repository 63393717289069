import React, { useEffect } from 'react'


function ChangePricePlan({ title }) {

    return (
        <div>ChangePricePlan</div>
    )


}

export default ChangePricePlan