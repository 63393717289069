import { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

// import axios from "axios";

import "./login.css";
import logo from "../../images/foresighthublogo.svg";
import illustration from "../../images/loginmainimage.svg";
import or from "../../images/or.svg";
import googleLogo from "../../images/Google_G_logo.svg";

import CustomPopup from "../Popup/CustomPopup";
// import {templatesRows} from '../../dummyData';
import { useAppState } from "../../state";
import { useGoogleLogin } from "@moeindana/google-oauth";

function Login( { customMessage} ) {
  const { signIn, user, isLoggedIn } = useAppState();

  const history = useHistory();
  // const navigate = useNavigate();
  const location = useLocation();
  const [register, setRegister] = useState(true)
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState("");
  const [messageShown, setMessageShown] = useState(false);

  const [emailAddress, setEmailAddress] = useState("");
  const [otp, setOtp] = useState("");

  const [forgotPasswordPopup, setforgotPasswordPopup] = useState(false);
  const [step, setStep] = useState(0);

  const openForgotPasswordPopup = (event) => {
    event.preventDefault();
    setforgotPasswordPopup(true);
  };

  const closeForgotPasswordPopup = () => {
    setforgotPasswordPopup(false);
    setStep(0);
    setEmailAddress("");
    setOtp("");
  };

  useEffect(() => {
    setTimeout(() => { setRegister(false) }, [5000])
  }, [])

  const verifyEmail = (email) => {
    return fetch(process.env.REACT_APP_API_BASEURL + "/verify-email", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });
  };

  const verifyOTP = (email, otp) => {
    return fetch(process.env.REACT_APP_API_BASEURL + "/verify-otp", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        otp: otp,
      }),
    });
  };

  const goToNextStep = () => {
    verifyEmail(emailAddress).then(async (response) => {
      const jsonResponse = await response.json();
      if (response.ok) {
        setStep(1);
      } else {
        if (jsonResponse?.message) {
          alert(jsonResponse.message);
        }
        throw new Error(response?.message);
      }
    });
  };

  const verifyOtp = () => {
    verifyOTP(emailAddress, otp).then(async (response) => {
      const jsonResponse = await response.json();
      console.log("jsonResponse::", jsonResponse);
      if (response.ok) {
        closeForgotPasswordPopup();
        history.push({
          pathname: "/passwordreset",
          state: { token: jsonResponse.token },
        });
      } else {
        if (jsonResponse?.message) {
          alert(jsonResponse.message);
        }
        throw new Error(response?.message);
      }
    });
  };

  const login = (event) => {
    event.preventDefault();
    signIn(username, password, rememberMe).then((loginUser) => {
      console.log(loginUser)
      console.log(!loginUser.informationAdded)
      if ((loginUser.isprimarycontact == 1) && !(loginUser.informationAdded)) {
        // history.replace({ pathname: `/required-info` });
        history.replace(location?.state?.from || { pathname: `/` });
      } else {
        history.replace(location?.state?.from || { pathname: `/` });
      }
      // navigate('/', { replace: true });
    })
    .catch(err => {
      console.log("ERROR---",err);
      // if (err.message) {
      //   alert(err.message);
      // }
    });
  };

  if (user || isLoggedIn) {
    history.replace("/");
    // navigate('/');
  }

  const Glogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      signIn(codeResponse.email, codeResponse.id).then((loginUser) => {
        if ((loginUser.isprimarycontact == 1) && !(loginUser.informationAdded)) {
          history.replace({ pathname: `/required-info` });
        } else {
          history.replace(location?.state?.from || { pathname: `/` });
        }
      });
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  
  if ( customMessage && ! messageShown ) {
    toast.error( customMessage );
    
    setMessageShown( true );
  }


  return (
    <div className="userlogin">
      <div className="userLoginContainer">
        <div className="userloginLeft">
   
        

            <div className="logoadminbottom">
              <img src={illustration} alt="" />
            </div>
     
        </div>
        <div className="userLoginRight">

          <div className="logoMobile">
            <img src={logo} alt="" />
          </div>
          <div className="brand-logo mb-0 text-center text-md-start mx-auto mx-md-0">
                      <a href="#">
                  
                        <img src={logo} alt="" className="footerlogo" />
              
                      </a>
                    </div>


          <div className="pageHeaderLogin">
            <div className="pageHeaderText">

              {(location.pathname.includes('registered')) && register ? <span className="pageHeaderDescriptionRegistered"> Thank you for completing your registration, you can now log in below.</span> : ""}

              <span className="loginTitle">Please login to continue!</span>
              <span className="pageHeaderDescription">
                {(location.pathname.includes('registered')) ? "" : ""}
              </span>
            </div>
          </div>
          <form className="loginForm">



            <div className="loginItem">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter your email address"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </div>

            <div className="loginItem">
              <label>Password</label>
              <input
                type="password"
                placeholder="xxxxxxxxxx"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />

              <div className="resetoptionsContainer">
                {/* <div className="resetoptionsLeft"><input type="checkbox"></input> Remember me</div> */}

                <div className="resetoptionsLeft">
                  <label
                    className="rememberMe  d-flex align-items-center"
                  >
                    <input type="checkbox" name="remember" onChange={(e) => setRememberMe(e.target.checked)}></input> <span>Remember me</span>
                  </label>
                </div>

                <div className="resetoptionsRight">
                  <button
                    type="button"
                    className="forgotpasswordbtn"
                    onClick={openForgotPasswordPopup}
                  >
                    Forgot Password?
                  </button>
                </div>
              </div>
              <button
                disabled={username === "" && password === ""}
                className="loginButton"
                type="submit"
                onClick={login}
              >
                Log In
              </button>
            </div>
          </form>

          {/* <div className="divider">
            <img src={or} alt="" />
          </div> */}



          {/* <div class="google-btn">
            <div class="google-icon-wrapper">
              <img class="google-icon" src={googleLogo} />
            </div>
            <p class="btn-text" onClick={() => Glogin()}>Login with Google</p>
          </div> */}
{/* 
          <div className="resetoptionsRight">
            <Link type="submit" className="needsanaccount" to={"/signup"}>
              Dont have an account? <span>Sign Up</span>
            </Link>
          </div> */}
        </div>
      </div>

      <CustomPopup
        trigger={forgotPasswordPopup}
        setTrigger={() => closeForgotPasswordPopup()}
      >
        {step === 0 ? (
          <>
            <h3 className="popupTitle">Please enter your email address</h3>
            <input
              value={emailAddress}
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
              type="email"
              className="popupText"
              placeholder="Example: John@gmail.com"
            />
            <button
              disabled={emailAddress === ""}
              className="loginButton"
              onClick={emailAddress === "" ? () => { } : goToNextStep}
            >
              Next
            </button>
          </>
        ) : step === 1 ? (
          <>
            <h3 className="popupTitle">Please check your email, we have just sent you a one time pass code</h3>
            <input
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              type="text"
              className="popupText"
              placeholder="xxxxxx"
            />
            <button
              disabled={otp === ""}
              className="loginButton"
              onClick={otp === "" ? () => { } : verifyOtp}
            >
              Verify
            </button>
          </>
        ) : (
          ""
        )}
      </CustomPopup>
    </div>
  );
}

export default Login;
