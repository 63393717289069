import axios from "axios";
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { permissions } from "../helpers/permissions";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import axiosInstance from "../helpers/request";
const cookies = new Cookies();

export const StateContext = createContext(null);

// export function getToken() {
//   return localStorage.getItem('cs_token');
// }

// export function getUser() {
//   const userData = JSON.parse(localStorage.getItem('cs_user'));
//   return userData
// }

export function login(username, password, rememberMe = false) {
  return fetch(process.env.REACT_APP_API_BASEURL + "/login", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
      rememberMe: rememberMe,
    }),
  });
}

export default function AppStateProvider(props) {
  const history = useHistory();

  const cs_user = localStorage.getItem("cs_user");
  const [user, setUser] = useState(cs_user);
  const [userSites, setUserSites] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setCompany] = useState(localStorage.getItem(`${cs_user?.id}-currentCompany`));
  const [selectedSite, setSite] = useState(localStorage.getItem(`${cs_user?.id}-currentSite`))

  const cs_permissions = localStorage.getItem("cs_permissions");
  const [userPermissions, setPermissions] = useState(cs_permissions ? JSON.parse(cs_permissions) : []);

  const cs_token = localStorage.getItem("cs_token");
  const cs_retoken = localStorage.getItem("cs_retoken");
  const [isLoggedIn, setIsLoggedIn] = useState(cs_token && cs_retoken && cs_user ? true : false);

  useEffect(() => {
    console.log("STATE-USE-EFFECT")
    const token = localStorage.getItem("cs_token");
    const user = localStorage.getItem("cs_user")
      ? JSON.parse(localStorage.getItem("cs_user"))
      : "";
    const permits = localStorage.getItem("cs_permissions")
      ? JSON.parse(localStorage.getItem("cs_permissions"))
      : "";

    if (token && user) {
      console.log("cs_permissions", permits);
      setIsLoggedIn(true);
      setUser(user);
      setPermissions(permits);
    } else {
      cookies.remove("cs_token");
      cookies.remove("cs_user");
      cookies.remove("cs_retoken");

      localStorage.removeItem("cs_token");
      localStorage.removeItem("cs_user");
      localStorage.removeItem("cs_retoken");

      setIsLoggedIn(false);
      // history.replace('/');
    }
  }, [history]);

  const getLogo = (id, userToken) => {
    return axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/company-logo/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const getContractorLogo = (id, userToken) => {
    return axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/contractor-logo/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const signIn = useCallback((username, password, rememberMe = false) => {
    return login(username, password, rememberMe).then(async (response) => {
      const jsonResponse = await response.json();
      console.log("signIn-jsonResponse", jsonResponse);

      if (response.ok && jsonResponse) {
        if (!jsonResponse.user.role.includes("admin")) {
          if (jsonResponse.user.contractorId) {
            const contractorLogoData = await getContractorLogo(jsonResponse.user.contractorId, jsonResponse.token)
            if (contractorLogoData.data.contractorLogo) {
              jsonResponse.user.contractorLogo = contractorLogoData.data?.contractorLogo
            } else {
              const logoData = await getLogo(jsonResponse.user.added_by, jsonResponse.token)
              if (logoData.data.companyLogo) {
                jsonResponse.user.companyLogo = logoData.data?.companyLogo
              }
            }
          } else {
            const logoData = await getLogo(jsonResponse.user.added_by, jsonResponse.token)
            if (logoData.data.companyLogo) {
              jsonResponse.user.companyLogo = logoData.data?.companyLogo
            }
          }
        }
        console.log('jsonResponse.user', jsonResponse.user)
        setSite(localStorage.getItem(`${jsonResponse.user.id}-currentSite`))
        setCompany(localStorage.getItem(`${jsonResponse.user.id}-currentCompany`))
        setUser(jsonResponse.user);
        setIsLoggedIn(true);

        cookies.set("cs_token", jsonResponse.token);
        cookies.set("cs_retoken", jsonResponse.refreshToken);
        cookies.set("cs_user", JSON.stringify(jsonResponse.user));

        localStorage.setItem("cs_token", jsonResponse.token);
        localStorage.setItem("cs_retoken", jsonResponse.refreshToken);
        localStorage.setItem("cs_user", JSON.stringify(jsonResponse.user));

        // console.log(
        //   "set ffff",
        //   jsonResponse.user,
        //   jsonResponse.user.isprimarycontact == 1 &&
        //   !jsonResponse.user.informationAdded
        // );

        if (jsonResponse.user.isprimarycontact == 1 && !jsonResponse.user.informationAdded) {
          console.log("Redirect to required-info");
          history.replace({ pathname: `/required-info` });
        } else {
          if (!jsonResponse.user.role.includes("admin")) {
            console.log("NOT ADMIN");
            axios.get(`${process.env.REACT_APP_API_BASEURL}/permissions/${jsonResponse.user.role}`,
              {
                headers: {
                  Authorization: `Bearer ${jsonResponse.token}`,
                },
              }
            ).then((res) => {
              console.log("response permissions", res.data);

              if (res.data.length > 0) {
                let allPermits = res.data
                  .map((v) => v.permissions)
                  .join(",")
                  .split(",");

                // console.log(permissions.map((v) => v.children).flat(), permissions);

                setPermissions(
                  permissions
                    .map((v) => v.children)
                    .flat()
                    .map((v) => [v.name, ...v.children.map((c) => c.name)])
                    .flat()
                    .filter((role) => allPermits.includes(role))
                    .filter((a) => a !== "viewAllaccidentNotifications")
                );

                cookies.set(
                  "cs_permissions",
                  JSON.stringify(
                    permissions
                      .map((v) => v.children)
                      .flat()
                      .map((v) => [v.name, ...v.children.map((c) => c.name)])
                      .flat()
                      .filter((role) => allPermits.includes(role))
                      .filter((a) => a !== "viewAllaccidentNotifications")
                  )
                );

                localStorage.setItem(
                  "cs_permissions",
                  JSON.stringify(
                    permissions
                      .map((v) => v.children)
                      .flat()
                      .map((v) => [v.name, ...v.children.map((c) => c.name)])
                      .flat()
                      .filter((role) => allPermits.includes(role))
                      .filter((a) => a !== "viewAllaccidentNotifications")
                  )
                );

                history.replace({ pathname: `/` });
              }
            })
              .catch((err) => {
                console.log("errr", err);
              });
          }
          else if (jsonResponse.user.role.toLowerCase() == "admin".toLowerCase()) {
            console.log("ADMIN");

            setPermissions(
              permissions
                .map((v) => v.children)
                .flat()
                .map((v) => [v.name, ...v.children.map((c) => c.name)])
                .flat()
                .filter((a) => a !== "viewAllaccidentNotifications")
            );

            cookies.set(
              "cs_permissions",
              JSON.stringify(
                permissions
                  .map((v) => v.children)
                  .flat()
                  .map((v) => [v.name, ...v.children.map((c) => c.name)])
                  .flat()
                  .filter((a) => a !== "viewAllaccidentNotifications")
              )
            );

            localStorage.setItem(
              "cs_permissions",
              JSON.stringify(
                permissions
                  .map((v) => v.children)
                  .flat()
                  .map((v) => [v.name, ...v.children.map((c) => c.name)])
                  .flat()
                  .filter((a) => a !== "viewAllaccidentNotifications")
              )
            );

            history.replace({ pathname: `/` });
          }
          else if (jsonResponse.user.role.toLowerCase() == "superadmin".toLowerCase()) {
            console.log("SUPER-ADMIN");

            setPermissions(
              permissions
                .map((v) => v.children)
                .flat()
                .map((v) => [v.name, ...v.children.map((c) => c.name)])
                .flat()
            );

            cookies.set(
              "cs_permissions",
              JSON.stringify(
                permissions
                  .map((v) => v.children)
                  .flat()
                  .map((v) => [v.name, ...v.children.map((c) => c.name)])
                  .flat()
              )
            );

            localStorage.setItem(
              "cs_permissions",
              JSON.stringify(
                permissions
                  .map((v) => v.children)
                  .flat()
                  .map((v) => [v.name, ...v.children.map((c) => c.name)])
                  .flat()
              )
            );

            history.replace({ pathname: `/` });
          }
        }
        return jsonResponse.user;
      } else {
        toast.error(jsonResponse?.message);
        throw new Error(jsonResponse?.message);
      }
    });
  }, []);

  const refreshUserDetails = useCallback(
    (goto) => {
      axios.post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, null, {
        headers: {
          Authorization: `Bearer ${cs_token}`,
        },
      }).then(async (res) => {
        if (res.data.length > 0) {
          if (!res.data[0].role.includes("admin")) {
            if (res.data[0].contractorId) {
              const contractorLogoData = await getContractorLogo(res.data[0].contractorId, res.data[0])
              if (contractorLogoData.data.contractorLogo) {
                res.data[0].contractorLogo = contractorLogoData.data?.contractorLogo
              } else {
                const logoData = await getLogo(res.data[0].added_by, res.data[0])
                if (logoData.data.companyLogo) {
                  res.data[0].companyLogo = logoData.data?.companyLogo
                }
              }
            } else {
              const logoData = await getLogo(res.data[0].added_by, res.data[0])
              if (logoData.data.companyLogo) {
                res.data[0].companyLogo = logoData.data?.companyLogo
              }
            }
          }
          setUser(res.data[0]);
          setSite(localStorage.getItem(`${res.data[0].id}-currentSite`))
          setCompany(localStorage.getItem(`${res.data[0].id}-currentCompany`))
          cookies.set("cs_user", JSON.stringify(res.data[0]));
          localStorage.setItem("cs_user", JSON.stringify(res.data[0]));
          if (goto) {
            history.push(goto);
          }
        }
      })
        .catch((err) => {
          // check if 401
          if (err?.response?.status === 401) {
            signOut();
          }
        });
    },
    [cs_token]
  );

  const signOut = useCallback(() => {
    setUser(null);
    setIsLoggedIn(false);
    setPermissions([]);
    setUserSites([]);
    cookies.remove("cs_token");
    cookies.remove("cs_user");
    cookies.remove("cs_retoken");
    cookies.remove("cs_permissions");

    localStorage.removeItem("cs_token");
    localStorage.removeItem("cs_user");
    localStorage.removeItem("cs_retoken");
    localStorage.removeItem("cs_permissions");

    history.replace("/");
  }, []);

  return (
    <StateContext.Provider
      value={{
        user,
        isLoggedIn,
        userPermissions,
        signIn,
        signOut,
        refreshUserDetails,
        selectedSite,
        setSite,
        userSites,
        setUserSites,
        companies,
        setCompanies,
        selectedCompany,
        setCompany
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}