import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import { commonService } from "../common/common.service";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import { useHistory } from "react-router-dom";
import fileIcon from "../../images/openfolderwhite.svg";
import { getBase64 } from "../common/imageHelper";
import Swal from "sweetalert2";
import Delete from "../../images/circle-cross.svg";
import { saveAs } from 'file-saver';

import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Link,
  pdf
} from "@react-pdf/renderer";

export default function ViewInspection({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, user, userPermissions, companyId, selectedCompany } = useAppState();
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    overallScore: 0,
    closedIssues: null,
    openIssues: null,
    carryOverIssues: null,
  });
  const [scoreData, setScoreData] = useState({
    overallScore: 0,
  });
  const [siteUsers, setSiteUsers] = useState([]);
  const [newIssuePopup, setPopup] = useState(true);
  const [closeIssuePopup, setClosePopup] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [housekeepingShown, setHouseKeepingShown] = useState(false);
  const [housekeepingPopup, setHousekeepingPopup] = useState("");

  const [reassignPopup, setReassignPopup] = useState(true);
  const [reassignData, setReassignData] = useState({});
  const [hasHousekeeping, setHasHouseKeeping] = useState(false);

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    approvedComment: "",
    deniedComment: "",
    signature: null,
    actionTaken: false,
    actionStatus: false,
    actionFiles: false,
    closedIssues: null,
    openIssues: null,
    carryOverIssues: null,
  });

  const [newIssueData, setNewIssueData] = useState({
    departmentId: null,
    siteId: null,
    companyId: null,
    hazard: "",
    ehs: "",
    riskFactor: "",
    response: "",
    assignedTo: "",
    dateToCompleteBy: "",
    status: "",
    comment: "",
    files: [],
  });

  const [closeIssueData, setCloseIssueData] = useState({
    issueId: 0,
    closedBy: "",
    dateClosed: "",
    ehs: "",
    closureDetails: "",
    files: [],
  });

  const reassignStatuses = [
    { value: "Counter charge", label: "Counter charge", name: "actionStatus" },
    { value: "Open", label: "Open", name: "actionStatus" },
    { value: "Reassign", label: "Reassign", name: "actionStatus" },
    { value: "In Progress", label: "In Progress", name: "actionStatus" },
  ];

  useEffect(() => {
    if ( ! housekeepingShown && newIssueData.ehs == 'Housekeeping' ) {
      setHouseKeepingShown(true);
      setHousekeepingPopup(true);
    } else if ( housekeepingShown && ( newIssueData.ehs != 'Housekeeping' ) ) {
      setHouseKeepingShown(false);
    }
  }, [newIssueData]);

  const [files, setFiles] = useState([]);

  const fetchInspectionDetails = (calculateScores) => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/inspections/inspection/details`,
        {
          inspectionId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data[0]);
        setLoading(false);
        getSiteUsers(res.data[0].siteId);
        getUserEmail(res.data[0].addedByInCompany);

        setNewIssueData({
          ...newIssueData,
          siteId: res.data[0].siteId,
          departmentId: res.data[0].departmentId,
          companyId: res.data[0].companyId,

          hazard: "",
          ehs: "",
          riskFactor: "",
          response: "",
          assignedTo: "",
          dateToCompleteBy: "",
          status: "",
          comment: "",
          files: [],
        });

        setFiles([]); // Reset Files.
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }

        // console.log("errr", err);
      });
  };

  const getRiskFactorScoring = (riskFactor) => {
    return {
      "Good Practice": 5,
      "Low Risk/ Needs Improvement": 4,
      "Poor Practice": 3,
      "Breach of Company Procedure": 0,
      "Breach Of Regulation": 0,
      "Foreseeable Risk Of Injury": 0,
      "Near Miss": 0,
      "Dangerous Occurrences": 0,
      "ISO 45001": 0,
    };
  };

  const recalculateScores = () => {
    let overallScore = 100;
    let totalNeagatives = 0;
    let totalPositives = 0;
    let totalIssues = 0;
    let possibleScore = 0;
    let housekeepingFound = 0;

    overallScore = 0;

    const riskFactorScore = getRiskFactorScoring();

    // loop through open issues
    if (data?.openIssues?.length) {
      totalIssues += data.openIssues.length;
      data.openIssues.forEach((issue) => {

        if ( issue.ehs == 'Housekeeping' ) {
          housekeepingFound = true;
        }

        if (typeof riskFactorScore[issue.riskFactor] !== "undefined") {
          overallScore += riskFactorScore[issue.riskFactor];

          if (riskFactorScore[issue.riskFactor] < 0) {
            totalNeagatives += 1;
          } else {
            totalPositives += 1;
          }
        }
      });
    }

    if (data?.closedIssues?.length) {
      totalIssues += data.closedIssues.length;
      data.closedIssues.forEach((issue) => {

        if ( issue.ehs == 'Housekeeping' ) {
          housekeepingFound = true;
        }

        if (typeof riskFactorScore[issue.riskFactor] !== "undefined") {
          overallScore += riskFactorScore[issue.riskFactor];

          if (riskFactorScore[issue.riskFactor] < 0) {
            totalNeagatives += 1;
          } else {
            totalPositives += 1;
          }
        }
      });
    }

    if (data?.carryOverIssues?.length) {
      totalIssues += data.carryOverIssues.length;
      data.carryOverIssues.forEach((issue) => {

        if ( issue.ehs == 'Housekeeping' ) {
          housekeepingFound = true;
        }

        if (typeof riskFactorScore[issue.riskFactor] !== "undefined") {
          overallScore += riskFactorScore[issue.riskFactor];

          if (riskFactorScore[issue.riskFactor] < 0) {
            totalNeagatives += 1;
          } else {
            totalPositives += 1;
          }
        }
      });
    }

    if ( housekeepingFound ) {
      setHasHouseKeeping(true);
    }

    possibleScore = totalIssues * 5;

    if ( possibleScore == 0 ) {
      return; // Do nothing
    }

    overallScore = ( overallScore / possibleScore ) * 100;
    
    overallScore = overallScore.toFixed(2).replace(".00", "");

    if (overallScore > 100) {
      overallScore = 100;
    }

    if (overallScore != scoreData.overallScore) {
      setScoreData({ ...scoreData, overallScore: overallScore });

      // update the score in database.
      axiosInstance
        .post(
          process.env.REACT_APP_API_BASEURL + "/inspection/update-score/",
          {
            inspectionId: id,
            overallScore: overallScore,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then(function (response) {})
        .catch(function (error) {});
    }
  };

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true);
    try {
      const siteUsersResponse = await axiosInstance.post(
        `/site-users/${siteId}`
      );
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false);
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchInspectionDetails();
    }
  }, [id]);

  useEffect(() => {
    recalculateScores();
  }, [data]);

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file);
        });
      }
    });
  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const userCanSeeIssue = (issue) => {
    if (
      user.role == "admin" ||
      user.id == data.addedByInCompany ||
      userPermissions.includes("submitsafetyOfficerInspections") ||
      issue.assignedTo == user.id
    ) {
      return true;
    } else {
      return issue.createdBy == user.id;
    }
  };

  const handleComplete = async (name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to complete ${name}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, complete it!",
    });

    if (result.isConfirmed) {
      const blob = await pdf((
          <MyDoc />
        )).toBlob();
        saveAs(blob, "inspection-" + data.id + ".pdf");
      
      const form = new FormData();
      form.append( 'pdf', blob, 'inspection-' + data.id + '.pdf' );

      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("safetyOfficerInspections") : v.permits.includes("safetyOfficerInspectionsreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref);

      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));

      axiosInstance
        .post(process.env.REACT_APP_API_BASEURL + "/complete-inspection/" + id, form, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(function (response) {
          toast.success("Safety Officer Inspection completed successfully");
          setData({ ...data, isCompleted: 1 });
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to complete Safety Officer Inspection"
          );
        });
    }
  };

  const handleSubmit = async () => {
    try {
      // let signature
      // if(!fieldData.denied){
      //   signature = await handleSaveVerifySign()
      // }
      handleSubmitAfterSave();
    } catch (error) {
      toast.error("Failed to save signature");
    }
  };

  const handleNewIssueSubmit = async () => {
    try {
      if (
        newIssueData.hazard !== "" &&
        newIssueData.ehs !== "" &&
        newIssueData.riskFactor !== "" &&
        newIssueData.response !== "" &&
        newIssueData.assignedTo !== "" &&
        newIssueData.dateToCompleteBy !== "" &&
        newIssueData.status !== ""
      ) {
        const form = new FormData();

        const mailUpdates = siteUsers
          .filter((v) => v.type == "admin")
          .map((v) => ({
            email: v.email,
            userid: v.userid,
            fullName: v.fullName,
            emailPref: v.emailPreference
              ? v.emailPreference.includes("accidentNotification")
              : v.permits.includes("accidentNotificationreceiveEmails"),
            isAdminWithNoPref: !v.emailPreference && v.role.includes("admin"),
          }))
          .filter((v) => v.emailPref || v.isAdminWithNoPref);

        form.append("inspectionId", id);

        form.append(
          "mailUpdates",
          mailUpdates.map((v) => `${v.email}--${v.fullName}`).join(",")
        );
        form.append(
          "notiticationUpdates",
          siteUsers
            .filter((v) => v.type == "admin")
            .filter((v) => v.userid)
            .map((v) => v.userid)
            .join(",")
        );

        form.append(
          "departmentId",
          newIssueData.departmentId == null ? null : newIssueData.departmentId
        );
        form.append(
          "siteId",
          newIssueData.siteId == null ? null : newIssueData.siteId
        );
        form.append(
          "companyId",
          newIssueData.companyId == null ? null : newIssueData.companyId
        );

        form.append("hazard", newIssueData.hazard);
        form.append("ehs", newIssueData.ehs);
        form.append("riskFactor", newIssueData.riskFactor);
        form.append("response", newIssueData.response);
        form.append("dateToCompleteBy", newIssueData.dateToCompleteBy);
        form.append("status", newIssueData.status);

        form.append("assignedTo", newIssueData.assignedTo);
        siteUsers.filter((d) => {
          if (d.id == newIssueData.assignedTo) {
            form.append("assignToName", d.fullName);
            form.append("assignToEmail", d.email);
          }
        });

        form.append("comment", newIssueData.comment);

        Object.entries(newIssueData?.files)?.map(([key, value]) => {
          form.append(`files${[key]}`, value);
        });

        setLoading(true);
        const apiCall = axiosInstance.post(
          `${process.env.REACT_APP_API_BASEURL}/inspection-issue/add`,
          form,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        apiCall
          .then((res) => {
            toast.success(res.data.message);
            setLoading(false);
            setPopup(true);
            fetchInspectionDetails(true);
          })
          .catch((err) => {
            setPopup(false);
            toast.error(
              err?.response?.data?.message || "Unable to process this request"
            );
            setLoading(false);
          });
      } else {
        toast.error("Please fill in all required fields.");
      }
    } catch (error) {
      toast.error("Failed to create issue");
    }
  };

  const handleCloseIssueSubmit = async () => {
    try {
      if (
        closeIssueData.closedBy !== "" &&
        closeIssueData.dateClosed !== "" &&
        closeIssueData.closureDetails !== ""
      ) {
        const form = new FormData();

        const mailUpdates = siteUsers
          .filter((v) => v.type == "admin")
          .map((v) => ({
            email: v.email,
            userid: v.userid,
            fullName: v.fullName,
            emailPref: v.emailPreference
              ? v.emailPreference.includes("accidentNotification")
              : v.permits.includes("accidentNotificationreceiveEmails"),
            isAdminWithNoPref: !v.emailPreference && v.role.includes("admin"),
          }))
          .filter((v) => v.emailPref || v.isAdminWithNoPref);

        form.append("inspectionId", id);
        form.append("issueId", closeIssueData.issueId);

        form.append(
          "mailUpdates",
          mailUpdates.map((v) => `${v.email}--${v.fullName}`).join(",")
        );
        form.append(
          "notiticationUpdates",
          siteUsers
            .filter((v) => v.type == "admin")
            .filter((v) => v.userid)
            .map((v) => v.userid)
            .join(",")
        );

        form.append(
          "departmentId",
          newIssueData.departmentId == null ? null : newIssueData.departmentId
        );
        form.append(
          "siteId",
          newIssueData.siteId == null ? null : newIssueData.siteId
        );
        form.append(
          "companyId",
          newIssueData.companyId == null ? null : newIssueData.companyId
        );

        form.append("closedBy", closeIssueData.closedBy);
        form.append("dateClosed", closeIssueData.dateClosed);
        form.append("closureDetails", closeIssueData.closureDetails);

        Object.entries(closeIssueData?.files)?.map(([key, value]) => {
          form.append(`files${[key]}`, value);
        });

        setLoading(true);

        const apiCall = axiosInstance.post(
          `${process.env.REACT_APP_API_BASEURL}/inspection-issue/close`,
          form,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        apiCall
          .then((res) => {
            toast.success(res.data.message);
            setLoading(false);
            setClosePopup(true);
            setCloseIssueData({
              issueId: 0,
              closedBy: "",
              dateClosed: "",
              ehs: "",
              closureDetails: "",
              files: [],
            });
            fetchInspectionDetails();
          })
          .catch((err) => {
            setClosePopup(false);
            toast.error(
              err?.response?.data?.message || "Unable to process this request"
            );
            setLoading(false);
          });
      } else {
        toast.error("Please fill in all required fields.");
      }
    } catch (error) {
      toast.error("Failed to close issue");
    }
  };

  const handleFileChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "newfiles") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map((file) => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setNewIssueData({
        ...newIssueData,
        files: modifiedFiles.filesList,
      });
    } else if (name == "newfilesCloseIssue") {
      Promise.all(Array.from(e.target.files).map((file) => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setCloseIssueData({
        ...closeIssueData,
        files: files,
      });
    }
  };

  const handleNewIssueChange = (e) => {
    const { name, value, files } = e.target || e;
    setNewIssueData({
      ...newIssueData,
      [name]: value,
    });
  };

  const handleCloseIssueChange = (e) => {
    const { name, value, files } = e.target || e;
    setCloseIssueData({
      ...closeIssueData,
      [name]: value,
    });
  };

  const toggleIssue = (issue_id) => {
    // toggle class on id: issue_id
    let element = document.getElementById("inspection-" + issue_id);
    if (element.classList.contains("active")) {
      element.classList.remove("active");
    } else {
      element.classList.add("active");
    }
  };

  const handleIssueDelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${name}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/inspection-issue/" + id)
        .then(function (response) {
          fetchInspectionDetails();
          toast.success("Safety Officer Inspection Issue deleted successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to delete Safety Officer Inspection"
          );
        });
    }
  };

  const handleSubmitAfterSave = () => {
    const form = new FormData();
    form.append("deniedComment", fieldData.deniedComment);
    form.append("approvedComment", fieldData.approvedComment);
    if (fieldData.approved) {
      form.append("approved", fieldData.approved);
    }
    if (fieldData.completed) {
      form.append("completed", fieldData.completed);
    }

    if (fieldData.actionTaken) {
      form.append("actionTaken", fieldData.actionTaken);
      form.append("actionStatus", fieldData.actionStatus);
      form.append("actionComment", fieldData.actionComment);
      form.append("actionRootCause", fieldData.actionRootCause);

      Object.entries(fieldData?.actionFiles)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
    }

    // form.append("signature", signature);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);
    form.append("id", id);
    setLoading(true);
    axiosInstance
      .post(
        fieldData.resubmitted || fieldData.completed
          ? `${process.env.REACT_APP_API_BASEURL}/resubmit/safety-observation`
          : fieldData.actionTaken
          ? `${process.env.REACT_APP_API_BASEURL}/safety-observation/action-taken`
          : `${process.env.REACT_APP_API_BASEURL}/update/safety-observation`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setFieldData({
          deniedComment: "",
          approvedComment: "",
          approved: false,
          resubmitted: false,
          completed: false,
          denied: false,
          signature: null,
          actionComment: "",
          actionTaken: false,
          actionStatus: "",
          actionRootCause: "",
        });

        setPopup(true);

        fetchInspectionDetails();
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  };

  const issueScore = (issue, type) => {
    let score = 0;
    if (type == "carryover") {
      if (issue.riskFactor == "Good Practice") {
        score = 1;
      } else {
        score = -3;
      }
    } else {
      const riskFactorScore = getRiskFactorScoring();

      if (typeof riskFactorScore[issue.riskFactor] !== "undefined") {
        score = riskFactorScore[issue.riskFactor];
      }
    }

    return <></>;
    // return <strong className={score < 1 ? 'red-text' : 'green-text'}>{score > 0 ? '+' : ''}{score}</strong>
  };

  const printIssues = (issues, type) => {
    return (
      <div className="inspection-issues">
        <div className="inspection-issue-row inspection-issue-row-header">
          <div className="inspection-issue-number">#</div>
          <div className="inspection-issue-hazard">Hazard</div>
          <div className="inspection-issue-resp-required">Response Req'd</div>
          <div className="inspection-issue-risk-factor">Risk Factor</div>
          <div className="inspection-issue-actions">Actions</div>
        </div>

        {issues.map(
          (issue) =>
            userCanSeeIssue(issue) && (
              <div
                className="inspection-issue-item"
                key={issue.id}
                id={"inspection-" + issue.id}
              >
                <div className="inspection-issue-row">
                  <div className="inspection-issue-number">
                    {issue.id}{" "}
                    <a
                      href="#"
                      className="inspection-issue-toggle"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleIssue(issue.id);
                      }}
                    >
                      <span></span>
                    </a>
                  </div>
                  <div className="inspection-issue-hazard">{issue.hazard}</div>
                  <div className="inspection-issue-resp-required">
                    <span
                      className={
                        "inspection-tag inspection-tag-" +
                        issue.response.toLowerCase().replace(" ", "-")
                      }
                    >
                      {issue.response ? issue.response : "-"}
                    </span>
                  </div>
                  <div className="inspection-issue-risk-factor">
                    {issue.riskFactor ? issue.riskFactor : "-"}{" "}
                    {issueScore(issue, type)}
                  </div>
                  <div className="inspection-issue-actions">
                    {issue.status != "Closed" && userCanSeeIssue(issue) && (
                      <>
                        <button
                          className="btn btn-danger btn-sm"
                          style={{ height: "35px", marginRight: "5px", color: "#fff" }}
                          onClick={() => {
                            setCloseIssueData({
                              ...closeIssueData,
                              issueId: issue.id,
                              ehs: issue.ehs,
                            });
                            setClosePopup(false);
                          }}
                        >
                          Take Action
                        </button>
                      </>
                    )}

                    {(user.role.includes("admin") ||
                      userPermissions.includes(
                        "submitsafetyOfficerInspections"
                      )) && (
                      <>
                        <img
                          src={Delete}
                          alt=""
                          onClick={() => {
                            handleIssueDelete(issue.id, "this issue");
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="inspection-issue-details">
                  <div className="d-flex flex-wrap inspection-issue-details-basic">
                    <div className="col-md-4 inspection-issue-details-item">
                      <strong>Action By:</strong>
                      <span>{issue.assignedToName}</span>
                    </div>

                    <div className="col-md-4 inspection-issue-details-item">
                      <strong>EHS:</strong>
                      <span>{issue.ehs}</span>
                    </div>

                    <div className="col-md-4 inspection-issue-details-item">
                      <strong>Close Out By:</strong>
                      <span>
                        {issue.dateToCompleteBy
                          ? commonService.formatDate(
                              issue.dateToCompleteBy,
                              "Do MMM YYYY"
                            )
                          : ""}
                      </span>
                    </div>

                    {issue.comment && (
                      <div className="col-md-12 inspection-issue-details-item">
                        <strong>Comment:</strong>
                        <span>{issue.comment}</span>
                      </div>
                    )}

                    {issue.dateClosed && (
                      <div className="col-md-12 inspection-issue-details-item">
                        <strong>Date Closed:</strong>
                        <span>
                          {issue.dateClosed
                            ? commonService.formatDate(
                                issue.dateClosed,
                                "Do MMM YYYY"
                              )
                            : ""}
                        </span>
                      </div>
                    )}

                    {issue.closureDetails && (
                      <div className="col-md-12 inspection-issue-details-item">
                        <strong>Closure Details:</strong>
                        <span>{issue.closureDetails}</span>
                      </div>
                    )}

                    {(user.role.includes("admin") ||
                        userPermissions.includes(
                          "submitsafetyOfficerInspections"
                        )) && issue && ["Open", "Closed"].includes(issue.status) && (
                          <>
                            <div className="col-md-12 inspection-issue-details-item">
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  setReassignData({
                                    ...reassignData,
                                    id: issue.id
                                  });
                                  setReassignPopup(false);
                                }}
                              >
                                Reassign
                              </button>
                            </div>
                          </>
                        )}

                    <div className="d-flex inspection-issue-details-files">
                      <div className="col-md-6">
                        <strong>Images added when opened</strong>
                        <div className="inspection-issue-details-files-grid">
                          <div className="d-flex">
                            {issue.files &&
                              issue.files.split(",").map((imgPreviewUrl) => (
                                <div
                                  id="profile_pic_1_preview"
                                  className="image-fixed m-2"
                                >
                                  {commonService.outputImageTile(imgPreviewUrl)}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <strong>Images added when closed</strong>
                        <div className="inspection-issue-details-files-grid">
                          <div className="d-flex">
                            {issue.closureFiles &&
                              issue.closureFiles
                                .split(",")
                                .map((imgPreviewUrl) => (
                                  <div
                                    id="profile_pic_1_preview"
                                    className="image-fixed m-2"
                                  >
                                    {commonService.outputImageTile(
                                      imgPreviewUrl
                                    )}
                                  </div>
                                ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
    );
  };

  const pdfStyles = StyleSheet.create({
    body: {
      padding: "20px 16px 30px",
      fontSize: "13px",
      fontFamily: "Helvetica",
      color: "#000",
    },
    logos: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },
    halfWidthRightAlign: {
      width: "50%",
      textAlign: "right",
    },
    halfWidthLeftAlign: {
      width: "50%",
      textAlign: "left",
    },
    detailsWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      fontSize: "10px",
      border: "1px solid #ddd",
      borderBottom: "none",
    },
    detailsCell: {
      width: "14.285%",
      flexBasis: "14.285%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    issueWrapper: {
      marginBottom: "16px",
      marginTop: "10px",
    },
    issueDetails: {
      fontSize: "10px",
    },
    riskFactor: {
      width: "50%",
      padding: "3px 5px",
      fontSize: "10px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
    },
  });

  const issueRiskColor = (risk) => {
    if (risk === "Urgent") {
      return "#e13a45";
    } else if (risk === "Immediate") {
      return "#f18f27";
    } else if (risk === "ASAP") {
      return "#2860fa";
    } else if (risk === "On-Going") {
      return "#16e6eb";
    } else if (risk === "Good Practice") {
      return "#198754";
    } else {
      return "#333";
    }
  };

  const MyDoc = () => {
    return (
      <Document>
        <Page style={pdfStyles.body}>
          <View style={pdfStyles.logos}>
            <View style={pdfStyles.halfWidthLeftAlign}>
              <Image
                src={{
                  uri:
                    process.env.REACT_APP_API_BASEURL +
                    "/uploads/foresight-logo.png",
                }}
                style={{ width: "100px" }}
              />
            </View>

            <View style={pdfStyles.halfWidthRightAlign}>
              <Image
                src={{
                  uri:
                    process.env.REACT_APP_API_BASEURL +
                    "/pdf-image?skipHeightResize=1&path=" +
                    (user.companyLogo || user.contractorLogo
                      ? user.contractorLogo
                        ? user.contractorLogo
                        : user.companyLogo
                      : "/uploads/foresight-logo.png"),
                }}
                style={{ marginLeft: "auto", marginRight: "0", width: "100px" }}
              />
            </View>
          </View>

          <View>
            <Text
              style={{
                fontSize: "20px",
                fontFamily: "Helvetica-Bold",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Safety Officer Inspection #{data.id}
            </Text>
          </View>

          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              Inspection Details
            </Text>
          </View>

          <View style={{ textAlign: "center", marginBottom: "20px" }}>
            <Text
              style={{
                fontSize: "12px",
                fontFamily: "Helvetica",
                textAlign: "center",
                color: '#fff',
                padding: "5px 10px",
                borderRadius: "14px",
                backgroundColor: ( scoreData.overallScore >= 85 ? '#198754' : ( scoreData.overallScore >= 67 ? '#FF861C' : '#b62c38' ) ),
              }}
            >
              Overall Score: {scoreData.overallScore}%
            </Text>
          </View>

          <View style={pdfStyles.detailsWrapper}>
            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>Submitted By: </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.submittedBy}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>Site: </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.siteName}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>Department: </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.departmentName}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>Safety Rep: </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.safetyRep}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>
                  Number of staff on site:{" "}
                </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.staffCount}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>
                  Number of sub conractors on site:{" "}
                </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.subContractorsCount}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>
                  Accidents on site since last audit:{" "}
                </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.accidentsSinceLastAudit}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>
                  First Aider on site?:{" "}
                </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.firstAider == 1 ? "Yes" : "No"}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>
                  First aid facilities available?:{" "}
                </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.firstAidFacilities == 1 ? "Yes" : "No"}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>
                  Weather Conditions:{" "}
                </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.weatherCondition}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>
                  Date of last audit:{" "}
                </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.lastAuditDate
                    ? commonService.formatDate(
                        data.lastAuditDate,
                        "Do MMM YYYY"
                      ) == "Invalid date"
                      ? data.lastAuditDate
                      : commonService.formatDate(
                          data.lastAuditDate,
                          "Do MMM YYYY"
                        )
                    : "-"}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>Date of Audit: </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.createdAt
                    ? commonService.formatDate(data.createdAt, "Do MMM YYYY") ==
                      "Invalid date"
                      ? data.createdAt
                      : commonService.formatDate(data.createdAt, "Do MMM YYYY")
                    : "-"}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>
                  Fire points located on site?:{" "}
                </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.firePoints == 1 ? "Yes" : "No"}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>
                  Ope's & Voids protected?:{" "}
                </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.voidsProtected == 1 ? "Yes" : "No"}
                </Text>
              </Text>
            </View>
          </View>

          <View style={{ marginTop: "35px" }}>
            <View>
              <Text
                style={{
                  fontSize: "18px",
                  fontFamily: "Helvetica-Bold",
                  textAlign: "left",
                  marginBottom: "10px",
                }}
              >
                Response Definitions
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                backgroundColor: "#f3f3f3",
                border: "1px solid #ddd",
                fontFamily: "Helvetica",
                fontSize: "12px",
              }}
            >
              <View style={pdfStyles.riskFactor}>
                <Text
                  style={{
                    backgroundColor: issueRiskColor("Urgent"),
                    color: "#fff",
                    padding: "2px 4px",
                    fontFamily: "Helvetica",
                    borderRadius: "5px",
                    fontSize: "10px",
                  }}
                >
                  Urgent
                </Text>
                <Text
                  style={{
                    marginLeft: "5px",
                    width: "73%",
                    fontFamily: "Helvetica",
                    fontSize: "10px",
                  }}
                >
                  NOW! To be completed within 2-3 hours.
                </Text>
              </View>

              <View style={pdfStyles.riskFactor}>
                <Text
                  style={{
                    backgroundColor: issueRiskColor("Immediate"),
                    color: "#fff",
                    padding: "2px 4px",
                    fontFamily: "Helvetica",
                    borderRadius: "5px",
                    fontSize: "10px",
                  }}
                >
                  Immediate
                </Text>
                <Text
                  style={{
                    marginLeft: "5px",
                    width: "73%",
                    fontFamily: "Helvetica",
                    fontSize: "10px",
                  }}
                >
                  By end of day, or not more than 24 hours.
                </Text>
              </View>

              <View style={pdfStyles.riskFactor}>
                <Text
                  style={{
                    backgroundColor: issueRiskColor("ASAP"),
                    color: "#fff",
                    fontFamily: "Helvetica",
                    padding: "2px 4px",
                    borderRadius: "5px",
                    fontSize: "10px",
                  }}
                >
                  ASAP
                </Text>
                <Text
                  style={{
                    marginLeft: "5px",
                    fontFamily: "Helvetica",
                    width: "73%",
                  }}
                >
                  As agreed with Site Manager, but not more than 72 hours.
                </Text>
              </View>

              <View style={pdfStyles.riskFactor}>
                <Text
                  style={{
                    backgroundColor: issueRiskColor("On-Going"),
                    fontFamily: "Helvetica",
                    color: "#fff",
                    padding: "2px 4px",
                    borderRadius: "5px",
                  }}
                >
                  ASAP
                </Text>
                <Text
                  style={{
                    marginLeft: "5px",
                    fontFamily: "Helvetica",
                    width: "73%",
                  }}
                >
                  To highlight potentially recurring minor issues.
                </Text>
              </View>

              <View style={{ ...pdfStyles.riskFactor, width: "100%" }}>
                <Text
                  style={{
                    backgroundColor: issueRiskColor("NCR"),
                    fontFamily: "Helvetica",
                    color: "#fff",
                    padding: "2px 4px",
                    borderRadius: "5px",
                  }}
                >
                  NCR
                </Text>
                <Text
                  style={{
                    marginLeft: "5px",
                    fontFamily: "Helvetica",
                    width: "86.5%",
                  }}
                >
                  Serious safety issues or issues not completed within agreed
                  timescaleto have corrective action form issued.
                </Text>
              </View>
            </View>
          </View>

          <View style={{ marginTop: "40px" }}>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Helvetica",
                textAlign: "center",
              }}
            >
              (See Next Page)
            </Text>
          </View>
        </Page>

        <Page style={pdfStyles.body}>
          <View
            style={{
              fontSize: "16px",
              fontFamily: "Helvetica-Bold",
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            <Text style={{ fontFamily: "Helvetica" }}>Closed Issues</Text>
          </View>

          {pdfIssues(data.closedIssues)}
        </Page>

        <Page style={pdfStyles.body}>
          <View
            style={{
              fontSize: "16px",
              fontFamily: "Helvetica-Bold",
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            <Text style={{ fontFamily: "Helvetica" }}>Carry Over Issues</Text>
          </View>

          {pdfIssues(data.carryOverIssues)}
        </Page>

        <Page style={pdfStyles.body}>
          <View
            style={{
              fontSize: "16px",
              fontFamily: "Helvetica-Bold",
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            <Text style={{ fontFamily: "Helvetica" }}>Current Issues</Text>
          </View>

          {pdfIssues(data.openIssues)}
        </Page>
      </Document>
    );
  };

  const pdfIssues = (issues) => {
    if (issues && issues.length > 0) {
      return (
        <View>
          {issues.map((issue, index) => (
            <View style={pdfStyles.issueWrapper} key={index}>
              <View wrap={false}>
                <View style={{ fontFamily: "Helvetica-Bold" }}>
                  <Text
                    style={{
                      fontSize: "12px",
                      fontFamily: "Helvetica-Bold",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                  >
                    Issue / Hazard #{issue.id}:
                  </Text>
                </View>
                <View style={{ fontSize: "12px", fontFamily: "Helvetica" }}>
                  <Text>{issue.hazard}</Text>
                </View>

                <View style={{ margin: "10px 0", fontSize: "10px" }}>
                  <View
                    style={{
                      fontFamily: "Helvetica-Bold",
                      marginBottom: "10px",
                    }}
                  >
                    <Text>Comment:</Text>
                  </View>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    {issue.comment ? issue.comment : " - "}
                  </Text>
                </View>
              </View>

              <View style={{ margin: "10px 0", fontSize: "10px" }}>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  Images added when issue opened
                </Text>
                <View
                  style={{
                    display: "flex",
                    fontFamily: "Helvetica",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {issue.files &&
                    issue.files.split(",").map((imgPreviewUrl) => (
                      <View
                        style={{
                          margin: "5px",
                          width: "100px",
                          border: "1px solid #ddd",
                        }}
                      >
                        <Link
                          src={
                            process.env.REACT_APP_API_BASEURL + imgPreviewUrl
                          }
                        >
                          <Image
                            src={{
                              uri:
                                process.env.REACT_APP_API_BASEURL +
                                "/pdf-image?path=" +
                                imgPreviewUrl,
                            }}
                          />
                        </Link>
                      </View>
                    ))}
                  {!issue.files && (
                    <>
                      <Text style={{ fontFamily: "Helvetica" }}>
                        No images added
                      </Text>
                    </>
                  )}
                </View>
              </View>

              {/* Print Response, Action, Clouse Out Date, ensure wrap is false on the wrapper */}
              <View
                wrap={false}
                style={{
                  margin: "10px 0",
                  fontSize: "10px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  backgroundColor: "#f3f3f3",
                  border: "1px solid #ddd",
                }}
              >
                <View
                  style={{
                    width: "50%",
                    padding: "8px",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  <View
                    style={{
                      dispay: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ fontFamily: "Helvetica" }}>
                      Response Req'd:{" "}
                    </Text>
                    <Text
                      style={{
                        backgroundColor: issueRiskColor(issue.response),
                        color: "#fff",
                        fontFamily: "Helvetica",
                        padding: "2px 4px",
                        borderRadius: "5px",
                        fontSize: "10px",
                      }}
                    >
                      {issue.response}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: "50%",
                    padding: "8px",
                    borderRight: "1px solid #ddd",
                  }}
                >
                  <Text>
                    <Text style={{ fontFamily: "Helvetica" }}>Risk Factor: </Text>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>
                      {issue.riskFactor}
                    </Text>
                  </Text>
                </View>

              </View>

              <view 
                wrap={false}
                style={{
                  margin: "10px 0",
                  fontSize: "10px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  backgroundColor: "#f3f3f3",
                  border: "1px solid #ddd",
                }}>
                  <View
                    style={{
                      width: "50%",
                      padding: "8px",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    <Text>
                      <Text style={{ fontFamily: "Helvetica" }}>Action By: </Text>
                      <Text style={{ fontFamily: "Helvetica-Bold" }}>
                        {issue.assignedToName}
                      </Text>
                    </Text>
                  </View>

                  <View style={{ width: "50%", padding: "8px" }}>
                    <Text style={{ fontFamily: "Helvetica" }}>
                      <Text style={{ fontFamily: "Helvetica" }}>
                        Close Out By:{" "}
                      </Text>
                      <Text style={{ fontFamily: "Helvetica-Bold" }}>
                        {issue.dateToCompleteBy
                          ? commonService.formatDate(
                              issue.dateToCompleteBy,
                              "Do MMM YYYY"
                            )
                          : ""}
                      </Text>
                    </Text>
                  </View>
              </view>

              {issue.status == "Closed" && (
                <>
                  <View
                    wrap={false}
                    style={{
                      margin: "10px 0",
                      fontSize: "10px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      backgroundColor: "#f3f3f3",
                      border: "1px solid #ddd",
                    }}
                  >
                    <View
                      style={{
                        width: "50%",
                        padding: "8px",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      <Text style={{ fontFamily: "Helvetica" }}>
                        <Text style={{ fontFamily: "Helvetica" }}>
                          Closure Details:{" "}
                        </Text>
                        <Text style={{ fontFamily: "Helvetica-Bold" }}>
                          {issue.closureDetails}
                        </Text>
                      </Text>
                    </View>

                    <View style={{ width: "50%", padding: "8px" }}>
                      <Text style={{ fontFamily: "Helvetica" }}>
                        <Text style={{ fontFamily: "Helvetica" }}>
                          Date Closed:{" "}
                        </Text>
                        <Text style={{ fontFamily: "Helvetica-Bold" }}>
                          {issue.dateClosed
                            ? commonService.formatDate(
                                issue.dateClosed,
                                "Do MMM YYYY"
                              )
                            : ""}
                        </Text>
                      </Text>
                    </View>
                  </View>

                  <View style={{ margin: "10px 0", fontSize: "10px" }}>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>
                      Images added when issue closed
                    </Text>
                    <View
                      style={{
                        fontFamily: "Helvetica",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {issue.closureFiles &&
                        issue.closureFiles.split(",").map((imgPreviewUrl) => (
                          <View
                            style={{
                              margin: "5px",
                              width: "100px",
                              border: "1px solid #ddd",
                            }}
                          >
                            <Link
                              src={
                                process.env.REACT_APP_API_BASEURL +
                                imgPreviewUrl
                              }
                            >
                              <Image
                                src={{
                                  uri:
                                    process.env.REACT_APP_API_BASEURL +
                                    "/pdf-image?path=" +
                                    imgPreviewUrl,
                                }}
                              />
                            </Link>
                          </View>
                        ))}
                      {!issue.closureFiles && (
                        <>
                          <Text style={{ fontFamily: "Helvetica" }}>
                            No images added
                          </Text>
                        </>
                      )}
                    </View>
                  </View>
                </>
              )}
            </View>
          ))}
        </View>
      );
    } else {
      return (
        <View style={pdfStyles.issueDetails}>
          <Text style={{ fontFamily: "Helvetica" }}>No issues.</Text>
        </View>
      );
    }
  };

  const ehsOptions = [
    { value: "Site Documentation", label: "Site Documentation", name: "ehs" },
    { value: "Signs / Notices", label: "Signs / Notices", name: "ehs" },
    {
      value: "Health & Safety Facilities",
      label: "Health & Safety Facilities",
      name: "ehs",
    },
    { value: "Access / Egress", label: "Access / Egress", name: "ehs" },
    { value: "Ladders", label: "Ladders", name: "ehs" },
    { value: "Edge Protection", label: "Edge Protection", name: "ehs" },
    { value: "Site Tidiness", label: "Site Tidiness", name: "ehs" },
    { value: "False Work", label: "False Work", name: "ehs" },
    { value: "Fire Precautions", label: "Fire Precautions", name: "ehs" },
    {
      value: "Hazardous Substances",
      label: "Hazardous Substances",
      name: "ehs",
    },
    {
      value: "Waste Segregation & Storage",
      label: "Waste Segregation & Storage",
      name: "ehs",
    },
    { value: "Scaffolding", label: "Scaffolding", name: "ehs" },
    { value: "Litter", label: "Litter", name: "ehs" },
    { value: "Floor Opes", label: "Floor Opes", name: "ehs" },
    { value: "Plant / Machinery", label: "Plant / Machinery", name: "ehs" },
    { value: "Lifting Equipment", label: "Lifting Equipment", name: "ehs" },
    {
      value: "Trespass & Unauthorized Access",
      label: "Trespass & Unauthorized Access",
      name: "ehs",
    },
    { value: "First Aid", label: "First Aid", name: "ehs" },
    {
      value: "Personal Protective Equipment",
      label: "Personal Protective Equipment",
      name: "ehs",
    },
    { value: "Temporary Electrics", label: "Temporary Electrics", name: "ehs" },
    {
      value: "Dust And Mud On External Roads",
      label: "Dust And Mud On External Roads",
      name: "ehs",
    },
    { value: "Accommodation", label: "Accommodation", name: "ehs" },
    { value: "Registers", label: "Registers", name: "ehs" },
    { value: "Demolition", label: "Demolition", name: "ehs" },
    {
      value: "Fuel Storage / Spill Kits Available",
      label: "Fuel Storage / Spill Kits Available",
      name: "ehs",
    },
    { value: "Roof Work", label: "Roof Work", name: "ehs" },
    { value: "Portable Tools", label: "Portable Tools", name: "ehs" },
    {
      value: "Water Effluent Controls",
      label: "Water Effluent Controls",
      name: "ehs",
    },
    { value: "Road Works", label: "Road Works", name: "ehs" },
    { value: "Excavations", label: "Excavations", name: "ehs" },
    { value: "Electrical / P.A.T.", label: "Electrical / P.A.T.", name: "ehs" },
    { value: "Housekeeping", label: "Housekeeping", name: "ehs" },
    { value: "W.A.H. Scaffolds", label: "W.A.H. Scaffolds", name: "ehs" },
    { value: "Welfare/Offices", label: "Welfare/Offices", name: "ehs" },
    { value: "Work Equipment", label: "Work Equipment", name: "ehs" },
    {
      value: "W.A.H. Ladders/Podiums/Mobile Towers/ Hop Ups",
      label: "W.A.H. Ladders/Podiums/Mobile Towers/ Hop Ups",
      name: "ehs",
    },
    { value: "COSHH Storage / Use", label: "COSHH Storage / Use", name: "ehs" },
    { value: "Plant & Machinery", label: "Plant & Machinery", name: "ehs" },
    { value: "Exclusion Zones", label: "Exclusion Zones", name: "ehs" },
    {
      value: "Display Boards/Signs",
      label: "Display Boards/Signs",
      name: "ehs",
    },
    {
      value: "W.A.H. Edge Protection",
      label: "W.A.H. Edge Protection",
      name: "ehs",
    },
    { value: "Site Documents", label: "Site Documents", name: "ehs" },
    { value: "W.A.H. MEWP's", label: "W.A.H. MEWP's", name: "ehs" },
    { value: "Traffic Management", label: "Traffic Management", name: "ehs" },
    {
      value: "Lift Shafts / Risers",
      label: "Lift Shafts / Risers",
      name: "ehs",
    },
    {
      value: "Dust And Mud On  Roads",
      label: "Dust And Mud On  Roads",
      name: "ehs",
    },
    { value: "Permits To Work", label: "Permits To Work", name: "ehs" },
    { value: "Foresight Close Out", label: "Foresight Close Out", name: "ehs" },
    {
      value: "Security/Hoarding / Fencing",
      label: "Security/Hoarding / Fencing",
      name: "ehs",
    },
    {
      value: "Dust/ Noise / Vibration ",
      label: "Dust/ Noise / Vibration ",
      name: "ehs",
    },
    {
      value: "Abrasive Wheels / Cutting And Grinding",
      label: "Abrasive Wheels / Cutting And Grinding",
      name: "ehs",
    },
    {
      value: "Lighting / Task Lighting",
      label: "Lighting / Task Lighting",
      name: "ehs",
    },
    {
      value: "Formwork / Braced/propping",
      label: "Formwork / Braced/propping",
      name: "ehs",
    },
    {
      value: "Management Inspections",
      label: "Management Inspections",
      name: "ehs",
    },
    { value: "Training", label: "Training", name: "ehs" },
    { value: "RAMS / SPA/SOP/LOTO", label: "RAMS / SPA/SOP/LOTO", name: "ehs" },
  ];

  const riskFactorOptions = [
    { value: "Good Practice", label: "Good Practice", name: "riskFactor" },
    {
      value: "Low Risk/ Needs Improvement",
      label: "Low Risk/ Needs Improvement",
      name: "riskFactor",
    },
    { value: "Poor Practice", label: "Poor Practice", name: "riskFactor" },
    {
      value: "Breach of Company Procedure",
      label: "Breach of Company Procedure",
      name: "riskFactor",
    },
    {
      value: "Breach Of Regulation",
      label: "Breach Of Regulation",
      name: "riskFactor",
    },
    {
      value: "Foreseeable Risk Of Injury",
      label: "Foreseeable Risk Of Injury",
      name: "riskFactor",
    },
    { value: "Near Miss", label: "Near Miss", name: "riskFactor" },
    {
      value: "Dangerous Occurrences",
      label: "Dangerous Occurrences",
      name: "riskFactor",
    },
    { value: "ISO 45001", label: "ISO 45001", name: "riskFactor" },
  ];

  const responseRequiredOptions = [
    { value: "On-going", label: "On-going", name: "response" },
    { value: "Immediate", label: "Immediate", name: "response" },
    { value: "ASAP", label: "ASAP", name: "response" },
    { value: "Urgent", label: "Urgent", name: "response" },
    { value: "NCR", label: "NCR", name: "response" },
    { value: "Good Practice", label: "Good Practice", name: "response" },
  ];

  const statusOptions = [
    { value: "Open", label: "Open", name: "status" },
    { value: "Closed", label: "Closed", name: "status" },
  ];

  const inspectionScore = () => {
    return <>
      <div className={( scoreData.overallScore >= 85 ? 'green-bg' : ( scoreData.overallScore >= 67 ? 'yellow-bg' : 'red-bg' ) ) + ' inspection-score' }>Overall Score: {scoreData.overallScore}%</div>
    </>
  };

  const reassignToNewUser = async() => {
    if(id && reassignData.id && reassignData.assignTo && reassignData.status && reassignData.comments) {
      const form = new FormData();
      form.append("id", reassignData.id);
      form.append("assignTo", reassignData.assignTo);
      form.append("status", reassignData.status);
      form.append("comments", reassignData.comments);
      form.append("inspectionId", id);
      
      siteUsers.filter((user) => {
        if (user.id == reassignData.assignTo) {
          form.append("assignToName", user.fullName);
          form.append("assignToEmail", user.fullName);
        }
      });
      axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/inspection-issues/${reassignData.id}/reassign`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setReassignData({});
        setReassignPopup(true);

        fetchInspectionDetails();
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
    }
  }

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Safety Officer Inspections List", path: "/inspections/" },
          { name: "Safety Officer Inspection", path: `/inspections/${id}` },
        ]}
      />


      <CustomPopup
        trigger={!reassignPopup}
        setTrigger={() => {
          setReassignPopup(true);
          setReassignData({});
        }}
      >
        <div className="col-12 form_inner p-2">
          <div className="input_icons">
            <div className="input_field">
              <div className="w-100 p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Assign To <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={(e) => {
                        setReassignData({
                          ...reassignData,
                          assignTo: e.value,
                        });
                      }}
                      placeholder="Select User"
                      options={siteUsers.map((v) => ({
                        label:
                          v.fullName +
                          " - " +
                          (v.contractorName
                            ? v.contractorName
                            : v.companyName) +
                          " - " +
                          (v.roleName ? v.roleName : v.role),
                        value: `${v.userid}`,
                        name: "assignTo",
                      }))}
                      value={siteUsers
                        .filter((v) => `${v.userid}` == reassignData?.assignTo)
                        .map((v) => ({
                          label:
                            v.fullName +
                            " - " +
                            (v.contractorName
                              ? v.contractorName
                              : v.companyName) +
                            " - " +
                            (v.roleName ? v.roleName : v.role),
                          value: `${v.userid}`,
                          name: "assignTo",
                        }))}
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Status <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      placeholder="Select a status"
                      options={reassignStatuses}
                      onChange={(e) => {
                        setReassignData({
                          ...reassignData,
                          status: e.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Comments <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <textarea
                      name="comments"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      rows="5"
                      cols="100"
                      onChange={(e) => {
                        setReassignData({
                          ...reassignData,
                          comments: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>
              <div className="sign_save_reset_btn">
              <button
                className="btn btn-success"
                disabled={
                  !reassignData?.assignTo ||
                  !reassignData?.status ||
                  !reassignData?.comments
                }
                onClick={reassignToNewUser}
              >
                Reassign
              </button>
            </div>
            </div>
          </div>
        </div>
      </CustomPopup>

      <CustomPopup
        trigger={!newIssuePopup}
        setTrigger={() => {
          setPopup(true);
          setNewIssueData({
            ...newIssueData,
            hazard: "",
            ehs: "",
            riskFactor: "",
            response: "",
            assignedTo: "",
            dateToCompleteBy: "",
            status: "",
            comment: "",
            files: [],
          });
        }}
      >
        <div className="col-12 form_inner add-issue-popup">
          <h3>Add New Issue</h3>

          <div className="d-flex flex-wrap">
            <div className="col-md-12">
              <div className="w-100 p-2 location_ladder">
                <label for="hazard">
                  <div className="require-label">
                    Hazard <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      id="hazard"
                      type="text"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      name="hazard"
                      placeholder=""
                      onChange={handleNewIssueChange}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_ladder">
                <label for="ehs">
                  <div className="require-label">
                    EHS <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <Select
                      onChange={handleNewIssueChange}
                      placeholder="-- Select an option --"
                      options={ehsOptions}
                      {...(id
                        ? {
                            value: ehsOptions.filter(
                              (v) => v.value == newIssueData.ehs
                            ),
                          }
                        : {})}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_ladder">
                <label for="riskFactor">
                  <div className="require-label">
                    Risk Factor <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <Select
                      onChange={handleNewIssueChange}
                      placeholder="-- Select an option --"
                      options={riskFactorOptions}
                      {...(id
                        ? {
                            value: riskFactorOptions.filter(
                              (v) => v.value == newIssueData.riskFactor
                            ),
                          }
                        : {})}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_ladder">
                <label for="response">
                  <div className="require-label">
                    Response Required <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <Select
                      onChange={handleNewIssueChange}
                      placeholder="-- Select an option --"
                      options={responseRequiredOptions}
                      {...(id
                        ? {
                            value: responseRequiredOptions.filter(
                              (v) => v.value == newIssueData.response
                            ),
                          }
                        : {})}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Assign To <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleNewIssueChange}
                      placeholder="Select User"
                      options={siteUsers.map((v) => ({
                        label:
                          v.fullName +
                          " - " +
                          (v.contractorName ? v.contractorName : v.companyName) +
                          " - " +
                          (v.roleName ? v.roleName : v.role),
                        value: `${v.userid}`,
                        name: "assignedTo",
                      }))}
                      value={siteUsers
                        .filter(
                          (v) => `${v.userid}` == newIssueData?.assignedTo
                        )
                        .map((v) => ({
                          label:
                            v.fullName +
                            " - " +
                            (v.contractorName ? v.contractorName : v.companyName) +
                            " - " +
                            (v.roleName ? v.roleName : v.role),
                          value: `${v.userid}`,
                          name: "assignedTo",
                        }))}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_access">
                <label for="dateToCompleteBy">
                  <div className="require-label">
                    Close Out By <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      value={newIssueData.dateToCompleteBy}
                      id="dateToCompleteBy"
                      min={new Date()
                        .toLocaleString("en-GB")
                        .substring(0, 10)
                        .split("/")
                        .reverse()
                        .join("-")}
                      type="date"
                      onChange={handleNewIssueChange}
                      name="dateToCompleteBy"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_ladder">
                <label for="response">
                  <div className="require-label">
                    Status <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <Select
                      onChange={handleNewIssueChange}
                      placeholder="-- Select an option --"
                      options={statusOptions}
                      {...(id
                        ? {
                            value: statusOptions.filter(
                              (v) => v.value == newIssueData.status
                            ),
                          }
                        : {})}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="w-100 p-2 location_ladder">
                <label for="comment">
                  <div className="require-label">Comment</div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      id="comment"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      type="text"
                      name="comment"
                      placeholder=""
                      onChange={handleNewIssueChange}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>
            </div>

            <div className="d-flex preview-upload-files w-100">
              {!!files.length &&
                files.map((imgPreviewUrl) => (
                  <div id="profile_pic_1_preview" className="image-fixed m-2">
                    <img
                      className="obj_fit_img obj-fit-cover rounded"
                      src={imgPreviewUrl}
                      alt=""
                      height="150"
                      width="150"
                    />
                  </div>
                ))}
            </div>

            <div className="select_files">
              <input
                name="newfiles"
                type="file"
                multiple
                id="upload"
                hidden
                onChange={handleFileChange}
              />
              <label for="upload">
                <img src={fileIcon} alt="Icon" />
                Select files...
              </label>
            </div>
            <div className="select_all_files">
              Select all files you wish to upload in one go and NOT
              individually.
            </div>
            <div className="hold_down">
              Hold down CTRL (CMD on Mac) to select multiple files.
            </div>
          </div>
        </div>
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            onClick={() => {
              handleNewIssueSubmit();
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>

      <CustomPopup
        trigger={housekeepingPopup}
        setTrigger={() => {
          setHousekeepingPopup(false);
        }}
      >
        <div className="popup-warning-notice">
          <p>Please Note:<br />If the area highlighted in the Observation is not cleaned to an acceptable standard and closed out within the estimated time and date given, you will be contra charged in your next schedule of payments!</p>
        </div>
      </CustomPopup>

      <CustomPopup
        trigger={!closeIssuePopup}
        setTrigger={() => {
          setClosePopup(true);
          setCloseIssueData({
            issueId: 0,
            closedBy: "",
            dateClosed: "",
            ehs: "",
            closureDetails: "",
            files: [],
          });
        }}
      >
        <div className="col-12 form_inner">
          <h3>Close Issue #{closeIssueData.issueId}</h3>

          {false && closeIssueData.ehs == "Housekeeping" && (
            <>
              <div className="popup-warning-notice">
                <p>
                  Please Note:
                  <br />
                  If the area highlighted in the Observation is not cleaned to
                  an acceptable standard and closed out within the estimated
                  time and date given, you will be contra charged in your next
                  schedule of payments!
                </p>
              </div>
            </>
          )}

          <div className="d-flex flex-wrap">
            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Closed By <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleCloseIssueChange}
                      placeholder="Select User"
                      options={
                        user.role == "admin" ||
                        user.id == data.addedByInCompany ||
                        userPermissions.includes(
                          "submitsafetyOfficerInspections"
                        )
                          ? siteUsers.map((v) => ({
                              label:
                                v.fullName +
                                " - " +
                                (v.contractorName
                                  ? v.contractorName
                                  : v.companyName) +
                                " - " +
                                (v.roleName ? v.roleName : v.role),
                              value: `${v.userid}`,
                              name: "closedBy",
                            }))
                          : siteUsers
                              .filter((v) => v.userid == user.id)
                              .map((v) => ({
                                label:
                                  v.fullName +
                                  " - " +
                                  (v.contractorName
                                    ? v.contractorName
                                    : v.companyName) +
                                  " - " +
                                  (v.roleName ? v.roleName : v.role),
                                value: `${v.userid}`,
                                name: "closedBy",
                              }))
                      }
                      value={siteUsers
                        .filter(
                          (v) => `${v.userid}` == closeIssueData?.closedBy
                        )
                        .map((v) => ({
                          label:
                            v.fullName +
                            " - " +
                            v.companyName +
                            " - " +
                            (v.roleName ? v.roleName : v.role),
                          value: `${v.userid}`,
                          name: "closedBy",
                        }))}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_access">
                <label for="dateClosed">
                  <div className="require-label">
                    Date Closed <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      value={closeIssueData.dateClosed}
                      id="dateClosed"
                      min={new Date()
                        .toLocaleString("en-GB")
                        .substring(0, 10)
                        .split("/")
                        .reverse()
                        .join("-")}
                      type="date"
                      onChange={handleCloseIssueChange}
                      name="dateClosed"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="w-100 p-2 location_ladder">
                <label for="closureDetails">
                  <div className="require-label">
                    Closure Details <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      id="closureDetails"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      type="text"
                      name="closureDetails"
                      placeholder=""
                      onChange={handleCloseIssueChange}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>
            </div>

            <div className="d-flex preview-upload-files w-100">
              {!!files.length &&
                files.map((imgPreviewUrl) => (
                  <div id="profile_pic_1_preview" className="image-fixed m-2">
                    <img
                      className="obj_fit_img obj-fit-cover rounded"
                      src={imgPreviewUrl}
                      alt=""
                      height="150"
                      width="150"
                    />
                  </div>
                ))}
            </div>

            <div className="select_files">
              <input
                name="newfilesCloseIssue"
                type="file"
                multiple
                id="upload"
                hidden
                onChange={handleFileChange}
              />
              <label for="upload">
                <img src={fileIcon} alt="Icon" />
                Select files...
              </label>
            </div>
            <div className="select_all_files">
              Select all files you wish to upload in one go and NOT
              individually.
            </div>
            <div className="hold_down">
              Hold down CTRL (CMD on Mac) to select multiple files.
            </div>
          </div>
        </div>
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            onClick={() => {
              handleCloseIssueSubmit();
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>

      <div className="main_container inspections-main-container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">
              {inspectionScore()}

              <div className="row">
                <div className="col-12 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">General Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          <div className="d-flex flex-wrap">
                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  Submitted By :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {data.submittedBy ? data.submittedBy : "-"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">Site : </div>
                                <div className="col-4 dd-access">
                                  {" "}
                                  {data.siteName ? data.siteName : "-"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  Department :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {" "}
                                  {data.departmentName
                                    ? data.departmentName
                                    : "-"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  Safety Rep :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {data.safetyRep ? data.safetyRep : "None"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  Number of staff on site :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {data.staffCount ? data.staffCount : "0"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  Number of sub contractors on site :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {data.subContractorsCount
                                    ? data.subContractorsCount
                                    : "0"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  Accidents on site since last audit :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {data.accidentsSinceLastAudit
                                    ? data.accidentsSinceLastAudit
                                    : "0"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  Date of audit :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {data.createdAt
                                    ? commonService.formatDate(
                                        data.createdAt,
                                        "Do MMM YYYY"
                                      ) == "Invalid date"
                                      ? data.createdAt
                                      : commonService.formatDate(
                                          data.createdAt,
                                          "Do MMM YYYY"
                                        )
                                    : "-"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  First aid facilities available :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {data.firstAidFacilities ? "Yes" : "No"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  Weather Condition :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {data.weatherCondition
                                    ? data.weatherCondition
                                    : "-"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  Date of last audit :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {data.lastAuditDate
                                    ? commonService.formatDate(
                                        data.lastAuditDate,
                                        "Do MMM YYYY"
                                      ) == "Invalid date"
                                      ? data.lastAuditDate
                                      : commonService.formatDate(
                                          data.lastAuditDate,
                                          "Do MMM YYYY"
                                        )
                                    : "-"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  Fire points located on site :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {data.firePoints ? "Yes" : "No"}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-8 dd-access">
                                  Ope's & Voids protected? :{" "}
                                </div>
                                <div className="col-4 dd-access">
                                  {data.voidsProtected ? "Yes" : "No"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {userPermissions.includes('submitsafetyOfficerInspections') && (data.isCompleted == 0) ? <>
                          <button className="btn btn-outline-success ms-1" onClick={() => {
                            history.push(`/edit-inspection/${data.id}`)
                          }}>Edit</button>
                        </>
                          : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-2">
                  <div className="mb-0">
                    <div className="header-access-view">
                      Response Definitions
                    </div>
                    <div className="p-2">
                      <div className="d-flex flex-wrap inspection-response-definitions">
                        <div className="d-flex col-6">
                          <div className="inspection-tag-left">
                            <div className="inspection-tag inspection-tag-urgent">
                              Urgent
                            </div>
                          </div>
                          <div className="inspection-tag-body">
                            NOW! To be completed within 2-3 hours.
                          </div>
                        </div>
                        <div className="d-flex col-6">
                          <div className="inspection-tag-left">
                            <div className="inspection-tag inspection-tag-immediate">
                              Immediate
                            </div>
                          </div>
                          <div className="inspection-tag-body">
                            By end of day, or not more than 24 hours.
                          </div>
                        </div>
                        <div className="d-flex col-6">
                          <div className="inspection-tag-left">
                            <div className="inspection-tag inspection-tag-asap">
                              ASAP
                            </div>
                          </div>
                          <div className="inspection-tag-body">
                            As agreed with Site Manager, but not more than 72
                            hours.
                          </div>
                        </div>
                        <div className="d-flex col-6">
                          <div className="inspection-tag-left">
                            <div className="inspection-tag inspection-tag-on-going">
                              On-going
                            </div>
                          </div>
                          <div className="inspection-tag-body">
                            To highlight potentially recurring minor issues.
                          </div>
                        </div>
                        <div className="d-flex col-6">
                          <div className="inspection-tag-left">
                            <div className="inspection-tag inspection-tag-ncr">
                              NCR
                            </div>
                          </div>
                          <div className="inspection-tag-body">
                            Serious safety issues or issues not completed within
                            agreed timescaleto have corrective action form
                            issued.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-2">
                  <div className="mb-3">
                    <div className="header-access-view">Closed Issues</div>
                    <div className="p-2">
                      <div className="main-details">
                        {data.closedIssues && data.closedIssues.length > 0
                          ? printIssues(data.closedIssues, "closed")
                          : "No Closed Issues."}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-2">
                  <div className="mb-3">
                    <div className="header-access-view">Carry Over Issues</div>
                    <div className="p-2">
                      <div className="main-details">
                        {data.carryOverIssues && data.carryOverIssues.length > 0
                          ? printIssues(data.carryOverIssues, "carryover")
                          : "No carry over issues were found."}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-2">
                  <div className="mb-3">
                    <div className="header-access-view">Current Issues</div>
                    <div className="p-2">
                      <div className="main-details">
                        {data.openIssues && data.openIssues.length > 0
                          ? printIssues(data.openIssues, "current")
                          : "No current issues were found."}

                        {(user.role.includes("admin") ||
                          userPermissions.includes(
                            "submitsafetyOfficerInspections"
                          )) &&
                          data.isCompleted != 1 && (
                            <>
                              <div className="add-new-issue-button">
                                <a
                                  href="#"
                                  className="btn btn-danger"
                                  onClick={() => setPopup(false)}
                                >
                                  Add New Issue
                                </a>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                {data.isCompleted == 1 &&
                  (user.role.includes("admin") ||
                    (
                      siteUsers
                      .filter((v) => v.userid == user.id).length && siteUsers
                      .filter((v) => v.userid == user.id)[0].type == 'admin'
                    ) ||
                    user.id == data.addedByInCompany) && (
                    <>
                      <PDFDownloadLink
                        document={<MyDoc />}
                        className="btn btn-primary pdf-download-btn"
                        fileName={"inspection-" + data.id + ".pdf"}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download PDF"
                        }
                      </PDFDownloadLink>
                    </>
                  )}

                {data.isCompleted != 1 &&
                  (user.role.includes("admin") ||
                    user.id == data.addedByInCompany) && (
                    <>
                      <div className="col-12 mb-2 text-right">
                        <div class="complete-inspection">
                          <a
                            href="#"
                            className="btn btn-success"
                            onClick={() => {
                              handleComplete(data.id, "this inspection");
                            }}
                          >
                            Complete Inspection
                          </a>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
