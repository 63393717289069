export const FILE = {
    IMAGES: /\.(svg|png|apng|jpeg|jpg|ico|hdr|bmp|webp|gif|tiff|tif|jp2|rgb|dds|cur|tga|heic|jfif|pgm|jpe|xpm|exr|map|ppm|g3|wbmp|jbg|pcx|pdb|avif|jps|rgba|heif|fax|xbm|yuv|pcd|pict|xwd|pal|vips|pgx|pbm|jbig|picon|ras|pct|fts|hrz|ipl|mng|mtv|otb|palm|pam|pfm|pnm|rgbo|sgi|sun|uyvy|viff|xv|g4|rgf|six|sixel|jif|jfi)$/i,
    VIDEO: /\.(avi|wmv|mpeg|webm|mov|mpg|divx|mpeg-2|3gp|flv|swf|m4v|ogv|mkv|mjpeg|vob|xvid|mts|av1|ts|avchd|hevc|asf|mxf|m2v|3g2|rmvb|m2ts|wtv|f4v|rm|mp4)$/i,
};

export const MODULE_STATUS = [
    {
        label: "Select Status",
        value : ""
    },
    {
        label: "Active",
        value : 1
    },
    {
        label: "Inactive",
        value : 0
    }
]