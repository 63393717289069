import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'

function Countdown({ eta }) {
    const [time, setTime] = useState("")
    useEffect(() => {
        let countDownDate = new Date(parseInt(eta?eta:0)*1000).getTime();
        let x = setInterval(function () {
            let now = new Date().getTime();
            let distance = countDownDate - now;
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setTime(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");
            if (distance < 0) {
                clearInterval(x);
                setTime("Free trial expired , you have to renew your subscription");
            }
        }, 1000);
        return ()=>clearInterval(x)
    }, [])

    return (
        <>{time}</>
    )
}

export default Countdown