import React from 'react'
import "./List.css"
import Header from '../Header/Header'
import { useAppState } from '../../state'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import axiosInstance from '../../helpers/request'
import { useEffect } from 'react'
import Edit2 from '../../images/edit-2.svg';
import Delete from '../../images/circle-cross.svg';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import Select from "react-select"
import { MODULE_STATUS } from '../common/constant'
import { useLocation } from 'react-router-dom'

import { commonService } from "../common/common.service";

import Table from "../common/Table";
import DataTable from 'react-data-table-component';
import { Spinner } from "react-bootstrap";
import { Document, Page, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import CustomPopup from "../Popup/CustomPopup";

function DepartmentList({ title }) {
    const { user, userPermissions, selectedSite } = useAppState()
    const cookies = new Cookies();
    const history = useHistory()
    const location = useLocation()
    const isArchived = location.pathname.includes("archived")
    const isOwn = location.pathname.includes("my-")
    const isSites = location.pathname.includes("sites-") || (user.role.includes('admin') ? false : localStorage.getItem(`${user.id}-currentSite`))
    const userToken = localStorage.getItem("cs_token");
    const [data, setData] = useState([])
    const [companyId, setUserID] = useState("")
    const [myId, setMyId] = useState("")
    const [search, setSearch] = useState("");
    const [pdfLoader, setPdfLoader] = useState(true);

    const [sortColumn, setSortColumn] = useState(null);
	  const [sortDirection, setSortDirection] = useState('asc');

    const [status, setStatus] = useState({
        label: "Select Status",
        value: ""
    })


    useEffect(() => {
        getDepartments()
    }, [companyId, status, selectedSite])

    useEffect(() => {
        userDetail()
    }, [])

    const getDepartments = () => {
        if ((localStorage.getItem(`${user.id}-currentSite`) && isSites) || !isSites) {
            axiosInstance
                .post(`${process.env.REACT_APP_API_BASEURL}/${((user.role == "superadmin") && !isOwn && !isSites) ? "every-departments" : "all-departments"}`, {
                    companyId: companyId,
                    status: status.value,
                    isOwn,
                    isSites,
                    siteId: localStorage.getItem(`${user.id}-currentSite`),
                    id: myId
                }, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                })
                .then((res) => {
                    setData(res.data.filter((department, index, arr) => res.data.findIndex(item => item.id == department.id) == index).filter(d => isArchived ? d.archived == "1" : true));

                    const savedSort = commonService.loadSortData('Departments');
                    if (savedSort) {
                      const { direction, columnSelector } = savedSort;
                      setSortDirection(direction);
                      setSortColumn(columnSelector);
                    }
                })
                .catch((err) => {
                    console.log("errr", err);
                });
        } else {
            setData([]);
            const savedSort = commonService.loadSortData('Departments');
            if (savedSort) {
              const { direction, columnSelector } = savedSort;
              setSortDirection(direction);
              setSortColumn(columnSelector);
            }
        }
    }

    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setUserID(((res.data[0].role !== "admin") && res.data[0].added_by) ? res.data[0].added_by : res.data[0].id);
                    setMyId(res.data[0].id);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }


    const handleDelete = async (id, name) => {
        const result = await Swal.fire({
            title: `Are you sure you want to delete ${name}`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .delete(process.env.REACT_APP_API_BASEURL + "/department/" + id)
                .then(function (response) {
                    getDepartments()
                    toast.success("Department deleted successfully");
                })
                .catch(function (error) {
                    toast.error(error?.response?.data?.message || "Unable to delete Department");
                });
        }
    };


    const handleChangeStatus = (module_id, value) => {
        axiosInstance
            .post(process.env.REACT_APP_API_BASEURL + "/update-department-status", {
                id: module_id,
                status: value
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then(function (response) {
                toast.success(response?.data?.message || "Department status has been updated successfully");
            })
            .catch(function (error) {
                console.log('error', error)
                toast.error(error?.response?.data?.message || "Unable to update Department status");
            });
        let newData = []
        data.forEach((itm) => {
            if (itm.id == module_id) {
                newData = [
                    ...newData,
                    {
                        ...itm,
                        is_active: value
                    }
                ];
            } else {
                newData = [...newData, itm]
            }
        })
        setData(newData)


    }

    const tableHeader = [
        'ID', 'Name', 'Description', 'Status'
      ];
    
      function getActionsConvertableArray() {
        let array = [];
    
        // add all items from data but skip actions
        data.forEach((d) => {
          array.push({
            id: d.id,
            name: d.name ? d.name : '',
            description: d.description ? d.description : '',
            status: d.is_active ? 'Active' : 'Inactive',
          })
        })
    
        return array;
      }
    
      const columns = [
        {
          name: 'View',
          selector: 'view',
          width: '100px',
          cell: row => <div>{row.view}</div>,
          sortFunction: (a, b) => {
            return a.id - b.id;
          }
        },
        {
          name: 'Name',
          selector: 'name',
          searchField: 'name',
          cell: row => <div>{row.name}</div>,
        },
        {
          name: 'Description',
          selector: 'description',
          searchField: 'description',
          cell: row => <div>{row.description}</div>,
        },
      ];
    
      // add minWidth to each item in columns
      columns.forEach((column) => {
        if ( typeof column.minWidth == 'undefined' && typeof column.width == 'undefined' ) {
          column.minWidth = '200px';
        }
    
        // Add sorting to all columns
        if ( typeof column.sortable === 'undefined' ) {
          column.sortable = true;
        }
    
        if ( typeof column.sortFunction === 'undefined' && typeof column.searchField !== 'undefined' ) {
          column.sortFunction = (a, b) => a[column.searchField].localeCompare( b[column.searchField] );
        }
      });
    
      if ( user.role.includes("admin") ) {

        columns.push( {
            name: 'Status',
            selector: 'status',
            searchField: 'status',
            cell: row => <div>
                <div className="form-check form-switch">
                    <input
                        className="sub-permission-check form-check-input form-check-input-color d-block"
                        style={{ width: "45px", height: "22px" }}
                        type="checkbox"
                        role={`switch-${row.id}`}
                        id={`flexSwitchCheckChecked-${row.id}`}
                        checked={row.status ? true : false}
                        onChange={(e) => {
                            handleChangeStatus(row.id, e.target.checked ? 1 : 0)
                        }} />
                </div>  
            </div>,
        } );
      }

      if (user.role.includes("admin") || userPermissions.includes('editdepartments') ) {
        columns.push({
          name: 'Action',
          sortable: false,
          selector: 'actions',
          cell: row => <div>{row.actions}</div>,
          allowOverflow: true
        });
      }
    
      const downloadExcel = () => {    
        commonService.downloadArrayOfObjectsAsXLSX(
          getActionsConvertableArray(),
          tableHeader,
          title.toLowerCase().replace(' ', '-').split('(')[0] + '.xlsx'
        );
    
      }
      
      const printList = () => {
        // Trigger the actual print
        commonService.triggerPrint(
          getActionsConvertableArray(),
          tableHeader,
          title
        );
      }
    
      const PDFReadyTable = () => {
        // Implement download PDF
        const pdfTableData = {
          column: tableHeader,
          data: getActionsConvertableArray()
        };
    
        return (
          <Document>
            <Page
              orientation="landscape"
              size="LETTER"
              style={{padding: "20px 16px 30px"}}
            >
              <Table
                data={pdfTableData}
                heading={title}
              />
            </Page>
          </Document>
        );
      }

      const downloadPDF = async () => {
        // If we have larger data set, show laoder.
        if ( data.length > 100 ) {
          setPdfLoader(false);
        }
        const blob = await pdf((
          <PDFReadyTable />
          )).toBlob();
          saveAs(blob, title.toLowerCase().replace(/ /g, '-').split('(')[0] + ".pdf");
          setPdfLoader(true);
      };
    
      const CustomSortIcon = () => (
        <span className="dt-sort-icon"></span>
      )
    
      const createData = () => {
        let parsedData = [];
    
        if ( data.length == 0 ) {
          return parsedData;
        }
    
        data.forEach((d) => {
          let row = {
            id: d.id,
            view: <span onClick={() => { history.push(`/view-department/${d.id}`); }} className="lists-id-col">{d.id}</span>,
            name: d.name ? d.name : '',
            description: d.description ? d.description : '',
            status: d.is_active ? true : false,
          };
    
          if ( user.role.includes( 'admin' ) || userPermissions.includes('editdepartments') ) {
            row.actions = <>
              {(user.role.includes("admin")) &&
                    <img src={Delete} alt="" onClick={() => {
                        handleDelete(d.id, d.name)
                    }} />}
                {userPermissions.includes('editdepartments') && <img className="ps-2" src={Edit2} alt="" onClick={() => {
                    history.push("/edit-department/" + d.id)
                }} />}
            </>
          }
    
          parsedData.push(row);
        });
    
        if ( parsedData.length > 0 && search && search.length > 0 ) {
          // Search for matching string in all columns for each row
          parsedData = parsedData.filter((d) => {
            // Combine all column values in a row into a single string
            const rowValues = Object.entries(d)
              .filter(([key]) => key !== 'action') // Exclude 'action' property
              .map(([key, value]) => value)
              .join(' ')
              .toLowerCase();
            
            // Check if the search string is present in any of the columns
            return rowValues.includes(search.toLowerCase());
          });
        }
    
        return parsedData;
      }

    const handleSort = (column, direction, sortedRows) => {
      setSortColumn(column);
      setSortDirection(direction);
      commonService.saveSortData(column, direction, 'Departments');
    };

    return (
        <div className="middle_page">
            <Header title={title} steps={[{ name: "Home", path: "/" }, { name: isArchived ? "Archived Departments" : "Department List", path: "/departments" }]} />

            <CustomPopup
              showClose={false}
              trigger={!pdfLoader}
              setTrigger={() => {
                
              }}
            >
              <h3>Please Wait...</h3>
              <Spinner visible="true" />
            </CustomPopup>

            <div className="main_container">
                <div>
                    <div className="form_inner table_search">
                        <div className="input_icons">
                            <img src={require("../../images/search.png")} alt="" />
                            <div className="input_field">
                                <input type="text" placeholder="Search Here" value={search} onChange={e => setSearch(e.target.value)} />
                            </div>
                        </div>
                        <div className="input_icons">
                            <div className="w-100">
                                <Select options={MODULE_STATUS} value={status} placeholder="Select Status" onChange={value => {
                                    setStatus(value)

                                }} />
                            </div>
                        </div>
                        <div className="listing-buttons">
                            <button onClick={() => { printList() }}>Print</button>
                            <button onClick={() => { downloadExcel() }}>Excel</button>
                            <button onClick={() => { downloadPDF() }}>PDF</button>

                            {(user.role.includes('admin') || userPermissions.includes('createdepartment')) && <button className='GA2-btn' onClick={() => {
                                history.push("/create-department")
                            }}>Add Department +</button>}
                        </div>
                    </div>
                    <hr />
                    <div id="wrapper">
                        { data.length ? <>
                        <DataTable
                            columns={columns}
                            data={createData()}
                            pagination={true}
                            sortIcon={<CustomSortIcon />}
                            onSort={handleSort}
                            defaultSortFieldId={sortColumn}
                            defaultSortAsc={sortDirection === 'asc'}
                        />
                        </> : <>
                        <Spinner size="sm" animation="border" variant="light" />
                        </>}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DepartmentList