import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import "./Access.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import { commonService } from "../common/common.service";

const AddAccess = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany } = useAppState();
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    siteId: null,
    departmentId: null,
    contractorId: user.contractorId && !id ? user.contractorId : null,
    supervisor: null,
    descriptionOfWorks: null,
    accessingLocationMethod: null,
    operativeName: "",
    contactPhone: null,
    locationOfWorks: null,
    startDate: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
    finishDate: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
    finishTime: (new Date()).toLocaleString('en-GB').substring(12, 17),
    verifySignature: null,
    resubmitComment: null,
    companyId: companyId,
    addedBy: companyId,
    files: "",
  };
  const [fieldData, setFieldData] = useState(fieldValues);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({ ...fieldValues, contractorId: null, startDate: null, finishDate: null, finishTime: null });
  const [contractorData, setContractorData] = useState([]);
  const [siteUsers, setSiteUsers] = useState([])

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(
            userCompanyId
          );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchAccessDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/access/permit/details`,
        {
          accessId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0], existingFiles: res.data[0].files });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        console.log("errr", err);
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchAccessDetails()
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {
    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {
      
      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else if ((name == "finishTime")) {
      let val = value
      if (fieldData.date == (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")) {
        const localTime = (new Date()).toLocaleString('en-GB').substring(12, 17)
        if (parseInt(e.target.value.split(":")[0]) <= parseInt(localTime.split(":")[0])) {
          if (parseInt(e.target.value.split(":")[1]) <= parseInt(localTime.split(":")[1])) {
            val = localTime
            toast.error("Selecting a time in the past is not possible")
          }
        }
      }
      setFieldData({
        ...fieldData,
        [name]: val,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value)
      }
    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      case "addedBy":
        if (!value) {
          message = stateObj[name] = "Added by is required.";
        }
        break;
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;
      case "supervisor":
        if (!value) {
          message = stateObj[name] = "Responsible person is required.";
        }
        break;
      case "descriptionOfWorks":
        if (!value) {
          message = stateObj[name] = "Description of Works is required.";
        }
        break;
      case "accessingLocationMethod":
        if (!value) {
          message = stateObj[name] = "Methods of accessing location is required.";
        }
        break;
      case "operativeName":
        if (!value) {
          message = stateObj[name] = "Person Involved field is required.";
        }
        break;
      case "contactPhone":
        if (!value) {
          message = stateObj[name] = "Contact Phone is required.";
        }
        break;
      case "locationOfWorks":
        if (!value) {
          message = stateObj[name] = "Location of works is required.";
        }
        break;
      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Contractor Id is required.";
        }
        break;
      case "Start Date":
        if (!value) {
          message = stateObj[name] = "Start Date is required.";
        }
        break;
      case "Finish Date":
        if (!value) {
          message = stateObj[name] = "Finish Date is required.";
        }
        break;
      case "finishTime":
        if (!value) {
          message = stateObj[name] = "Finish Time is required.";
        }
        break;
      case "verifySignature":
        if (!value) {
          message = stateObj[name] = "Upload signature.";
        }
        break;
      
      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      const [verifySignature] = await Promise.all([handleSaveVerifySign()])
      handleSubmitAfterSave(verifySignature)
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (verifySignature) => {
    let errorShow = {
      companyId: fieldData.companyId,
      addedBy: fieldData.addedBy,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      supervisor: fieldData.supervisor,
      descriptionOfWorks: fieldData.descriptionOfWorks,
      accessingLocationMethod: fieldData.accessingLocationMethod,
      operativeName: fieldData.operativeName,
      fireWatchPerson: fieldData.fireWatchPerson,
      contactPhone: fieldData.contactPhone,
      locationOfWorks: fieldData.locationOfWorks,
      contractorId: (user.contractorId||user.role=="admin")?fieldData.contractorId:true,
      startDate: fieldData.startDate,
      finishDate: fieldData.finishDate,
      finishTime: fieldData.finishTime,
      verifySignature: verifySignature,
    };
    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.companyId !== null &&
      fieldData.addedBy !== null &&
      fieldData.siteId !== null &&
      fieldData.departmentId !== null &&
      fieldData.supervisor !== null &&
      fieldData.descriptionOfWorks !== null &&
      fieldData.accessingLocationMethod !== null &&
      fieldData.operativeName != "" &&
      fieldData.contactPhone !== null &&
      fieldData.locationOfWorks !== null &&
      (user.contractorId||user.role=="admin"?fieldData.contractorId !== null:true) &&
      fieldData.startDate !== null &&
      fieldData.finishDate !== null &&
      fieldData.finishTime !== null &&
      verifySignature !== null
    ) {
      const form = new FormData();
      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("accessPermits") : v.permits.includes("accessPermitsreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      form.append("departmentId", fieldData.departmentId == null ? null : fieldData.departmentId);
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      form.append("supervisor", fieldData.supervisor);
      form.append("descriptionOfWorks", fieldData.descriptionOfWorks);
      form.append("accessingLocationMethod", fieldData.accessingLocationMethod);
      form.append("operativeName", fieldData.operativeName);
      form.append("contactPhone", fieldData.contactPhone);
      form.append("locationOfWorks", fieldData.locationOfWorks);
      form.append("contractorId", fieldData.contractorId);
      form.append("startDate", fieldData.startDate);
      form.append("finishDate", fieldData.finishDate);
      form.append("finishTime", fieldData.finishTime);
      form.append("verifySignature", verifySignature);
      form.append("companyId", companyId);
      form.append("addedBy", companyId);
      form.append("addedByInCompany", user.id);
      form.append("approvedByName", user.fullName);
      if (id) {
        form.append("resubmitComment", fieldData.resubmitComment);
      }
      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }

      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
      setLoading(true);
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update-details/access-permit/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/access-permit`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/view-own/access-permits')
        setFieldData(fieldData);
        handleClearVerifySign();
      })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const signatureVerifyRef = useRef();

  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRef.current.isEmpty()) {
        res(null)
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file)
        });
      }
    })
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }))

  const departmentOptions = allDepartment.filter(v => v.siteId == fieldData.siteId).map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentId",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">
        <p className="redText">This must be accompanied with a method statement approved by site management. Permits may only be issued on a daily basis.</p>
        <div className="addaccess_first_container">
          <div className="general_details">General Details</div>
          <div className="general_details_fields justify-content-start flex-wrap">
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Site <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Sites"
                    options={siteOptions}
                    {...(id ? {
                      value: siteOptions.filter(v => v.value == fieldData.siteId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.siteId && <span className="err">{error.siteId}</span>}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Department <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Department"
                    options={departmentOptions}
                    {...(id ? {
                      value: departmentOptions.filter(v => v.value == fieldData.departmentId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.departmentId && (
                <span className="err">{error.departmentId}</span>
              )}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Contractor <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  {user.role.includes("admin") ? <Select
                    onChange={handleChange}
                    placeholder="Select Contractor"
                    value={fieldData.contractorId ? contractorData.filter(v => v.id == fieldData.contractorId).map(d => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    })) : null}
                    options={contractorData.map((d) => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    }))}
                  /> : fieldData.contractorId && contractorData.filter(v => v.id == fieldData.contractorId).length ? contractorData.filter(v => v.id == fieldData.contractorId)[0].name : user.companyName}
                </div>
              </div>
              {error.contractorId && (
                <span className="err">{error.contractorId}</span>
              )}
            </div>
            <div className="col-3  p-2 location_access">
              <label for="">
                <div className="require-label">
                  {" "}
                  Location <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="locationOfWorks"
                    value={fieldData.locationOfWorks}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.locationOfWorks && (
                <span className="err">{error.locationOfWorks}</span>
              )}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Start Date <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.startDate} disabled={!!id} min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")} type="date" onChange={handleChange} name="startDate" />
                </div>
              </div>
              {error.startDate && <span className="err">{error.startDate}</span>}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Finish Date <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.finishDate} disabled={!!id} min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")} type="date" onChange={handleChange} name="finishDate" />
                </div>
              </div>
              {error.finishDate && <span className="err">{error.finishDate}</span>}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Finish Time <span className="text-danger">*</span>{" "}
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.finishTime} disabled={!!id} type="time" pattern="hh:mm" onChange={handleChange} name="finishTime" />
                </div>
              </div>
              {error.finishTime && <span className="err">{error.finishTime}</span>}
            </div>
            <div className="col-3  p-2 location_access">
              <label for="">
                <div className="require-label">
                  {" "}
                  Supervisor <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="supervisor"
                    value={fieldData.supervisor}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.supervisor && (
                <span className="err">{error.supervisor}</span>
              )}
            </div>
            <div className="col-3  p-2 location_access">
              <label for="">
                <div className="require-label">
                  {" "}
                  Contact Number <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="contactPhone"
                    value={fieldData.contactPhone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.contactPhone && (
                <span className="err">{error.contactPhone}</span>
              )}
            </div>
          </div>
        </div>
        <div className="addaccess_second_container">
          <div className="general_details">Other Details</div>
          <div>
            <div className="col-8 form_inner p-2">
              <label for="">
                Description of works <span className="text-danger">*</span>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="descriptionOfWorks"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="5"
                    cols="100"
                    value={fieldData.descriptionOfWorks}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              {error.descriptionOfWorks && (
                <span className="err">{error.descriptionOfWorks}</span>
              )}
            </div>
          </div>
          <div>
            <div className="col-8 form_inner p-2">
              <label for="">
                Method Of Accessing Location <span className="text-danger">*</span>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="accessingLocationMethod"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="5"
                    cols="100"
                    value={fieldData.accessingLocationMethod}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              {error.accessingLocationMethod && (
                <span className="err">{error.accessingLocationMethod}</span>
              )}
            </div>
          </div>
        </div>
        <div className="addaccess_fourth_container">
          <div className="general_details">Operatives</div>

          <div>
            <div className="col-8 location_access p-2">
              <label for="">
                Names of person(s) involved in access permit
              </label>
              {(fieldData.operativeName ? fieldData.operativeName : "").split(",").map((str, i, arr) => (<div className="input_icons mt-3">
                <div className="input_field">
                  <input
                    name={`operativeName${i}`}
                    placeholder="Enter Operative Name"
                    value={str}
                    onChange={e => {
                      setFieldData({ ...fieldData, operativeName: (fieldData.operativeName ? fieldData.operativeName : "").split(",").map((v, i2) => i == i2 ? e.target.value : v).join(",") })
                    }}
                  />
                </div>
                <div>
                  {arr.length == (i + 1) ?
                    <button
                      className="btn btn-primary ms-2"
                      style={{ minWidth: "80px" }}
                      onClick={() => {
                        setFieldData({ ...fieldData, operativeName: `${fieldData.operativeName},` })
                      }}
                    >
                      +
                    </button>
                    : <button
                      className="btn btn-danger ms-2"
                      style={{ minWidth: "80px" }}
                      onClick={() => {
                        setFieldData({ ...fieldData, operativeName: (fieldData.operativeName ? fieldData.operativeName : "").split(",").filter((v,i2)=>i!=i2).join(",") })
                      }}
                    >
                      -
                    </button>
                  }
                </div>
              </div>))}
              {error.operativeName && (
                <span className="err">{error.operativeName}</span>
              )}
            </div>
          </div>
        </div>
        <div className="addaccess_fifth_container">
          <div className="general_details">Upload Files</div>
          <div className="d-flex">
            {!!files.length && files.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
              <img
                className="obj_fit_img obj-fit-cover rounded"
                src={imgPreviewUrl}
                alt=""
                height="150"
                width="150"
              />
            </div>))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
          {error.files && (
              <span className="err">{error.files}</span>
            )}
          </div>
        </div>
        <div className="addaccess_sixth_container">
          <div className="general_details">Signature(s)</div>
          <div className="p-2">
            <p className="hold_down">
            I have read and understood my employer's risk assessment and method statement for the works to be carried out. I will comply with the Risk Assessment and Method Statement. I fully realise that it is my responsibility to wear and clip on my safety harness at unprotected edges.
            </p>
            <div className="sign_image">
              <SignatureCanvas
                ref={signatureVerifyRef}
                canvasProps={{ width: 263, height: 270 }}
              />
              <span
                className="cancel-custom cursor-pointer"
                onClick={handleClearVerifySign}
              >
                <i
                  className="clear_btn  text-danger fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            {error.verifySignature && (
              <span className="err">{error.verifySignature}</span>
            )}
            {!!id && <div className="d-flex">
              <div className="textArea mx-0">
                <p className="mb-0 text-dark">
                  <div className="require-label">
                    {" "}
                    Resubmit Comment
                  </div>
                </p>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="resubmitComment"
                      rows="3"
                      cols="100"
                      onChange={handleChange}
                      value={fieldData.resubmitComment}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Resubmit" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn">cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddAccess;
