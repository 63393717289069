import React from "react";
import "./list.css";
import Header from "../../Header/Header";
import { useAppState } from "../../../state";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../helpers/request";
import { useEffect, useState } from "react";
import UpDownArrow from "../../../images/up-down-arrow.svg";
import Edit2 from "../../../images/edit-2.svg";
import Delete from "../../../images/circle-cross.svg";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { commonService } from "../../common/common.service";

import Table from "../../common/Table";
import DataTable from 'react-data-table-component';
import { Spinner } from "react-bootstrap";
import { Document, Page, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import CustomPopup from "../../Popup/CustomPopup";

export default function CommonLadderPermitList({ title, isAll, isDeleted }) {
  const { user, selectedSite, userPermissions, selectedCompany } = useAppState();
  const cookies = new Cookies();
  const history = useHistory();
  const userToken = localStorage.getItem("cs_token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyId, setUserID] = useState("");
  const [search, setSearch] = useState("");
  const [pdfLoader, setPdfLoader] = useState(true);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  
  useEffect(() => {
    if (companyId) {
      getLadderPermits();
    }
  }, [companyId,selectedSite]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  useEffect(() => {
    userDetail();
  }, []);

  const getLadderPermits = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/ladder/permits`,
        {
          companyId: companyId,
          userId: user.id,
          isAll: isAll,
          isDeleted: isDeleted,
          siteId: localStorage.getItem(`${user.id}-currentSite`),
          contractorId: user.role.includes("admin") ? null : user.contractorId
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        // if (res.data.length > 0) {
        setData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );

        const savedSort = commonService.loadSortData('LadderPermits');
        if (savedSort) {
          const { direction, columnSelector } = savedSort;
          setSortDirection(direction);
          setSortColumn(columnSelector);
        }

        setLoading(false);
        // }
      })
      .catch((err) => {
        setLoading(false);
        console.log("errr", err);
      });
  };

  function userDetail() {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          if ( res.data[0].role == 'superadmin' ) {
            setUserID( selectedCompany );
          } else {
            setUserID(
              res.data[0].added_by ? res.data[0].added_by : res.data[0].id
            );
          }
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const handleDelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${name}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/ladder/" + id)
        .then(function (response) {
          getLadderPermits();
          toast.success("Ladder Permit deleted successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message || "Unable to delete Ladder Permit"
          );
        });
    }
  };

  const tableHeader = [
    'ID', 'Site', 'Operator Name', 'Contractor', 'Location of Ladder', 'Defects Found', 'Inspected By', 'Submitted By', 'Approved', 'Date Submitted'
  ];

  function getActionsConvertableArray() {
    let array = [];

    // add all items from data but skip actions
    data.forEach((d) => {
      array.push({
        id: d.id,
        siteName: d.siteName ? d.siteName : '',
        operatorName: d.operatorName ? d.operatorName : '',
        contractorName: d.contractorName&&d.contractorId ? d.contractorName : d.companyName,
        ladderLocation: d.ladderLocation ? d.ladderLocation : '',
        defectsFound: d.isDefectFound !== "" && d.isDefectFound !== null ? ( d.isDefectFound ? "yes" : "No" ) : "-",
        inspectorName: d.inspectorName ? d.inspectorName : '',
        submittedBy: d.submittedBy ? d.submittedBy : (d.immutableSubmittedBy ? d.immutableSubmittedBy : ''),
        isApproved: d.isApproved == 1 ? "Yes" : ( d.isApproved == 0 ? "No" : "Pending" ),
        createdAt: d.createdAt ? commonService.formatDate(d.createdAt, "Do MMM YYYY") : '',
      })
    })

    return array;
  }

  const columns = [
    {
      name: 'View',
      selector: 'view',
      width: '100px',
      cell: row => <div>{row.view}</div>,
      sortFunction: (a, b) => {
        return a.id - b.id;
      }
    },
    {
      name: 'Site',
      selector: 'siteName',
      searchField: 'siteName',
      cell: row => <div>{row.siteName}</div>,
    },
    {
      name: 'Operator Name',
      selector: 'operatorName',
      searchField: 'operatorName',
      cell: row => <div>{row.operatorName}</div>,
    },
    {
      name: 'Contractor',
      selector: 'contractorName',
      searchField: 'contractorName',
      cell: row => <div>{row.contractorName}</div>,
    },
    {
      name: 'Location of Ladder',
      selector: 'ladderLocation',
      searchField: 'ladderLocation',
      cell: row => <div>{row.ladderLocation}</div>,
    },
    {
      name: 'Defects Found',
      selector: 'defectsFound',
      searchField: 'defectsFound',
      cell: row => <div><span className={`roundPill ${row.defectsFoundValue ? "red-bg" : "green-bg"}`}>{row.defectsFound}</span></div>,
    },
    {
      name: 'Inspected By',
      selector: 'inspectorName',
      searchField: 'inspectorName',
      cell: row => <div>{row.inspectorName}</div>,
    },
    {
      name: 'Submitted By',
      selector: 'submittedBy',
      searchField: 'submittedBy',
      cell: row => <div>{row.submittedBy}</div>,
    },
    {
      name: 'Approved',
      selector: 'isApproved',
      searchField: 'isApproved',
      cell: row => <div><span className={`roundPill ${row.isApprovedValue == 0 ? "red-bg" : row.isApprovedValue == 1 ? "green-bg" : "yellow-bg"}`}>{row.isApproved}</span></div>,
    },
    {
      name: 'Date Submitted',
      selector: 'createdAt',
      searchField: 'createdAt',
      cell: row => row.createdAt,
      sortFunction: (a, b) => {
        return a.createdAtPlain - b.createdAtPlain;
      }
    },
  ];

  // add minWidth to each item in columns
  columns.forEach((column) => {
    if ( typeof column.minWidth == 'undefined' && typeof column.width == 'undefined' ) {
      column.minWidth = '200px';
    }

    // Add sorting to all columns
    if ( typeof column.sortable === 'undefined' ) {
      column.sortable = true;
    }

    if ( typeof column.sortFunction === 'undefined' && typeof column.searchField !== 'undefined' ) {
      column.sortFunction = (a, b) => a[column.searchField].localeCompare( b[column.searchField] );
    }
  });

  if ( user.role.includes("admin") ) {
    columns.push({
      name: 'Action',
      sortable: false,
      selector: 'actions',
      cell: row => <div>{row.actions}</div>,
      allowOverflow: true
    });
  }

  const downloadExcel = () => {    
    commonService.downloadArrayOfObjectsAsXLSX(
      getActionsConvertableArray(),
      tableHeader,
      title.toLowerCase().replace(' ', '-') + '.xlsx'
    );

  }
  
  const printList = () => {
    // Trigger the actual print
    commonService.triggerPrint(
      getActionsConvertableArray(),
      tableHeader,
      title
    );
  }

  const PDFReadyTable = () => {
    // Implement download PDF
    const pdfTableData = {
      column: tableHeader,
      data: getActionsConvertableArray()
    };

    return (
      <Document>
        <Page
          orientation="landscape"
          size="LETTER"
          style={{padding: "20px 16px 30px"}}
        >
          <Table
            data={pdfTableData}
            heading={title}
          />
        </Page>
      </Document>
    );
  }

  const downloadPDF = async () => {
    // If we have larger data set, show laoder.
    if ( data.length > 100 ) {
      setPdfLoader(false);
    }
    const blob = await pdf((
      <PDFReadyTable />
      )).toBlob();
      saveAs(blob, title.toLowerCase().replace(/ /g, '-').split('(')[0] + ".pdf");
      setPdfLoader(true);
  };

  const CustomSortIcon = () => (
    <span className="dt-sort-icon"></span>
  )

  const createData = () => {
    let parsedData = [];

    if ( data.length == 0 ) {
      return parsedData;
    }

    data.forEach((d) => {
      let row = {
        id: d.id,
        view: <span onClick={() => { history.push(`/ladder-permit/${d.id}`); }} className="lists-id-col">{d.id}</span>,
        siteName: d.siteName ? d.siteName : '',
        operatorName: d.operatorName ? d.operatorName : '',
        contractorName: d.contractorName&&d.contractorId ? d.contractorName : (d.companyName?d.companyName:""),
        ladderLocation: d.ladderLocation ? d.ladderLocation : '',
        defectsFound: d.isDefectFound !== "" && d.isDefectFound !== null ? ( d.isDefectFound ? "Yes" : "No" ) : "-",
        inspectorName: d.inspectorName ? d.inspectorName : '',
        submittedBy: d.submittedBy ? d.submittedBy : (d.immutableSubmittedBy ? d.immutableSubmittedBy : ''),
        isApproved: d.isApproved == 1 ? "Yes" : ( d.isApproved == 0 ? "No" : "Pending" ),
        createdAt: d.createdAt ? commonService.formatDate(d.createdAt, "Do MMM YYYY") : '',
        
        createdAtPlain: d.createdAt ? commonService.formatDate(d.createdAt, "YYYYMMDD") : 0,
        isApprovedValue: d.isApproved,
        defectsFoundValue: d.isDefectFound,
      };

      if ( user.role.includes( 'admin' ) ) {
        row.actions = <>
          {!isDeleted && user.role.includes("admin") && (
                <img
                  src={Delete}
                  alt=""
                  onClick={() => {
                    handleDelete(d.id, ' this permit');
                  }}
                />
              )}
              {((d.isApproved == 0)) && <img
                src={Edit2}
                alt=""
                className="ms-2"
                onClick={() => {
                  history.push(`/edit-ladder-permit/${d.id}`)
                }}
              />}
        </>
      }

      parsedData.push(row);
    });

    if ( parsedData.length > 0 && search && search.length > 0 ) {
      // Search for matching string in all columns for each row
      parsedData = parsedData.filter((d) => {
        // Combine all column values in a row into a single string
        const rowValues = Object.entries(d)
          .filter(([key]) => key !== 'action') // Exclude 'action' property
          .map(([key, value]) => value)
          .join(' ')
          .toLowerCase();
        
        // Check if the search string is present in any of the columns
        return rowValues.includes(search.toLowerCase());
      });
    }

    return parsedData;
  }

  const handleSort = (column, direction, sortedRows) => {
    setSortColumn(column);
    setSortDirection(direction);
    commonService.saveSortData(column, direction, 'LadderPermits');
  };

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          { name: "Ladder Permit List", path: "/ladder-permits" },
        ]}
      />

      <CustomPopup
        showClose={false}
        trigger={!pdfLoader}
        setTrigger={() => {
          
        }}
      >
        <h3>Please Wait...</h3>
        <Spinner visible="true" />
      </CustomPopup>

      <div className="main_container">
        <div>
          <div className="form_inner table_search">
            <div className="input_icons">
              <img src={require("../../../images/search.png")} alt="" />
              <div className="input_field">
                <input
                  type="text"
                  placeholder="Search Here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>

            <div className="listing-buttons">
              <button onClick={() => { printList() }}>Print</button>
              <button onClick={() => { downloadExcel() }}>Excel</button>
              <button onClick={() => { downloadPDF() }}>PDF</button>

              { userPermissions.includes('submitladderPermits') && <>
                <button
                  className="GA2-btn"
                  onClick={() => {
                    history.push("/ladder/add-permit");
                  }}
                >
                  Add Ladder Permit +
                </button>
              </> }
            </div>
          </div>
          <hr />
          <div id="wrapper">
            { ! loading ? <>
              <DataTable
                columns={columns}
                data={createData()}
                pagination={true}
                sortIcon={<CustomSortIcon />}
                onSort={handleSort}
                defaultSortFieldId={sortColumn}
                defaultSortAsc={sortDirection === 'asc'}
              />
            </> : <>
              <Spinner size="sm" animation="border" variant="light" />
            </>}
          </div>
        </div>
      </div>
    </div>
  );
}
