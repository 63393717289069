import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SecurityOutlined } from '@material-ui/icons';
import axios from "axios";
import PasswordChecklist from "react-password-checklist"
import { toast } from 'react-toastify';
import logo from "../../images/foresighthublogo.svg";
import illustration from "../../images/loginmainimage.svg";


import './passwordreset.css'

export default function PasswordReset() {
    // const location = useLocation();
    const history = useHistory();

    const [token, setToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        if (history.location.state && history.location.state.token) {
            let state = { ...history.location.state };
            setToken(state.token);
            delete state.token;
            history.replace({ ...history.location, state });
        } else {
            history.push("/login");
        }
    }, [history])

    const isValidData = () => {
        let isValid = true;
        let message = '';

        if (newPassword === "" || confirmPassword === "") {
            isValid = false;
            message = "All fields are mandatory";
        } else if (newPassword !== confirmPassword) {
            isValid = false;
            message = "Password and Confirm Password are not matched";
        }

        return { isValid, message };
    }

    const resetPassword = (event) => {
        event.preventDefault();
        if (token) {
            const { isValid, message } = isValidData();
            if (isValid) {
                setUpdating(true);
                axios.post(process.env.REACT_APP_API_BASEURL + '/reset-password', { token: token, password: confirmPassword })
                    .then(function (response) {
                        setUpdating(false);
                        toast.success("Reset password successfully");
                        history.replace('/');
                    })
                    .catch(function (error) {
                        setUpdating(false);
                        toast.error("Unable to process reset password");
                    });
            } else {
                toast.error(message);
            }
        } else {
            toast.error('Unable to verify details, Please retry with new otp');
            history.replace('/');
        }
    }

    return (
        <div className="userlogin">
      <div className="userLoginContainer">
        <div className="userloginLeft">
   
        

            <div className="logoadminbottom">
              <img src={illustration} alt="" />
            </div>
     
        </div>
        <div className="userLoginRight">

          <div className="logoMobile">
            <img src={logo} alt="" />
          </div>
          <div className="brand-logo mb-0 text-center text-md-start mx-auto mx-md-0">
                      <a href="#">
                  
                        <img src={logo} alt="" className="footerlogo" />
              
                      </a>
                    </div>


       
          <form className="passwordResetForm">
                <div className="passwordResetItem">
                    <label>Password</label>
                    <input onChange={(e) => { setNewPassword(e.target.value); }} type="password" placeholder='Enter a password for the user' />
                </div>
                <div className="passwordResetItem">
                    <label>Confirm Password</label>
                    <input onChange={(e) => { setConfirmPassword(e.target.value); }} type="password" placeholder='Confirm the password' />

                    <div className="passwordContainer">
                        <PasswordChecklist
                            rules={["minLength", "specialChar", "number", "capital", "match"]}
                            minLength={8}
                            value={newPassword}
                            valueAgain={confirmPassword}
                            onChange={(isValid) => { }} />
                    </div>


                    <button disabled={updating} onClick={resetPassword} className="passwordResetButton">Reset</button>
                </div>




            </form>

      </div>

      </div>
      </div>
    )
}
