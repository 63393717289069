import React, { useEffect } from "react";
import Header from "../Header/Header";
import { useState } from "react";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import SubmitLoader from "../common/SubmitLoader";
import ImgInput from "../common/form/ImgInput";
import DefaultImg from "../../images/default/no_image.png";
import PageLoader from "../common/form/loaders/PageLoader";
import { useAppState } from "../../state";
import { permissions } from '../../helpers/permissions'

function EditDetails({ title }) {
  const [loading, setLoading] = useState(false);
  const { refreshUserDetails } = useAppState()
  const [data, setData] = useState({
    companyName: "",
    companyaddress: "",
    companytelephonenumber: "",
    companyInformation: "",
    website: "",
    companyLogoFile: null,
    companyLogo: "",
    permissions: permissions.map(v => v.children).flat().map(v => ({ name: v.name, value: false })),
  });
  console.log("data", data);
  const [dataEr, setDataEr] = useState({
    companyName: "",
    companyaddress: "",
  });

  const [oldImage, setOldImage] = useState(null);

  console.log("dataEr", dataEr);

  const error = {
    companyName: "Company name is required.",
    companyaddress: "Address is required.",
  };

  const [fetchLoading, setFetchLoading] = useState(true);
  const cookies = new Cookies();
  const userToken = cookies.get("cs_token");

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          const allPermit = res.data[0].disabledPermissions ? res.data[0].disabledPermissions.split(",").filter(v => !v.requiresSuperAdmin && !v.requiresRestricted) : [];
          setData({
            ...res.data[0],
            companyLogoFile: null,
            permissions: permissions.map(v => v.children)
                  .flat()
                  .map(v => ({ name: v.name, value: !allPermit.includes(v.name) }))
                .filter(v => !v.requiresSuperAdmin && !v.requiresRestricted),
          });
          setOldImage(res.data[0].companyLogo);
          setFetchLoading(false);
        } else {
          toast.error("No user found.");
        }
      })
      .catch((err) => {
        toast.error(
          error?.response?.data?.message || "Unable to process this request"
        );
        console.log("errr", err);
      });
  };

  const validateForm = () => {
    let new_error = {};
    let is_validate = true;
    Object.keys(dataEr).forEach((er_key) => {
      new_error = {
        ...new_error,
        [er_key]: "",
      };
    });

    Object.keys(new_error).forEach((er_key) => {
      if (data[er_key] == "" || !data[er_key]) {
        new_error = {
          ...new_error,
          [er_key]: error[er_key],
        };
        is_validate = false;
      }
    });
    setDataEr(new_error);
    return is_validate;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);

      const form = new FormData();
      form.append("companyName", data.companyName);
      form.append("companyaddress", data.companyaddress);
      form.append("companytelephonenumber", data.companytelephonenumber);
      form.append("companyInformation", data.companyInformation);
      form.append("website", data.website);
      form.append(
        "isRemoveImage",
        data.companyLogo == "" ||
          !data.companyLogo ||
          oldImage !== data.companyLogo
          ? 1
          : 0
      );
      form.append("id", data.id);

      if (data.companyLogoFile) {
        form.append("companyLogoFile", data.companyLogoFile);
      }

      form.append( 'permissions', data.permissions.filter(x => !x.value).map(y => y.name).join(',') );

      axiosInstance
        .post(
          `${process.env.REACT_APP_API_BASEURL}/update/company_details`,
          form,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
          setLoading(false);
          // if (data.companyLogoFile) {
            refreshUserDetails()
          // }
        })
        .catch((err) => {
          toast.error(
            error?.response?.data?.message || "Unable to process this request"
          );
          console.log("errr", err);
          setLoading(false);
        });
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value ? e.target.value : "",
    });
  };

  useEffect(() => {
    userDetail();
  }, []);

  return (
    <div class="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          { name: "Company Details ", path: "/company/edit-details" },
        ]}
      />
      <div class="main_container">
        {/* <p class="legal">(In Contemplation of Legal Proceedings)</p> */}
        <div>
          <div class="accident">
            <div class="accident_text">
              <h5>Company Details</h5>
            </div>
            {/* <div class="cross_icon"><img src={require("../../images/minus.png")} alt="" /></div> */}
          </div>
          {!fetchLoading ? (
            <>
              <form enctype="multipart/form-data">
                <div class="form-1">
                  <div class="form justify-content-start row">
                    <div class="col-6 form_inner p-2">
                      <label for="">
                        Name <span className="text-danger">*</span>{" "}
                      </label>
                      <div class="input_icons">
                        <div class="input_field">
                          <input
                            type="text"
                            value={data.companyName ? data.companyName : ""}
                            name="companyName"
                            onChange={handleChange}
                            placeholder="Name"
                          />
                        </div>
                      </div>
                      {dataEr.companyName ? (
                        <div className="error text-danger">
                          {dataEr.companyName}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="col-6 form_inner p-2">
                      <label for="">Company Information </label>
                      <div class="input_icons">
                        <div class="input_field">
                          <textarea
                            name="companyInformation"
                            value={
                              data.companyInformation
                                ? data.companyInformation
                                : ""
                            }
                            onChange={handleChange}
                            rows="1"
                            cols="50"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 form_inner p-2">
                      <label for="">
                        Address <span className="text-danger">*</span>{" "}
                      </label>
                      <div class="input_icons">
                        <div class="input_field">
                          <textarea
                            name="companyaddress"
                            value={
                              data.companyaddress ? data.companyaddress : ""
                            }
                            onChange={handleChange}
                            rows="1"
                            cols="50"
                          ></textarea>
                        </div>
                      </div>
                      {dataEr.companyaddress ? (
                        <div className="error text-danger">
                          {dataEr.companyaddress}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="col-6 form_inner p-2">
                      <label for="">Phone Number</label>
                      <div class="input_icons">
                        <div class="input_field">
                          <input
                            value={
                              data.companytelephonenumber
                                ? data.companytelephonenumber
                                : ""
                            }
                            name="companytelephonenumber"
                            onChange={handleChange}
                            type="text"
                            placeholder="Phone Number"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6 form_inner p-2">
                      <label for="">Website Address</label>
                      <div class="input_icons">
                        <div class="input_field">
                          <input
                            type="text"
                            value={data.website ? data.website : ""}
                            name="website"
                            onChange={handleChange}
                            placeholder="Website Address"
                          />
                        </div>
                      </div>
                    </div>
                    <div className=""></div>
                    <ImgInput
                      style={{ div: "col-md-3 col-6" }}
                      title={"Company Logo"}
                      // img_src={userData.profile_img ? userData.profile_img  : formInputs.profile_img ? formInputs.profile_img : : ImgInput  }
                      img_src={
                        data.companyLogoFile
                          ? data.companyLogo
                          : data.companyLogo
                            ? process.env.REACT_APP_API_BASEURL + data.companyLogo
                            : DefaultImg
                      }
                      input_ref_id="company_img"
                      onChange={(value, file) => {
                        console.log("value", value);
                        console.log("file", file);
                        setData({
                          ...data,
                          companyLogo: value,
                          companyLogoFile: file,
                        });
                      }}
                    />
                  </div>
                  
                  <div class="accident">
                    <div class="accident_text">
                      <h5>Active Sections</h5>
                    </div>
                  </div>

                  <div className="row mt-4 w-100">
                    <div className="col-8 mb-4">
                      <div className="d-flex justify-content-between">

                      </div>
                    </div>
                    <div className="col-4 mb-4">
                      <div className="d-flex justify-content-end">
                        <label className="p-2" for="">
                          {" "}
                          Select All
                        </label>

                        <div class="form-check form-switch">
                          <input
                            class="sub-permission-check form-check-input form-check-input-color d-block"
                            style={{ width: "50px" }}
                            type="checkbox"
                            role={`switch`}
                            id={`flexSwitchCheckChecked`}
                            checked={data.permissions.filter(p => p.value).length == data.permissions.length}
                            onChange={(e) => {
                              setData(d => ({
                                  ...d,
                                  permissions: d.permissions.map(p => {
                                      return { ...p, value: e.target.checked };
                                  })
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="company-active-sections">
                      {permissions.map((v, i) => (
                        <div className='row mt-4'>
                          <h5 className='mb-4'>{v.name}</h5>
                          {/* Don't allow 'company' to be togglable.  */}
                          {v.children.filter(px => ! [ 'companies', 'company' ].includes(px.name) ).map(v=>(<div className='col-md-6 col-xl-4 col-sm-6  mb-4'>
                              <div className='d-flex justify-content-between'>
                                  <label for={`flexSwitchCheckChecked-${i}`}>{v.label} </label>
                                  <div class="form-check form-switch">
                                    <input
                                      class="sub-permission-check form-check-input form-check-input-color d-block"
                                      style={{ width: "50px" }}
                                      type="checkbox"
                                      role={`switch-${i}`}
                                      id={`flexSwitchCheckChecked-${i}`}
                                      checked={data.permissions.filter(p => p.name === v.name)[0].value}
                                      onChange={e => {
                                          setData(d => ({
                                              ...d,
                                              permissions: d.permissions.map(p => {
                                                  if (p.name === v.name) {
                                                      return { ...p, value: e.target.checked }
                                                  } else {
                                                      return p
                                                  }
                                              })
                                          }))
                                      }} />
                                  </div>
                              </div>
                            </div>))}
                          </div>
                        ))}
                  </div>

                </div>
              </form>
              <div class="ps-2 signature">
                <div class="sign_btns">
                  <div>
                    {!loading ? (
                      <button
                        type="button"
                        class="submit"
                        disabled={loading}
                        onClick={(e) => handleSubmit(e)}
                      >
                        Submit
                      </button>
                    ) : (
                      <SubmitLoader />
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <PageLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default EditDetails;
