import "./Mandatory.css"
import Header from '../Header/Header'
import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import axios from '../../helpers/request';
import { toast } from 'react-toastify';

import { useAppState } from '../../state';

const EMAIL_REGEX = new RegExp(/\S+@\S+\.\S+/);

function MandatoryForm() {
    const { user: loggedUser, refreshUserDetails } = useAppState();
    const [user, setData] = useState({
        fullName: '',
        email: '',
        role: '',
        companyName: '',
        type0fbusiness: '',
        companyaddress: '',
        companytelephonenumber: '',
        nameofownerlandlord: '',
        landlordtelephonenumber: '',
        firesafetymanager: '',
        deputyfiresafetymanager: '',
        assemblypoints: '',
        staffAmount: ""
    });

    const params = useParams();
    const userId = params?.userId
    const location = useLocation();
    const history = useHistory();
    const mandatoryForm = location.pathname.includes("required")
    const isEditable = mandatoryForm || location.pathname.split("/").includes('user-edit');

    const roles = [
        { label: 'Admin', value: 'admin' },
        { label: 'Super Admin', value: 'superadmin' },
        { label: 'Employee', value: 'employee' },
        { label: 'Supervisor', value: 'supervisor' },
    ];

    const filteredRoles = roles.filter((role) => {
        if (loggedUser.role === "superadmin" || role.value !== "superadmin") {
            return role
        }
    })

    const [adding, setAdding] = useState(false);


    const getUserDetails = (userId) => {
        axios.get('/user/' + userId).then(async (response) => {
            if (response.status === 200 && response.data) {
                setData(response.data)
            } else {
                throw new Error(response?.error);
            }
        });
    }


    useEffect(() => {
        console.log(loggedUser)
        if (mandatoryForm) {
            getUserDetails(loggedUser.id)
        } else {
            getUserDetails(userId)
        }
    }, [userId, mandatoryForm])



    const handleChange = (event) => {
        const { name, value } = event.target;

        setData(prevUser => ({
            ...prevUser,
            [name]: value
        }));
    }

    const isValidData = () => {
        const {
            type0fbusiness,
            companyName,
            staffAmount,
            fullName,
            companyaddress,
            companytelephonenumber,
            firesafetymanager,
            deputyfiresafetymanager,
            assemblypoints,
            nameofownerlandlord,
            landlordtelephonenumber,
        } = user;

        let isValid = true;
        let message = '';
        console.log(user)
        if (!type0fbusiness || !companyName || !staffAmount || !fullName || !companyaddress || !companytelephonenumber || !firesafetymanager || !deputyfiresafetymanager || !assemblypoints || !nameofownerlandlord || !landlordtelephonenumber) {
            isValid = false;
            message = "All fields are mandatory";
        } 
        return { isValid, message };
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const { isValid, message } = isValidData();

        if (isValid) {
            setAdding(true);
            axios.put('/user/' + (mandatoryForm ? loggedUser.id : userId), { data: {...user,informationAdded:1} })
                .then(function (response) {
                    setAdding(false);
                    if (mandatoryForm) {
                        history.push("/")
                        refreshUserDetails("/")
                    } else {
                        getUserDetails(userId)
                    }
                    toast.success("User updated successfully");
                })
                .catch(function (error) {
                    setAdding(false);
                    toast.error(error?.response?.data?.message || "Unable to process this request");
                });
        } else {
            toast.error(message);
        }
    }

    const isViewStatement = useMemo(() => {
        if (location.pathname.includes("view")) {
            return true;
        } else {
            return false;
        }
    });
    return (
        <div class="middle_page">
            <Header steps={[{ name: "Mandatory Details" }]} />
            <div class="main_container">
                <div>
                    <div class="accident">
                        <div class="accident_text">
                            <h5>Company Information</h5>
                        </div>
                        <div class="cross_icon"><img src={require("../../images/minus.png")} alt="" /></div>
                    </div>
                    <form>
                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div class="form_inner p-2">
                                    <label for="">Type of Business </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={user.type0fbusiness} name="type0fbusiness" disabled={isViewStatement} onChange={handleChange} placeholder="Type of Business" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Name of Premises </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={user.companyName} name="companyName" disabled={isViewStatement} onChange={handleChange} placeholder="Name of Premises" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Amount of staff </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="number" value={user.staffAmount} name="staffAmount" disabled={isViewStatement} onChange={handleChange} placeholder="Amount of staff" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Name of person in charge </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={user.fullName} name="fullName" disabled={isViewStatement} placeholder="Name of person in charge" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Company Address </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={user.companyaddress} name="companyaddress" disabled={isViewStatement} onChange={handleChange} placeholder="Company Address" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Company Telephone Number </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={user.companytelephonenumber} name="companytelephonenumber" disabled={isViewStatement} onChange={handleChange} placeholder="Company Telephone Number" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Fire Safety Manager </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={user.firesafetymanager} name="firesafetymanager" disabled={isViewStatement} onChange={handleChange} placeholder="Fire Safety Manager" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Deputy Fire Safety Manager </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={user.deputyfiresafetymanager} name="deputyfiresafetymanager" disabled={isViewStatement} onChange={handleChange} placeholder="Deputy Fire Safety Manager" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Assembly Points </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={user.assemblypoints} name="assemblypoints" disabled={isViewStatement} onChange={handleChange} placeholder="Assembly Points" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Name of Owner/Landlord </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={user.nameofownerlandlord} name="nameofownerlandlord" disabled={isViewStatement} onChange={handleChange} placeholder="Name of Owner/Landlord" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Owner/Landlord Telephone Number </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={user.landlordtelephonenumber} name="landlordtelephonenumber" disabled={isViewStatement} onChange={handleChange} placeholder="Owner/Landlord Telephone Number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="signature">
                        <div class="sign_btns">
                            <div>
                                <button class="submit" disabled={adding} onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MandatoryForm