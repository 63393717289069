import axios from "../libs/axios";

class RiskAssessmentService {
    getRiskAssessments(params) {
        return axios.get(`/risk-assessments`, params);
    }
    getRiskAssessment(id) {
        return axios.get(`/risk-assessment/${id}`);
    }

    addRiskAssessment(payload) {
        return axios.post(`/add/risk-assessment`, payload);
    }

    updateRiskAssessment(id, payload) {
        return axios.put(`/risk-assessments/${id}`, payload);
    }

    deleteRiskAssessment(id) {
        return axios.delete(`/risk-assessments/${id}`);
    }
}

export default new RiskAssessmentService();
