import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { commonService } from "../common/common.service";
import fileIcon from "../../images/openfolderwhite.svg";
import "./SafetyHarnessIssues.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";

const AddSafetyHarnessIssue = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany } = useAppState()
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    siteId: null,
    departmentId: null,
    companyId: companyId,
    contractorId: null,
    addedBy: companyId,

    receivedBy: null,
    harnessSerialNumber: null,
    accessoriesSerialNumber: null,
    dateReceived: null,
    timeReceived: null,
    conditionOnIssue: null,
    files: "",
  };

  const [fieldData, setFieldData] = useState(fieldValues);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({ ...fieldValues });
  const [siteUsers, setSiteUsers] = useState([]);
  const [contractorData, setContractorData] = useState([]);
  const signatureVerifyRef = useRef();

  // check if /return is present in URL
  const isReturning = location.pathname.includes( "/return" );

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID( userCompanyId );

          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
            contractorId: ( user.role.includes("admin") ? fieldData.contractorId : res.data[0].contractorId )
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const fetchSafetyHarnessIssueDetails = () => {

    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/safety-harness-issues/details`,
        {
          safetyHarnessIssueId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0], existingFiles: res.data[0].files });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRef.current.isEmpty()) {
        res(null);
        rej(new Error("Signature is empty"));
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );

          console.log(file);
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file)
        });
      }
    })
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchSafetyHarnessIssueDetails()
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {

    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value)
      }
    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;

      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Employer is required.";
        }
        break;
      
      case "receivedBy":
        if (!value) {
          message = stateObj[name] = "Receiver name is required.";
        }
        break;
      
      case "harnessSerialNumber":
        if (!value) {
          message = stateObj[name] = "Harness Serial Number is required.";
        }
        break;
      
      case "accessoriesSerialNumber":
        if (!value) {
          message = stateObj[name] = "Accessories Serial Number is required.";
        }
        break;
      
      case "dateReceived":
        if (!value) {
          message = stateObj[name] = "Date Received is required.";
        }
        break;
      
      case "timeReceived":
        if (!value) {
          message = stateObj[name] = "Time Received is required.";
        }
        break;
      
      case "conditionOnIssue":
        if (!value) {
          message = stateObj[name] = "Condition on Issue is required.";
        }
        break;

      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      const verifySignature = await handleSaveVerifySign();
      handleSubmitAfterSave(verifySignature);
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = ( verifySignature ) => {
    let errorShow = {
      companyId: fieldData.companyId,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      contractorId: fieldData.contractorId,
      
      receivedBy: fieldData.receivedBy,
      harnessSerialNumber: fieldData.harnessSerialNumber,
      accessoriesSerialNumber: fieldData.accessoriesSerialNumber,
      dateReceived: fieldData.dateReceived,
      timeReceived: fieldData.timeReceived,
      conditionOnIssue: fieldData.conditionOnIssue,      
    };


    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      (
        ! isReturning &&
        fieldData.companyId != null &&
        fieldData.siteId != null &&
        fieldData.contractorId != null &&
        fieldData.receivedBy != null &&
        fieldData.harnessSerialNumber != null &&
        fieldData.accessoriesSerialNumber != null &&
        fieldData.dateReceived != null &&
        fieldData.timeReceived != null &&
        fieldData.conditionOnIssue != null
      ) || (
        isReturning &&
        fieldData.returnedBy != null &&
        fieldData.dateReturned != null &&
        fieldData.timeReturned != null &&
        fieldData.conditionOnReturn != null
      )
    ) {
      
      const form = new FormData();

      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("safetyHarnessIssues") : v.permits.includes("safetyHarnessIssuesreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      
      if ( isReturning ) {
        form.append("returnedBy", fieldData.returnedBy );
        form.append("dateReturned", commonService.formatDate( fieldData.dateReturned,"YYYY-MM-DD" ) );
        form.append("timeReturned", fieldData.timeReturned );
        form.append("conditionOnReturn", fieldData.conditionOnReturn);
      } else {
        form.append("departmentId", fieldData.departmentId == null ? null : fieldData.departmentId);
        form.append("siteId", fieldData == null ? null : fieldData.siteId);
        form.append("companyId", companyId);
        form.append("addedByInCompany", user.id);
        form.append("contractorId", fieldData.contractorId);
        
        form.append("receivedBy", fieldData.receivedBy);
        form.append("harnessSerialNumber", fieldData.harnessSerialNumber);
        form.append("accessoriesSerialNumber", fieldData.accessoriesSerialNumber);
        form.append("dateReceived", commonService.formatDate( fieldData.dateReceived,"YYYY-MM-DD" ) );
        form.append("timeReceived", fieldData.timeReceived );
        form.append("conditionOnIssue", fieldData.conditionOnIssue);
      }

      form.append("verifySignature", verifySignature);

      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }

      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
      
      setLoading(true);
      const apiCall =
        id ?
          (
            isReturning ? axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/return/safety-harness-issue/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) : axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update-details/safety-harness-issue/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })
          ) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/safety-harness-issue`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        
        id ? history.push('/safety-harness-issue/' + id) : history.push('/view-own/safety-harness-issues');

        setFieldData(fieldData);
      })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }))

  const departmentOptions = allDepartment.filter(v => v.siteId == fieldData.siteId).map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentId",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">
        { ! isReturning && <>
          <div className="addaccess_first_container mb-20">
            <div className="general_details">General Details</div>

            <div className="d-flex flex-wrap">
              
              <div className="col-md-4">
                <div className="w-100 p-2">
                  <label for="">
                    <div className="require-label">
                      Site <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="w-100">
                      <Select
                        onChange={handleChange}
                        placeholder="Select Sites"
                        options={siteOptions}
                        {...(id ? {
                          value: siteOptions.filter(v => v.value == fieldData.siteId),
                          isDisabled: true
                        } : {})}
                      />
                    </div>
                  </div>
                  {error.siteId && <span className="err">{error.siteId}</span>}
                </div>
              </div>

              <div className="col-md-4">
                <div className="w-100 p-2">
                  <label for="">
                    <div className="require-label">
                      Department <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="w-100">
                      <Select
                        onChange={handleChange}
                        placeholder="Select Department"
                        options={departmentOptions}
                        {...(id ? {
                          value: departmentOptions.filter(v => v.value == fieldData.departmentId),
                          isDisabled: true
                        } : {})}
                      />
                    </div>
                  </div>
                  {error.departmentId && (
                    <span className="err">{error.departmentId}</span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="w-100 p-2 location_ladder">
                  <label for="">
                    <div className="require-label">
                      Received By <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="receivedBy"
                        {...(id ? {
                          value: fieldData.receivedBy,
                        } : {})}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {error.receivedBy && (
                    <span className="err">{error.receivedBy}</span>
                  )}
                </div>
              </div>
              
              <div className="col-md-4">
                <div className="w-100 p-2 location_ladder">
                  <label for="">
                    <div className="require-label">
                      Harness Serial Number <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="harnessSerialNumber"
                        {...(id ? {
                          value: fieldData.harnessSerialNumber,
                        } : {})}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {error.harnessSerialNumber && (
                    <span className="err">{error.harnessSerialNumber}</span>
                  )}
                </div>
              </div>
              
              <div className="col-md-4">
                <div className="w-100 p-2 location_ladder">
                  <label for="">
                    <div className="require-label">
                      Accessories Serial Number <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="accessoriesSerialNumber"
                        {...(id ? {
                          value: fieldData.accessoriesSerialNumber,
                        } : {})}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {error.accessoriesSerialNumber && (
                    <span className="err">{error.accessoriesSerialNumber}</span>
                  )}
                </div>
              </div>
            </div>

          </div>
        </>}

        <div className="row">
          { ! isReturning ? <>
            <div className="col-md-6 mb-20">
              <div className="addaccess_first_container">
                <div className="general_details">Receiver Information</div>
                  
                  <div className="d-flex flex-wrap">
                    
                    <div className="col-md-12">
                      <div className="w-100 p-2">
                        <label for="">
                          <div className="require-label">
                            Employer <span className="text-danger">*</span>
                          </div>
                        </label>
                        <div className="input_icons">
                          <div className="w-100">
                            <Select
                              onChange={handleChange}
                              placeholder="Select Contractor"
                              value={fieldData.contractorId ? contractorData.filter(v => v.id == fieldData.contractorId).map(d => ({
                                label: d.name,
                                value: d.id,
                                name: "contractorId",
                              })) : null}
                              options={contractorData.map((d) => ({
                                label: d.name,
                                value: d.id,
                                name: "contractorId",
                              }))}
                            />
                          </div>
                        </div>
                        {error.contractorId && (
                          <span className="err">{error.contractorId}</span>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-wrap w-100">
                      <div className="col-6 p-2 location_access">
                        <label for="">
                          <div className="require-label">
                            {" "}
                            Date Received <span className="text-danger">*</span>
                          </div>
                        </label>
                        <div className="input_icons">
                          <div className="input_field">
                            <input value={fieldData.dateReceived? commonService.formatDate( fieldData.dateReceived,"YYYY-MM-DD" ) : ''} type="date" onChange={handleChange} name="dateReceived" />
                          </div>
                        </div>
                        {error.dateReceived && <span className="err">{error.dateReceived}</span>}
                      </div>

                      <div className="col-6 p-2 location_access">
                        <label for="">
                          <div className="require-label">
                            {" "}
                            Time Received <span className="text-danger">*</span>{" "}
                          </div>
                        </label>
                        <div className="input_icons">
                          <div className="input_field">
                            <input value={fieldData.timeReceived} type="time" pattern="hh:mm" onChange={handleChange} name="timeReceived" />
                          </div>
                        </div>
                        {error.timeReceived && <span className="err">{error.timeReceived}</span>}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form_inner p-2 w-100">
                        <label for="">
                          Condition on Issue <span className="text-danger">*</span>
                        </label>
                        <div className="input_icons">
                          <div className="input_field">
                            <textarea
                              name="conditionOnIssue"
                              rows="5"
                              className="w-100"
                              cols="100"
                              value={fieldData.conditionOnIssue}
                              onChange={handleChange}
                              placeholder=""
                            ></textarea>
                          </div>
                        </div>
                        {error.conditionOnIssue && (
                          <span className="err">{error.conditionOnIssue}</span>
                        )}
                      </div>
                    </div>

                  </div>

              </div>
            </div>
          </> : <>
            <div className="col-md-6 mb-20">
              <div className="addaccess_first_container">
                <div className="general_details">Returner Information</div>
                  
                  <div className="d-flex flex-wrap">
                    
                    <div className="col-md-12">
                      <div className="w-100 p-2 location_ladder">
                        <label for="">
                          <div className="require-label">
                            Returned By <span className="text-danger">*</span>
                          </div>
                        </label>
                        <div className="input_icons">
                          <div className="w-100">
                            <input
                              type="text"
                              name="returnedBy"
                              value={fieldData?.returnedBy}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {error.contractorId && (
                          <span className="err">{error.contractorId}</span>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-wrap w-100">
                      <div className="col-6 p-2 location_access">
                        <label for="">
                          <div className="require-label">
                            {" "}
                            Date Returned <span className="text-danger">*</span>
                          </div>
                        </label>
                        <div className="input_icons">
                          <div className="input_field">
                            <input value={fieldData.dateReturned? commonService.formatDate( fieldData.dateReturned,"YYYY-MM-DD" ) : ''} type="date" onChange={handleChange} name="dateReturned" />
                          </div>
                        </div>
                        {error.dateReturned && <span className="err">{error.dateReturned}</span>}
                      </div>

                      <div className="col-6 p-2 location_access">
                        <label for="">
                          <div className="require-label">
                            {" "}
                            Time Returned <span className="text-danger">*</span>{" "}
                          </div>
                        </label>
                        <div className="input_icons">
                          <div className="input_field">
                            <input value={fieldData.timeReturned} type="time" pattern="hh:mm" onChange={handleChange} name="timeReturned" />
                          </div>
                        </div>
                        {error.timeReturned && <span className="err">{error.timeReturned}</span>}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form_inner p-2 w-100">
                        <label for="">
                          Condition on Return <span className="text-danger">*</span>
                        </label>
                        <div className="input_icons">
                          <div className="input_field">
                            <textarea
                              name="conditionOnReturn"
                              rows="5"
                              className="w-100"
                              cols="100"
                              value={fieldData.conditionOnReturn}
                              onChange={handleChange}
                              placeholder=""
                            ></textarea>
                          </div>
                        </div>
                        {error.conditionOnReturn && (
                          <span className="err">{error.conditionOnReturn}</span>
                        )}
                      </div>
                    </div>

                  </div>

              </div>
            </div>
          </>}

          <div className="col-md-6 mb-20">
            <div className="addaccess_first_container">
              <div className="general_details">Signature</div>
              <div className="p-2">
                <div className="sign_image submitter_signature">
                  <SignatureCanvas
                    ref={signatureVerifyRef}
                    canvasProps={{ width: 263, height: 270 }}
                  />
                  <span
                    className="cancel-custom cursor-pointer"
                    onClick={handleClearVerifySign}
                  >
                    <i
                      className="clear_btn  text-danger fa fa-times-circle"
                      aria-hidden="true"
                    ></i>
                  </span>
                  </div>
              </div>
            </div>
          </div>

        </div>

        <div className="addladder_fifth_container mb-20">
          <div className="general_details">Upload Files</div>
          <div className="d-flex">
            {!!files.length && files.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
              <img
                className="obj_fit_img obj-fit-cover rounded"
                src={imgPreviewUrl}
                alt=""
                height="150"
                width="150"
              />
            </div>))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
          </div>
        </div>

        {!isReturning && <>
          <div className="harness-warning popup-warning-notice">
            <p>This harness has been inspected prior to issue, is complete with lanyard and hooks and is deemed to be in good conditions, any defects are to be recorded on form 513 and not used.</p>
          </div> 
        </>}

        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? ( isReturning ? "Return" : "Update Data" ) : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn" onClick={() => history.push("/safety-harness-issues/")}>cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddSafetyHarnessIssue;
