import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import "./Dig.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import { FormHelperText } from "@mui/material";
import { commonService } from "../common/common.service";

const AddDig = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany } = useAppState();
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    siteId: null,
    departmentId: null,
    contractorId: user.contractorId && !id ? user.contractorId : null,
    descriptionOfWorks: null,
    supervisor: null,
    spaRequired: null,
    maxWidth: null,
    maxDepth: null,
    prHotworks: null,
    prConfined: null,
    prElectrical: null,
    prOther: null,
    arLocal: null,
    arElectrical: null,
    arClient: null,
    arOther: null,
    drawingAvailable: null,
    drawingsChecked: null,
    groundScanned: null,
    undergroundCables: null,
    plantRequired: null,
    equipmentRequired: null,
    contactPhone: null,
    locationOfWorks: null,
    date: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
    finishDate: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
    finishTime: (new Date()).toLocaleString('en-GB').substring(12, 17),
    verifySignature: null,
    companyId: companyId,
    addedBy: companyId,
    files: "",
    resubmitComment: null,

    lugsSupervisor: null,
    spotterName: null,
    ramsSPARef: null,
    excavationSupportsRequired: null,
    supportsDescription: null,
    materialsProtectionMeans: null,
    peopleVehiclesProtectionMeans: null,
    nearbyStructuresProtectionMeans: null,
    safeAccessProtectionMeans: null,
    buntingRequired: null,
    overheadPractices: null,
    trialPitRequired: null,
    termsAgreed: null,
    catScanBy: null,
    catScanDate: null,
    isScannerCertified: null,
    certExpiryDate: null,
  };
  const [fieldData, setFieldData] = useState(fieldValues);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({ ...fieldValues, contractorId: null, date: null, finishDate: null, finishTime: null });
  const [contractorData, setContractorData] = useState([]);
  const [siteUsers, setSiteUsers] = useState([])

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(
            userCompanyId
          );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchDigDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/dig/permit/details`,
        {
          digId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0], existingFiles: res.data[0].files });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        console.log("errr", err);
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchDigDetails()
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {
    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {
      
      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else if ((name == "finishTime")) {
      let val = value
      if (fieldData.date == (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")) {
        const localTime = (new Date()).toLocaleString('en-GB').substring(12, 17)
        if (parseInt(e.target.value.split(":")[0]) <= parseInt(localTime.split(":")[0])) {
          if (parseInt(e.target.value.split(":")[1]) <= parseInt(localTime.split(":")[1])) {
            val = localTime
            toast.error("Selecting a time in the past is not possible")
          }
        }
      }
      setFieldData({
        ...fieldData,
        [name]: val,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value)
      }
    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      case "addedBy":
        if (!value) {
          message = stateObj[name] = "Added by is required.";
        }
        break;
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;
      case "supervisor":
        if (!value) {
          message = stateObj[name] = "Supervisor is required.";
        }
        break;
      case "descriptionOfWorks":
        if (!value) {
          message = stateObj[name] = "Description of Works is required.";
        }
        break;
      case "spaRequired":
        if (!value) {
          message = stateObj[name] = "1.25 Method Statement/SPA required is required";
        }
        break;
      case "maxWidth":
        if (!value) {
          message = stateObj[name] = "Max Width is required";
        }
        break;
      case "maxDepth":
        if (!value) {
          message = stateObj[name] = "Max Depth is required";
        }
        break;
      case "prHotworks":
        if (!value) {
          message = stateObj[name] = "Hot Works is required";
        }
        break;
      case "prConfined":
        if (!value) {
          message = stateObj[name] = "Confined Space is required";
        }
        break;
      case "prElectrical":
        if (!value) {
          message = stateObj[name] = "Live Electrical is required";
        }
        break;
      case "prOther":
        if (!value) {
          message = stateObj[name] = "Other (Client) is required";
        }
        break;
      case "arLocal":
        if (!value) {
          message = stateObj[name] = "Local Authority is required";
        }
        break;
      case "arElectrical":
        if (!value) {
          message = stateObj[name] = "Electrical Company is required";
        }
        break;
      case "arClient":
        if (!value) {
          message = stateObj[name] = "Client is required";
        }
        break;
      case "arOther":
        if (!value) {
          message = stateObj[name] = "Other is required";
        }
        break;
      case "drawingAvailable":
        if (!value) {
          message = stateObj[name] = "Are drawings available? is required";
        }
        break;
      case "drawingsChecked":
        if (!value) {
          message = stateObj[name] = "Drawings Checked is required";
        }
        break;
      case "groundScanned":
        if (value==null) {
          message = stateObj[name] = "Grounds Scanned is required";
        }
        break;
      case "undergroundCables":
        if (!value) {
          message = stateObj[name] = "Underground Cables/Services is required";
        }
        break;
      case "plantRequired":
        if (!value) {
          message = stateObj[name] = "Plant Required field is required.";
        }
        break;
      case "equipmentRequired":
        if (!value) {
          message = stateObj[name] = "Equipment Required field is required.";
        }
        break;
      case "contactPhone":
        if (!value) {
          message = stateObj[name] = "Contact Phone is required.";
        }
        break;
      case "locationOfWorks":
        if (!value) {
          message = stateObj[name] = "Location of works is required.";
        }
        break;
      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Contractor Id is required.";
        }
        break;
      case "date":
        if (!value) {
          message = stateObj[name] = "date is required.";
        }
        break;
      case "finishDate":
        if (!value) {
          message = stateObj[name] = "Finish Date is required.";
        }
        break;
      case "finishTime":
        if (!value) {
          message = stateObj[name] = "Finish Time is required.";
        }
        break;
      case "lugsSupervisor":
        if (!value) {
          message = stateObj[name] = "Contractor Lugs Supervisor is required.";
        }
        break;
      case "spotterName":
        if (!value) {
          message = stateObj[name] = "Spotter Name is required.";
        }
        break;
      case "ramsSPARef":
        if (!value) {
          message = stateObj[name] = "RAMS / SPA Ref No. is required";
        }
        break;
      case "excavationSupportsRequired":
        if (value==null) {
          message = stateObj[name] = "Escavation Supports Required field is required";
        }
        break;
      case "materialsProtectionMeans":
        if (!value) {
          message = stateObj[name] = "Materials Protection Means field is required";
        }
        break;
      case "peopleVehiclesProtectionMeans":
        if (!value) {
          message = stateObj[name] = "People Vehicle Protection Means field is required";
        }
        break;
      case "nearbyStructuresProtectionMeans":
        if (!value) {
          message = stateObj[name] = "Nearby Structures Protection Means field is required";
        }
        break;
      case "safeAccessProtectionMeans":
        if (!value) {
          message = stateObj[name] = "Safe Access Protection Means field is required";
        }
        break;
      case "buntingRequired":
        if (value == null) {
          message = stateObj[name] = "Bunting Required field is required";
        }
        break;
      case "overheadPractices":
        if (value == null) {
          message = stateObj[name] = "Ovrehead Practices field is required";
        }
        break;
      case "trialPitRequired":
        if (value == null) {
          message = stateObj[name] = "Confirm if a trial pit is required";
        }
        break;
      case "termsAgreed":
        if (value==null) {
          message = stateObj[name] = "Terms must be agreed";
        }
        break;
      case "catScanBy":
        if (!value) {
          message = stateObj[name] = "Cat & Genny Scan carried out by is required";
        }
        break;
      case "catScanDate":
        if (!value) {
          message = stateObj[name] = "Cat & Genny Scan Date is required";
        }
        break;
      case "isScannerCertified":
        if (value==null) {
          message = stateObj[name] = "Scanner certified is required";
        }
        break;
      case "certExpiryDate":
        if (!value) {
          message = stateObj[name] = "Certification Expiry Date is required";
        }
        break;
      case "verifySignature":
        if (!value) {
          message = stateObj[name] = "Upload signature.";
        }
        break;
      case "files":
        if (!value) {
          message = stateObj[name] = "You must select at least 1 file to upload.";
        }
        break;
      
      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      const [verifySignature] = await Promise.all([handleSaveVerifySign()])
      handleSubmitAfterSave(verifySignature)
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (verifySignature) => {
    let errorShow = {
      companyId: fieldData.companyId,
      addedBy: fieldData.addedBy,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      supervisor: fieldData.supervisor,
      descriptionOfWorks: fieldData.descriptionOfWorks,
      // spaRequired: fieldData.spaRequired,
      maxDepth: fieldData.maxDepth,
      maxWidth: fieldData.maxWidth,
      prHotworks: fieldData.prHotworks,
      prConfined: fieldData.prConfined,
      prElectrical: fieldData.prElectrical,
      prOther: fieldData.prOther,
      // arLocal: fieldData.arLocal,
      // arElectrical: fieldData.arElectrical,
      // arClient: fieldData.arClient,
      // arOther: fieldData.arOther,
      // drawingAvailable: fieldData.drawingAvailable,
      // drawingsChecked: fieldData.drawingsChecked,
      groundScanned: fieldData.groundScanned,
      // undergroundCables: fieldData.undergroundCables,
      plantRequired: fieldData.plantRequired,
      equipmentRequired: fieldData.equipmentRequired,
      contactPhone: fieldData.contactPhone,
      locationOfWorks: fieldData.locationOfWorks,
      contractorId: user.contractorId||user.role=="admin"?fieldData.contractorId:true,
      date: fieldData.date,
      finishDate: fieldData.finishDate,
      finishTime: fieldData.finishTime,

      lugsSupervisor: fieldData.lugsSupervisor,
      spotterName: fieldData.spotterName,
      ramsSPARef: fieldData.ramsSPARef,
      excavationSupportsRequired: fieldData.excavationSupportsRequired,
      
      materialsProtectionMeans: fieldData.materialsProtectionMeans,
      peopleVehiclesProtectionMeans: fieldData.peopleVehiclesProtectionMeans,
      nearbyStructuresProtectionMeans: fieldData.nearbyStructuresProtectionMeans,
      safeAccessProtectionMeans: fieldData.safeAccessProtectionMeans,
      buntingRequired: fieldData.buntingRequired,
      overheadPractices: fieldData.overheadPractices,
      trialPitRequired: fieldData.trialPitRequired,
      termsAgreed: fieldData.termsAgreed,
      catScanBy: fieldData.catScanBy,
      catScanDate: fieldData.catScanDate,
      isScannerCertified: fieldData.isScannerCertified,
      certExpiryDate: fieldData.certExpiryDate,

      verifySignature: verifySignature,
      files: (fieldData.spaRequired == 1) ? files.length !== 0 : true,
    };
    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.companyId !== null &&
      fieldData.addedBy !== null &&
      fieldData.siteId !== null &&
      fieldData.departmentId !== null &&
      fieldData.supervisor !== null &&
      fieldData.descriptionOfWorks !== null &&
      // fieldData.spaRequired !== null &&
      fieldData.maxWidth !== null &&
      fieldData.maxWidth !== null &&
      fieldData.prHotworks !== null &&
      fieldData.prConfined !== null &&
      fieldData.prElectrical !== null &&
      fieldData.prOther !== null &&
      // fieldData.arLocal !== null &&
      // fieldData.arElectrical !== null &&
      // fieldData.arClient !== null &&
      // fieldData.arOther !== null &&
      // fieldData.drawingAvailable !== null &&
      // fieldData.drawingsChecked !== null &&
      fieldData.groundScanned !== null &&
      // fieldData.undergroundCables !== null &&
      fieldData.plantRequired !== null &&
      fieldData.equipmentRequired !== null &&
      fieldData.contactPhone !== null &&
      fieldData.locationOfWorks !== null &&
      (user.contractorId||user.role=="admin"?fieldData.contractorId !== null:true) &&
      fieldData.date !== null &&
      fieldData.finishDate !== null &&
      fieldData.finishTime !== null &&

      fieldData.lugsSupervisor != null &&
      fieldData.spotterName != null &&
      fieldData.ramsSPARef != null &&
      fieldData.excavationSupportsRequired != null &&
      
      fieldData.materialsProtectionMeans != null &&
      fieldData.peopleVehiclesProtectionMeans != null &&
      fieldData.nearbyStructuresProtectionMeans != null &&
      fieldData.safeAccessProtectionMeans != null &&
      fieldData.buntingRequired != null &&
      fieldData.overheadPractices != null &&
      fieldData.trialPitRequired != null &&
      fieldData.termsAgreed != null &&
      fieldData.catScanBy != null &&
      fieldData.catScanDate != null &&
      fieldData.isScannerCertified != null &&
      fieldData.certExpiryDate != null &&

      // ((fieldData.spaRequired == 1) ? files.length !== 0 : true) &&
      verifySignature !== null
    ) {
      const form = new FormData();
      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("digPermits") : v.permits.includes("digPermitsreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      form.append("departmentId", fieldData.departmentId == null ? null : fieldData.departmentId);
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      form.append("supervisor", fieldData.supervisor);
      form.append("descriptionOfWorks", fieldData.descriptionOfWorks);
      form.append("locationOfWorks", fieldData.locationOfWorks);
      // form.append("spaRequired", fieldData.spaRequired);
      form.append("maxWidth", fieldData.maxWidth);
      form.append("maxDepth", fieldData.maxDepth);
      
      form.append("prHotworks", fieldData.prHotworks);
      form.append("prConfined", fieldData.prConfined);
      form.append("prElectrical", fieldData.prElectrical);
      form.append("prOther", fieldData.prOther);
      // form.append("arLocal", fieldData.arLocal);
      // form.append("arElectrical", fieldData.arElectrical);
      // form.append("arClient", fieldData.arClient);
      // form.append("arOther", fieldData.arOther);
      // form.append("drawingAvailable", fieldData.drawingAvailable);
      // form.append("drawingsChecked", fieldData.drawingsChecked);
      
      form.append("groundScanned", fieldData.groundScanned);
      
      // form.append("undergroundCables", fieldData.undergroundCables);
      form.append("plantRequired", fieldData.plantRequired);
      form.append("equipmentRequired", fieldData.equipmentRequired);
      form.append("contactPhone", fieldData.contactPhone);
      form.append("contractorId", fieldData.contractorId);
      form.append("date", fieldData.date);
      form.append("finishDate", fieldData.finishDate);
      form.append("finishTime", fieldData.finishTime);
      form.append("verifySignature", verifySignature);
      form.append("companyId", companyId);
      form.append("addedBy", companyId);
      form.append("addedByInCompany", user.id);
      form.append("approvedByName", user.fullName);

      form.append( "lugsSupervisor", fieldData.lugsSupervisor );
      form.append( "spotterName", fieldData.spotterName );
      form.append( "ramsSPARef", fieldData.ramsSPARef );
      form.append( "excavationSupportsRequired", fieldData.excavationSupportsRequired );
      form.append( "supportsDescription", fieldData.supportsDescription );
      form.append( "materialsProtectionMeans", fieldData.materialsProtectionMeans );
      form.append( "peopleVehiclesProtectionMeans", fieldData.peopleVehiclesProtectionMeans );
      form.append( "nearbyStructuresProtectionMeans", fieldData.nearbyStructuresProtectionMeans );
      form.append( "safeAccessProtectionMeans", fieldData.safeAccessProtectionMeans );
      form.append( "buntingRequired", fieldData.buntingRequired );
      form.append( "overheadPractices", fieldData.overheadPractices );
      form.append( "trialPitRequired", fieldData.trialPitRequired );
      form.append( "termsAgreed", fieldData.termsAgreed );
      form.append( "catScanBy", fieldData.catScanBy );
      form.append( "catScanDate",  commonService.formatDate( fieldData.catScanDate, "YYYY-MM-DD" ) );
      form.append( "isScannerCertified", fieldData.isScannerCertified );
      form.append( "certExpiryDate", commonService.formatDate( fieldData.certExpiryDate, "YYYY-MM-DD" ) );

      if (id) {
        form.append("resubmitComment", fieldData.resubmitComment);
      }
      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }
      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
      setLoading(true);
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update-details/dig-permit/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/dig-permit`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/view-own/dig-permits')
        setFieldData(fieldData);
        handleClearVerifySign();
      })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const signatureVerifyRef = useRef();

  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRef.current.isEmpty()) {
        res(null)
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file)
        });
      }
    })
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }))

  const departmentOptions = allDepartment.filter(v => v.siteId == fieldData.siteId).map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentId",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">
        <div className="text-center">
          <p className="mb-1 redText">A TO DIG PERMIT IS REQUIRED FOR THE BREAKING OF GROUND.  ANY DIG REQUIRES AN APPROVED RAMS/SPA</p>
          <h3 className="redText">TREAT ALL SERVICES AS LIVE</h3>
        </div>
        {/* <p className="redText">You must ensure areas below you works are closed to prevent others access below your works. </p> */}
        <div className="adddig_first_container">
          <div className="general_details">General Details</div>
          <div className="general_details_fields justify-content-start flex-wrap">
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Site <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Sites"
                    options={siteOptions}
                    {...(id ? {
                      value: siteOptions.filter(v => v.value == fieldData.siteId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.siteId && <span className="err">{error.siteId}</span>}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Department <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Department"
                    options={departmentOptions}
                    {...(id ? {
                      value: departmentOptions.filter(v => v.value == fieldData.departmentId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.departmentId && (
                <span className="err">{error.departmentId}</span>
              )}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Contractor <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  {user.role.includes("admin") ? <Select
                    onChange={handleChange}
                    placeholder="Select Contractor"
                    value={fieldData.contractorId ? contractorData.filter(v => v.id == fieldData.contractorId).map(d => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    })) : null}
                    options={contractorData.map((d) => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    }))}
                  /> : fieldData.contractorId && contractorData.filter(v => v.id == fieldData.contractorId).length ? contractorData.filter(v => v.id == fieldData.contractorId)[0].name : user.companyName}
                </div>
              </div>
              {error.contractorId && (
                <span className="err">{error.contractorId}</span>
              )}
            </div>
            <div className="col-3  p-2 location_dig">
              <label for="">
                <div className="require-label">
                  {" "}
                  Exact Location of works <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="locationOfWorks"
                    value={fieldData.locationOfWorks}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.locationOfWorks && (
                <span className="err">{error.locationOfWorks}</span>
              )}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Start Date <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.date} min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")} type="date" onChange={handleChange} name="date" />
                </div>
              </div>
              {error.date && <span className="err">{error.date}</span>}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Finish Date <span className="text-danger">*</span>{" "}
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.finishDate} min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")} type="date" onChange={handleChange} name="finishDate" />
                </div>
              </div>
              {error.finishDate && <span className="err">{error.finishDate}</span>}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Finish Time <span className="text-danger">*</span>{" "}
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.finishTime} type="time" pattern="hh:mm" onChange={handleChange} name="finishTime" />
                </div>
              </div>
              {error.finishTime && <span className="err">{error.finishTime}</span>}
            </div>
            <div className="col-3  p-2 location_dig">
              <label for="">
                <div className="require-label">
                  {" "}
                  Supervisor <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="supervisor"
                    value={fieldData.supervisor}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.supervisor && (
                <span className="err">{error.supervisor}</span>
              )}
            </div>
            <div className="col-3  p-2 location_dig">
              <label for="">
                <div className="require-label">
                  {" "}
                  Contact Phone <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="contactPhone"
                    value={fieldData.contactPhone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.contactPhone && (
                <span className="err">{error.contactPhone}</span>
              )}
            </div>
            <div className="col-6  p-2 location_dig">
              <label for="">
                <div className="require-label">
                  {" "}
                  Contractor LUGS Supervisor <small>(Person Who Scanned Ground)</small> <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="lugsSupervisor"
                    value={fieldData.lugsSupervisor}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.lugsSupervisor && (
                <span className="err">{error.lugsSupervisor}</span>
              )}
            </div>
            <div className="col-3  p-2 location_dig">
              <label for="">
                <div className="require-label">
                  {" "}
                  Spotter Name <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="spotterName"
                    value={fieldData.spotterName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.spotterName && (
                <span className="err">{error.spotterName}</span>
              )}
            </div>
            <div className="col-3  p-2 location_dig"></div>
            {/* <p className="redText ms-2">Fire Watch : The person required to maintain a careful watch for during Hot works / Break Times and a minimum of 1 hour following the completion of the hot works.</p> */}
            {/* <p className="redText ms-2 mt-3 mb-1">Responsible Person : This Person is charged with the safe execution of all aspects of this permit.</p> */}
          </div>
        </div>
        <div className="adddig_second_container">
          <div className="general_details">Work Details</div>

          <div className="row">
              <div className="col-md-6">
                <div className="col-12 form_inner p-2">
                  <label for="">
                    Brief description of works <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <textarea
                        name="descriptionOfWorks"
                        maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                        rows="5"
                        cols="100"
                        value={fieldData.descriptionOfWorks}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
                  {error.descriptionOfWorks && (
                    <span className="err">{error.descriptionOfWorks}</span>
                  )}
                </div>

                <div className="col-12 location_dig p-2">
                  <label for="">
                    Max Width Of Dig <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="maxWidth"
                        value={fieldData.maxWidth}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {error.maxWidth && (
                    <span className="err">{error.maxWidth}</span>
                  )}
                </div>
                
                <div className="col-12 location_dig p-2">
                  <label for="">
                    Max Depth Of Dig <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="maxDepth"
                        value={fieldData.maxDepth}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {error.maxDepth && (
                    <span className="err">{error.maxDepth}</span>
                  )}
                </div>
                  
                {false && <>
                  <div className="col-12 location_dig p-2">
                    <div className="inspected_radio_btn m-0">
                      <div>
                        <FormControl>
                          <FormLabel class="inspected_label">
                            {"> 1.25 Method Statement/SPA required"}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="spaRequired"
                            onChange={handleChange}
                          >
                            <FormControlLabel checked={fieldData.spaRequired == 1} value={1} control={<Radio />} label="Yes" />
                            <FormControlLabel checked={fieldData.spaRequired == 0} value={0} control={<Radio />} label="No" />
                          </RadioGroup>
                          <FormHelperText className="m-0">If 'Yes' is selected please upload relevant documents</FormHelperText>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </>}
              </div>

              <div className="col-md-6">
                  <div className="col-12  p-2 location_dig">
                    <label for="">
                      <div className="require-label">
                        {" "}
                        RAMS / SPA Ref No. <span className="text-danger">*</span>
                      </div>
                    </label>
                    <div className="input_icons">
                      <div className="input_field">
                        <input
                          type="text"
                          name="ramsSPARef"
                          value={fieldData.ramsSPARef}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {error.ramsSPARef && (
                      <span className="err">{error.ramsSPARef}</span>
                    )}
                  </div>

                  <div className="col-12 p-2">
                    <FormControl>
                      <FormLabel class="inspected_label">
                        Excavation Supports Required <span className="text-danger">*</span>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="excavationSupportsRequired"
                        onChange={handleChange}
                      >
                        <FormControlLabel checked={fieldData.excavationSupportsRequired == 1} value={1} control={<Radio />} label="Yes" />
                        <FormControlLabel checked={fieldData.excavationSupportsRequired == 0} value={0} control={<Radio />} label="No" />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  {fieldData.excavationSupportsRequired == 1 && <>
                    <div className="col-12 form_inner p-2">
                      <label for="">
                        Description of Supports required <span className="text-danger">*</span>
                      </label>
                      <div className="input_icons">
                        <div className="input_field">
                          <textarea
                            name="supportsDescription"
                            rows="5"
                            cols="100"
                            value={fieldData.supportsDescription}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                      {error.supportsDescription && (
                        <span className="err">{error.supportsDescription}</span>
                      )}
                    </div>
                  </>}

              </div>
          </div>
          
          <div className="row">
            <div className="col-12 d-flex flex-wrap">
              <div className="col-6 location_dig p-2">
                <label for="">
                  Means of preventing materials falling onto people working in the excavation <span className="text-danger">*</span>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="materialsProtectionMeans"
                      value={fieldData.materialsProtectionMeans}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.materialsProtectionMeans && (
                  <span className="err">{error.materialsProtectionMeans}</span>
                )}
              </div>
              
              <div className="col-6 location_dig p-2">
                <label for="">
                  Means of preventing people and vehicles falling into excavation <span className="text-danger">*</span>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="peopleVehiclesProtectionMeans"
                      value={fieldData.peopleVehiclesProtectionMeans}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.peopleVehiclesProtectionMeans && (
                  <span className="err">{error.peopleVehiclesProtectionMeans}</span>
                )}
              </div>
             
              <div className="col-6 location_dig p-2">
                <label for="">
                  Means of preventing the undermining of nearby structures <span className="text-danger">*</span>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="nearbyStructuresProtectionMeans"
                      value={fieldData.nearbyStructuresProtectionMeans}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.nearbyStructuresProtectionMeans && (
                  <span className="err">{error.nearbyStructuresProtectionMeans}</span>
                )}
              </div>
             
              <div className="col-6 location_dig p-2">
                <label for="">
                  Means of safe access into the excavation <span className="text-danger">*</span>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="safeAccessProtectionMeans"
                      value={fieldData.safeAccessProtectionMeans}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.safeAccessProtectionMeans && (
                  <span className="err">{error.safeAccessProtectionMeans}</span>
                )}
              </div>

            </div>
          </div>

        </div>

        <div className="adddig_fourth_container">
          <div className="general_details">All to Dig operation must be scanned by the Contractor before any ground is Broken:</div>

          <div className="p-2">
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Please confirm ground has been scanned
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="groundScanned"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.groundScanned == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.groundScanned == 0} value={0} control={<Radio />} label="No" />
                </RadioGroup>
                {(fieldData.groundScanned == 0) && <FormHelperText className="m-0 text-danger">All Grounds must be scanned before breaking ground!</FormHelperText>}
              </FormControl>
            </div>
            
            <div className="row p-2">
              <div className="col-6 location_dig p-2">
                <label for="">
                  CAT & Genny Scan carried out by <span className="text-danger">*</span>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="catScanBy"
                      value={fieldData.catScanBy}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.catScanBy && (
                  <span className="err">{error.catScanBy}</span>
                )}
              </div>
              
              <div className="col-6 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    {" "}
                    Date CAT & Genny Scan was carried out <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      value={fieldData.catScanDate? commonService.formatDate( fieldData.catScanDate, "YYYY-MM-DD" ) : ''}
                      type="date"
                      onChange={handleChange}
                      name="catScanDate"
                    />
                  </div>
                </div>
                {error.catScanDate && <span className="err">{error.catScanDate}</span>}
              </div>

              <div className="col-6">
                <FormControl>
                  <FormLabel class="inspected_label">
                    Confirm Scanner is certified and Calibrated
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="isScannerCertified"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.isScannerCertified == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.isScannerCertified == 0} value={0} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="col-6 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    {" "}
                    Expire Date of Cert <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      value={fieldData.certExpiryDate? commonService.formatDate( fieldData.certExpiryDate, "YYYY-MM-DD" ) : ''}
                      min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")}
                      type="date"
                      onChange={handleChange}
                      name="certExpiryDate"
                    />
                  </div>
                </div>
                {error.certExpiryDate && <span className="err">{error.certExpiryDate}</span>}
              </div>

            </div>

          </div>
        </div>

         <div className="adddig_fourth_container">
          <div className="general_details">Other Information</div>
          
          <div className="extra-permits p-2">
            <div className="p-2">
              <FormControl>
                <FormLabel class="inspected_label">
                  Is Bunting or Goalpost required for any overhead obstructions
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="buntingRequired"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.buntingRequired == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.buntingRequired == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.buntingRequired == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            
            <div className="p-2">
              <FormControl>
                <FormLabel class="inspected_label">
                  If Yes, Does it meet the Cod of Practice for the Avoiding Danger from Overhead Electrical Lines
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="overheadPractices"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.overheadPractices == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.overheadPractices == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.overheadPractices == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            
            <div className="p-2">
              <FormControl>
                <FormLabel class="inspected_label">
                  Is a trial pit required?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="trialPitRequired"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.trialPitRequired == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.trialPitRequired == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.trialPitRequired == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            
            <div className="p-2">
              <FormControl>
                <FormLabel class="inspected_label">
                  If working in the vicinity of known services, please circle to agree that, digging will proceed with caution using gummy bucket and spotter. No mechanical plant will be used within 500mm of same (If services are found to be incapsulated an require mechanical breaking all work will stop for review and approval by BCL)
                  <br />
                  <span className="redText">(If you do not agree, works do not proceed)</span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="termsAgreed"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.termsAgreed == 1} value={1} control={<Radio />} label="Yes, I Agree" />
                  <FormControlLabel checked={fieldData.termsAgreed == 0} value={0} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>

          </div>

          <div className="d-flex">
            <div className="textArea">
              <label for="">
                <div className="require-label">
                  {" "}
                  PlantRequired
                  <span className="text-danger"> *</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="plantRequired"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="3"
                    cols="100"
                    onChange={handleChange}
                    value={fieldData.plantRequired}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              {error.plantRequired && <span className="err">{error.plantRequired}</span>}
            </div>
            <div className="textArea">
              <label for="">
                <div className="require-label">
                  {" "}
                  Equipment Required
                  <span className="text-danger"> *</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="equipmentRequired"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="3"
                    cols="100"
                    onChange={handleChange}
                    value={fieldData.equipmentRequired}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              {error.equipmentRequired && <span className="err">{error.equipmentRequired}</span>}
            </div>
          </div>
        </div>

        <div className="adddig_fourth_container">
          <div className="general_details">Permits Required</div>

          <div className="inspected_radio_btn">
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Hot Works
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="prHotworks"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.prHotworks == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.prHotworks == 0} value={0} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Confined Space
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="prConfined"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.prConfined == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.prConfined == 0} value={0} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Live Electrical
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="prElectrical"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.prElectrical == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.prElectrical == 0} value={0} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Other (Client)
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="prOther"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.prOther == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.prOther == 0} value={0} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="adddig_fifth_container">
          <div className="general_details">Upload Files</div>
          <div className="d-flex">
            {!!files.length && files.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
              <img
                className="obj_fit_img obj-fit-cover rounded"
                src={imgPreviewUrl}
                alt=""
                height="150"
                width="150"
              />
            </div>))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
            {error.files && (
              <span className="err">{error.files}</span>
            )}
          </div>
        </div>
        
        <div className="adddig_sixth_container">
          <div className="general_details">Signature(s)</div>
          <div className="p-2">
            <p className="hold_down">
              I accept the conditions of this permit as specified and undertake to carry out the works strictly in accordance with the agreed RAMS / SPA and I will bring to the attention the requirement of this permit to the team involved in the works under this permit.
            </p>
            <div className="sign_image">
              <SignatureCanvas
                ref={signatureVerifyRef}
                canvasProps={{ width: 263, height: 270 }}
              />
              <span
                className="cancel-custom cursor-pointer"
                onClick={handleClearVerifySign}
              >
                <i
                  className="clear_btn  text-danger fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            {error.verifySignature && (
              <span className="err">{error.verifySignature}</span>
            )}
            {!!id && <div className="d-flex">
              <div className="textArea mx-0">
                <p className="mb-0 text-dark">
                  <div className="require-label">
                    {" "}
                    Resubmit Comment
                  </div>
                </p>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="resubmitComment"
                      rows="3"
                      cols="100"
                      onChange={handleChange}
                      value={fieldData.resubmitComment}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>}
            <p className="redText">
              <strong>Please Note:</strong> Work cannot be started until the permit is approved.
            </p>
          </div>
        </div>
        
        {/* These are no longer standard sections, they are only for Managers for approvals */}

        { false && <>
          {/* Permits Required */}
          <div className="adddig_fourth_container">
            <div className="general_details">Permits Required</div>

            <div className="inspected_radio_btn">
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Hot Works
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="prHotworks"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.prHotworks == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.prHotworks == 0} value={0} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Confined Space
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="prConfined"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.prConfined == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.prConfined == 0} value={0} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Live Electrical
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="prElectrical"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.prElectrical == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.prElectrical == 0} value={0} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Other (Client)
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="prOther"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.prOther == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.prOther == 0} value={0} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>

          {/* Approval Required */}
          <div className="adddig_fourth_container">
            <div className="general_details">Approval Required From</div>

            <div className="inspected_radio_btn">
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Local Authority
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="arLocal"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.arLocal == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.arLocal == 0} value={0} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Electrical Company
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="arElectrical"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.arElectrical == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.arElectrical == 0} value={0} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Client
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="arClient"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.arClient == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.arClient == 0} value={0} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Other
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="arOther"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.arOther == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.arOther == 0} value={0} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>

          {/* Services Available */}
          <div className="adddig_fourth_container">
            <div className="general_details">Services Drawing available for the following services:</div>

            <div className="inspected_radio_btn">
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Data Cabling
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="dataCablingDrawing"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.dataCablingDrawing == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.dataCablingDrawing == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.dataCablingDrawing == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>

              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Foul
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="foulDrawing"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.foulDrawing == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.foulDrawing == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.foulDrawing == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>

              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Telecoms
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="telecomsDrawing"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.telecomsDrawing == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.telecomsDrawing == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.telecomsDrawing == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Main Water
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="mainWaterDrawing"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.mainWaterDrawing == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.mainWaterDrawing == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.mainWaterDrawing == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Gas
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="gasDrawing"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.gasDrawing == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.gasDrawing == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.gasDrawing == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Electricity
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="electricityDrawing"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.electricityDrawing == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.electricityDrawing == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.electricityDrawing == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>
              
              <div>
                <FormControl>
                  <FormLabel class="inspected_label">
                    Fibre Optic
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="fibreOpticDrawing"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.fibreOpticDrawing == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.fibreOpticDrawing == 0} value={0} control={<Radio />} label="No" />
                    <FormControlLabel checked={fieldData.fibreOpticDrawing == 2} value={2} control={<Radio />} label="N/A" />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="col-md-12 location_dig p-2">
                <label for="">
                  Other <span className="text-danger">*</span>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="otherDrawing"
                      value={fieldData.otherDrawing}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.otherDrawing && (
                  <span className="err">{error.otherDrawing}</span>
                )}
              </div>
            </div>
          </div>
        </> }

        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Resubmit" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn">cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>

      </div>
    </div>
  );
};

export default AddDig;
