import { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "../../helpers/request";
import "./SignUp.css";
import or from "../../images/or.svg";
import { useGoogleLogin } from "@moeindana/google-oauth";
import logo from "../../images/foresighthublogo.svg";
import illustration from "../../images/loginmainimage.svg";
import showeye from "../../images/Vector.svg";
import vectorc from "../../images/Vector (1).svg";
import googleLogo from "../../images/Google_G_logo.svg";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import Cookies from "universal-cookie";
const cookies = new Cookies();
function SignUp() {
  const history = useHistory();
  // const navigate = useNavigate();
  const location = useLocation();

  console.log('localStorage.getItem("cs_subscribe")' , localStorage.getItem("cs_subscribe"))

  const subscribe = localStorage.getItem("cs_subscribe") ?  JSON.parse(localStorage.getItem("cs_subscribe")) :"";

  const validEmail = new RegExp(/\S+@\S+\.\S+/);
  const validPassword = new RegExp(
    // /^([@#](?=[^aeiou]{7,13}$)(?=[[:alnum:]]{7,13}$)(?=.*[A-Z]{1,}.*$).+)$/
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/
  );

  // const services = [
  //   { label: 'Fire Safety', value: 'fireSafety' },
  //   { label: 'Food Safety', value: 'foodSafety' },
  //   // { label: 'Safety Management', value: 'safetyManagement' },
  //   { label: 'Safety Training', value: 'safetyTraining' },
  //   { label: 'Remote Working', value: 'remoteWorking' },
  //   { label: 'SMS Safety', value: 'smsSafety' },

  // ];
  // const [values, setvalues] = useState([services[0]]);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",

    email: "",
    password: "",
    cpassword: "",
    companyName: "",
    // chosenServices: values
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    cpassword: "",
    companyName: "",
    // chosenServices: ""
  });

  const [shown, setShown] = useState(false);
  const [adding, setAdding] = useState(true);
  const [addError, setaddError] = useState({});
  const [Guser, setGUser] = useState();

  console.log('addError', addError)


  // const onchangeselect = (e) => {
  //   user.chosenServices = e
  //   setvalues(e)
  // }
  const handleChange = (event) => {
    const { name, value } = event.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
    isValidData(event.target.value, event.target.name);
  };

  const isValidData = (value, name) => {

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "firstName":
          if (!value) {
            stateObj[name] = "Please enter First Name.";
          }
          break;
        case "lastName":
          if (!value) {
            stateObj[name] = "Please enter Last Name.";
          }
          break;
        case "email":
          if (!value) {
            stateObj[name] = "Please enter a valid email address.";
          } else if (!validEmail.test(value)) {
            stateObj[name] = "Please enter valid email address.";
          }
          break;

        case "companyName":
          if (!value) {
            stateObj[name] = "Please enter company name.";
          }
          break;

        // case "chosenServices":
        //   if (!values) {
        //     stateObj[name] = "Please select your services.";
        //   }
        //   break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter a password.";
          } else if (!validPassword.test(value)) {
            stateObj[name] =
              "Password should contain atleast uppercase, lowercase, special character,number and limit 8-20 must be required..";
          } else if (user.cpassword && value !== user.cpassword) {
            stateObj["cpassword"] =
              "Password and Confirm Password does not match.";
          }

          break;

        case "cpassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (user.password && value !== user.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }

          break;

        default:
          break;
      }
      var a = stateObj;
      setaddError(a);
      if (
        addError.cpassword === "" &&
        addError.firstName === "" &&
        addError.lastName === "" &&
        addError.email === "" &&
        addError.password === "" &&
        addError.companyName === "" 
        // addError.chosenServices === ""
      ) {
        setAdding(false);
      }
      return stateObj;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let errorShow = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      companyName: user.companyName,
      password: user.password,
      cpassword: user.cpassword,
    }

    Object.keys(errorShow).forEach((key)=>{
      isValidData(errorShow[key] , key)
    })

    if (
      user.cpassword !== "" &&
      user.firstName !== "" &&
      user.lastName !== "" &&
      user.email !== "" &&
      user.password !== "" &&
      user.companyName !== ""
      // user.chosenServices !== "" &&
      // subscribe?.trial
    ) {

      axios
        .post(process.env.REACT_APP_API_BASEURL + "/signup/", { user: { ...user, trial: 'no_trial_needed' } })
        .then(function (response) {
          setAdding(false);
          // resetForm(); 
          setUser("");
          // addTemplates(response?.data?.id)
          history.push("/");
          toast.success(
            "Thanks for signing up, please check your email to verify your account and complete registration"
          );
        })
        .catch(function (error) {
          setAdding(false);
          // already exists in our records
          if (error?.response?.data?.message.email !== "" && error?.response?.data?.message.companyName !== "") {
            if (error?.response?.data?.message.email === user.email && error?.response?.data?.message.companyName === user.companyName) {
              return toast.error(
                ` Email and CompanyName already exists in our records` || "Unable to process this request"
              )
            } else if (error?.response?.data?.message.email === user.email) {
              return toast.error(
                ` Email already exists in our records` || "Unable to process this request"
              )
            } else {
              return toast.error(
                ` CompanyName already exists in our records ` || "Unable to process this request"
              )
            }
          } else {
            toast.error("Unable to process this request");
          }
        });
    } else {
      console.log("err");
    }
  };

  const Glogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser({
        ...user,
        email: codeResponse.email,
        firstName: codeResponse.name.split(' ').length ? codeResponse.name.split(' ')[0] : "",
        lastName: codeResponse.name.split(' ').length > 1 ? codeResponse.name.split(' ')[1] : "",
        password: codeResponse.id,
        cpassword: codeResponse.id,
      });
      setGUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <div className="usersignup">
      <div className="userSignupContainerMain">
        <div className="usersignupLeft">
          <span className="logoadmin">
            <div className="logoadmintop">
              <img src={logo} alt="" />
            </div>

            <div className="logoadminbottom">
              <img src={illustration} alt="" />
            </div>
          </span>
        </div>
        <div className="userSignupRightMain">

          <div className="logoMobile">
            <img src={logo} alt="" />
          </div>


          <div className="pageHeaderSignup">
            <div className="pageHeaderText">
              <span className="pageHeaderTitle">Sign Up</span>
              <span className="pageHeaderDescription">
                To Create Account, please fill in the form below.
              </span>
            </div>
          </div>
          <form className="signupFormMain" onSubmit={(e) => handleSubmit(e)}>
            {!Guser && (
              <>
                <div className="signupItem">
                  <label>First Name</label>

                  <input
                    value={user.firstName}
                    onChange={handleChange}
                    name="firstName"
                    type="text"
                    placeholder="Enter your first name"
                  />
                </div>
                {error.firstName && <span className="err">{error.firstName}</span>}


                <div className="signupItem">
                  <label>Last Name</label>

                  <input
                    value={user.lastName}
                    onChange={handleChange}
                    name="lastName"
                    type="text"
                    placeholder="Enter your full name"
                  />
                </div>
                {error.lastName && <span className="err">{error.lastName}</span>}

                <div className="signupItem">
                  <label>Work Email Address</label>
                  <input
                    value={user.email}
                    onChange={handleChange}
                    name="email"
                    type="email"
                    placeholder="Enter your work email address"
                  />
                </div>
                {error.email && <span className="err">{error.email}</span>}
              </>
            )}

            <div className="signupItem">
              <label>Company Name</label>

              <input
                value={user.companyName}
                onChange={handleChange}
                name="companyName"
                type="text"
                placeholder="Enter your company name"
              />
            </div>
            {error.companyName && <span className="err">{error.companyName}</span>}



            {/* <div className="signupItem">
              <label>Select Your Services</label>


              <Select
                defaultValue={values}
                value={values}
                isMulti
                name="chosenServices"
                options={services}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={onchangeselect}
              />




            </div> */}
            {/* {error.chosenServices && <span className="err">{error.chosenServices}</span>} */}
            {!Guser && (
              <>
                <div className="signupItem">
                  <label>Password</label>

                  <input
                    value={user.password}
                    onChange={handleChange}
                    name="password"
                    type={shown ? "text" : "password"}
                    placeholder="Set your password"
                  />
                  <img
                    className="showpasswordImg"
                    src={showeye}
                    alt="showpassword"
                    onClick={() => setShown(!shown)}
                  />
                </div>
                {error.password && <span className="err">{error.password}</span>}

                <div className="signupItem">
                  <label>Verify Password</label>
                  <input
                    value={user.cpassword}
                    onChange={handleChange}
                    name="cpassword"
                    type="password"
                    placeholder="Retype password"
                  />
                  <img
                    className="verifypasswordImg"
                    src={vectorc}
                    alt="verifypassword"
                  />
                </div>
                {error.cpassword && <span className="err">{error.cpassword}</span>}
              </>
            )}

            <button disabled={adding} className="signupButtonMain" type="submit">
              Sign Up
            </button>
            <div className="divider">
              <img src={or} alt="" />
            </div>
            {!Guser && (

              <div class="google-btn">
                <div class="google-icon-wrapper">
                  <img class="google-icon" src={googleLogo} />
                </div>
                <p class="btn-text" onClick={() => Glogin()}>Sign Up with Google</p>
              </div>
            )}
            <Link className="resetoptionsRight" to={"/login"}>
              <button type="button" className="needsanaccount">
                Already have an account? <span>Log in</span>
              </button>
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
