import React, { Fragment, useEffect, useState } from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

export default function Table({ data, heading }) {

    const [tableData, setTableData] = useState();
    const styles = StyleSheet.create({
        rowView: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: '1px solid #ddd',
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 4,
            textAlign: "left"
        }
    });


    useEffect(() => {
        if (data !== undefined) setTableData(data);
    }, []);


    return (
        <>
            {tableData &&
                (
                    <Fragment style={{ fontSize: '10px' }}>
                        {heading && <>
                            <View>
                                <Text style={{ fontSize: '16px', fontFamily: "Helvetica-Bold", marginBottom: "10px" }}>{heading}</Text>
                            </View>
                        </>}
                        <View style={styles.rowView}>
                            {tableData["column"].map((c) => <Text style={{
                                width: `${100 / tableData["column"].length}%`,
                                fontSize: '10px',
                                fontFamily: "Helvetica-Bold"
                            }}>{c}</Text>)}
                        </View>
                        {tableData["data"].map((rowData) => <>
                            <View style={styles.rowView}>
                                {Object.keys(rowData).map((c) =>
                                    <Text style={{ fontSize: '10px', width: `${100 / tableData["column"].length}%` }}>{rowData[c]}</Text>
                                )}
                            </View>
                        </>)}
                    </Fragment>
                )}
        </>

    )
}