import React from 'react'
import "./List.css"
import Header from '../Header/Header'
import axiosInstance from '../../helpers/request';
import { useState } from 'react';
import { useHistory,useLocation } from 'react-router-dom';
import Cookies from "universal-cookie";
import { useEffect } from 'react';
import { useAppState } from '../../state';
import Edit2 from '../../images/edit-2.svg';
import Search from '../../images/search.svg';
import Delete from '../../images/circle-cross.svg';
import { Modal, Button } from 'react-bootstrap'
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import Select from "react-select"
import { MODULE_STATUS } from '../common/constant'

import { commonService } from "../common/common.service";

import Table from "../common/Table";
import DataTable from 'react-data-table-component';
import { Spinner } from "react-bootstrap";
import { Document, Page, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import CustomPopup from "../Popup/CustomPopup";


function RolesList({ title }) {
    const { user ,userPermissions} = useAppState()
    const cookies = new Cookies();
    const history = useHistory()
    const userToken = localStorage.getItem("cs_token");
    const location = useLocation()
    const isOwn = location.pathname.includes("my-")
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [companyId, setUserID] = useState("")
    const [myId, setMyId] = useState("")
    const [search, setSearch] = useState("")
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [pdfLoader, setPdfLoader] = useState(true);

    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const [showChangeUserRole, setShowChangeUserRole] = useState(false)
    const [associatedUsers, setAssociatedUsers] = useState([])
    const [assignLoading, setAssignLoading] = useState(false)

    const [deleteRoleId, setDeleteRoleId] = useState(null)
    const [deleteCount, setDeleteCount] = useState(0)
    const [status, setStatus] = useState({
        label: "Select Status",
        value: ""
    })

    useEffect(() => {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/${((user.role=="superadmin")&&!isOwn)?"every-roles":"all-roles"}`, {
                companyId: companyId,
                status: status.value,
                isOwn,
                id:myId
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                // if (res.data.length > 0) {
                setData(res.data);
                // }

                const savedSort = commonService.loadSortData('Roles');
                if (savedSort) {
                    const { direction, columnSelector } = savedSort;
                    setSortDirection(direction);
                    setSortColumn(columnSelector);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }, [companyId, deleteCount, status.value])

    useEffect(() => {
        userDetail()
    }, [])


    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setUserID(((res.data[0].role!=="admin")&&res.data[0].added_by) ? res.data[0].added_by : res.data[0].id);
                    setMyId(res.data[0].id);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const deleteConfirmation = async (name) => {
        const result = await Swal.fire({
            title: `Are you sure you want to delete ${name} ?`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        return result;
    }
    const handleDelete = (id, name) => {
        setDeleteLoading(true)
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/role/users`, {
                role_id: id
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then(async (res) => {
                let is_direct_delete = false;
                let confirmDelete = false;
                if (res.data.length) {
                    is_direct_delete = false
                    confirmDelete = true
                } else {
                    is_direct_delete = true
                    let result = await deleteConfirmation(name)
                    confirmDelete = result.isConfirmed
                }

                if (confirmDelete) {
                    if (is_direct_delete) {
                        deleteRole(id)
                        handleCloseChangeUserRole()
                    } else {
                        let newAssociatedArray = [];
                        res.data.forEach((itm) => {
                            itm['new_role_id'] = ""
                            newAssociatedArray = [
                                ...newAssociatedArray,
                                itm
                            ]
                        })
                        console.log('newAssociatedArray', newAssociatedArray)
                        setAssociatedUsers(newAssociatedArray)
                        setShowChangeUserRole(true)
                        setDeleteRoleId(id)

                    }
                } else {
                    setDeleteLoading(false)
                }
            })
            .catch((err) => {
                console.log("errr", err);
                setDeleteLoading(false)
            });

    }


    const handleCloseChangeUserRole = () => {
        setAssociatedUsers([])
        setShowChangeUserRole(false)
        setDeleteRoleId(null)
        setDeleteLoading(false)
    }

    const validateUserRoles = () => {
        let isValidate = true;
        associatedUsers.forEach((itm) => {
            if (itm.new_role_id == "") {
                isValidate = false;
            }
        })

        return isValidate;
    }
    const handleUpdate = () => {
        if (validateUserRoles()) {
            setAssignLoading(true)
            axiosInstance
                .post(`${process.env.REACT_APP_API_BASEURL}/users/change-role`, {
                    users: associatedUsers
                }, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                })
                .then(async (res) => {
                    setAssignLoading(false)
                    toast.success('New role has been assigned to users.')

                    let result = await deleteConfirmation(data.filter((itm) => itm.id == deleteRoleId)[0].name)
                    if (result.isConfirmed) {
                        deleteRole(deleteRoleId)
                        handleCloseChangeUserRole()
                    } else {
                        handleCloseChangeUserRole()
                    }
                })
                .catch((err) => {
                    console.log("errr", err);
                    setAssignLoading(false)
                    setDeleteLoading(false)
                });
        } else {
            toast.error('Please assign new role to users.')

        }
    }

    const handleSelectionOfRole = (user_id, role_id) => {
        console.log('user_id , role_id', user_id, role_id)
        let newAssociatedArray = []
        associatedUsers.forEach((itm) => {
            if (itm.id == user_id) {
                itm = {
                    ...itm,
                    new_role_id: role_id

                }
                newAssociatedArray = [...newAssociatedArray, itm]
            } else {
                newAssociatedArray = [...newAssociatedArray, itm]
            }
        })
        setAssociatedUsers(newAssociatedArray)
    }

    const deleteRole = (role_id) => {
        axiosInstance
            .delete(process.env.REACT_APP_API_BASEURL + "/role/" + role_id)
            .then(function (res) {
                toast.success(res.data.message)
                setDeleteCount(deleteCount + 1)
            })
            .catch(function (error) {
                toast.error(error?.response?.data?.message || "Unable to delete user")
            });
    }


    const handleChangeStatus = (module_id, value) => {
        axiosInstance
            .post(process.env.REACT_APP_API_BASEURL + "/update-role-status", {
                id: module_id,
                status: value
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then(function (response) {
                toast.success(response?.data?.message || "Role status has been updated successfully");
            })
            .catch(function (error) {
                console.log('error', error)
                toast.error(error?.response?.data?.message || "Unable to update Role status");
            });
        let newData = []
        data.forEach((itm) => {
            if (itm.id == module_id) {
                newData = [
                    ...newData,
                    {
                        ...itm,
                        is_active: value
                    }
                ];
            } else {
                newData = [...newData, itm]
            }
        })
        setData(newData)


    }

    const tableHeader = [
        'ID', 'Name', 'Description', 'Status'
      ];
    
      function getActionsConvertableArray() {
        let array = [];
    
        // add all items from data but skip actions
        data.forEach((d) => {
          array.push({
            id: d.id,
            name: d.name ? d.name : '',
            description: d.description ? d.description : '',
            status: d.is_active ? 'Active' : 'Inactive',
          })
        })
    
        return array;
      }
    
      const columns = [
        {
          name: 'View',
          selector: 'view',
          width: '100px',
          cell: row => <div>{row.view}</div>,
          sortFunction: (a, b) => {
            return a.id - b.id;
          }
        },
        {
          name: 'Name',
          selector: 'name',
          searchField: 'name',
          cell: row => <div>{row.name}</div>,
        },
        {
          name: 'Description',
          selector: 'description',
          searchField: 'description',
          cell: row => <div>{row.description}</div>,
        },
      ];
    
      // add minWidth to each item in columns
      columns.forEach((column) => {
        if ( typeof column.minWidth == 'undefined' && typeof column.width == 'undefined' ) {
          column.minWidth = '200px';
        }
    
        // Add sorting to all columns
        if ( typeof column.sortable === 'undefined' ) {
          column.sortable = true;
        }
    
        if ( typeof column.sortFunction === 'undefined' && typeof column.searchField !== 'undefined' ) {
          column.sortFunction = (a, b) => a[column.searchField].localeCompare( b[column.searchField] );
        }
      });
    
      if ( user.role.includes("admin") ) {

        columns.push( {
            name: 'Status',
            selector: 'status',
            searchField: 'status',
            cell: row => <div>
                <div className="form-check form-switch">
                    <input
                        className="sub-permission-check form-check-input form-check-input-color d-block"
                        style={{ width: "45px", height: "22px" }}
                        type="checkbox"
                        role={`switch-${row.id}`}
                        id={`flexSwitchCheckChecked-${row.id}`}
                        checked={row.status ? true : false}
                        onChange={(e) => {
                            handleChangeStatus(row.id, e.target.checked ? 1 : 0)
                        }} />
                </div>  
            </div>,
        } );
      }

      if (user.role.includes("admin") || userPermissions.includes('editRoles') ) {
        columns.push({
          name: 'Action',
          sortable: false,
          selector: 'actions',
          cell: row => <div>{row.actions}</div>,
          allowOverflow: true
        });
      }
    
      const downloadExcel = () => {    
        commonService.downloadArrayOfObjectsAsXLSX(
          getActionsConvertableArray(),
          tableHeader,
          title.toLowerCase().replace(' ', '-').split('(')[0] + '.xlsx'
        );
    
      }
      
      const printList = () => {
        // Trigger the actual print
        commonService.triggerPrint(
          getActionsConvertableArray(),
          tableHeader,
          title
        );
      }
    
      const PDFReadyTable = () => {
        // Implement download PDF
        const pdfTableData = {
          column: tableHeader,
          data: getActionsConvertableArray()
        };
    
        return (
          <Document>
            <Page
              orientation="landscape"
              size="LETTER"
              style={{padding: "20px 16px 30px"}}
            >
              <Table
                data={pdfTableData}
                heading={title}
              />
            </Page>
          </Document>
        );
      }

      const downloadPDF = async () => {
        // If we have larger data set, show laoder.
        if ( data.length > 100 ) {
          setPdfLoader(false);
        }
        const blob = await pdf((
          <PDFReadyTable />
          )).toBlob();
          saveAs(blob, title.toLowerCase().replace(/ /g, '-').split('(')[0] + ".pdf");
          setPdfLoader(true);
      };
    
      const CustomSortIcon = () => (
        <span className="dt-sort-icon"></span>
      )
    
      const createData = () => {
        let parsedData = [];
    
        if ( data.length == 0 ) {
          return parsedData;
        }
    
        data.forEach((d) => {
          let row = {
            id: d.id,
            view: <span onClick={() => { history.push(`/view-role/${d.id}`); }} className="lists-id-col">{d.id}</span>,
            name: d.name ? d.name : '',
            description: d.description ? d.description : '',
            status: d.is_active ? true : false,
          };
    
          if ( user.role.includes( 'admin' ) || userPermissions.includes('editRoles') ) {
            row.actions = <>
              {(user.role.includes("admin")) &&
                    <img src={Delete} alt="" onClick={() => {
                        handleDelete(d.id, d.name)
                    }} />}
                {userPermissions.includes('editRoles') && <img className="ps-2" src={Edit2} alt="" onClick={() => {
                    history.push("/edit-role/" + d.id)
                }} />}
            </>
          }
    
          parsedData.push(row);
        });
    
        if ( parsedData.length > 0 && search && search.length > 0 ) {
          // Search for matching string in all columns for each row
          parsedData = parsedData.filter((d) => {
            // Combine all column values in a row into a single string
            const rowValues = Object.entries(d)
              .filter(([key]) => key !== 'action') // Exclude 'action' property
              .map(([key, value]) => value)
              .join(' ')
              .toLowerCase();
            
            // Check if the search string is present in any of the columns
            return rowValues.includes(search.toLowerCase());
          });
        }
    
        return parsedData;
      }
    
    const handleSort = (column, direction, sortedRows) => {
        setSortColumn(column);
        setSortDirection(direction);
        commonService.saveSortData(column, direction, 'Roles');
    };

    return (
        <div class="middle_page">
            <Header title={title} steps={[{ name: "Home", path: "/" }, { name: "Roles List", path: "/roles" }]} />
            
            <CustomPopup
                showClose={false}
                trigger={!pdfLoader}
                setTrigger={() => {
                
                }}
            >
                <h3>Please Wait...</h3>
                <Spinner visible="true" />
            </CustomPopup>

            <div class="main_container">
                <div>
                    <div class="form_inner table_search">
                        <div class="input_icons">
                            <img src={Search} alt="" />
                            <div class="input_field">
                                <input type="text" placeholder="Search Here" value={search} onChange={e => setSearch(e.target.value)} />
                            </div>
                        </div>
                        <div class="input_icons">
                            <div class="w-100">
                                <Select options={MODULE_STATUS} value={status} placeholder="Select Status" onChange={value => {
                                    setStatus(value)

                                }} />
                            </div>
                        </div>
                        <div>
                            <button onClick={() => { printList() }}>Print</button>
                            <button onClick={() => { downloadExcel() }}>Excel</button>
                            <button onClick={() => { downloadPDF() }}>PDF</button>

                            {(user.role.includes('admin')||userPermissions.includes('createRole'))&&<button className='GA2-btn' onClick={() => {
                                history.push("/create-role")
                            }}>Add Role +</button>}
                        </div>
                    </div>
                    <hr />
                    <div id="wrapper">
                        { data.length ? <>
                        <DataTable
                            columns={columns}
                            data={createData()}
                            pagination={true}
                            sortIcon={<CustomSortIcon />}
                            onSort={handleSort}
                            defaultSortFieldId={sortColumn}
                            defaultSortAsc={sortDirection === 'asc'}
                        />
                        </> : <>
                        <Spinner size="sm" animation="border" variant="light" />
                        </>}
                    </div>
                </div>
            </div>
            {showChangeUserRole && <Modal show={showChangeUserRole} onHide={handleCloseChangeUserRole}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Users Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row h6'>
                        <div className='col-12'>
                            {data.filter((itm) => itm.id == deleteRoleId)[0].name} is assigned to other users. Please select a new role to be assigned to these users.
                        </div>
                    </div>
                    <div className='row'>
                        {associatedUsers.map((itm, i) => {
                            return (<div key={i} className='row mt-3'>
                                <div className="col-5">{itm.fullName}</div>
                                <div className="col-2" > : </div>
                                <div className="col-5">
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <select name="role" onChange={(e) => handleSelectionOfRole(itm.id, e.target.value)} className="w-100" >
                                                <option selected={itm.new_role_id == "" ? true : false} value="">Select Role</option>
                                                {data.filter((itm) => itm.id != deleteRoleId).map((slc, j) => {
                                                    return (<option value={slc.id} selected={slc.id == itm.new_role_id ? true : false}>{slc.name}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleCloseChangeUserRole}>Close</Button>
                    <Button variant='primary' onClick={() => {
                        if (!assignLoading) {
                            handleUpdate()
                        }
                    }}>Assign</Button>
                </Modal.Footer>
            </Modal>}
        </div>


    )
}

export default RolesList