import React from "react";
import "./SafetyChecklist.css";
import Header from "../Header/Header";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../helpers/request";
import { useEffect } from "react";
import { toast } from "react-toastify";
import SubmitLoader from "../common/SubmitLoader";
import SafetyService from "../../services/safety.service";
import { onError } from "../../libs/errorLib";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

function CreateSafetyChecklistTemplate({ title }) {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const isEdit = location.pathname.includes("edit-contractor");
  const [data, setData] = useState({
    name: "",
    scoringSystem: 0,
    sections: 0,
    defaultChoice: 0
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit && id) {
      getTemplate();
    }
  }, []);

  async function getTemplate() {
    setLoading(true);
    try {
      const response = await SafetyService.getSafetyTemplate(id);
      if (response) {
        setData(response);
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    if (data.name) {
      setLoading(true);

      if (isEdit) {
        try {
          const response = await SafetyService.updateSafetyTemplate(id, data);
          if (response) {
            toast.success(response.message);
            history.push(`/safety/templates/${id}`);
          }
        } catch (e) {
          onError(e);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          const response = await SafetyService.createSafetyTemplate(data);
          if (response) {
            toast.success(response.message);
            history.push(`/safety/templates/${response.id}`);
          }
        } catch (e) {
          onError(e);
        } finally {
          setLoading(false);
        }
      }
    } else {
      toast.error("Name is a mandatory field.");
    }
  };

  return (
    <div class="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          {
            name: !isEdit
              ? "Add Safety Checklist Template"
              : "Edit Saftey Checklist Template",
            path: "/safety/create-safety-template",
          },
        ]}
      />
      <div class="main_container">
        <div>
          <div class="accident">
            <div class="accident_text">
              <h5>{!isEdit ? "Add Template" : "Edit Template"}</h5>
            </div>
          </div>
          <form>
            <div class="form-1">
              <div class="form justify-content-start">
                <div class="form_inner p-2">
                  <label for="">
                    Name <span className="text-danger">*</span>{" "}
                  </label>
                  <div class="input_icons">
                    <div class="input_field">
                      <input
                        type="text"
                        value={data.name}
                        name="name"
                        onChange={handleChange}
                        placeholder="Name"
                      />
                    </div>
                  </div>
                </div>

                <div class="form_inner p-2">
                  <label for="">
                    Scoring System <span className="text-danger">*</span>{" "}
                  </label>
                  <div class="input_icons scoring-system">
                    <div class="input_field">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="scoringSystem"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            checked={data.scoringSystem == 1}
                            value={1}
                            control={<Radio />}
                            label="Pass / Fail / NA"
                          />
                          <FormControlLabel
                            checked={data.scoringSystem == 2}
                            value={2}
                            control={<Radio />}
                            label="Yes / NO / NA"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div class="form_inner p-2">
                  <label for="">
                    Sections <span className="text-danger">*</span>{" "}
                  </label>
                  <div class="input_icons sections">
                    <div class="input_field">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="sections"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            checked={data.sections == 0}
                            value={0}
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            checked={data.sections != 0}
                            value={1}
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div class="form_inner p-2">
                  <label for="">
                    Default Choice <span className="text-danger">*</span>{" "}
                  </label>
                  <div class="input_icons sections">
                    <div class="input_field">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="defaultChoice"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            checked={data.defaultChoice == 0}
                            value={0}
                            control={<Radio />}
                            label="N/A"
                          />
                          <FormControlLabel
                            checked={data.defaultChoice != 0}
                            value={1}
                            control={<Radio />}
                            label="Yes/Pass"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
          <div class="ps-2 signature">
            <div class="sign_btns">
              <div>
                {!loading ? (
                  <button
                    type="button"
                    class="submit"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {!isEdit ? "Create" : "Update"}
                  </button>
                ) : (
                  <SubmitLoader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateSafetyChecklistTemplate;
