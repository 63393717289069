import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { commonService } from "../common/common.service";
import fileIcon from "../../images/openfolderwhite.svg";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import { permissions } from '../../helpers/permissions'

const AddCompanies = ({ title }) => {
  const { id } = useParams()
  const { user } = useAppState()
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailPreference, setEmailPref] = useState(permissions.map(v => v.children).flat().map(v => ({ name: v.name, value: false })))

  const fieldValues = {
    companyName: null,
    primaryContactFirstName: null,
    primaryContactLastName: null,
    primaryContactEmail: null,
    companyAddress: null,
    companyTelephone: null,
    companyInformation: null,
    companyWebsite: null,
    landlordName: null,
    landlordTelephone: null,
    typeofBusiness: null,
    firesafetyManager: null,
    deputyFiresafetyManager: null,
    assemblyPoints: null,
    password: null,
    permissions: permissions.map(v => v.children).flat().map(v => ({ name: v.name, value: true })),
    passwordType: 'password'

  };

  const [fieldData, setFieldData] = useState(fieldValues);
  const [error, setError] = useState({ ...fieldValues });
  const [siteUsers, setSiteUsers] = useState([]);
  const [contractorData, setContractorData] = useState([]);

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setUserID(
            res.data[0].added_by ? res.data[0].added_by : res.data[0].id
          );

          if ( res.data[0].role != 'superadmin' ) {
            history.push('/');
          }

          setFieldData({
            ...fieldData,
            companyId: res.data[0].added_by ? res.data[0].added_by : res.data[0].id,
            addedBy: res.data[0].added_by ? res.data[0].added_by : res.data[0].id,
            contractorId: ( user.role.includes("admin") ? fieldData.contractorId : res.data[0].contractorId )
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchCompanyDetails = () => {
    setLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASEURL}/company/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        // setFieldData({ ...res.data[0] });
        let newData = {};
        let receivedData = res.data;

        newData['primaryContactFirstName'] = receivedData.firstName;
        newData['primaryContactLastName'] = receivedData.lastName;
        newData['companyTelephone'] = receivedData.companytelephonenumber;
        newData['companyAddress'] = receivedData.companyaddress;
        newData['companyName'] = receivedData.companyName;
        newData['companyInformation'] = receivedData.companyInformation;
        newData['primaryContactEmail'] = receivedData.email;
        newData['companyWebsite'] = receivedData.website;

        const allPermit = receivedData.disabledPermissions ? receivedData.disabledPermissions.split(",").filter(v => !v.requiresSuperAdmin&&!v.requiresRestricted) : [];

        setFieldData({
          ...fieldData,
          ...newData,
          permissions: permissions.map(v => v.children)
                  .flat()
                  .map(v => ({ name: v.name, value: !allPermit.includes(v.name) }))
                .filter(v => !v.requiresSuperAdmin&&!v.requiresRestricted),
        } );

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: companyId,
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchCompanyDetails()
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  const getSites = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${user.id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: companyId,
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setFieldData({
      ...fieldData,
      [name]: value,
    });
    if (name == "siteId") {
      getSiteUsers(value)
    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyName":
        if ( ! value ) {
          message = stateObj[name] = "Company Name is required";
        }
        break;
      case "primaryContactFirstName":
        if ( ! value ) {
          message = stateObj[name] = "Primary Contact First Name is required";
        }
        break;
      case "primaryContactLastName":
        if ( ! value ) {
          message = stateObj[name] = "Primary Contact Last Name is required";
        }
        break;
      case "primaryContactEmail":
        if ( ! value ) {
          message = stateObj[name] = "Primary Contact Email is required";
        }
        break;
      case "companyAddress":
        if ( ! value ) {
          message = stateObj[name] = "Company Address is required";
        }
        break;
      case "companyTelephone":
        if ( ! value ) {
          message = stateObj[name] = "Company Telephone is required";
        }
        break;
      case "password":
        if ( ! value ) {
          message = stateObj[name] = "Password is required";
        }
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      handleSubmitAfterSave()
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = () => {
    let errorShow = {
      companyName: fieldData.companyName,
      primaryContactFirstName: fieldData.primaryContactFirstName,
      primaryContactLastName: fieldData.primaryContactLastName,
      primaryContactEmail: fieldData.primaryContactEmail,
      companyAddress: fieldData.companyAddress,
      companyTelephone: fieldData.companyTelephone,
    };

    if ( ! id ) {
      errorShow['password'] = fieldData.password;
    }

    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.companyName != null &&
      fieldData.primaryContactFirstName != null &&
      fieldData.primaryContactLastName != null &&
      fieldData.primaryContactEmail != null &&
      fieldData.companyAddress != null &&
      fieldData.companyTelephone != null &&
      ( id ? true : fieldData.password != null )
    ) {

      const form = new FormData();

      form.append("companyName", fieldData.companyName);
      form.append("firstName", fieldData.primaryContactFirstName);
      form.append("lastName", fieldData.primaryContactLastName);
      form.append("email", fieldData.primaryContactEmail);
      form.append("username", fieldData.primaryContactEmail);
      form.append("companyaddress", fieldData.companyAddress);
      form.append("companytelephonenumber", fieldData.companyTelephone);
      form.append("companyInformation", fieldData.companyInformation);
      form.append("website", fieldData.companyWebsite);

      if ( ! id ) {
        form.append("password", fieldData.password);
      }

      form.append( 'emailPreferences', emailPreference.map(x => x.name).join(',') );
      form.append( 'permissions', fieldData.permissions.filter(x => !x.value).map(y => y.name).join(',') );

      setLoading(true);
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update/company/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/company`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);

        id ? history.push('/companies/edit/' + id) : history.push('/companies');

        setFieldData(fieldData);
      })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  function generateRandomPassword() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setFieldData({ ...fieldData, password: retVal });
  }

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">

        <div className="addaccess_first_container mb-20">
          <div className="general_details">Details</div>

          <div className="d-flex flex-wrap">

            <div className="col-md-6">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Company Name <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="companyName"
                      {...(id ? {
                        value: fieldData.companyName,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.companyName && (
                  <span className="err">{error.companyName}</span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Company Address <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="companyAddress"
                      {...(id ? {
                        value: fieldData.companyAddress,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.companyAddress && (
                  <span className="err">{error.companyAddress}</span>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Company Telephone <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="companyTelephone"
                      {...(id ? {
                        value: fieldData.companyTelephone,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.companyTelephone && (
                  <span className="err">{error.companyTelephone}</span>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Company Information
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="companyInformation"
                      {...(id ? {
                        value: fieldData.companyInformation,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Website
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="companyWebsite"
                      {...(id ? {
                        value: fieldData.companyWebsite,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Primary Contact First Name <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="primaryContactFirstName"
                      {...(id ? {
                        value: fieldData.primaryContactFirstName,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.primaryContactFirstName && (
                  <span className="err">{error.primaryContactFirstName}</span>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Primary Contact Last Name <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="primaryContactLastName"
                      {...(id ? {
                        value: fieldData.primaryContactLastName,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.primaryContactLastName && (
                  <span className="err">{error.primaryContactLastName}</span>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Primary Contact Email <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="primaryContactEmail"
                      {...(id ? {
                        value: fieldData.primaryContactEmail,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.primaryContactEmail && (
                  <span className="err">{error.primaryContactEmail}</span>
                )}
              </div>
            </div>

            { !id && (
              <div className="col-md-12">
                <div className="w-100 p-2 location_ladder">
                  <label for="">
                    <div className="require-label">
                      Password <span className="text-danger">*</span> <a href="#" onClick={generateRandomPassword}>Generate</a>
                    </div>
                  </label>
                  <div className="input_icons form-group">
                    <div className="input_field input-group password-with-visibility">
                      <input
                        type={fieldData.passwordType}
                        name="password"
                        className="form-control"
                        value={fieldData.password ? fieldData.password : ""}
                        onChange={handleChange}
                      />
                      <div className="input-group-addon">
                        <a href="#" onClick={() => setFieldData({ ...fieldData, passwordType: fieldData.passwordType === "password" ? "text" : "password" })}><i className="fa fa-eye-slash" aria-hidden="true"></i></a>
                      </div>
                    </div>
                  </div>
                  {error.password && (
                    <span className="err">{error.password}</span>
                  )}
                </div>
              </div>
            )}

          </div>

        </div>

        <div className="addaccess_first_container">
          <div className="general_details">Active Sections</div>

          <div className="row mt-4 w-100">
            <div className="col-8 mb-4">
              <div className="d-flex justify-content-between">

              </div>
            </div>
            <div className="col-4 mb-4">
              <div className="d-flex justify-content-end">
                <label className="p-2" for="">
                  {" "}
                  Select All
                </label>

                <div class="form-check form-switch">
                  <input
                    class="sub-permission-check form-check-input form-check-input-color d-block"
                    style={{ width: "50px" }}
                    type="checkbox"
                    role={`switch`}
                    id={`flexSwitchCheckChecked`}
                    checked={fieldData.permissions.filter(p => p.value).length == fieldData.permissions.length}
                    onChange={(e) => {
                      setFieldData(d => ({
                          ...d,
                          permissions: d.permissions.map(p => {
                              return { ...p, value: e.target.checked };
                          })
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="p-2">
            <div className="company-active-sections">
            {permissions.map((v, i) => (
                <div className='row mt-4'>
                  <h5 className='mb-4'>{v.name}</h5>
                  {/* Don't allow 'company' to be togglable.  */}
                  {v.children.filter(px => ! [ 'companies', 'company' ].includes(px.name) ).map(v=>(<div className='col-md-6 col-xl-4 col-sm-6  mb-4'>
                    <div className='d-flex justify-content-between'>
                      <label for={`flexSwitchCheckChecked-${i}`}>{v.label} </label>
                      <div class="form-check form-switch">
                        <input
                            class="sub-permission-check form-check-input form-check-input-color d-block"
                            style={{ width: "50px" }}
                            type="checkbox"
                            role={`switch-${i}`}
                            id={`flexSwitchCheckChecked-${i}`}
                            checked={fieldData.permissions.filter(p => p.name === v.name)[0].value}
                            onChange={e => {
                              setFieldData(d => ({
                                ...d,
                                permissions: d.permissions.map(p => {
                                  if (p.name === v.name) {
                                    return { ...p, value: e.target.checked }
                                  } else {
                                    return p
                                  }
                                })
                              }))
                            }} />
                      </div>
                    </div>
                  </div>))}
                </div>
            ))}
          </div>
          </div>
        </div>

        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Update Data" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn" onClick={() => history.push("/companies")}>cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddCompanies;
