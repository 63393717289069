import React from 'react'
import {
  Text,
  View,
} from "@react-pdf/renderer";

export default function TabularData({tabularSections}) {
  return (
    <>
      {tabularSections.map((section) => (
        <View wrap={false}>
          
          <View
            style={{
              fontSize: "16px",
              fontFamily: "Helvetica-Bold",
              textAlign: "left",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <Text style={{ fontFamily: "Helvetica" }}>{section.name}</Text>
          </View>

          <View style={{display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              fontSize: "10px",
              border: "1px solid #ddd",
              borderBottom: "none"
            }}>
            {section.rows.map((row) => (
              <View style={{
                width: ( row[2] ? row[2] : "50%" ),
                flexBasis: ( row[2] ? row[2] : "50%" ),
                padding: "5px",
                borderRight: "1px solid #ddd",
                borderBottom: "1px solid #ddd",
              }}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>{row[0]}: </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {row[1]}
                  </Text>
                </Text>
              </View>
            ))}
          </View>

        </View>
      ))}
    </>
  )
}