import React from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { permissions } from "../../helpers/permissions";
import { useAppState } from "../../state";
import { useEffect } from "react";
import Logo from "../../images/logo.svg";
import Minus from "../../images/minus.svg";
import UpArrow from "../../images/up-arrow.svg";
import LeftArrow from "../../images/left-arrow.svg";
import Eye from "../../images/eye.svg";

function Sidebar() {
  const history = useHistory();

  const { userPermissions, user,selectedSite } = useAppState();
  console.log("Sidebar-userPermissions", userPermissions);
  console.log("Sidebar-user", user);

  const [active, setActive] = useState(permissions[0].name);
  const [active2, setActive2] = useState(null);

  const [allPermissions, setPermissions] = useState([]);
  console.log("Sidebar-allPermissions", allPermissions);

  useEffect(() => {
    console.log('comes in to handle disabled');
    
    const disabledPermissions = user.disabledPermissions ? user.disabledPermissions.split(",") : [];

    setPermissions(
      permissions
        .filter(
          (v) =>
            v.children.filter((c) => userPermissions.includes(c.name)).length
        )
        .map((v) => {
          return {
            ...v,
            children: v.children
              .filter(
                  (p) => 
                    ( userPermissions.includes(p.name) &&  
                    ! disabledPermissions.includes(p.name) &&
                    ( ! p.requiresSuperAdmin || user.role == 'superadmin' ) )
                  )
              .map((c) => {
                return {
                  ...c,
                  children: c.children.filter((gc) =>
                    (
                        (
                            userPermissions.includes(gc.name) ||
                            (gc.adminOnly&&((user.role=="admin")||(user.role=="superadmin")))
                        )
                    )
                  ),
                };
              }),
          };
        })
    );
  }, [userPermissions, user]);

  return (
    <div className="sidebar">
      <div className="logo cursor-pointer" onClick={() => {
          history.push("/");

          if ( window.innerWidth < 768) {
            document.getElementById("slide-slidebar").click();
          }
        }} >
        <img src={Logo} alt="" height="81px" />
      </div>

      <ul>
        {allPermissions.map((l1) => (
          <li>
            <div className="first_menu cursor-pointer"
              onClick={() => {
                setActive((a) => {
                  // if (a.includes(l1.name)) {
                  //     return a.filter(v => v != l1.name)
                  // } else {
                  //     return [...a, l1.name]
                  // }
                  if (a === l1.name) {
                    return null;
                  } else {
                    return l1.name;
                  }
                });
              }}
            >
              <a href="" onClick={(e) => e.preventDefault()} title={l1.name}>
                {l1.name}
              </a>
              <img src={Minus} alt="" />
            </div>

            {active == l1.name && l1.children.map((l2) => (
              <ul className="second_menu_head">
                <li>
                  <div className="second_menu cursor-pointer"
                    onClick={() => {
                      setActive2((a) => {
                        // if (a.includes(l1.name + l2.name)) {
                        //     return a.filter(v => v != (l1.name + l2.name))
                        // } else {
                        //     return [...a, (l1.name + l2.name)]
                        // }
                        if (a === l2.name) {
                          return null;
                        } else {
                          return l2.name;
                        }
                      });
                      if (l2.path) {
                        history.push(l2.path);

                        if ( window.innerWidth < 768) {
                          document.getElementById("slide-slidebar").click();
                        }
                      }
                    }}
                  >
                    <div>
                      <img src={require(`../../images/${l2.icon}`)} alt="" width="20px" height="20px" />
                      <a href="" onClick={(e) => e.preventDefault()} title={l2.label} > {l2.label} </a>
                    </div>

                    <div className="second_menu_icon">
                      {active2 == l2.name ? (
                        <img src={UpArrow} alt="" />
                      ) : (
                        <img src={LeftArrow} alt="" />
                      )}
                    </div>

                  </div>

                  {active2 == l2.name && l2?.children?.filter((l3) => {
                    return l3.adminOnly ? ((user.role == "admin") || (user.role == "superadmin")) : true}).filter(v=>v.type=="on-site-select"?!!selectedSite:true).filter(v=>v.type!=="non-sidebar").map(
                    (l3) => (
                      console.log("L3", l3,user.role),
                      (
                        <ul class="third_menu">
                          {/* {active2.includes(l1.name + l2.name) && l2?.children?.filter(l3 => l3.admin ? user.role == "admin" : true).map(l3 => (<ul class="third_menu"> */}
                          <li>
                            <img src={require(`../../images/${l3.icon}`)} alt="" width="16px" height="16px" className="me-2" />
                            {" "}
                            <a href="" onClick={(e) => {
                              e.preventDefault();
                              if (l3.path) {
                                history.push(l3.path);

                                if ( window.innerWidth < 768) {
                                  document.getElementById("slide-slidebar").click();
                                }
                              }
                            }}
                              title={l3.label}
                            >
                              {l3.label}
                            </a>
                          </li>
                        </ul>
                      )
                    )
                  )}
                </li>
              </ul>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
