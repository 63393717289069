import React from 'react'
import {
    Text,
    View,
    Image,
    Link,
} from "@react-pdf/renderer";

export default function PDFFiles({block}) {

    /**
     * Generate thumb image path
     */
    const generateThumbImagePath = (url) => {
      // check if we have url ending in an image format
      const imageFormats = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".JPG", ".JPEG", ".PNG", ".GIF", ".BMP"];
      
        if ( block.type == 'signatures' ) {
          url = process.env.REACT_APP_API_BASEURL + url;
          return url;
        }

        // Images must get converted to a PNG, pdf-image?path= handles that.
        if ( imageFormats.some( (format) => url.endsWith(format) ) ) {
            url = process.env.REACT_APP_API_BASEURL + "/pdf-image?path=" + url;
        } else if ( url.endsWith( '.pdf' ) || url.endsWith( '.PDF' ) ) {
            url = process.env.REACT_APP_API_BASEURL + '/uploads/icons/pdf.png'; 
        } else if ( url.endsWith( '.doc' ) || url.endsWith( '.docx' ) ) {
            url = process.env.REACT_APP_API_BASEURL + '/uploads/icons/doc.png'; 
        } else if ( url.endsWith( '.xls' ) || url.endsWith( '.xlsx' ) ) {
            url = process.env.REACT_APP_API_BASEURL + '/uploads/icons/xls.png'; 
        } else {
            url = process.env.REACT_APP_API_BASEURL + '/uploads/icons/file.png'; 
        }
        


        return url;
    }

    return (
      <>
        <View
          wrap={block.files && block.files.length > 10 ? true : false}
        >
            <View
              style={{
                fontSize: "16px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <Text style={{ fontFamily: "Helvetica" }}>{block.name}</Text>
              {block.subtitle && <>
              <Text style={{ fontFamily: "Helvetica",fontSize: '10px',marginTop: '5px', color: '#667378' }}>{block.subtitle}</Text>
              </>}
            </View>
            {block.files ? <>
              <View
                  style={{
                    display: "flex",
                    fontFamily: "Helvetica",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                {block.files.split(",").map((imgPreviewUrl) => (
                  <View
                    style={{
                      margin: "5px",
                      width: "100px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <Link
                      src={
                        process.env.REACT_APP_API_BASEURL + imgPreviewUrl
                      }
                    >
                      <Image
                        src={{
                          uri: generateThumbImagePath( imgPreviewUrl )
                        }}
                      />
                    </Link>
                  </View>
                ))}
              </View>
            </> : <>
              <View><Text>(No files in this section)</Text></View>
            </>}
          </View>
      </>  
    );
}