import React, { useEffect, useState } from "react";
import DefaultImg from "../../../images/default/no_image.png";
import { getBase64 } from "../imageHelper.js";

export default function ImgInput({
    mainClass = null,
    title,
    input_ref_id,
    onChange,
    error,
    accept_file_type = null,
    style,
    img_src,
}) {

    const [imgPreviewUrl, setImgPreviewUrl] = useState(img_src);


    useEffect(() => {
    
          setImgPreviewUrl(img_src);
      
      }, [img_src]);


    const [localError, setLocalError] = useState("");
    return (
        <>
            <div className={`${style?.div ? style.div : "col-md-6 col-12"}`}>
                <div className="card border shadow-none mb-2 app-file-info">
                    <div className="p-3 text-center">
                        <div id="profile_pic_1_preview" className="image-fixed mb-3">
                            <img
                                className="obj_fit_img obj-fit-cover rounded"
                                src={imgPreviewUrl}
                                alt=""
                                height="150"
                                width="150"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = DefaultImg;
                                }}
                            />
                        </div>
                        <div className="form-group add-new-file text-center mb-0">
                            <label
                                htmlFor={input_ref_id}
                                style={{ height: "auto" }}
                                className="btn btn-primary btn-block glow mb-2 d-block add-file-btn text-capitalize"
                            >
                                Select Image
                            </label>
                            <label  
                                onClick={()=>{onChange("" , null)}}
                                style={{ height: "auto" }}
                                className="btn btn-danger btn-block glow d-block add-file-btn text-capitalize"
                            >
                                Remove
                            </label>
                            <input
                                type="file"
                                className="d-none"
                                id={input_ref_id}
                                accept={`${accept_file_type ? accept_file_type : "image/x-png, image/jpeg"}`}
                                onChange={(e) => {
                                    if (
                                        e.target.files[0].name.match(/\.(jpg|jpeg|png|svg|webp|JPEG|JPG|PNG|SVG|WEBP)$/)
                                    ) {
                                        if (parseInt((e.target.files[0].size / 1000000).toFixed(2)) <= 5) {
                                            setLocalError("");
                                            getBase64(e.target.files[0])
                                                .then((result) => {
                                                    onChange(result , e.target.files[0]);
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                });
                                        } else {
                                            setLocalError("Image should be less than 5 MB.");
                                        }
                                    } else {
                                        setLocalError("Only jpg, jpeg, png, svg and webp files are supported.");
                                        onChange("" , null);
                                    }
                                }}
                            />
                            <label className="form-label-input mt-3 text-break">{title}</label>
                        </div>
                    </div>
                </div>

                {localError && <span className="text-danger">{localError}</span>}
                {error && <span className="error mt-2">{error}</span>}
            </div>
        </>
    );
}
