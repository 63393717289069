import React from 'react'
import "./ViewRole.css"
import Header from '../Header/Header'
import { useState } from 'react'
import { permissions } from '../../helpers/permissions'
import axiosInstance from '../../helpers/request'
import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { useAppState } from '../../state'

function ViewRole({ title }) {
    const cookies = new Cookies();
    const history = useHistory()
    const { user, userPermissions } = useAppState();
    const location = useLocation()
    const { id } = useParams()
    const userToken = localStorage.getItem("cs_token");
    const [data, setData] = useState({
        name: "",
        description: "",
        permissions: permissions.map(v => v.children).flat().map(v => ({ name: v.name, value: false, children: v.children.map(c => ({ name: c.name, value: false })) }))
    });

    const [allPermissions, setPermissions] = useState([]);

    useEffect(() => {
        console.log('comes in to handle disabled');
        
        const disabledPermissions = user.disabledPermissions ? user.disabledPermissions.split(",") : [];

        setPermissions(
        permissions
            .filter(
            (v) =>
                v.children.filter((c) => userPermissions.includes(c.name)).length
            )
            .map((v) => {
            return {
                ...v,
                children: v.children
                .filter(
                    (p) => 
                        ( userPermissions.includes(p.name) &&  
                        ! disabledPermissions.includes(p.name) &&
                        ( ! p.requiresSuperAdmin || user.role == 'superadmin' ) )
                    )
                .map((c) => {
                    return {
                    ...c,
                    children: c.children.filter((gc) =>
                        (
                            (
                                userPermissions.includes(gc.name) ||
                                (gc.adminOnly&&((user.role=="admin")||(user.role=="superadmin")))
                            )
                        )
                    ),
                    };
                }),
            };
            })
        );
    }, [userPermissions, user]);

    useEffect(() => {
        if (id) {
            getRole()
        }
    }, [])

    function getRole() {
        axiosInstance
            .get(`${process.env.REACT_APP_API_BASEURL}/role/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.id) {
                    const allPermit = res.data.permissions ? res.data.permissions.split(",") : []
                    setData({ ...res.data, permissions: permissions.map(v => v.children).flat().map(v => ({ name: v.name, value: allPermit.includes(v.name), children: v.children.map(c => ({ name: c.name, value: allPermit.includes(c.name) })) })) });
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    return (
        <div class="middle_page">
            <Header title={title} steps={[{ name: "Home", path: "/" }, { name: "Roles List", path: "/roles" }, { name: "View Role" }]} />
            <div class="main_container">
                <div>
                    <div class="accident">
                        <div class="accident_text">
                            <h5>View Role</h5>
                        </div>
                    </div>
                    <form>
                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div class="form_inner p-2">
                                    <label for="">Name </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" disabled value={data.name} name="name" placeholder="Name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Description </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" disabled value={data.description} name="description" placeholder="Descriptions" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <div className='row w-100'>
                                        <label className='p-2' for="">Permissions</label>
                                        {allPermissions.map((v, i) => (
                                            <div className='row mt-4'>
                                                <h5 className='mb-4'>{v.name}</h5>
                                                {v.children.map(v=>(<div className='col-md-6 col-xl-4 col-sm-6  mb-4'>
                                                    <div class="d-flex justify-content-between">
                                                        <label for={`flexSwitchCheckChecked-${i}`}>{v.label} </label>

                                                        <div class="form-check form-switch">
                                                            <input
                                                                class="sub-permission-check form-check-input form-check-input-color d-block"
                                                                style={{ width: "50px" }}
                                                                type="checkbox"
                                                                role={`switch-${i}`}
                                                                id={`flexSwitchCheckChecked-${i}`}
                                                                checked={data.permissions.filter(p => p.name === v.name)[0].value}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                    {data.permissions.filter(p => p.name === v.name)[0].value ?
                                                        <div className='mt-2 ps-2'>
                                                            {v.children.map((gc, i) => (
                                                                <div className="d-flex justify-content-between">
                                                                    <label style={{ fontSize: "14px" }} for={`flexSwitchCheckCheckedInner-${i}`}>{gc.label} </label>
                                                                    <div class="form-check form-switch">
                                                                        <input
                                                                            class="form-check-input form-check-input-color d-block"
                                                                            style={{ width: "50px" }}
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id={`flexSwitchCheckCheckedInner-${i}`}
                                                                            disabled
                                                                            checked={data.permissions.filter(p => p.name === v.name)[0].children.filter(c => c.name === gc.name)[0].value}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        : ""}
                                                </div>))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ViewRole