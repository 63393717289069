import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const baseInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});

baseInstance.defaults.headers.common["Accept"] = "application/json";
baseInstance.defaults.headers.common["Content-Type"] = "application/json";
baseInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cs_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

baseInstance.interceptors.response.use(
  (res) => res.data,
  (err) => {
    if (err?.response?.status === 401) {
      cookies.remove("cs_token");
      cookies.remove("cs_user");
      cookies.remove("cs_retoken");
      cookies.remove("cs_permissions");

      localStorage.removeItem("cs_token");
      localStorage.removeItem("cs_user");
      localStorage.removeItem("cs_retoken");
      localStorage.removeItem("cs_permissions");
    }
    throw err;
  }
);

export default baseInstance;
