import axios from "../libs/axios";

class TrainingService {
    getTrainings(params) {
        return axios.get(`/trainings`, params);
    }
    getTraining(id) {
        return axios.get(`/training/${id}`);
    }

    addTraining(payload) {
        return axios.post(`/add/training`, payload);
    }

    updateTraining(id, payload) {
        return axios.put(`/trainings/${id}`, payload);
    }

    deleteTraining(id) {
        return axios.delete(`/trainings/${id}`);
    }
}

export default new TrainingService();
