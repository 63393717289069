import React, { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./RiskAssessments.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { commonService } from "../common/common.service";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import riskAssessmentService from "../../services/riskAssessment.service";
import { onError } from "../../libs/errorLib";
import deparmentService from "../../services/deparment.service";

const AddRiskAssessment = ({ title }) => {
  const { id } = useParams();
  const { user } = useAppState();
  const history = useHistory();
  const location = useLocation();
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    siteId: null,
    departmentId: null,
    companyId: companyId,
    addedBy: companyId,
    contractorId: null,

    task: null,  
    assessedBy: null,  
    assessmentDate: commonService.formatDate( new Date(), "YYYY-MM-DD" ),
    numberOfDays: null,  
  };

  const [fieldData, setFieldData] = useState(fieldValues);
  const [error, setError] = useState({ ...fieldValues, assessmentDate: "" });
  const [siteUsers, setSiteUsers] = useState([]);
  const [contractorData, setContractorData] = useState([]);

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setUserID(
            res.data[0].added_by ? res.data[0].added_by : res.data[0].id
          );
          setFieldData({
            ...fieldData,
            companyId: res.data[0].added_by
              ? res.data[0].added_by
              : res.data[0].id,
            addedBy: res.data[0].added_by
              ? res.data[0].added_by
              : res.data[0].id,
            contractorId: user.role.includes("admin")
              ? fieldData.contractorId
              : res.data[0].contractorId,
          });
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const fetchRiskAssessmentDetails = async () => {
    setLoading(true);
    try {
      const response = await riskAssessmentService.getRiskAssessment(id);
      if (response) {
        setFieldData({ ...response[0], assessmentDate: commonService.formatDate( response[0].assessmentDate, "YYYY-MM-DD" ) });
        getSiteUsers(response[0].siteId);
      }
    } catch (error) {
      onError(error || "Unable to process request");
    } finally {
      setLoading(false);
    }
  };

  const getDepartments = async () => {
    try {
      const res = await deparmentService.getAllDepartments({
        companyId: companyId,
        status: "",
      });
      if (res) {
        setDepartments(
          res.filter(
            (department, index, arr) =>
              res.findIndex((item) => item.id == department.id) == index
          )
        );
      }
    } catch (error) {
      console.log("error", error);
      onError(error || "Unable to process request");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchRiskAssessmentDetails();
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  const getSites = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${user.id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true);
    try {
      const siteUsersResponse = await axiosInstance.post(
        `/site-users/${siteId}`
      );
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false);
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: companyId,
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setFieldData({
      ...fieldData,
      [name]: value,
    });
    if (name == "siteId") {
      getSiteUsers(value);
    }
  };

  const isValidData = (value, name) => {
    let message = "";
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;

      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;

      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;

      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Company is required.";
        }
        break;

      case "assessedBy":
        if (!value) {
          message = stateObj[name] = "Assessed By is required.";
        }
        break;

      case "assessmentDate":
        if (!value) {
          message = stateObj[name] = "Date is required.";
        }
        break;

      case "numberOfDays":
        if (!value) {
          message = stateObj[name] = "Number of Days is required.";
        }
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    let errorShow = {
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      addedBy: fieldData.addedBy,
      contractorId: fieldData.contractorId,

      task: fieldData.task,
      assessedBy: fieldData.assessedBy,
      assessmentDate: fieldData.assessmentDate,
      numberOfDays: fieldData.numberOfDays,
    };

    let trigger = true;
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message);
        trigger = false;
      }
    });

    if (
      fieldData.siteId != null &&
      
      fieldData.task != null &&
      fieldData.assessedBy != null &&
      fieldData.assessmentDate != null &&
      fieldData.numberOfDays != null
    ) {
      const form = new FormData();

      const mailUpdates = siteUsers
        .filter((v) => v.type == "admin")
        .map((v) => ({
          email: v.email,
          userid: v.userid,
          fullName: v.fullName,
          emailPref: v.emailPreference
            ? v.emailPreference.includes("riskAssessments")
            : v.permits.includes("riskAssessmentsreceiveEmails"),
          isAdminWithNoPref: !v.emailPreference && v.role.includes("admin"),
        }))
        .filter((v) => v.emailPref || v.isAdminWithNoPref);

      form.append(
        "mailUpdates",
        mailUpdates.map((v) => `${v.email}--${v.fullName}`).join(",")
      );
      form.append(
        "notiticationUpdates",
        siteUsers
          .filter((v) => v.type == "admin")
          .filter((v) => v.userid)
          .map((v) => v.userid)
          .join(",")
      );

      form.append(
        "departmentId",
        fieldData.departmentId == null ? null : fieldData.departmentId
      );
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      form.append("companyId", companyId);
      form.append("addedByInCompany", user.id);
      form.append("contractorId", fieldData.contractorId);

      form.append("task", fieldData.task);
      form.append("assessedBy", fieldData.assessedBy);
      form.append("assessmentDate", fieldData.assessmentDate);
      form.append("numberOfDays", fieldData.numberOfDays);

      setLoading(true);

      let response;
      try {
        if (id) {
          response = await riskAssessmentService.updateRiskAssessment(id, form);
        } else {
          response = await riskAssessmentService.addRiskAssessment(form);
        }

        if (response) {
          toast.success(response.message);
          id
            ? history.push("/risk-assessment/" + id)
            : history.push("/risk-assessment/" + response.id);
          setFieldData(fieldData);
        }
      } catch (error) {
        onError(error);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("error", fieldData);
    }
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }));

  const departmentOptions = allDepartment
    .filter((v) => v.siteId == fieldData.siteId)
    .map((d) => ({
      label: d.name,
      value: d.id,
      name: "departmentId",
    }));

  return (
    <div className="middle_page">
      <Header title={title} steps={[{ name: "Home", path: "/" }]} />
      <div className="main_container">
        <div className="addaccess_first_container">
          <div className="general_details">Details</div>

          <div className="d-flex flex-wrap">
            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Site <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Sites"
                      options={siteOptions}
                      {...(id
                        ? {
                            value: siteOptions.filter(
                              (v) => v.value == fieldData.siteId
                            ),
                            isDisabled: true,
                          }
                        : {})}
                    />
                  </div>
                </div>
                {error.siteId && <span className="err">{error.siteId}</span>}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Company
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Contractor"
                      value={fieldData.contractorId ? contractorData.filter(v => v.id == fieldData.contractorId).map(d => ({
                        label: d.name,
                        value: d.id,
                        name: "contractorId",
                      })) : null}
                      options={contractorData.map((d) => ({
                        label: d.name,
                        value: d.id,
                        name: "contractorId",
                      }))}
                    />
                  </div>
                </div>
                {error.contractorId && (
                  <span className="err">{error.contractorId}</span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Department <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Department"
                      options={departmentOptions}
                      {...(id
                        ? {
                            value: departmentOptions.filter(
                              (v) => v.value == fieldData.departmentId
                            ),
                            isDisabled: true,
                          }
                        : {})}
                    />
                  </div>
                </div>
                {error.departmentId && (
                  <span className="err">{error.departmentId}</span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Task {" "}
                    <small>
                      Name the job/what is being done? (E.G. driving forklift or
                      working at height)
                    </small>{" "}
                    <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="task"
                      {...(id
                        ? {
                            value: fieldData.task,
                          }
                        : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.task && <span className="err">{error.task}</span>}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Assessed By <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select User"
                      options={siteUsers.map((v) => ({
                        label:
                          v.fullName +
                          " - " +
                          (v.contractorName ? v.contractorName : v.companyName) +
                          " - " +
                          (v.roleName ? v.roleName : v.role),
                        value: `${v.userid}`,
                        name: "assessedBy",
                      }))}
                      value={siteUsers
                        .filter((v) => `${v.userid}` == fieldData?.assessedBy)
                        .map((v) => ({
                          label:
                            v.fullName +
                            " - " +
                            (v.contractorName ? v.contractorName : v.companyName) +
                            " - " +
                            (v.roleName ? v.roleName : v.role),
                          value: `${v.userid}`,
                          name: "assessedBy",
                        }))}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_access">
                <label for="">
                  <div className="require-label">
                    {" "}
                    Date <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      value={fieldData.assessmentDate}
                      min={new Date()
                        .toLocaleString("en-GB")
                        .substring(0, 10)
                        .split("/")
                        .reverse()
                        .join("-")}
                      type="date"
                      onChange={handleChange}
                      name="assessmentDate"
                    />
                  </div>
                </div>
                {error.assessmentDate && (
                  <span className="err">{error.assessmentDate}</span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_access">
                <label for="">
                  <div className="require-label">
                    {" "}
                    Number of days to review(0-365) <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      value={fieldData.numberOfDays}
                      min="0"
                      max="365"
                      type="number"
                      onChange={handleChange}
                      name="numberOfDays"
                    />
                  </div>
                </div>
                {error.numberOfDays && (
                  <span className="err">{error.numberOfDays}</span>
                )}
              </div>
            </div>

          </div>
        </div>

        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Update Data" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button
            className="cancel_btn"
            onClick={() => history.push("/risk-assessments/")}
          >
            cancel
          </button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddRiskAssessment;
