import React from 'react'
import "./contractor.css"
import Header from '../Header/Header'
import { useState } from 'react'
import Cookies from 'universal-cookie';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../helpers/request';
import { useEffect } from 'react';
import { useAppState } from '../../state';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import SubmitLoader from '../common/SubmitLoader';
import DefaultImg from "../../images/default/no_image.png";
import ImgInput from '../common/form/ImgInput';
import Select from "react-select"

function AddContractor({ title }) {
    const { user } = useAppState()
    const cookies = new Cookies();
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()
    const isEdit = location.pathname.includes("edit-contractor")
    const userToken = localStorage.getItem("cs_token");
    const [data, setData] = useState({
        name: "",
        description: "",
        addedByInCompany: "",
        sites: "",
        contractorLogoFile: null,
        contractorLogo: "",
        email: '',
    })
    const [roles, setRoles] = useState([])
    const [loading, setLoading] = useState(false)
    const [companyId, setUserID] = useState("")
    const [oldImage, setOldImage] = useState(null);
    const [sites, setSites] = useState([]);

    useEffect(() => {
        userDetail()
        if (isEdit && id) {
            getContractor()
        }
    }, [])


    useEffect(() => {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-roles`, {
                companyId: companyId,
                status: ""
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setRoles(res.data);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });

        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-sites`, {
                companyId: companyId,
                status: ""
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setSites(res.data.filter((v, i) => res.data.findIndex(v1 => v1.id == v.id) == i));
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });

    }, [companyId])



    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setUserID(((res.data[0].role !== "admin") && res.data[0].added_by) ? res.data[0].added_by : res.data[0].id);
                    if (!isEdit) {
                        setData({
                            ...data,
                            addedByInCompany: res.data[0].id
                        })
                    }
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    function getContractor() {
        axiosInstance
            .get(`${process.env.REACT_APP_API_BASEURL}/contractor/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length) {

                    console.log('res.data', res.data)

                    setData({
                        ...res.data[0],
                        contractorLogoFile: null,
                        sites: res.data[0].sites?res.data[0].sites:"",
                    });
                    setOldImage(res.data[0].contractorLogo);
                    if (res.data.map(v => v.userid).filter(v => v).length) {
                        // getContractorUsers(res.data.map(v => v.userid).filter(v => v))
                    }
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }



    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }
    const handleSubmit = () => {
        if (data.name) {
            setLoading(true)
            const form = new FormData();
            
            form.append("name", data.name);
            form.append("description", data.description);
            form.append("sites", typeof data.sites == 'object' ? data.sites.join(",") : data.sites );
            form.append("email", data.email ? data.email : null);
            form.append("addedByInCompany", user.id);

            if (data.contractorLogoFile) {
                form.append("contractorLogoFile", data.contractorLogoFile);
            }
            if (isEdit) {
                axiosInstance.put(`/contractor/${id}`, form).then(async (response) => {
                    if (response.status === 200) {
                        // setData(response.data)
                        toast.success("Successfully Updated contractor")
                        history.push("/contractors")
                    } else {
                        throw new Error(response?.error);
                    }
                    setLoading(false)
                }).catch(err => {
                    console.log(err)
                    setLoading(false)
                    toast.error("Failed to Update contractor")
                });
            } else {
                form.append("companyId", companyId);
                axiosInstance.post('/add-contractor', form, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                })
                .then(async (response) => {
                    if (response.status === 200) {
                        // setData(response.data)
                        toast.success("Successfully Added contractor")
                        history.push("/contractors")
                    } else {
                        throw new Error(response?.error);
                    }
                    setLoading(false)
                }).catch(err => {
                    console.log(err)
                    setLoading(false)
                    toast.error("Failed to add contractor")
                });
            }
        } else {
            toast.error("Name is a mandatory field.")
        }
    }
    const handleDelete = async (id, name) => {
        const result = await Swal.fire({
            title: `Are you sure to remove ${name} from contractor`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .delete(process.env.REACT_APP_API_BASEURL + "/contractor-user/" + id)
                .then(function (response) {
                    getContractor();
                    toast.success("Contractor deleted successfully");
                })
                .catch(function (error) {
                    toast.error(error?.response?.data?.message || "Unable to delete contractor");
                });
        }
    };

    return (
        <div class="middle_page">
            <Header title={title} steps={[{ name: "Home", path: "/" }, { name: !isEdit ? "Add Contractor" : "Edit Contractor", path: "/create-contractor" }]} />
            <div class="main_container">
                {/* <p class="legal">(In Contemplation of Legal Proceedings)</p> */}
                <div>
                    <div class="accident">
                        <div class="accident_text">
                            <h5>{!isEdit ? "Add Contractor" : "Edit Contractor"}</h5>
                        </div>
                        {/* <div class="cross_icon"><img src={require("../../images/minus.png")} alt="" /></div> */}
                    </div>
                    <form>
                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div class="form_inner p-2">
                                    <label for="">Name <span className='text-danger'>*</span> </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={data.name} name="name" onChange={handleChange} placeholder="Name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Sites <span className='text-danger'>*</span></label>
                                    <div class="input_icons">
                                        {/* <div class="input_field"> */}
                                            <div class="w-100">
                                                <Select isMulti options={sites.map(d => ({ label: d.name, value: d.id }))} value={sites.filter(v => data.sites.includes(v.id)).map(v => ({ label: v.name, value: v.id }))} placeholder="Select Sites" onChange={v => {
                                                    setData({
                                                        ...data,
                                                        sites: v && v.length ? v.map(v => v.value) : []
                                                    })
                                                }} />
                                            </div>
                                            {/* <select name="siteId" value={data.siteId} onChange={handleChange} className="w-100" >
                                                <option value="">Select Site</option>
                                                {sites.map(s => (
                                                    <option value={s.id}>{s.name}</option>
                                                ))}
                                            </select> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Description</label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={data.description} name="description" onChange={handleChange} placeholder="Descriptions" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Email</label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="email" value={data.email} name="email" onChange={handleChange} placeholder="Email Address" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <ImgInput
                                        style={{ div: "col-md-3 col-6 mt-3" }}
                                        title={"Contractor Logo"}
                                        // img_src={userData.profile_img ? userData.profile_img  : formInputs.profile_img ? formInputs.profile_img : : ImgInput  }
                                        img_src={
                                            data.contractorLogoFile
                                                ? data.contractorLogo
                                                : data.contractorLogo
                                                    ? process.env.REACT_APP_API_BASEURL + data.contractorLogo
                                                    : DefaultImg
                                        }
                                        input_ref_id="company_img"
                                        onChange={(value, file) => {
                                            console.log("value", value);
                                            console.log("file", file);
                                            setData({
                                                ...data,
                                                contractorLogo: value,
                                                contractorLogoFile: file,
                                            });
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                    </form>
                    <div class="ps-2 signature">
                        <div class="sign_btns">
                            <div>
                                {!loading ? <button type='button' class="submit" disabled={loading} onClick={handleSubmit}>{!isEdit ? "Create" : "Update"}</button>
                                    : <SubmitLoader />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddContractor