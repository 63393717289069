import React from "react";
import "./SafetyChecklist.css";
import Header from "../Header/Header";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Edit2 from "../../images/edit-2.svg";
import Delete from "../../images/circle-cross.svg";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import SafetyService from "../../services/safety.service";
import { onError } from "../../libs/errorLib";
import CustomPopup from "../Popup/CustomPopup";
import CreatableSelect from "react-select/creatable";

import arrayMove from "array-move";
import { ReactSortable } from "react-sortablejs";

function SafetyCheckListTemplate({ title }) {
  const { id } = useParams();
  const [template, setTemplate] = useState([]);
  const [sections, setSections] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [templateItems, setTemplateItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [newSection, setNewSection] = useState([]);
  const [newSectionPopup, setNewSectionPopup] = useState([]);
  const [loading, setLoading] = useState(false);

  const [newItemPopup, setNewItemPopup] = useState(true);
  const [newItem, setNewItem] = useState({
    description: "",
    id: null,
  });

  useEffect(() => {
    getTemplateDetails();
    getAllItems();
  }, []);

  useEffect(() => {
    getTemplateSections();
  }, [template]);

  useEffect(() => {
    getTemplateItems();
  }, [template, sections]);

  async function getTemplateDetails() {
    setLoading(true);
    try {
      if (id) {
        const response = await SafetyService.getSafetyTemplateDetails(id);
        if (response) {
          setTemplate(response);
          getTemplateItems();
        }
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  // get all items for the company
  async function getAllItems() {
    setLoading(true);
    try {
      if (id) {
        const response = await SafetyService.getSafetyItemsForSelect();
        if (response) {
          setAllItems(response);
        }
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  async function getTemplateSections() {
    setLoading(true);
    try {
      if (template && template.sections) {
        const response = await SafetyService.getTemplateSections(id);
        if (response) {
          setSections(response);
        }
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  async function getTemplateItems() {
    setLoading(true);
    try {
      if (template && template.id) {
        const response = await SafetyService.getTemplateItems(id);
        if (response) {
          const items = [];
          if (template.sections && sections.length) {
            sections.forEach((section) => {
              
              items[section.id] = response.filter(
                (item) => item.sti_sectionId == section.id
              );

              items[section.id] = items[section.id].sort(
                (a, b) => a.sti_sortingOrder - b.sti_sortingOrder
              );
              
            });
            setTemplateItems(items);
          } else {
            setTemplateItems(response);
          }
        }
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  async function createSefetyChecklistItem(description) {
    setLoading(true);
    try {
      const response = await SafetyService.createSafetyItem({ description });
      if (response) {
        setNewItem({ label: description, value: response.insertId });
        getAllItems();
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  async function addItemToTemplate() {
    setLoading(true);
    try {
      let sortingOrder = -1;
      const items = template.section
        ? templateItems[selectedSection]
        : templateItems;
      if (items.length) {
        items.map((item) => {
          if (item && item.sortingOrder > sortingOrder) {
            sortingOrder = item.sortingOrder;
          }
        });
      }

      const payload = {
        itemId: newItem.value,
        sectionId: selectedSection ? selectedSection : null,
        sortingOrder: sortingOrder + 1,
      };
      const response = await SafetyService.addItemToTemplate(id, payload);
      if (response) {
        setSelectedItem([]);
        getTemplateItems();
        getAllItems();
        setNewItem({
          description: "",
          id: null,
        });

        toast.success('Item added successfully' );
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
      setNewItemPopup(true);
    }
  }

  const deleteConfirmation = async (name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${name} ?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    return result;
  };

  const deleteSefetyChecklistItem = async (id) => {
    setLoading(true);
    try {
      const response = await SafetyService.deleteTemplateItem(id);
      if (response) {
        toast.success(response.message);
        getTemplateItems();
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id, description) => {
    setLoading(true);
    try {
      let confirmDelete = false;
      let result = await deleteConfirmation(description);
      confirmDelete = result.isConfirmed;
      if (confirmDelete) {
        deleteSefetyChecklistItem(id);
      }
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  const addNewSection = async () => {
    setLoading(true);
    try {
      const response = await SafetyService.createTemplateSection(
        id,
        newSection
      );
      if (response) {
        getTemplateSections();
        setNewSection({
          name: "",
          templateId: null,
        });
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
      setNewSectionPopup(true);
    }
  };

  const setSortedList = (list) => {
    // console.log( list );
    if (list.length) {
      if (template && template.sections) {
        const sectionId = list[0].sti_sectionId;
        let items = [...templateItems];
        items[sectionId] = list;
        setSelectedSection(sectionId);
        setTemplateItems(items);
      } else {
        setTemplateItems(list);
      }
    }
  };

  const onSortEnd = (list) => {
    if ( template && template.sections ) {
      // get all template pairs and set their sorting order
      var sortingOrders = [];
  
      templateItems.forEach((section) => {
  
        if ( section ) {
          section.forEach((item, index) => {
              item.sti_sortingOrder = index + 1;
      
              sortingOrders.push({
                id: item.sti_id,
                sortingOrder: item.sti_sortingOrder,
                sectionId: item.sti_sectionId,
                checklistTemplateId: id,
              });
          });
  
        }
      });
      
      try {
        console.log( sortingOrders );
        const response = SafetyService.updateSortingForSectionItems(
          id,
          sortingOrders
        );
      } catch (e) {
        // Do nothing.
      }
      console.log( sortingOrders );
    }  

  };

  async function updateSortingOrder(id, updatedList) {
    setLoading(true);
    try {
      if (id && updatedList.length) {
        const response = await SafetyService.updateTemplateItemsOrder(
          id,
          updatedList
        );
        if (response && template?.sections) {
          getTemplateSections();
        } else {
          getTemplateItems();
        }
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
      setSelectedSection(null);
    }
  }

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          { name: "Safety Templates", path: "/safety/templates" },
        ]}
      />

      <CustomPopup
        trigger={!newItemPopup}
        setTrigger={() => {
          setNewItemPopup(true);
          setNewItem({
            description: "",
            id: null,
          });
        }}
      >
        <div className="col-12 form_inner p-2 location_access">
          <label for="new-question-title">Select Safety Checklist Item</label>
          <div class="input_icons">
            <div class="w-100">
              <CreatableSelect
                isClearable
                isDisabled={loading}
                isLoading={loading}
                onChange={(newValue) => setNewItem(newValue)}
                onCreateOption={createSefetyChecklistItem}
                options={allItems}
                value={newItem}
                placeholder="Select Item"
              />
            </div>
          </div>
        </div>
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            onClick={() => {
              addItemToTemplate();
            }}
          >
            Add Item
          </button>
        </div>
      </CustomPopup>

      <CustomPopup
        trigger={!newSectionPopup}
        setTrigger={() => {
          setNewSectionPopup(true);
          setNewSection({
            name: "",
            templateId: null,
          });
        }}
      >
        <div className="col-12 form_inner p-2 location_access">
          <label for="new-question-title">Add new section</label>
          <div class="input_icons">
            <div class="w-100">
              <input
                type="text"
                name="name"
                value={newSection.name ? newSection.name : ""}
                placeholder="Enter Section Name"
                onChange={(e) => {
                  setNewSection({
                    ...newSection,
                    name: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            onClick={() => {
              addNewSection();
            }}
          >
            Add Section
          </button>
        </div>
      </CustomPopup>

      {template.sections == 0 ? (
        <>
          <div className="template-btns">
            <button
              className="btn btn-primary"
              onClick={() => {
                setSelectedSection(null);
                setNewItem({ label: "", value: null });
                setNewItemPopup(false);
              }}
            >
              + Add Item
            </button>
            {template.sections == 1 ? (
              <button
                className="btn btn-primary"
                onClick={() => addNewSection()}
              >
                + Add New Section
              </button>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div className="template-btns">
            {template.sections == 1 ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  setNewSection({ name: "", templateId: template.id });
                  setNewSectionPopup(false);
                }}
              >
                + Add Section
              </button>
            ) : null}
          </div>
        </>
      )}

      {template.sections == 1 ? (
        <>
          {sections.length > 0 ? (
            <>
              {sections.map((section, index) => (
                <>
                  <div
                    className="main_container section"
                    id={section.id}
                    key={index}
                  >
                    <>
                      <div className="header-firecheck-view">
                        {section.name || "Section #" + section.id}
                      </div>
                      {templateItems.length > 0 ? (
                        <>
                          <div className="ga2-questions">
                            <ReactSortable
                              list={
                                Array.isArray(templateItems[section.id])
                                  ? templateItems[section.id]
                                  : []
                              }
                              swap
                              setList={setSortedList}
                              onEnd={onSortEnd}
                            >
                              {Array.isArray(templateItems[section.id]) &&
                                templateItems[section.id].map(
                                  (item, itemIndex) => (
                                    <div key={itemIndex} className>
                                      <div className="item ga2-questions-item d-flex flex-spaced">
                                        <div className="ga2-questions-item-title">
                                          {item.si_description}
                                        </div>
                                        <div className="ga2-questions-item-actions">
                                          {!item.isDeleted && (
                                            <img
                                              src={Delete}
                                              alt=""
                                              onClick={() => {
                                                handleDelete(
                                                  item.sti_id,
                                                  item.si_description
                                                );
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                            </ReactSortable>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>Safety Checklist template not found</div>
                        </>
                      )}
                    </>

                    <div className="template-btns">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setSelectedSection(section.id);
                          setNewItem({ description: "", id: null });
                          setNewItemPopup(false);
                        }}
                      >
                        + Add Item
                      </button>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : null}
        </>
      ) : (
        <>
          <div className="main_container">
            {templateItems.length > 0 ? (
              <>
                <div className="ga2-questions">
                  <ReactSortable
                    swap
                    list={templateItems}
                    setList={setSortedList}
                    onEnd={onSortEnd}
                  >
                    {templateItems.map((item, index) => (
                      <div key={index}>
                        <div className="item ga2-questions-item d-flex flex-spaced">
                          <div className="ga2-questions-item-title">
                            {item.si_description}
                          </div>
                          <div className="ga2-questions-item-actions">
                            {!item.isDeleted && (
                              <img
                                src={Delete}
                                alt=""
                                onClick={() => {
                                  handleDelete(
                                    item.sti_id,
                                    item.si_description
                                  );
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </ReactSortable>
                </div>
              </>
            ) : (
              <>
                <div>Safety Checklist template items not found</div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default SafetyCheckListTemplate;
