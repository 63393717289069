import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import { commonService } from "../common/common.service";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import fileIcon from "../../images/openfolderwhite.svg";
import { getBase64 } from "../common/imageHelper";
import {
  PDFDownloadLink,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import PDFRenderer from "../common/pdfRenderer/pdfRenderer";
import PDFFiles from "../common/pdfRenderer/files";

import Swal from "sweetalert2";


export default function ViewSafetyHarnessIssue({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, userPermissions } = useAppState()
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [siteUsers, setSiteUsers] = useState([])
  const [activities, setActivities] = useState([])
  const [popup, setPopup] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    approvedComment: "",
    deniedComment: "",
    signature: null,
    actionTaken: false,
    actionStatus: false,
    actionFiles: false,
  });

  const [files, setFiles] = useState([]);

  const statusOptions = [
    { value: 'Open', label: 'Open', name: 'actionStatus', },
    { value: 'In Progress', label: 'In Progress', name: 'actionStatus', },
    { value: 'Closed', label: 'Closed', name: 'actionStatus', },
  ];

  const fetchSafetyHarnessIssueDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/safety-harness-issues/details`,
        {
          safetyHarnessIssueId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data[0]);
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
        getUserEmail(res.data[0].addedByInCompany)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
        console.log("errr", err);
      });
  };


  const fetchActivities = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/safety-observations-activities/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setActivities(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchSafetyHarnessIssueDetails();
      fetchActivities();
    }
  }, [id]);

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file)
        });
      }
    })

  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      // let signature
      // if(!fieldData.denied){
      //   signature = await handleSaveVerifySign()
      // }
      handleSubmitAfterSave()
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleFileChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "newfiles") {
      Promise.all(Array.from(e.target.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        actionFiles: files,
      });
    }
  };

  const handleSubmitAfterSave = () => {
    const form = new FormData();
    form.append("deniedComment", fieldData.deniedComment);
    form.append("approvedComment", fieldData.approvedComment);
    if (fieldData.approved) {
      form.append("approved", fieldData.approved);
    }
    if (fieldData.completed) {
      form.append("completed", fieldData.completed);
    }

    if (fieldData.actionTaken) {
      form.append("actionTaken", fieldData.actionTaken);
      form.append("actionStatus", fieldData.actionStatus);
      form.append("actionComment", fieldData.actionComment);
      form.append("actionRootCause", fieldData.actionRootCause);

      Object.entries(fieldData?.actionFiles)?.map( ([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      } );
    }

    // form.append("signature", signature);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);
    form.append("id", id);
    setLoading(true);
    axiosInstance
      .post((fieldData.resubmitted || fieldData.completed) ? `${process.env.REACT_APP_API_BASEURL}/resubmit/safety-observation` : ( fieldData.actionTaken ? `${process.env.REACT_APP_API_BASEURL}/safety-observation/action-taken` : `${process.env.REACT_APP_API_BASEURL}/update/safety-observation` ), form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setFieldData({
          deniedComment: "",
          approvedComment: "",
          approved: false,
          resubmitted: false,
          completed: false,
          denied: false,
          signature: null,
          actionComment: "",
          actionTaken: false,
          actionStatus: "",
          actionComment: "",
          actionRootCause: "",
        });
        
        setPopup(true);

        fetchSafetyHarnessIssueDetails();
        fetchActivities();
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const pdfStyles = StyleSheet.create({
    body: {
      padding: "20px 16px 30px",
      fontSize: "13px",
      fontFamily: "Helvetica",
      color: "#000",
    },
    logos: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },
    halfWidthRightAlign: {
      width: "50%",
      textAlign: "right",
    },
    halfWidthLeftAlign: {
      width: "50%",
      textAlign: "left",
    },
    detailsWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      fontSize: "10px",
      border: "1px solid #ddd",
      borderBottom: "none",
    },
    detailsCell3n1: {
      width: "33.3333333%",
      flexBasis: "33.3333333%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    detailsCell5in1: {
      width: "20%",
      flexBasis: "20%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    detailsCell: {
      width: "50%",
      flexBasis: "50%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    detailsCellFullWidth: {
      width: "100%",
      flexBasis: "100%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    }
  });

  const MyDoc = () => {
    const filesBlocks = [
      {
        name: "Uploaded Files",
        files: data.files,
      }
    ];

    if ( data.receiverSignature ) {
      filesBlocks.push({
        name: 'Receiver Signature',
        type: 'signatures',
        files: data.receiverSignature,
      });
    }

    if ( data.returnerSignature ) {
      filesBlocks.push({
        name: 'Returner Signature',
        type: 'signatures',
        files: data.returnerSignature,
      });
    }

    return (
      <PDFRenderer name={"Safety Harness Issue #" + id} user={userDetails}>
        <View>
          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              Details
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Site : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.siteName ? data.siteName : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Department : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.departmentName ? data.departmentName : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Harness Serial Number :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.harnessSerialNumber ? data.harnessSerialNumber : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Accessories Serial Number :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.accessoriesSerialNumber
                      ? data.accessoriesSerialNumber
                      : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Received By :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.receivedBy ? data.receivedBy : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Employer :{" "}</Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.contractorName ? data.contractorName : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Date Received :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.dateReceived
                      ? commonService.formatDate(
                          data.dateReceived,
                          "Do MMM YYYY"
                        ) == "Invalid date"
                        ? data.dateReceived
                        : commonService.formatDate(
                            data.dateReceived,
                            "Do MMM YYYY"
                          )
                      : "-"}{" "}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Time Received :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.timeReceived ? data.timeReceived : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Condition on Issue :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.conditionOnIssue ? data.conditionOnIssue : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Date Submitted :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.createdAt
                      ? commonService.formatDate(
                          data.createdAt,
                          "Do MMM YYYY"
                        ) == "Invalid date"
                        ? data.createdAt
                        : commonService.formatDate(
                            data.createdAt,
                            "Do MMM YYYY"
                          )
                      : "-"}{" "}
                  </Text>
                </Text>
              </View>

              {data.dateReturned && (
                <>
                  <View style={pdfStyles.detailsCell}>
                    <Text>
                      <Text style={{ fontFamily: "Helvetica" }}>
                        Returned By :{" "}
                      </Text>
                      <Text style={{ fontFamily: "Helvetica-Bold" }}>
                        {data.returnedBy ? data.returnedBy : "-"}
                      </Text>
                    </Text>
                  </View>

                  <View style={pdfStyles.detailsCell}>
                    <Text>
                      <Text style={{ fontFamily: "Helvetica" }}>
                        Date Returned :{" "}
                      </Text>
                      <Text style={{ fontFamily: "Helvetica-Bold" }}>
                        {data.dateReturned
                          ? commonService.formatDate(
                              data.dateReturned,
                              "Do MMM YYYY"
                            ) == "Invalid date"
                            ? data.dateReturned
                            : commonService.formatDate(
                                data.dateReturned,
                                "Do MMM YYYY"
                              )
                          : "-"}{" "}
                      </Text>
                    </Text>
                  </View>

                  <View style={pdfStyles.detailsCell}>
                    <Text>
                      <Text style={{ fontFamily: "Helvetica" }}>
                        Time Returned :{" "}
                      </Text>
                      <Text style={{ fontFamily: "Helvetica-Bold" }}>
                        {data.timeReturned ? data.timeReturned : "-"}
                      </Text>
                    </Text>
                  </View>

                  <View style={pdfStyles.detailsCell}>
                    <Text>
                      <Text style={{ fontFamily: "Helvetica" }}>
                        Condition on Return :{" "}
                      </Text>
                      <Text style={{ fontFamily: "Helvetica-Bold" }}>
                        {data.conditionOnReturn ? data.conditionOnReturn : "-"}
                      </Text>
                    </Text>
                  </View>
                </>
              )}

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Submitted By :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.submittedBy ? data.submittedBy : "-"}
                  </Text>
                </Text>
              </View>
            </View>
          </View>
        </View>

        {filesBlocks.map((block) => (
          <PDFFiles block={block} />
        ))}
      </PDFRenderer>
    );
  };

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/safety-harness-issue/" + deleteId)
        .then(function (response) {
          toast.success("Safety Harness Issue archived successfully");
          window.location = '/safety-harness-issues';
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Safety Harness Issue"
          );
        });
    }
  };

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Safety Harness Issues List", path: "/safety-harness-issues/" },
          { name: "Safety Harness Issue", path: `/safety-harness-issue/${id}` },
        ]}
      />
   
      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">

              {! data.dateReturned && <>
                <div className="top-notification-bar">
                  This safety harness has not yet been returned. <a href={'/safety-harness-issue/' + id + '/return'} onClick={(e) => { e.preventDefault(); history.push(`/safety-harness-issue/${id}/return`) }}>Click here to return it</a>.
                </div>
              </>}

              <div className="row">
                <div className="col-6 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Site : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.siteName ? data.siteName : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Department : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.departmentName ? data.departmentName : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Harness Serial Number : </div>
                            <div className="col-6 dd-access">
                              {data.harnessSerialNumber ? data.harnessSerialNumber : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Accessories Serial Number : </div>
                            <div className="col-6 dd-access">
                              {data.accessoriesSerialNumber ? data.accessoriesSerialNumber : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Received By : </div>
                            <div className="col-6 dd-access">
                              {data.receivedBy ? data.receivedBy : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Employer : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.contractorName ? data.contractorName : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Date Received :{" "}
                            </div>
                            <div className="col-6 dd-access">
                            {data.dateReceived
                                ? ( commonService.formatDate(
                                  data.dateReceived,
                                  "Do MMM YYYY"
                                ) == 'Invalid date' ? data.dateReceived : commonService.formatDate(
                                  data.dateReceived,
                                  "Do MMM YYYY"
                                ))
                                : "-"}{" "}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Time Received : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.timeReceived ? data.timeReceived : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Condition on Issue : </div>
                            <div className="col-6 dd-access">
                              {data.conditionOnIssue ? data.conditionOnIssue : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Date Submitted :{" "}
                            </div>
                            <div className="col-6 dd-access">
                            {data.createdAt
                                ? ( commonService.formatDate(
                                  data.createdAt,
                                  "Do MMM YYYY"
                                ) == 'Invalid date' ? data.createdAt : commonService.formatDate(
                                  data.createdAt,
                                  "Do MMM YYYY"
                                ))
                                : "-"}{" "}
                            </div>
                          </div>

                          {data.dateReturned && <>
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Returned By : </div>
                              <div className="col-6 dd-access">
                                {data.returnedBy ? data.returnedBy : "-"}
                              </div>
                            </div>

                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">
                                Date Returned :{" "}
                              </div>
                              <div className="col-6 dd-access">
                              {data.dateReturned
                                  ? ( commonService.formatDate(
                                    data.dateReturned,
                                    "Do MMM YYYY"
                                  ) == 'Invalid date' ? data.dateReturned : commonService.formatDate(
                                    data.dateReturned,
                                    "Do MMM YYYY"
                                  ))
                                  : "-"}{" "}
                              </div>
                            </div>
                            
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Time Returned : </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.timeReturned ? data.timeReturned : "-"}
                              </div>
                            </div>
                            
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Condition on Return : </div>
                              <div className="col-6 dd-access">
                                {data.conditionOnReturn ? data.conditionOnReturn : "-"}
                              </div>
                            </div>
                          </>}
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Submitted By : </div>
                            <div className="col-6 dd-access">
                              {data.submittedBy ? data.submittedBy : "-"}
                            </div>
                          </div>
                          
                          <div className="p-2"></div> 

                          { data.isDeleted != '1' && <>
                            <button className="btn btn-outline-success ms-1" onClick={() => {
                              history.push(`/edit-safety-harness-issue/${data.id}`)
                            }}>Edit</button>
                          </> }

                          {id && userPermissions.includes('submitsafetyHarnessIssues') && data.isDeleted != 1 ? <>
                            <button className="btn btn-outline-danger ms-1" onClick={() => handleArchive(id)}>
                              Archive
                            </button>
                          </> : ''}
                            
                          <div className="p-2"></div> 
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="col-6 mb-2">
                    <div className="d-flex flex-column">
                      <div className="mb-3">
                        <div className="header-access-view">Signatures</div>
                        <div className="p-2">
                          {data.receiverSignature && <>
                            <h5>Received By:</h5>
                            <img
                              onError={(e) => {
                                e.target.src = DefaultImage;
                              }}
                              className="obj-fit-cover"
                              height="250"
                              width="250"
                              src={
                                data.receiverSignature
                                  ? process.env.REACT_APP_API_BASEURL +
                                  data.receiverSignature
                                  : DefaultImage
                              }
                            ></img>
                          </>}
                          
                          {data.returnerSignature && <>
                            <h5 style={{ marginTop: "40px" }}>Returned By:</h5>
                            <img
                              onError={(e) => {
                                e.target.src = DefaultImage;
                              }}
                              className="obj-fit-cover"
                              height="250"
                              width="250"
                              src={
                                data.returnerSignature
                                  ? process.env.REACT_APP_API_BASEURL +
                                  data.returnerSignature
                                  : DefaultImage
                              }
                            ></img>
                          </>}

                        </div>
                      </div>
                    </div>
                </div>

              </div>

              <div className="row">
                <div className="col-12">
                  <div className="header-access-view"> Uploaded Files</div>
                  <div className="d-flex">
                    {data.files && data.files.split(",").map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                      {commonService.outputImageTile(imgPreviewUrl)}
                    </div>))}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <PDFDownloadLink
                    document={<MyDoc />}
                    className="mt-2 btn btn-primary pdf-download-btn"
                    fileName={"safety-harness-issue-" + id + ".pdf"}
                  >
                    {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download PDF"
                    }
                  </PDFDownloadLink>
                </div>
              </div>
              

            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
