import React from "react";
import Header from "../../Header/Header";
import { useAppState } from "../../../state";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../helpers/request";
import { useEffect, useState } from "react";
import Edit2 from "../../../images/edit-2.svg";
import Delete from "../../../images/circle-cross.svg";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { commonService } from "../../common/common.service";

import Table from "../../common/Table";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import { Document, Page, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import CustomPopup from "../../Popup/CustomPopup";

export default function Companies({ title, isAll, isDeleted }) {
  const { user, selectedSite, userPermissions, selectedCompany } = useAppState();
  const cookies = new Cookies();
  const history = useHistory();
  const userToken = localStorage.getItem("cs_token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyId, setUserID] = useState("");
  const [search, setSearch] = useState("");
  const [pdfLoader, setPdfLoader] = useState(true);

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  useEffect(() => {
    if (companyId) {
      getCompanies();
    }
  }, [companyId, selectedSite]);

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          if ( res.data[0].role == 'superadmin' ) {
            setUserID( selectedCompany );
          } else {
            setUserID(
              res.data[0].added_by ? res.data[0].added_by : res.data[0].id
            );
          }
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getCompanies = () => {
    setLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASEURL}/companies`,
        {
          isAll: isAll,
          isDeleted: isDeleted,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        // if (res.data.length > 0) {
        setData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
        setLoading(false);
        // }
      })
      .catch((err) => {
        setLoading(false);
        console.log("errr", err);

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
      });
  };

  const handleDeleteReconfirm = async (id, name) => {
    const result = await Swal.fire({
      title: `Do you really want to delete this company?`,
      text: "This action is irreversible!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/companies/" + id)
        .then(function (response) {
          getCompanies();
          toast.success("Company deleted successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to delete Company"
          );
        });
    }
  };

  const handleDelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete this company`,
      text: "This will also delete all records for the company across all sections.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      handleDeleteReconfirm( id, name );
    }
  };

  const handleDeactivate = async (id, status) => {
    const result = await Swal.fire({
      title: `Are you sure you want to deactivate this company`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deactivate it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .post(process.env.REACT_APP_API_BASEURL + "/deactivate-company/", {
            id: id
        }, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(function (response) {
          getCompanies();
          toast.success("Company deactivated successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to deactivate Company"
          );
        });
    }
  };

  const handleActivate = async (id, status) => {
    const result = await Swal.fire({
      title: `Are you sure you want to activate this company`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, activate it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .post(process.env.REACT_APP_API_BASEURL + "/activate-company/", {
            id: id
        }, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(function (response) {
          getCompanies();
          toast.success("Company activated successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to activate Company"
          );
        });
    }
  };

  const tableHeader = [
    "ID",
    "Company Name",
    "Phone",
    "Primary Contact",
    "Status"
  ];

  function getActionsConvertableArray() {
    let array = [];

    // add all items from data but skip actions
    data.forEach((d) => {
      array.push({
        id: d.id,
        companyName: d.companyName ? d.companyName : "",
        phone: d.companytelephonenumber ? d.companytelephonenumber : "",
        primaryContact: d.fullName ? d.fullName : "",
        status: d.is_active == 1 ? 'Active' : 'Inactive',
      });
    });

    return array;
  }

  const columns = [
    {
      name: "ID",
      selector: "view",
      width: "100px",
      cell: (row) => <div>{row.view}</div>,
      sortFunction: (a, b) => {
        return a.id - b.id;
      },
    },
    {
      name: "Company Name",
      selector: "companyName",
      searchField: "companyName",
      cell: (row) => <div>{row.companyName}</div>,
    },
    {
      name: "Phone",
      selector: "phone",
      searchField: "phone",
      cell: (row) => <div>{row.phone}</div>,
    },
    {
      name: "Primary Contact",
      selector: "primaryContact",
      searchField: "primaryContact",
      cell: (row) => <div>{row.primaryContact}</div>,
    },
    {
      name: "Status",
      selector: "status",
      searchField: "status",
      cell: (row) => <div>{row.status}</div>,
      sortFunction: (a, b) => {
        return a.status - b.status;
      },
    },
  ];

  // add minWidth to each item in columns
  columns.forEach((column) => {
    if (
      typeof column.minWidth == "undefined" &&
      typeof column.width == "undefined"
    ) {
      column.minWidth = "200px";
    }

    // Add sorting to all columns
    if (typeof column.sortable === "undefined") {
      column.sortable = true;
    }

    if (
      typeof column.sortFunction === "undefined" &&
      typeof column.searchField !== "undefined"
    ) {
      column.sortFunction = (a, b) =>
        a[column.searchField].localeCompare(b[column.searchField]);
    }
  });

  if (user.role.includes("superadmin")) {
    columns.push({
      name: "Action",
      sortable: false,
      selector: "actions",
      cell: (row) => <div>{row.actions}</div>,
      allowOverflow: true,
    });
  }

  const downloadExcel = () => {
    commonService.downloadArrayOfObjectsAsXLSX(
      getActionsConvertableArray(),
      tableHeader,
      title.toLowerCase().replace(" ", "-") + ".xlsx"
    );
  };

  const printList = () => {
    // Trigger the actual print
    commonService.triggerPrint(
      getActionsConvertableArray(),
      tableHeader,
      title
    );
  };

  const PDFReadyTable = () => {
    // Implement download PDF
    const pdfTableData = {
      column: tableHeader,
      data: getActionsConvertableArray(),
    };

    return (
      <Document>
        <Page
          orientation="landscape"
          size="LETTER"
          style={{ padding: "20px 16px 30px" }}
        >
          <Table data={pdfTableData} heading={title} />
        </Page>
      </Document>
    );
  };

  const downloadPDF = async () => {
    // If we have larger data set, show laoder.
    if (data.length > 100) {
      setPdfLoader(false);
    }
    const blob = await pdf(<PDFReadyTable />).toBlob();
    saveAs(blob, title.toLowerCase().replace(/ /g, "-").split("(")[0] + ".pdf");
    setPdfLoader(true);
  };

  const CustomSortIcon = () => <span className="dt-sort-icon"></span>;

  const createData = () => {
    let parsedData = [];

    if (data.length == 0) {
      return parsedData;
    }

    data.forEach((d) => {
      let row = {
        id: d.id,
        view: (
          <span onClick={() => { history.push(`/companies/edit/${d.id}`); }} className="lists-id-col">
            {d.id}
          </span>
        ),

        companyName: d.companyName ? d.companyName : "",
        phone: d.companytelephonenumber? d.companytelephonenumber : '',
        primaryContact: d.fullName ? d.fullName : "",

        status: d.is_active == 0 ? <span className="roundPill yellow-bg">Inactive</span> : <span className="roundPill green-bg">Active</span>,

      };

      if (user.role.includes("superadmin")) {
        row.actions = (
          <div className="d-flex align-items-center company-actions-col flex-row" style={{minWidth: '200px'}}>
            {!isDeleted && user.role.includes("superadmin") && (
              <img
                src={Delete}
                alt=""
                onClick={() => {
                  handleDelete(d.id, " this Company");
                }}
              />
            )}

            {d.is_active ? <>
              <button className="btn btn-sm btn-warning" onClick={() => handleDeactivate(d.id, d.is_active)}>Deactivate</button>
            </> : <>
              <button className="btn btn-sm btn-success" onClick={() => handleActivate(d.id, d.is_active)}>Activate</button>
            </>}
          </div>
        );
      }

      parsedData.push(row);
    });

    if (parsedData.length > 0 && search && search.length > 0) {
      // Search for matching string in all columns for each row
      parsedData = parsedData.filter((d) => {
        // Combine all column values in a row into a single string
        const rowValues = Object.entries(d)
          .filter(([key]) => key !== "action") // Exclude 'action' property
          .map(([key, value]) => value)
          .join(" ")
          .toLowerCase();

        // Check if the search string is present in any of the columns
        return rowValues.includes(search.toLowerCase());
      });
    }

    return parsedData;
  };

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          { name: "Clients List", path: "/companies" },
        ]}
      />

      <CustomPopup showClose={false} trigger={!pdfLoader} setTrigger={() => {}}>
        <h3>Please Wait...</h3>
        <Spinner visible="true" />
      </CustomPopup>

      <div className="main_container">
        <div>
          <div className="form_inner table_search">
            <div className="input_icons">
              <img src={require("../../../images/search.png")} alt="" />
              <div className="input_field">
                <input
                  type="text"
                  placeholder="Search Here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div>
              <button
                onClick={() => {
                  printList();
                }}
              >
                Print
              </button>
              <button
                onClick={() => {
                  downloadExcel();
                }}
              >
                Excel
              </button>
              <button
                onClick={() => {
                  downloadPDF();
                }}
              >
                PDF
              </button>

              <button
                  className="GA2-btn"
                  onClick={() => {
                    history.push("/companies/add");
                  }}
              >
                Add Client +
              </button>

            </div>
          </div>
          <hr />
          <div id="wrapper">
            {!loading ? (
              <>
                <DataTable
                  columns={columns}
                  data={createData()}
                  pagination={true}
                  sortIcon={<CustomSortIcon />}
                />
              </>
            ) : (
              <>
                <Spinner size="sm" animation="border" variant="light" />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
