import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet
} from "@react-pdf/renderer";


export default function PDFRenderer(props) {
  const user = props.user;

  const pdfStyles = StyleSheet.create({
    body: {
      padding: "20px 16px 30px",
      fontSize: "13px",
      fontFamily: "Helvetica",
      color: "#000",
    },
    logos: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },
    halfWidthRightAlign: {
      width: "50%",
      textAlign: "right",
    },
    halfWidthLeftAlign: {
      width: "50%",
      textAlign: "left",
    },
    detailsWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      fontSize: "10px",
      border: "1px solid #ddd",
      borderBottom: "none",
    },
    detailsCell: {
      width: "14.285%",
      flexBasis: "14.285%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    }
  });
 

  return (
    <Document>
      <Page style={pdfStyles.body}>
        <View style={pdfStyles.logos}>
          <View style={pdfStyles.halfWidthLeftAlign}>
            <Image
              src={{
                uri:
                  process.env.REACT_APP_API_BASEURL +
                  "/uploads/foresight-logo.png",
              }}
              style={{ width: "100px" }}
            />
          </View>

          <View style={pdfStyles.halfWidthRightAlign}>
            <Image
              src={{
                uri:
                  process.env.REACT_APP_API_BASEURL +
                  "/pdf-image?skipHeightResize=1&path=" +
                  (user.companyLogo || user.contractorLogo
                    ? user.contractorLogo
                      ? user.contractorLogo
                      : user.companyLogo
                    : "/uploads/foresight-logo.png"),
              }}
              style={{ marginLeft: "auto", marginRight: "0", width: "100px" }}
            />
          </View>
        </View>

        <View>
          <Text
            style={{
              fontSize: "20px",
              fontFamily: "Helvetica-Bold",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            {props.name}
          </Text>
        </View>

        {props.children}
      </Page>
    </Document>
  );
}
