import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import { commonService } from "../common/common.service";
import "./GroupInspection.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";

const AddGroupInspection = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany } = useAppState()
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    siteId: null,
    departmentId: null,
    
    accidentsSinceLastAudit: null,
    lastAuditDate: null,
    
    companyId: companyId,
    addedBy: companyId,
    files: "",
  };

  const actionValues = {};
  actionValues[1] = {
    assignTo: "",
    action: "",
    date: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
    status: "",
    files: ""
  }

  const [actionRequired, setActionRequired] = useState(actionValues);
  const [fieldData, setFieldData] = useState(fieldValues);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({ ...fieldValues, contractorId: null, startDate: null });
  const [contractorData, setContractorData] = useState([]);
  const [siteUsers, setSiteUsers] = useState([])
  const [showActionPopup, setShowActionPopup] = useState("");

  const firstAiderOptions = [
    { value: '1', label: 'Yes', name: 'firstAider' },
    { value: '0', label: 'No', name: 'firstAider' },
  ];
 
  const firstAidFacilitiesOptions = [
    { value: '1', label: 'Yes', name: 'firstAidFacilities' },
    { value: '0', label: 'No', name: 'firstAidFacilities' },
  ];
  
  const firePointsOptions = [
    { value: '1', label: 'Yes', name: 'firePoints' },
    { value: '0', label: 'No', name: 'firePoints' },
  ];
  
  const weatherOptions = [
    { value: 'Dry', label: 'Dry', name: 'weatherCondition' },
    { value: 'Cold', label: 'Cold', name: 'weatherCondition' },
    { value: 'Rainy', label: 'Rainy', name: 'weatherCondition' },
    { value: 'Sunny', label: 'Sunny', name: 'weatherCondition' },
    { value: 'Hot', label: 'Hot', name: 'weatherCondition' },
    { value: 'Stormy', label: 'Stormy', name: 'weatherCondition' },
    { value: 'Cloudy', label: 'Cloudy', name: 'weatherCondition' },
    { value: 'Wet', label: 'Wet', name: 'weatherCondition' },
  ];

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID( userCompanyId );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchInspections = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/groupinspections/groupinspection/details`,
        {
          inspectionId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0], existingFiles: res.data[0].files, visitDate: commonService.formatDate(res.data[0].visitDate, "YYYY-MM-DD") });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        console.log("errr", err);
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchInspections()
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {

    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getLastAuditDate = ( departmentId ) => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/groupinspections/get-audit-date`,
        {
          companyId: companyId,
          departmentId: departmentId,
          siteId: fieldData.siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        // res.data.
        if ( res.data.lastAuditDate ) {
          setFieldData({
            ...fieldData,
            departmentId: departmentId,
            lastAuditDate: res.data.lastAuditDate,
            accidentsSinceLastAudit: res.data.totalAccidents
          })
        }
      })
      .catch((err) => {
      });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {
      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }

      Promise.all(Array.from(modifiedFiles.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value)
      }

      if ( !id && name == 'departmentId' ) {
        getLastAuditDate( value );
      }

    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;
      case "accidentsSinceLastAudit":
        if (!value) {
          message = stateObj[name] = "Number of accidents on site since last audit is required.";
        }
        break;
      case "firstAider":
        if (!value) {
          message = stateObj[name] = "First aider on site required.";
        }
        break;
      case "firstAidFacilities":
        if (!value) {
          message = stateObj[name] = "First aide facilities available on site required.";
        }
        break;
      case "weatherCondition":
        if (!value) {
          message = stateObj[name] = "Weather Condition on site required.";
        }
        break;
      case "lastAuditDate":
        if (!value) {
          message = stateObj[name] = "Date of last audit required.";
        }
        break;
      case "firePoints":
        if (!value) {
          message = stateObj[name] = "Are fire points located on site? is required.";
        }
        break;
      case "visitDate":
        if (!value) {
          message = stateObj[name] = "Date is required.";
        }
        break;

      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      handleSubmitAfterSave()
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = () => {
    let errorShow = {
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      
      firstAider: fieldData.firstAider,
      firstAidFacilities: fieldData.firstAidFacilities,
      weatherCondition: fieldData.weatherCondition,
      
      firePoints: fieldData.firePoints,
      visitDate: fieldData.visitDate,
    };

    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.firstAider !== null &&
      fieldData.firstAidFacilities !== null &&
      fieldData.weatherCondition !== null &&
      
      fieldData.firePoints !== null &&
      fieldData.visitDate !== null
    ) {
      
      const form = new FormData();

      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("groupSafetyVisits") : v.permits.includes("groupSafetyVisitsreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      
      form.append("companyId", companyId);

      form.append("departmentId", fieldData.departmentId == null ? null : fieldData.departmentId);
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      
      form.append("firstAider", fieldData.firstAider);
      form.append("firstAidFacilities", fieldData.firstAidFacilities);
      form.append("weatherCondition", fieldData.weatherCondition);
      form.append("firePoints", fieldData.firePoints);
      form.append("visitDate", commonService.formatDate(
        fieldData.visitDate,
        "YYYY-MM-DD"
      ));
      
      if ( fieldData.lastAuditDate ) {
        form.append("lastAuditDate", commonService.formatDate(
          fieldData.lastAuditDate,
          "YYYY-MM-DD"
        ));
      }
      
      form.append("addedByInCompany", user.id);

      setLoading(true);
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update/groupinspection/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/groupinspection`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);

        if ( res.data.id ) {
          history.push('/groupinspection/' + res.data.id);
        } else {
          history.push('/view-own/groupinspections');
        }

        setFieldData(fieldData);
      })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }))

  const departmentOptions = allDepartment.filter(v => v.siteId == fieldData.siteId).map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentId",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">
        <div className="addaccess_first_container">
          <div className="general_details">General Details</div>

          <div className="row">
            <div className="col-md-4">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Site <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Sites"
                      options={siteOptions}
                      {...(id ? {
                        value: siteOptions.filter(v => v.value == fieldData.siteId),
                        isDisabled: true
                      } : {})}
                    />
                  </div>
                </div>
                {error.siteId && <span className="err">{error.siteId}</span>}
              </div>
            </div>
            
            <div className="col-md-4">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Department <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Department"
                      options={departmentOptions}
                      {...(id ? {
                        value: departmentOptions.filter(v => v.value == fieldData.departmentId),
                        isDisabled: true
                      } : {})}
                    />
                  </div>
                </div>
                {error.departmentId && (
                  <span className="err">{error.departmentId}</span>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2 location_access">
                <label for="">
                  <div className="require-label">
                    Date <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input value={fieldData.visitDate} type="date" onChange={handleChange} name="visitDate" />
                  </div>
                </div>
                {error.visitDate && <span className="err">{error.visitDate}</span>}
              </div>
            </div>
            
            <div className="col-md-4">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    First aider on site? <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="-- Select an option --"
                      options={firstAiderOptions}
                      {...(id ? {
                        value: firstAiderOptions.filter(v => v.value == fieldData.firstAider),
                      } : {})}
                    />
                  </div>
                </div>
                {error.firstAider && <span className="err">{error.firstAider}</span>}
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    First aid facilities available? <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="-- Select an option --"
                      options={firstAidFacilitiesOptions}
                      {...(id ? {
                        value: firstAidFacilitiesOptions.filter(v => v.value == fieldData.firstAidFacilities),
                      } : {})}
                    />
                  </div>
                </div>
                {error.firstAidFacilities && <span className="err">{error.firstAidFacilities}</span>}
              </div>
            </div>
            
            <div className="col-md-4">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Weather Condition <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="-- Select an option --"
                      options={weatherOptions}
                      {...(id ? {
                        value: weatherOptions.filter(v => v.value == fieldData.weatherCondition),
                      } : {})}
                    />
                  </div>
                </div>
                {error.weatherCondition && <span className="err">{error.weatherCondition}</span>}
              </div>
            </div>

            {! id && <>
              <div className="col-4">
                <div className="w-100 p-2 location_ladder">
                  <label for="lastAuditDate">
                    <div className="require-label">
                      {" "}
                      Date of last audit <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        id="lastAuditDate"
                        type="text"
                        value={fieldData.lastAuditDate? (commonService.formatDate(
                          fieldData.lastAuditDate,
                          "Do MMM YYYY"
                        )) :''}
                        name="lastAuditDate"
                        readonly="true"
                        placeholder={fieldData.departmentId ? "" : "Select department first" }
                      />
                    </div>
                  </div>
                  {error.lastAuditDate && (
                    <span className="err">{error.lastAuditDate}</span>
                  )}
                </div>
              </div>
            </>}

            <div className="col-md-4">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Are fire points located on site? <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="-- Select an option --"
                      options={firePointsOptions}
                      {...(id ? {
                        value: firePointsOptions.filter(v => v.value == fieldData.firePoints),
                      } : {})}
                    />
                  </div>
                </div>
                {error.firePoints && <span className="err">{error.firePoints}</span>}
              </div>
            </div>
            
          </div>
        </div>

        
        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Resubmit" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn">cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddGroupInspection;
