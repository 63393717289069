import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import "./AccidentNotification.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import { commonService } from "../common/common.service";

const AddAccidentNotification = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany } = useAppState()
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    siteId: null,
    departmentId: null,
    
    accidentDate: '',
    accidentTime: null,
    trigger: null,
    bodyPart: null,
    accidentDetails: null,
    
    fullName: null,
    employer: null,
    trade: null,
    
    
    actionRequired: null,
    isActionRequired: null,
    
    accidentType: null,
    ambulanceCalled: null,
   
    tetanus: null,
    hospitalAdvise: null,
    medicallyAdvised: null,
    
    verifySignature: null,
    verifySignatureTwo: null,
    companyId: companyId,
    addedBy: companyId,
    files: "",
  };
  const [fieldData, setFieldData] = useState(fieldValues);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({ ...fieldValues, contractorId: null, startDate: null });
  const [contractorData, setContractorData] = useState([]);
  const [siteUsers, setSiteUsers] = useState([])

  const triggerOptions = [
    { value: 'Collapse, Breakage', label: 'Collapse, Breakage', name: 'trigger', },
    { value: 'Electrical Shock', label: 'Electrical Shock', name: 'trigger' },
    { value: 'Fall from Height', label: 'Fall from Height', name: 'trigger' },
    { value: 'Falling Materials', label: 'Falling Materials', name: 'trigger' },
    { value: 'Impact', label: 'Impact', name: 'trigger' },
    { value: 'Lifting, Carrying', label: 'Lifting, Carrying', name: 'trigger' },
    { value: 'Slip,Trip', label: 'Slip,Trip', name: 'trigger' },
    { value: 'Stood', label: 'Stood', name: 'trigger' },
    { value: 'Other', label: 'Other', name: 'trigger' },
  ];

  const bodyParts = [
    { value: 'Ankle', label: 'Ankle', name: 'bodyPart' },
    { value: 'Arm', label: 'Arm', name: 'bodyPart' },
    { value: 'Back', label: 'Back', name: 'bodyPart' },
    { value: 'Chest', label: 'Chest', name: 'bodyPart' },
    { value: 'Ear', label: 'Ear', name: 'bodyPart' },
    { value: 'Eye', label: 'Eye', name: 'bodyPart' },
    { value: 'Face', label: 'Face', name: 'bodyPart' },
    { value: 'Finger', label: 'Finger', name: 'bodyPart' },
    { value: 'Foot', label: 'Foot', name: 'bodyPart' },
    { value: 'Hand', label: 'Hand', name: 'bodyPart' },
    { value: 'Head', label: 'Head', name: 'bodyPart' },
    { value: 'Hip', label: 'Hip', name: 'bodyPart' },
    { value: 'Knee', label: 'Knee', name: 'bodyPart' },
    { value: 'Leg', label: 'Leg', name: 'bodyPart' },
    { value: 'Nose', label: 'Nose', name: 'bodyPart' },
    { value: 'Neck', label: 'Neck', name: 'bodyPart' },
    { value: 'Ribs', label: 'Ribs', name: 'bodyPart' },
    { value: 'Shoulder', label: 'Shoulder', name: 'bodyPart' },
    { value: 'Teeth', label: 'Teeth', name: 'bodyPart' },
    { value: 'Toes', label: 'Toes', name: 'bodyPart' },
    { value: 'Wrist', label: 'Wrist', name: 'bodyPart' },
  ];

  const accidentTypes = [
    { value: 'Amputation', label: 'Amputation', name: 'accidentType', },
    { value: 'Bruising', label: 'Bruising', name: 'accidentType', },
    { value: 'Burn', label: 'Burn', name: 'accidentType', },
    { value: 'Crush', label: 'Crush', name: 'accidentType', },
    { value: 'Cut', label: 'Cut', name: 'accidentType', },
    { value: 'Dislocation', label: 'Dislocation', name: 'accidentType', },
    { value: 'Dust', label: 'Dust', name: 'accidentType', },
    { value: 'Fracture', label: 'Fracture', name: 'accidentType', },
    { value: 'Puncture', label: 'Puncture', name: 'accidentType', },
    { value: 'Sprain/Strain', label: 'Sprain/Strain', name: 'accidentType', },
    { value: 'Other', label: 'Other', name: 'accidentType', },
    { value: 'COVID-19', label: 'COVID-19', name: 'accidentType', },
  ];
  
  const ambulanceCalledOptions = [
    { value: 'Yes', label: 'Yes', name: 'ambulanceCalled', },
    { value: 'No', label: 'No', name: 'ambulanceCalled', },
  ];
  
  const medicalAdviseOptions = [
    { value: 'Yes', label: 'Yes', name: 'medicallyAdvised', },
    { value: 'No', label: 'No', name: 'medicallyAdvised', },
    { value: 'N/A', label: 'N/A', name: 'medicallyAdvised', },
  ];
  
  const hospitalOptions = [
    { value: 'Yes', label: 'Yes', name: 'hospitalAdvise', },
    { value: 'No', label: 'No', name: 'hospitalAdvise', },
    { value: 'N/A', label: 'N/A', name: 'hospitalAdvise', },
  ];
  
  const tetanusOptions = [
    { value: 'Yes', label: 'Yes', name: 'tetanus', },
    { value: 'No', label: 'No', name: 'tetanus', },
    { value: 'N/A', label: 'N/A', name: 'tetanus', },
  ];
  
  const tradeOptions = [
    { value: 'Bricklayer', label: 'Bricklayer', name: 'trade', },
    { value: 'Carpenter', label: 'Carpenter', name: 'trade', },
    { value: 'Carpet Tiler', label: 'Carpet Tiler', name: 'trade', },
    { value: 'Drywall', label: 'Drywall', name: 'trade', },
    { value: 'Electrician', label: 'Electrician', name: 'trade', },
    { value: 'Formworker', label: 'Formworker', name: 'trade', },
    { value: 'G.O.', label: 'G.O.', name: 'trade', },
    { value: 'Glazier', label: 'Glazier', name: 'trade', },
    { value: 'Mechanical', label: 'Mechanical', name: 'trade', },
    { value: 'Member Of The Public', label: 'Member Of The Public', name: 'trade', },
    { value: 'Painter', label: 'Painter', name: 'trade', },
    { value: 'Plant Operator', label: 'Plant Operator', name: 'trade', },
    { value: 'Plasterer', label: 'Plasterer', name: 'trade', },
    { value: 'Plumber', label: 'Plumber', name: 'trade', },
    { value: 'Roofer', label: 'Roofer', name: 'trade', },
    { value: 'Scaffolder', label: 'Scaffolder', name: 'trade', },
    { value: 'Site Management', label: 'Site Management', name: 'trade', },
  ];

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(
            userCompanyId
          );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchAccidentNotificationsDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/AccidentNotifications/notification/details`,
        {
          notificationId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0], trigger: res.data[0].triggeredBy, existingFiles: res.data[0].files });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        console.log("errr", err);
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchAccidentNotificationsDetails()
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {

    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );

      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }

      Promise.all(Array.from(modifiedFiles.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value)
      }
    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      case "addedBy":
        if (!value) {
          message = stateObj[name] = "Added by is required.";
        }
        break;
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;
      case "descriptionOfWorks":
        if (!value) {
          message = stateObj[name] = "Equipment is required.";
        }
        break;
      case "accessingLocationMethod":
        if (!value) {
          message = stateObj[name] = "Location & Description is required.";
        }
        break;
      case "isActionRequired":
        if (!value) {
          message = stateObj[name] = "Is Action Required is required.";
        }
        break;
      case "actionRequired":
        if (!value) {
          message = stateObj[name] = "Action Required is required.";
        }
        break;
      case "safetoUse":
        if (!value) {
          message = stateObj[name] = "Safe to use is required.";
        }
        break;
      case "resultsOfInspection":
        if (!value) {
          message = stateObj[name] = "Results of Inspection is required.";
        }
        break;
      case "locationOfWorks":
        if (!value) {
          message = stateObj[name] = "Positions is required.";
        }
        break;
      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Contractor Id is required.";
        }
        break;
      case "Start Date":
        if (!value) {
          message = stateObj[name] = "Start Date is required.";
        }
        break;
      case "verifySignature":
        if (!value) {
          message = stateObj[name] = "Upload signature.";
        }
        break;
      case "verifySignatureTwo":
        if (!value) {
          message = stateObj[name] = "Upload signature.";
        }
        break;

      default:
        // Do nothing.
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      let verifySignature = '';
      let verifySignatureTwo = '';
      
      if ( id ) {
        verifySignatureTwo = '';
        [verifySignature] = await Promise.all( [ handleSaveVerifySign() ] )
      } else {
        [verifySignature, verifySignatureTwo] = await Promise.all( [ handleSaveVerifySign(), handleSaveVerifySignTwo() ] )
      }
      handleSubmitAfterSave(verifySignature, verifySignatureTwo)
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (verifySignature, verifySignatureTwo) => {
    let errorShow = {
      companyId: fieldData.companyId,
      addedBy: fieldData.addedBy,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      
      accidentDate: fieldData.accidentDate,
      accidentTime: fieldData.accidentTime,
      trigger: fieldData.trigger,
      bodyPart: fieldData.bodyPart,
      accidentDetails: fieldData.accidentDetails,
      
      fullName: fieldData.fullName,
      employer: fieldData.employer,
      trade: fieldData.trade,
      
      tetanus: fieldData.tetanus,
      medicallyAdvised: fieldData.medicallyAdvised,
      hospitalAdvise: fieldData.hospitalAdvise,
      
      accidentType: fieldData.accidentType,
      ambulanceCalled: fieldData.ambulanceCalled,
      
      verifySignature: verifySignature,
      // verifySignatureTwo: verifySignatureTwo,
    };

    if ( ! id ) {
      errorShow.verifySignatureTwo = verifySignatureTwo
    }

    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.accidentDate !== null &&
      fieldData.accidentTime !== null &&
      fieldData.trigger !== null &&
      fieldData.bodyPart !== null &&
      fieldData.accidentDetails !== null &&
      
      fieldData.siteId !== null &&
      fieldData.departmentId !== null &&
      
      fieldData.fullName !== null &&
      fieldData.employer !== null &&
      fieldData.trade !== null &&
      
      fieldData.tetanus !== null &&
      fieldData.medicallyAdvised !== null &&
      fieldData.hospitalAdvise !== null &&
      
      fieldData.ambulanceCalled !== null &&
      fieldData.accidentType !== null &&
      
      verifySignature !== null
    ) {
      
      const form = new FormData();

      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("accidentNotification") : v.permits.includes("accidentNotificationreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      
      form.append("departmentId", fieldData.departmentId == null ? null : fieldData.departmentId);
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      
      form.append("accidentDate", fieldData.accidentDate);
      form.append("accidentTime", fieldData.accidentTime);
      form.append("triggeredBy", fieldData.trigger);
      form.append("bodyPart", fieldData.bodyPart);
      form.append("accidentDetails", fieldData.accidentDetails);
      
      form.append("fullName", fieldData.fullName);
      form.append("employer", fieldData.employer);
      form.append("trade", fieldData.trade);
      
      form.append("tetanus", fieldData.tetanus);
      form.append("hospitalAdvise", fieldData.hospitalAdvise);
      form.append("medicallyAdvised", fieldData.medicallyAdvised);
      
      form.append("accidentType", fieldData.accidentType);
      form.append("ambulanceCalled", fieldData.ambulanceCalled);
      
      form.append("verifySignature", verifySignature);
      
      if ( ! id ) {
        form.append("verifySignatureTwo", verifySignatureTwo);
      }
      
      form.append("companyId", companyId);
      form.append("addedBy", companyId);
      form.append("addedByInCompany", user.id);
      form.append("approvedByName", user.fullName);
      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }

      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
      setLoading(true);
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update-details/accident-notification/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/accident-notification`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/create-accident-report/' +  (id? id : res.data.notificationId))
        setFieldData(fieldData);
        handleClearVerifySign();
        if ( ! id ) {
          handleClearVerifySignTwo();
        }
      })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const signatureVerifyRef = useRef();
  const signatureVerifyRefTwo = useRef();

  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRef.current.isEmpty()) {
        res(null)
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file)
        });
      }
    })
  };

  const handleSaveVerifySignTwo = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRefTwo.current.isEmpty()) {
        res(null)
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRefTwo.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignatureTwo: file,
            };
          });
          res(file)
        });
      }
    })
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };
  
  const handleClearVerifySignTwo = () => {
    signatureVerifyRefTwo.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignatureTwo: null,
      };
    });
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }))

  const departmentOptions = allDepartment.filter(v => v.siteId == fieldData.siteId).map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentId",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">
        <div className="addaccess_first_container">
          <div className="general_details">General Details</div>

          <div className="row">
            <div className="col-md-8">
              <div className="flex flex-wrap">
                <div className="col-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Date of Accident <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input value={fieldData.accidentDate} type="date" onChange={handleChange} name="accidentDate" />
                    </div>
                  </div>
                  {error.accidentDate && <span className="err">{error.accidentDate}</span>}
                </div>

                <div className="col-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Time of Accident <span className="text-danger">*</span>{" "}
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input value={fieldData.accidentTime} type="time" pattern="hh:mm" onChange={handleChange} name="accidentTime" />
                    </div>
                  </div>
                  {error.accidentTime && <span className="err">{error.accidentTime}</span>}
                </div>
              </div>

              <div className="flex flex-wrap">
                <div className="col-6  p-2">
                  <label for="">
                    <div className="require-label">
                      Trigger <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="w-100">
                      <Select
                        onChange={handleChange}
                        placeholder="Select a trigger"
                        options={triggerOptions}
                        {...(id ? {
                          value: triggerOptions.filter(v => v.value == fieldData.trigger),
                        } : {})}
                      />
                    </div>
                  </div>
                  {error.trigger && <span className="err">{error.trigger}</span>}
                </div>

                <div className="col-6  p-2">
                  <label for="">
                    <div className="require-label">
                      Part of Body <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="w-100">
                      <Select
                        onChange={handleChange}
                        placeholder="Select a body part"
                        options={bodyParts}
                        {...(id ? {
                          value: bodyParts.filter(v => v.value == fieldData.bodyPart),
                        } : {})}
                      />
                    </div>
                  </div>
                  {error.bodyPart && <span className="err">{error.bodyPart}</span>}
                </div>
              </div>
              
              <div className="form_inner p-2 w-100">
                  <label for="">
                  Accident Details <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <textarea
                        name="accidentDetails"
                        rows="5"
                        className="w-100"
                        cols="100"
                        value={fieldData.accidentDetails}
                        onChange={handleChange}
                        placeholder="Enter a description of the event leading to the accident / injury (if available)"
                      ></textarea>
                    </div>
                  </div>
                  {error.accidentDetails && (
                    <span className="err">{error.accidentDetails}</span>
                  )}
                </div>

            </div>
            <div className="col-md-4">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Type of Accident <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select an option"
                      options={accidentTypes}
                      {...(id ? {
                        value: accidentTypes.filter(v => v.value == fieldData.accidentType),
                      } : {})}
                    />
                  </div>
                </div>
                {error.accidentType && <span className="err">{error.accidentType}</span>}
              </div>

              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Was an Ambulance called? <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select an option"
                      options={ambulanceCalledOptions}
                      {...(id ? {
                        value: ambulanceCalledOptions.filter(v => v.value == fieldData.ambulanceCalled),
                      } : {})}
                    />
                  </div>
                </div>
                {error.ambulanceCalled && <span className="err">{error.ambulanceCalled}</span>}
              </div>
              
              <div className="w-100  p-2">
                <label for="">
                  <div className="require-label">
                    Site <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Sites"
                      options={siteOptions}
                      {...(id ? {
                        value: siteOptions.filter(v => v.value == fieldData.siteId),
                        isDisabled: true
                      } : {})}
                    />
                  </div>
                </div>
                {error.siteId && <span className="err">{error.siteId}</span>}
              </div>

              <div className="w-100  p-2">
                <label for="">
                  <div className="require-label">
                    Department <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Department"
                      options={departmentOptions}
                      {...(id ? {
                        value: departmentOptions.filter(v => v.value == fieldData.departmentId),
                        isDisabled: true
                      } : {})}
                    />
                  </div>
                </div>
                {error.departmentId && (
                  <span className="err">{error.departmentId}</span>
                )}
              </div>

            </div>
          </div>
        </div>

        <div className="addaccess_second_container">
          <div className="general_details">User Details</div>
          <div className="flex flex-wrap">
            
            <div className="col-4 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Full Name <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="fullName"
                    value={fieldData.fullName}
                    placeholder="Enter full name of the injured party"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.fullName && (
                <span className="err">{error.fullName}</span>
              )}
            </div>

            <div className="col-4 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Employer <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="employer"
                    value={fieldData.employer}
                    placeholder="Enter the employer name"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.employer && (
                <span className="err">{error.employer}</span>
              )}
            </div>

            <div className="col-4  p-2">
              <label for="">
                <div className="require-label">
                  Trade <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select a trade"
                    options={tradeOptions}
                    {...(id ? {
                      value: tradeOptions.filter(v => v.value == fieldData.trade),
                    } : {})}
                  />
                </div>
              </div>
              {error.trade && (
                <span className="err">{error.trade}</span>
              )}
            </div>
          </div>
        </div>

        <div className="addaccess_second_container">
          <div className="general_details">Was the user advised on:</div>
          <div className="flex flex-wrap">
            
          <div className="col-4  p-2">
              <label for="">
                <div className="require-label">
                  Getting a tetanus injection <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select an option"
                    options={tetanusOptions}
                    {...(id ? {
                      value: tetanusOptions.filter(v => v.value == fieldData.tetanus),
                    } : {})}
                  />
                </div>
              </div>
              {error.tetanus && (
                <span className="err">{error.tetanus}</span>
              )}
            </div>

            <div className="col-4  p-2">
              <label for="">
                <div className="require-label">
                  Attending a hospital <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select an option"
                    options={hospitalOptions}
                    {...(id ? {
                      value: hospitalOptions.filter(v => v.value == fieldData.hospitalAdvise),
                    } : {})}
                  />
                </div>
              </div>
              {error.hospitalAdvise && (
                <span className="err">{error.hospitalAdvise}</span>
              )}
            </div>

            <div className="col-4  p-2">
              <label for="">
                <div className="require-label">
                  Getting medical treatment <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select an option"
                    options={medicalAdviseOptions}
                    {...(id ? {
                      value: medicalAdviseOptions.filter(v => v.value == fieldData.medicallyAdvised),
                    } : {})}
                  />
                </div>
              </div>
              {error.medicallyAdvised && (
                <span className="err">{error.medicallyAdvised}</span>
              )}
            </div>
          </div>
        </div>

        <div className="addladder_fifth_container">
          <div className="general_details">Upload Files</div>
          <div className="d-flex">
            {!!files.length && files.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
              <img
                className="obj_fit_img obj-fit-cover rounded"
                src={imgPreviewUrl}
                alt=""
                height="150"
                width="150"
              />
            </div>))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
          </div>
        </div>


        <div className="addaccess_sixth_container">
          <div className="general_details">Signature(s)</div>
          <div className="row">
            <div className="col-md-4">
              <div className="p-2">
                <strong>Submitter Signature</strong>
                <div className="sign_image submitter_signature">
                  <SignatureCanvas
                    ref={signatureVerifyRef}
                    canvasProps={{ width: 263, height: 270 }}
                  />
                  <span
                    className="cancel-custom cursor-pointer"
                    onClick={handleClearVerifySign}
                  >
                    <i
                      className="clear_btn  text-danger fa fa-times-circle"
                      aria-hidden="true"
                    ></i>
                  </span>


                  
                </div>
                {error.verifySignature && (
                  <span className="err">{error.verifySignature}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
            </div>

            {! id && <>
              <div className="col-md-4">
                <div className="p-2">
                  <strong>Injured Person Signature</strong>
                  <div className="sign_image injured_signature">
                    <SignatureCanvas
                      ref={signatureVerifyRefTwo}
                      canvasProps={{ width: 263, height: 270 }}
                    />
                    <span
                      className="cancel-custom cursor-pointer"
                      onClick={handleClearVerifySignTwo}
                    >
                      <i
                        className="clear_btn  text-danger fa fa-times-circle"
                        aria-hidden="true"
                      ></i>
                    </span>


                    
                  </div>
                  {error.verifySignatureTwo && (
                    <span className="err">{error.verifySignatureTwo}</span>
                  )}
                </div>
              </div>
            </>}
          </div>
        </div>

        
        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Resubmit" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn">cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddAccidentNotification;
