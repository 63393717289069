import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import "./digPermitView.css";
import { commonService } from "../common/common.service";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { getBase64 } from "../common/imageHelper";
import Swal from "sweetalert2";

import {
  PDFDownloadLink,
  Text,
  View,
  Image,
  Link,
  StyleSheet,
} from "@react-pdf/renderer";
import PDFRenderer from "../common/pdfRenderer/pdfRenderer";
import TabularData from "../common/pdfRenderer/tabularData";
import PDFFiles from "../common/pdfRenderer/files";

export default function ViewDigPermit({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, userPermissions } = useAppState()
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [siteUsers, setSiteUsers] = useState([])
  const [activities, setActivities] = useState([])
  const [popup, setPopup] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  
  const [approvalScrolled, setApprovalScrolled] = useState(false);
  const [files, setFiles] = useState([]);
  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    approvedComment: "",
    deniedComment: "",
    signature: null,

    arLocal: 0,
    arElectrical: 0,
    arClient: 0,
    arOther: 0,
    dataCablingDrawing: 0,
    foulDrawing: 0,
    telecomsDrawing: 0,
    mainWaterDrawing: 0,
    gasDrawing: 0,
    electricityDrawing: 0,
    fibreOpticDrawing: 0,
    otherDrawing: '',
    noServiceDetected: 0,

    completedDate: null,
  })

  const fetchDigDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/dig/permit/details`,
        {
          digId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data[0]);
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
        getUserEmail(res.data[0].addedByInCompany)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
        console.log("errr", err);
      });
  };


  const fetchActivities = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/digactivity/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setActivities(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchDigDetails();
      fetchActivities();
    }
  }, [id]);

  useEffect(() => {
    if (! approvalScrolled && fieldData.approved) {
      // scroll to #dig-approval-form
      document.getElementById('dig-approval-form').scrollIntoView({ behavior: 'smooth' });
      setApprovalScrolled(true);
    }
  }, [fieldData]);

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file)
        });
      }
    })

  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      let signature
      if(!fieldData.denied){
        signature = await handleSaveVerifySign()
      }
      handleSubmitAfterSave(signature)
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;

    if (name == "files") {
      Promise.all(Array.from(e.target.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: files,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
    }
  }

  const handleSubmitAfterSave = (signature) => {
    const form = new FormData();
    form.append("deniedComment", fieldData.deniedComment);
    form.append("approvedComment", fieldData.approvedComment);
    if (fieldData.approved) {
      form.append("approved", fieldData.approved);

      var approvalExtraFields = [
        'arLocal',
        'arElectrical',
        'arClient',
        'arOther',
        'dataCablingDrawing',
        'foulDrawing',
        'telecomsDrawing',
        'mainWaterDrawing',
        'gasDrawing',
        'electricityDrawing',
        'fibreOpticDrawing',
        'otherDrawing',
        'noServiceDetected',
        'groundMarkedBy',
      ];

      // for each of the item in approvalExtraFields append field to form and value from fieldData
      approvalExtraFields.forEach((field, index) => {
        form.append(field, fieldData[field]);
      });
    }

    if ( fieldData.approved ) {
      var approvalFailed = false;
      if ( files.length === 0 ) {
        toast.error("Marked-Up Drawing(s) are required.");
        approvalFailed = true;
      }
      
      if ( fieldData.groundMarkedBy == '' ) {
        toast.error("Ground Marked By is required.");
        approvalFailed = true;
      }

      if ( fieldData.groundMarkedOn == '' ) {
        toast.error("Ground Marked On By is required.");
        approvalFailed = true;
      }

      if ( approvalFailed ) {
        return;
      }
      
      form.append("groundMarkedOn", commonService.formatDate( fieldData.groundMarkedOn, "YYYY-MM-DD" ));
      
      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });

    }
    
    if (fieldData.completed) {
      form.append("completed", fieldData.completed);

      if ( fieldData.completedDate == null ) {
        toast.error("Please enter the completed date");
        return;
      }

      form.append("completedDate", commonService.formatDate( fieldData.completedDate, "YYYY-MM-DD" ));
    }
    form.append("signature", signature);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);
    form.append("id", id);
    setLoading(true);
    axiosInstance
      .post((fieldData.resubmitted || fieldData.completed) ? `${process.env.REACT_APP_API_BASEURL}/resubmit/dig-permit` : `${process.env.REACT_APP_API_BASEURL}/update/dig-permit`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/dig-permits')
        setFieldData({
          deniedComment: "",
          approvedComment: "",
          approved: false,
          resubmitted: false,
          completed: false,
          denied: false,
          signature: null,
        });
        handleClearSignature();
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const MyDoc = () => {

    const tabularSections = [
      {
        name: 'General Details',
        rows: [
          ['Approved', data.isApproved == 1 ? "Yes" : data.isApproved == 0 ? "No" : "Pending"],
          ['Completed Date', data.completedDate ? commonService.formatDate(data.completedDate, "Do MMM YYYY" ) : '-'],
          ['Site', data.siteName ? data.siteName : "-"],
          ['Department', data.departmentName ? data.departmentName : "-"],
          ['Contractor', data.contractorName ? data.contractorName : data.companyName],
          ['Exact Location of works', data.locationOfWorks ? data.locationOfWorks : "-"],
          ['Submitted By', data.submittedBy ? data.submittedBy : "-"],
          ['Start Date', data.date ? commonService.formatDate( data.date, "Do MMM YYYY" ) : "-"],
          ['Finish Date', data.finishDate],
          ['Finish Time', data.finishTime],
          ['Responsible Person', data.supervisor],
          ['Contractor LUGS Supervisor', data.lugsSupervisor ? data.lugsSupervisor : "-"],
          ['Spotter Name', data.spotterName ? data.spotterName : "-"],
          ['Contact Phone', data.contactPhone],
          ['Brief description of works', data.descriptionOfWorks],
          ['Max Width', data.maxWidth],
          ['Max Depth', data.maxDepth],
          ['RAMS / SPA Ref No', data.ramsSPARef ? data.ramsSPARef : "-"],
          ['Excavation Supports Required', data.excavationSupportsRequired == 1 ? 'Yes' : 'No'],
          ['Description of Supports required', data.supportsDescription ? data.supportsDescription : "-"],
          ['Means of preventing materials falling onto people working in the excavation', data.materialsProtectionMeans ? data.materialsProtectionMeans : "-"],
          ['Means of preventing people and vehicles falling into excavation', data.peopleVehiclesProtectionMeans ? data.peopleVehiclesProtectionMeans : "-"],
          ['Means of preventing the undermining of nearby structures', data.nearbyStructuresProtectionMeans ? data.nearbyStructuresProtectionMeans : "-"],
          ['Means of safe access into the excavation', data.safeAccessProtectionMeans ? data.safeAccessProtectionMeans : "-"],
        ]
      },
      {
        name: 'Other Information',
        rows: [
          ['Is Bunting or Goalpost required for any overhead obstructions', data.buntingRequired == '1' ? "Yes" : "No"],
          ['If Yes, Does it meet the Cod of Practice for the Avoiding Danger from Overhead Electrical Lines', data.overheadPractices == '1' ? "Yes" : "No"],
          ['Is a trial pit required?', data.trialPitRequired == '1' ? "Yes" : "No"],
          ['Terms Agreed?', data.termsAgreed == '1' ? "Yes" : "No"],
          ['Plant Required', data.plantRequired],
          ['Equipment Required', data.equipmentRequired],
        ]
      },
      {
        name: 'To Dig Operation Scanning',
        rows: [
          ['Please confirm ground has been scanned', data.groundScanned == '1' ? "Yes" : "No"],
          ['CAT & Genny Scan carried out by', data.catScanBy ? data.catScanBy : "-"],
          ['Date CAT & Genny Scan was carried out', data.catScanDate ? commonService.formatDate( data.catScanDate, "Do MMM YYYY" ) : "-"],
          ['Confirm Scanner is certified and Calibrated', data.isScannerCertified == '1' ? "Yes" : "No"],
          ['Expire Date of Cert', data.certExpiryDate ? commonService.formatDate( data.certExpiryDate, "Do MMM YYYY" ) : "-", '100%'],
        ]
      },
      {
        name: 'Permits Required',
        rows: [
          ['Hot Works', (data.prHotworks != 2) ? (data.prHotworks == 1) ? "Yes" : "No" : "N/A"],
          ['Confined Space', (data.prConfined != 2) ? (data.prConfined == 1) ? "Yes" : "No" : "N/A"],
          ['Live Electrical', (data.prElectrical != 2) ? (data.prElectrical == 1) ? "Yes" : "No" : "N/A"],
          ['Other (Client)', (data.prOther != 2) ? (data.prOther == 1) ? "Yes" : "No" : "N/A"],
        ]
      },
      {
        name: 'Approval Required From',
        rows: [
          ['Local Authority', (data.arLocal != 2) ? (data.arLocal == 1) ? "Yes" : "No" : "N/A"],
          ['Electrical Company', (data.arElectrical != 2) ? (data.arElectrical == 1) ? "Yes" : "No" : "N/A"],
          ['Client', (data.arClient != 2) ? (data.arClient == 1) ? "Yes" : "No" : "N/A"],
          ['Other', (data.arOther != 2) ? (data.arOther == 1) ? "Yes" : "No" : "N/A"],
        ]
      },
      {
        name: 'Services Drawing available for the following services',
        rows: [
          ['Data Cabling', (data.dataCablingDrawing != 2) ? (data.dataCablingDrawing == 1) ? "Yes" : "No" : "N/A"],
          ['Foul', (data.foulDrawing != 2) ? (data.foulDrawing == 1) ? "Yes" : "No" : "N/A"],
          ['Telecoms', (data.telecomsDrawing != 2) ? (data.telecomsDrawing == 1) ? "Yes" : "No" : "N/A"],
          ['Main Water', (data.mainWaterDrawing != 2) ? (data.mainWaterDrawing == 1) ? "Yes" : "No" : "N/A"],
          ['Gas', (data.gasDrawing != 2) ? (data.gasDrawing == 1) ? "Yes" : "No" : "N/A"],
          ['Electricity', (data.electricityDrawing != 2) ? (data.electricityDrawing == 1) ? "Yes" : "No" : "N/A"],
          ['Fibre Optic', (data.fibreOpticDrawing != 2) ? (data.fibreOpticDrawing == 1) ? "Yes" : "No" : "N/A"],
          ['Other', data.otherDrawing ? data.otherDrawing : "N/A"],
          ['No Services Detected', (data.noServiceDetected != 2) ? (data.noServiceDetected == 1) ? "Yes" : "No" : "N/A", '100%'],
        ]
      },
      {
        name: 'Ground Marking',
        rows: [
          ['Ground Marked By', data.groundMarkedBy ? data.groundMarkedBy : "N/A"],
          ['Ground Marked On', data.groundMarkedOn ? commonService.formatDate( data.groundMarkedOn, "Do MMM YYYY" ) : "N/A"],
        ]
      }
    ];

    if ( ! data.lugsSupervisor ) {
      tabularSections[0].rows.push( ['1.25 Method Statement/SPA required', data.spaRequired == 1 ? "Yes" : data.spaRequired == 0 ? "No" : "N/A"] );
    }

    if ( ! data.isApproved ) {
      tabularSections[0].rows.push( ['Reason Denied', data.denialComment ? data.denialComment : "-"] );
    }

    const filesBlocks = [
      {
        name: "Marked-Up Drawings",
        files: data.markedDrawings,
      },
      {
        name: "Other Files",
        files: data.files,
      }
    ];

    activities.map((activity) => {
      if ( activity.signature ) {
        filesBlocks.push({
          name: activity.action + ' at' + activity.datetime,
          subtitle: activity.comment,
          type: 'signatures',
          files: activity.signature,
        });
      }
    });

    return (
      <PDFRenderer name={"Dig Permit #" + id} user={userDetails}>

       <View wrap={false}>
        <View style={{
                fontSize: "14px",
                fontFamily: "Helvetica-Bold",
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "10px",
                color: "red"
              }}>
          <Text style={{
                fontSize: "10px"
          }}>A TO DIG PERMIT IS REQUIRED FOR THE BREAKING OF GROUND. ANY DIG REQUIRES AN APPROVED RAMS/SPA</Text>
          <Text style={{
            textAlign: "center"
          }}>TREAT ALL SERVICES AS LIVE</Text>
        </View>
      </View>

        <TabularData
          tabularSections={tabularSections}
        />

        {filesBlocks.map((block) => (
          <PDFFiles block={block} />
        ))}

      </PDFRenderer>
    )
  }

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/dig/" + deleteId)
        .then(function (response) {
          toast.success("Dig Permit archived successfully");
          history.push(`/dig-permits`)
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Dig Permit"
          );
        });
    }
  };
  
  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Dig Permit List", path: "/dig-permits" },
          { name: "Dig Permit", path: `/dig-permit/${id}` },
        ]}
      />
      <CustomPopup
        trigger={!popup}
        setTrigger={() => {
          setPopup(true)
          setFieldData({
            approved: false,
            resubmitted: false,
            completed: false,
            denied: false,
            approvedComment: "",
            deniedComment: "",
            signature: null,
            
            completedDate: null,
          })
        }}
      >

        {fieldData.completed && <>
          <h3>Works under this permit have been completed and I have made the area safe.</h3>
        </> }

        {(fieldData.approved || fieldData.resubmitted || fieldData.completed) && <>
          {fieldData.completed && <>
            
            <div className="col-12 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  Completion Date <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    value={fieldData.completedDate}
                    min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")}
                    type="date"
                    onChange={handleChange}
                    name="completedDate"
                  />
                </div>
              </div>
            </div>

            <label for="" style={{marginTop: '30px'}}>Completion Signature</label>
          </>}
          <div className="sign_image">
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{ width: 263, height: 270 }}
            />
            <span
              className="cancel-custom cursor-pointer"
              onClick={handleClearSignature}
            >
              <i
                className="clear_btn  text-danger fa fa-times-circle"
                aria-hidden="true"
              ></i>
            </span>
          </div>
        </>
        }
        {!fieldData.resubmitted && !fieldData.completed && <div className="col-12 form_inner p-2">
          <label for="">
            {fieldData.approved ? "Approval Comment" : "Reason for Denial"} <span className="text-danger">*</span>
          </label>
          <div className="input_icons">
            <div className="input_field">
              <textarea
                name="approvedComment"
                rows="5"
                cols="100"
                onChange={(e) => {
                  if (fieldData.approved) {
                    setFieldData({
                      ...fieldData,
                      approvedComment: e.target.value,
                    })
                  } else {
                    setFieldData({
                      ...fieldData,
                      deniedComment: e.target.value,
                    })
                  }
                }}
              ></textarea>
            </div>
          </div>
        </div>}
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            {...(fieldData.approved ? {
              disabled:
                fieldData.approved ?
                  false :
                  !(fieldData.deniedComment)
            } : null)}
            onClick={() => {
              handleSubmit()
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>
      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-dig-view">General Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">Approved : </div>
                            <div className="col-6 dd-dig">
                              <span className={"roundPill " + (data.isApproved == 2 ? "yellow-bg" : ( data.isApproved == 1 ? "green-bg" : "red-bg" ) ) }>
                                {data.isApproved == 1
                                  ? "Yes"
                                  : data.isApproved == 0
                                    ? "No"
                                    : "Pending"}
                              </span>
                            </div>
                          </div>
                          {data.isCompleted && data.completedDate && <>
                            <div className="mb-1 d-flex dl-dig">
                              <div className="col-6 dd-dig">Completed Date : </div>
                              <div className="col-6 dd-dig">
                                {commonService.formatDate(data.completedDate, "Do MMM YYYY" )}
                              </div>
                            </div>
                          </>}
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">Site : </div>
                            <div className="col-6 dd-dig">
                              {" "}
                              {data.siteName ? data.siteName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">Department : </div>
                            <div className="col-6 dd-dig">
                              {" "}
                              {data.departmentName ? data.departmentName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">Contractor : </div>
                            <div className="col-6 dd-dig">
                              {data.contractorName ? data.contractorName : data.companyName}{" "}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Exact Location of works:{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {" "}
                              {data.locationOfWorks ? data.locationOfWorks : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Submitted By :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {" "}
                              {data.submittedBy ? data.submittedBy : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Start Date :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.date
                                ? commonService.formatDate(
                                  data.date,
                                  "Do MMM YYYY"
                                )
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Finish Date :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.finishDate}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Finish Time :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.finishTime}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Responsible Person :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.supervisor}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Contractor LUGS Supervisor :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.lugsSupervisor ? data.lugsSupervisor : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Spotter Name :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.spotterName ? data.spotterName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Contact Phone :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.contactPhone}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Brief description of works :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.descriptionOfWorks}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Max Width :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.maxWidth}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Max Depth :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.maxDepth}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              RAMS / SPA Ref No :
                            </div>
                            <div className="col-6 dd-dig">
                              {data.ramsSPARef ? data.ramsSPARef : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Excavation Supports Required
                            </div>
                            <div className="col-6 dd-dig">
                              {data.excavationSupportsRequired == 1 ? 'Yes' : 'No'}
                            </div>
                          </div>
                          { data.excavationSupportsRequired == 1 && <>
                            <div className="mb-1 d-flex dl-dig">
                              <div className="col-6 dd-dig">
                              Description of Supports required
                              </div>
                              <div className="col-6 dd-dig">
                                {data.supportsDescription ? data.supportsDescription : "-"}
                              </div>
                            </div>
                          </> }
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Means of preventing materials falling onto people working in the excavation :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.materialsProtectionMeans ? data.materialsProtectionMeans : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Means of preventing people and vehicles falling into excavation :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.peopleVehiclesProtectionMeans ? data.peopleVehiclesProtectionMeans : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Means of preventing the undermining of nearby structures :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.nearbyStructuresProtectionMeans ? data.nearbyStructuresProtectionMeans : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-dig">
                            <div className="col-6 dd-dig">
                              Means of safe access into the excavation :{" "}
                            </div>
                            <div className="col-6 dd-dig">
                              {data.safeAccessProtectionMeans ? data.safeAccessProtectionMeans : "-"}
                            </div>
                          </div>

                          { !data.lugsSupervisor && <>
                            <div className="mb-1 d-flex dl-dig">
                              <div className="col-6 dd-dig">{"> "}1.25 Method Statement/SPA required : </div>
                              <div className="col-6 dd-dig">
                                {" "}
                                {data.spaRequired == 1
                                  ? "Yes"
                                  : data.spaRequired == 0
                                    ? "No"
                                    : "N/A"}
                              </div>
                            </div>
                          </>}

                          {!data.isApproved && <div className="mb-1 d-flex dl-dig alert alert-danger">
                            <div className="col-6 dd-dig">Reason Denied : </div>
                            <div className="col-6 dd-dig">
                              {data.denialComment ? data.denialComment : "-"}{" "}
                            </div>
                          </div>}
                        </div>
                      </div>
                      
                      {! fieldData.approved && <>
                        {(userDetails.role.includes("admin") || siteUsers.filter(user => (user.type == "admin") && (user.userid == userDetails.id)).length) && (data.isApproved == 2) && (data.isCompleted != 1) ? <>
                          <button className="btn btn-success" onClick={() => {
                            setFieldData({
                              ...fieldData,
                              approved: true
                            });
                          }}>Approve</button>
                          <button className="btn btn-danger ms-2" onClick={() => {
                            setFieldData({
                              ...fieldData,
                              denied: true
                            })
                            setPopup(false)
                          }}>Deny</button>
                        </>
                          : null}
                        {(data.isApproved == 0) && (data.isCompleted != 1) ? <>
                          <button className="btn btn-success" onClick={() => {
                            setFieldData({
                              ...fieldData,
                              resubmitted: true
                            })
                            setPopup(false)
                          }}>Resubmit</button>
                        </>
                          : null}
                          {(data.isApproved == 2) && (data.isCompleted != 1) ? <>
                            <button className="btn btn-outline-success ms-1" onClick={() => {
                              history.push(`/edit-dig-permit/${data.id}`)
                            }}>Edit</button>
                          </>
                            : null}
                        { (userDetails.role.includes('admin') || data.addedByInCompany == userDetails.id ) && (data.isApproved == 1) && (data.isCompleted != 1) ? <>
                          <button className="btn btn-success" onClick={() => {
                            setFieldData({
                              ...fieldData,
                              completed: true
                            })
                            setPopup(false)
                          }}>Complete</button>
                        </>
                          : null}
                      </>}

                      {id && userPermissions.includes('submitdigPermits') && data.isDeleted != 1 ? <>
                        <button className="btn btn-outline-danger ms-1 mt-2" onClick={() => handleArchive(id)}>
                          Archive
                        </button>
                        </> : ''}

                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-7 mb-2">
                  <div className="d-flex flex-column">

                    <div className="mb-3">
                      <div className="header-dig-view">Other Information</div>
                      <div className="p-2 main-restriction-div">
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Is Bunting or Goalpost required for any overhead obstructions:
                            </span>
                            <span className="rst-details fw-bold">
                              { data.buntingRequired == '1' ? "Yes" : "No"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              If Yes, Does it meet the Cod of Practice for the Avoiding Danger from Overhead Electrical Lines:
                            </span>
                            <span className="rst-details fw-bold">
                              { data.overheadPractices == '1' ? "Yes" : "No"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Is a trial pit required?
                            </span>
                            <span className="rst-details fw-bold">
                              { data.trialPitRequired == '1' ? "Yes" : "No"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Terms Agreed?
                            </span>
                            <span className="rst-details fw-bold">
                              { data.termsAgreed == '1' ? "Yes" : "No"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Plant Required:
                            </span>
                            <span className="rst-details fw-bold">
                              { data.plantRequired}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Equipment Required:
                            </span>
                            <span className="rst-details fw-bold">
                              { data.equipmentRequired}
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="header-dig-view">To Dig Operation Scanning</div>
                      <div className="p-2 main-restriction-div">
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Please confirm ground has been scanned:
                            </span>
                            <span className="rst-details fw-bold">
                              { data.groundScanned == '1' ? "Yes" : "No"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              CAT & Genny Scan carried out by:
                            </span>
                            <span className="rst-details fw-bold">
                              { data.catScanBy ? data.catScanBy : "-"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Date CAT & Genny Scan was carried out:
                            </span>
                            <span className="rst-details fw-bold">
                              { data.catScanDate ? commonService.formatDate( data.catScanDate, "Do MMM YYYY" ) : "-"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Confirm Scanner is certified and Calibrated:
                            </span>
                            <span className="rst-details fw-bold">
                              { data.isScannerCertified == '1' ? "Yes" : "No"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Expire Date of Cert:
                            </span>
                            <span className="rst-details fw-bold">
                              { data.certExpiryDate ? commonService.formatDate( data.certExpiryDate, "Do MMM YYYY" ) : "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="header-dig-view">Permits Required</div>
                      <div className="p-2 main-restriction-div">
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Hot Works :{" "}
                            </span>
                            <span className="rst-details fw-bold">
                              {(data.prHotworks != 2) ? (data.prHotworks == 1) ? "Yes" : "No" : "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Confined Space :{" "}
                            </span>
                            <span className="rst-details fw-bold">
                              {(data.prConfined != 2) ? (data.prConfined == 1) ? "Yes" : "No" : "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Live Electrical :{" "}
                            </span>
                            <span className="rst-details fw-bold">
                              {(data.prElectrical != 2) ? (data.prElectrical == 1) ? "Yes" : "No" : "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Other (Client) :{" "}
                            </span>
                            <span className="rst-details fw-bold">
                              {(data.prOther != 2) ? (data.prOther == 1) ? "Yes" : "No" : "N/A"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {data.isApproved == 1 && <>
                      <div className="mb-3">
                        <div className="header-dig-view">Approval Required From</div>
                        <div className="p-2 main-restriction-div">
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Local Authority :{" "}
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.arLocal != 2) ? (data.arLocal == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Electrical Company :{" "}
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.arElectrical != 2) ? (data.arElectrical == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Client :{" "}
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.arClient != 2) ? (data.arClient == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Other :{" "}
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.arOther != 2) ? (data.arOther == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="header-dig-view">Services Drawing available for the following services:</div>
                        <div className="p-2 main-restriction-div">
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Data Cabling :
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.dataCablingDrawing != 2) ? (data.dataCablingDrawing == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Foul :
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.foulDrawing != 2) ? (data.foulDrawing == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Telecoms :
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.telecomsDrawing != 2) ? (data.telecomsDrawing == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Main Water :
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.mainWaterDrawing != 2) ? (data.mainWaterDrawing == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Gas :
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.gasDrawing != 2) ? (data.gasDrawing == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Electricity :
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.electricityDrawing != 2) ? (data.electricityDrawing == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Fibre Optic :
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.fibreOpticDrawing != 2) ? (data.fibreOpticDrawing == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Other :
                              </span>
                              <span className="rst-details fw-bold">
                                {data.otherDrawing ? data.otherDrawing : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                No Services Detected :
                              </span>
                              <span className="rst-details fw-bold">
                                {(data.noServiceDetected != 2) ? (data.noServiceDetected == 1) ? "Yes" : "No" : "N/A"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="header-dig-view">Ground Marking:</div>
                        <div className="p-2 main-restriction-div">
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Ground Marked By
                              </span>
                              <span className="rst-details fw-bold">
                                {data.groundMarkedBy ? data.groundMarkedBy : "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Ground Marked On
                              </span>
                              <span className="rst-details fw-bold">
                                {data.groundMarkedOn ? commonService.formatDate( data.groundMarkedOn, "Do MMM YYYY" ) : "N/A"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>}

                    <div className="mb-3" style={{ border: '1px solid #e2e6ea', borderRadius: '8px', overflow: "auto", maxHeight: "670px" }}>
                      <div className="header-dig-view position-sticky" style={{ top: 0 }}>Activity</div>
                      {activities.map(activity => (<div className="m-2">
                        <div className="p-2 signature-div-inner">
                          <div className="d-flex justify-content-between mb-2">
                            <span className="rst-title me-2">
                              {activity.action} :{" "}
                            </span>
                            <span className="rst-title me-2 text-secondary">
                              {activity.datetime}
                            </span>
                          </div>
                          {activity.comment ?
                            <p>{activity.comment}</p>
                            : null}
                          {activity.signature ? <img
                            onError={(e) => {
                              e.target.src = DefaultImage;
                            }}
                            className="obj-fit-cover"
                            height="250"
                            width="250"
                            src={
                              activity.signature
                                ? process.env.REACT_APP_API_BASEURL +
                                activity.signature
                                : DefaultImage
                            }
                          ></img> : null}
                        </div>
                      </div>))}
                    </div>
                  </div>
                </div>
              </div>

              {data.isApproved == 1 && <>
                <div className="row">
                  <div className="col-12">
                    <div className="header-dig-view"> Marked-Up Drawings</div>
                    <div className="d-flex">
                      {data.markedDrawings && data.markedDrawings.split(",").map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                        {commonService.outputImageTile(imgPreviewUrl)}
                      </div>))}
                    </div>
                  </div>
                </div>
              </>}

              <div className="row">
                <div className="col-12">
                  <div className="header-dig-view"> Uploaded Files</div>
                  <div className="d-flex">
                    {data.files && data.files.split(",").map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                      {commonService.outputImageTile(imgPreviewUrl)}
                    </div>))}
                  </div>
                </div>
              </div>

              {fieldData.approved && <>
                <div className="adddig_fourth_container dig-approval-form-wrapper" id="dig-approval-form">
                  <div className="header-dig-view">For approval following details must be filled in:</div>
                  <div className="p-2">

                    {/* Permits Required has been removed from here, it is now in AddDig */}
                    {false && <>
                      <div className="adddig_fourth_container">
                        <div className="general_details">Permits Required</div>

                        <div className="inspected_radio_btn">
                          <div>
                            <FormControl>
                              <FormLabel class="inspected_label">
                                Hot Works
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="prHotworks"
                                onChange={handleChange}
                              >
                                <FormControlLabel checked={fieldData.prHotworks == 1} value={1} control={<Radio />} label="Yes" />
                                <FormControlLabel checked={fieldData.prHotworks == 0} value={0} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div>
                            <FormControl>
                              <FormLabel class="inspected_label">
                                Confined Space
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="prConfined"
                                onChange={handleChange}
                              >
                                <FormControlLabel checked={fieldData.prConfined == 1} value={1} control={<Radio />} label="Yes" />
                                <FormControlLabel checked={fieldData.prConfined == 0} value={0} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div>
                            <FormControl>
                              <FormLabel class="inspected_label">
                                Live Electrical
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="prElectrical"
                                onChange={handleChange}
                              >
                                <FormControlLabel checked={fieldData.prElectrical == 1} value={1} control={<Radio />} label="Yes" />
                                <FormControlLabel checked={fieldData.prElectrical == 0} value={0} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div>
                            <FormControl>
                              <FormLabel class="inspected_label">
                                Other (Client)
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="prOther"
                                onChange={handleChange}
                              >
                                <FormControlLabel checked={fieldData.prOther == 1} value={1} control={<Radio />} label="Yes" />
                                <FormControlLabel checked={fieldData.prOther == 0} value={0} control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </>}

                    {/* Approval Required */}
                    <div className="adddig_fourth_container">
                      <div className="general_details">Approval Required From</div>

                      <div className="inspected_radio_btn">
                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              Local Authority
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="arLocal"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.arLocal == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.arLocal == 0} value={0} control={<Radio />} label="No" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              Electrical Company
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="arElectrical"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.arElectrical == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.arElectrical == 0} value={0} control={<Radio />} label="No" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              Client
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="arClient"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.arClient == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.arClient == 0} value={0} control={<Radio />} label="No" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              Other
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="arOther"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.arOther == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.arOther == 0} value={0} control={<Radio />} label="No" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    {/* Services Available */}
                    <div className="adddig_fourth_container">
                      <div className="general_details">Services Drawing available for the following services:</div>

                      <div className="inspected_radio_btn">
                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              Data Cabling
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="dataCablingDrawing"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.dataCablingDrawing == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.dataCablingDrawing == 0} value={0} control={<Radio />} label="No" />
                              <FormControlLabel checked={fieldData.dataCablingDrawing == 2} value={2} control={<Radio />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </div>

                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              Foul
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="foulDrawing"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.foulDrawing == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.foulDrawing == 0} value={0} control={<Radio />} label="No" />
                              <FormControlLabel checked={fieldData.foulDrawing == 2} value={2} control={<Radio />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </div>

                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              Telecoms
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="telecomsDrawing"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.telecomsDrawing == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.telecomsDrawing == 0} value={0} control={<Radio />} label="No" />
                              <FormControlLabel checked={fieldData.telecomsDrawing == 2} value={2} control={<Radio />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        
                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              Main Water
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="mainWaterDrawing"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.mainWaterDrawing == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.mainWaterDrawing == 0} value={0} control={<Radio />} label="No" />
                              <FormControlLabel checked={fieldData.mainWaterDrawing == 2} value={2} control={<Radio />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        
                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              Gas
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="gasDrawing"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.gasDrawing == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.gasDrawing == 0} value={0} control={<Radio />} label="No" />
                              <FormControlLabel checked={fieldData.gasDrawing == 2} value={2} control={<Radio />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        
                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              Electricity
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="electricityDrawing"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.electricityDrawing == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.electricityDrawing == 0} value={0} control={<Radio />} label="No" />
                              <FormControlLabel checked={fieldData.electricityDrawing == 2} value={2} control={<Radio />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        
                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              Fibre Optic
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="fibreOpticDrawing"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.fibreOpticDrawing == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.fibreOpticDrawing == 0} value={0} control={<Radio />} label="No" />
                              <FormControlLabel checked={fieldData.fibreOpticDrawing == 2} value={2} control={<Radio />} label="N/A" />
                            </RadioGroup>
                          </FormControl>
                        </div>

                        <div className="col-md-12 location_dig p-2">
                          <label for="">
                            Other
                          </label>
                          <div className="input_icons">
                            <div className="input_field">
                              <input
                                type="text"
                                name="otherDrawing"
                                value={fieldData.otherDrawing}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <FormControl>
                            <FormLabel class="inspected_label">
                              No Services Detected
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="noServiceDetected"
                              onChange={handleChange}
                            >
                              <FormControlLabel checked={fieldData.noServiceDetected == 1} value={1} control={<Radio />} label="Yes" />
                              <FormControlLabel checked={fieldData.noServiceDetected == 0} value={0} control={<Radio />} label="No" />
                            </RadioGroup>
                          </FormControl>
                        </div>

                      </div>
                    </div>

                    <div className="adddig_fourth_container">
                      <div className="general_details">Ground Marking</div>
                      <div className="p-2">
                        
                        <div className="d-flex">
                          <div className="col-6 p-2 location_ladder">
                            <label for="">
                              <div className="require-label">
                                Ground Marked By <span className="text-danger">*</span>
                              </div>
                            </label>
                            <div className="input_icons">
                              <div className="input_field">
                                <input
                                  value={fieldData.groundMarkedBy}
                                  type="text"
                                  maxLength="100"
                                  onChange={handleChange}
                                  name="groundMarkedBy"
                                />
                              </div>
                            </div>
                          </div>
                          
                          <div className="col-6 p-2 location_ladder">
                            <label for="">
                              <div className="require-label">
                                Ground Marked On <span className="text-danger">*</span>
                              </div>
                            </label>
                            <div className="input_icons">
                              <div className="input_field">
                                <input
                                  value={fieldData.groundMarkedOn}
                                  type="date"
                                  onChange={handleChange}
                                  name="groundMarkedOn"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="adddig_fourth_container">
                      <div className="general_details">Marked-Up Drawing</div>

                      <div className="p-2">
                        <div className="popup-warning-notice"><p>You must attach a copy of a Marked-Up Drawing showing the area of the dig and the location of all identified services in the location of the dig as part of this permit:</p></div>

                        <div className="d-flex">
                          {!!files.length && files.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                            <img
                              className="obj_fit_img obj-fit-cover rounded"
                              src={imgPreviewUrl}
                              alt=""
                              height="150"
                              width="150"
                            />
                          </div>))}
                        </div>
                        <div className="select_files">
                          <input
                            name="files"
                            type="file"
                            multiple
                            id="upload"
                            hidden
                            onChange={handleChange}
                          />
                          <label for="upload">
                            <img src={fileIcon} alt="Icon" />
                            Select files...
                          </label>
                        </div>
                        <div className="select_all_files">
                          Select all files you wish to upload in one go and NOT individually.
                        </div>
                        <div className="hold_down">
                          Hold down CTRL (CMD on Mac) to select multiple files.
                        </div>
                      </div>

                    </div>

                    <div className="adddig_fourth_container">
                      <div className="general_details">Signature</div>
                      <div className="p-2">
                        <div className="sign_image">
                          <SignatureCanvas
                            ref={signatureRef}
                            canvasProps={{ width: 263, height: 270 }}
                          />
                          <span
                            className="cancel-custom cursor-pointer"
                            onClick={handleClearSignature}
                          >
                            <i
                              className="clear_btn  text-danger fa fa-times-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="sign_save_reset_btn">
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            handleSubmit()
                          }}
                        >
                          Approve Now
                        </button>
                        
                        <button
                          className="btn btn-outline-success ms-1"
                          onClick={() => {
                            setFieldData({ ...fieldData, approved: false });
                            setApprovalScrolled(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                  </div>
                </div>
                  

              </>}
              
              {data.isApproved == 1 && <>
                <div className="row mt-3">
                  <div className="col-md-4 mt-3">
                    <PDFDownloadLink
                      document={<MyDoc />}
                      className="mt-2 btn btn-primary pdf-download-btn"
                      fileName={"dig-permit-" + id + ".pdf"}
                    >
                      {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download PDF"
                      }
                    </PDFDownloadLink>
                  </div>
                </div>
              </>}

            </div>

          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
