import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { commonService } from "../common/common.service";
import fileIcon from "../../images/openfolderwhite.svg";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";

const AddCourse = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany, userPermissions } = useAppState()
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    id: null,
    siteId: null,
    departmentId: null,
    companyId: companyId,
    contractorId: null,
    addedBy: companyId,
    course_name: null,
    days_to_retrain: null,
    receivedBy: null,
    comments: null,
    departmentIds: []
  };

  const [fieldData, setFieldData] = useState(fieldValues);
  const [error, setError] = useState({ ...fieldValues });
  const [siteUsers, setSiteUsers] = useState([]);
  const [contractorData, setContractorData] = useState([]);
  const [notificationEmail, setNotificationEmail] = useState('');
  
  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          // Check if the user is admin
          if ( res.data[0].role !== 'superadmin' && res.data[0].role !== 'admin' && ! userPermissions.includes('courses-create') ) {
            history.push('/');
          }

          setUserID(userCompanyId);
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
            contractorId: ( user.role.includes("admin") ? fieldData.contractorId : res.data[0].contractorId )
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchCourse = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/courses/details`,
        {
          courseId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0], departmentIds: res.data[0].departmentIds.split(',').map(Number) });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchCourse()
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {

    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        let options = res.data.filter(
          (site, index, arr) =>
            res.data.findIndex((item) => item.id == site.id) == index
        );
        
        // insert item at first position
        options = [{
          id: -1,
          name: user.companyName,
        }, ...options];

        if ( ! id ) {
          setFieldData({
            ...fieldData,
            otherCompany: user.companyName
          });
        }

        setContractorData(
          options
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setFieldData({
      ...fieldData,
      [name]: value,
    });

    if ( name == 'contractorId' ) {
      // get email address from first match in filter
      setNotificationEmail( contractorData.find(v => v.id == value).email );
    }
    if (name == "siteId") {
      getSiteUsers(value)
    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;

      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Company is required.";
        }
        break;
      
      case "course_name":
        if (!value) {
          message = stateObj[name] = "Course Name is required.";
        }
        break;
      
      case "departmentIds":
        if (!value) {
          message = stateObj[name] = "Department is required.";
        }
        break;

      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = () => {
    let errorShow = {
      course_name: fieldData.course_name,
    };

    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.course_name != null
      
    ) {
      
      const form = new FormData();

      form.append("companyId", companyId);
      form.append("addedByInCompany", user.id);
      
      form.append("course_name", fieldData.course_name);
      form.append("days_to_retrain", fieldData.days_to_retrain ? fieldData.days_to_retrain : 0);
      // form.append("departmentIds", fieldData.departmentIds.join(','));
      form.append("departmentIds", '');
      
      setLoading(true);
      
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/courses/update/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/courses/add`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        
        history.push('/courses/list');

        setFieldData(fieldData);
      })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    }
  };

  const departmentOptions = allDepartment.map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentIds",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">
        <div className="addaccess_first_container">
          <div className="general_details">Details</div>

          <div className="d-flex flex-wrap">
            
            <div className="col-md-6">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Course Name <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="course_name"
                      {...(id ? {
                        value: fieldData.course_name,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.course_name && (
                  <span className="err">{error.course_name}</span>
                )}
              </div>
            </div>
            
            <div className="col-md-6">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Days to Retrain
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="number"
                      name="days_to_retrain"
                      {...(id ? {
                        value: fieldData.days_to_retrain,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            
            {false && <>
              <div className="col-md-12">
                <div className="w-100 p-2">
                  <label for="">
                    <div className="require-label">
                      Department(s) <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="w-100">
                      { (!id || fieldData.departmentIds.length > 0) && <Select
                        onChange={(e) => {
                          let value = [];
                          if (e ) {
                            value = e.map((v) => v.value);
                          }

                          setFieldData({
                            ...fieldData,
                            departmentIds: value
                          });
                        }}
                        isMulti
                        placeholder="Select Departments"
                        options={departmentOptions}
                        {...(id ? {
                          value: departmentOptions.filter((v) => fieldData.departmentIds.includes(v.value)),
                        } : {})}
                      /> }
                    </div>
                  </div>
                  {error.siteId && <span className="err">{error.siteId}</span>}
                </div>
              </div>
            </>}

          </div>

        </div>

        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Update Data" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn" onClick={() => history.push("/courses/list/")}>cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddCourse;
