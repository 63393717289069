import React from 'react'
import Header from '../Header/Header'
import { useState } from 'react'
import Cookies from 'universal-cookie';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axiosInstance from '../../helpers/request';
import { useAppState } from '../../state';
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Delete from '../../images/circle-cross.svg';
import TableLoading from "../common/TableLoading";
import statisticsService from '../../services/statistics.service';
import { onError } from '../../libs/errorLib';

function ViewDepartment({ title }) {
    console.log("ViewDepartment");

    const { user } = useAppState()

    const { id } = useParams()
    const userToken = localStorage.getItem("cs_token");
    const history = useHistory()
    const location = useLocation()
    const isEdit = location.pathname.includes("view-department")

    const [data, setData] = useState({
        name: "",
        description: ""
    })

    const [loading, setLoading] = useState(false)
    const [companyUsers, setCompanyUsers] = useState([])
    const [departmentUsers, setDepartmentUsers] = useState([])


    const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([])
    const [departmentAdminUsers, setDepartmentAdminUsers] = useState([])
    const [departmentNormalUsers, setDepartmentNormalUsers] = useState([])

    const [selectedAdminUser, setSelectedAdminUser] = useState('');
    const [selectedNormalUser, setSelectedNormalUser] = useState('');
    const [pageNumber, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)

    const [pageNumber2, setPage2] = useState(1)
    const [pageSize2, setPageSize2] = useState(10)
    const [total2, setTotal2] = useState(0)
    const [roles, setRoles] = useState([])
    const [stats, setStats] = useState(null);
    // const [uniqueRoles, setUniqueRoles] = useState([])

    // const [companyId, setUserID] = useState("")
    // const [newUser, setUser] = useState(null)

    useEffect(() => {
        if (id) {
            userDetail(id);
            getDepartmentStats(id);
        }
    }, [])

    const getDepartmentStats = async () => {
        try {
            const response = await statisticsService.getStatistics({departmentId: id});
            if (response) {
              setStats(response);
            }
          } catch (e) {
            onError(e);
          }
    }

    const userDetail = async (departmentId) => {
        try {
            const userDetailsResponse = await axiosInstance.post(`/userdetails`);
            const userDetailsResponseData = userDetailsResponse.data;

            if (userDetailsResponseData) {
                const companyId = userDetailsResponseData[0].added_by ? userDetailsResponseData[0].added_by : userDetailsResponseData[0].id;
                if (companyId) {
                    axiosInstance
                    .post(`${process.env.REACT_APP_API_BASEURL}/all-roles`, {
                        companyId: companyId,
                        status: ""
                    })
                    .then((res) => {
                        if (res.data.length > 0) {
                            setRoles(res.data);
                            getCompanyUsers(companyId, departmentId,res.data);
                            }
                        })
                        .catch((err) => {
                            console.log("errr", err);
                        });
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const getCompanyUsers = async (companyId, departmentId,roles) => {
        try {
            const allUsersResponse = await axiosInstance.post(`/all-users`, {
                companyId: companyId,
                status: ""
            });

            const allUsersResponseData = allUsersResponse.data;

            if (allUsersResponseData) {
                const formattedUsers = allUsersResponseData.map(user => {
                    return {
                        label: `${user.fullName} - ${user.contractorName? user.contractorName : user.companyName} - ${user.role?.split(",").map(v => roles.filter(r => r.id == v).length ? roles.filter(r => r.id == v)[0].name : v)}`,
                        id: user.id
                    }
                })
                console.log("Company-Users", formattedUsers);
                setCompanyUsers(formattedUsers);
                getDepartment(departmentId)
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const getDepartment = async (departmentId) => {
        try {
            const departmentResponse = await axiosInstance.get(`/department/${departmentId}`);
            const departmentResponseData = departmentResponse.data;

            if (departmentResponseData) {
                console.log("departmentData",departmentResponseData[0])
                setData(departmentResponseData[0]);
                getDepartmentUsers(departmentId)
            }

        } catch (error) {
            console.log("error", error);
        }
    }

    const getDepartmentUsers = async (departmentId) => {
        setLoading(true)
        try {
            const departmentUserResponse = await axiosInstance.get(`/department-users/${departmentId}`);
            const departmentUserResponseData = departmentUserResponse.data;
            setLoading(false)
            if (departmentUserResponseData) {
                console.log("departmentUsersData", departmentUserResponseData);
                setDepartmentUsers(departmentUserResponseData);
            }
        } catch (error) {
            setLoading(false)
            console.log("error", error);
        }

        // .then((res) => {
        //     if (res.data && res.data.length) {
        //         let departmentUsersData = res.data;
        //         setDepartmentUsers(departmentUsersData);
        //         console.log("departmentUsersData", departmentUsersData);

        //         const adminUsers = departmentUsersData.filter(user => user.type == "admin");
        //         console.log("adminUsers", adminUsers);
        //         setDepartmentAdminUsers(adminUsers)

        //         const normalUsers = departmentUsersData.filter(user => user.type == "user");
        //         console.log("normalUsers", normalUsers);
        //         setDepartmentNormalUsers(normalUsers)

        //         console.log("companyUsers", companyUsers);

        //         let filteredCompanyUsers = companyUsers.filter(cu => !departmentUsersData.filter(du => cu.id == du.userid).length);
        //         console.log("filteredCompanyUsers", filteredCompanyUsers);
        //         setSelectedCompanyUsers(filteredCompanyUsers)
        //     } else {
        //         console.log("companyUsers-all", companyUsers);
        //         setSelectedCompanyUsers(companyUsers)
        //     }
        // }).catch((err) => {
        //     console.log("errr", err);
        // });
    }

    useEffect(() => {
        if (companyUsers.length && departmentUsers.length) {
            const adminUsers = departmentUsers.filter(user => user.type == "admin");
            console.log("adminUsers", adminUsers);
            setDepartmentAdminUsers(adminUsers)
            setTotal(adminUsers.length)

            const normalUsers = departmentUsers.filter(user => user.type == "user");
            console.log("normalUsers", normalUsers);
            setDepartmentNormalUsers(normalUsers)
            setTotal2(normalUsers.length)

            console.log("companyUsers", companyUsers);

            let filteredCompanyUsers = companyUsers.filter(cu => !departmentUsers.filter(du => cu.id == du.userid).length);
            console.log("filteredCompanyUsers", filteredCompanyUsers);
            setSelectedCompanyUsers(filteredCompanyUsers)
        } else if (companyUsers.length) {
            console.log("companyUsers-all", companyUsers);
            setSelectedCompanyUsers(companyUsers)
        }

    }, [companyUsers, departmentUsers])

    const addDepartmentUser = (type) => {
        let newUser = {
            departmentid: id,
            type
        };

        if (type == "admin" && selectedAdminUser) {
            newUser.userid = selectedAdminUser.id

            submitDepartmentUser(newUser)
        } else if (type == "user" && selectedNormalUser) {
            newUser.userid = selectedNormalUser.id
            submitDepartmentUser(newUser)
        } else {
            toast.error("Please select user")
        }
    }

    const resetUser = () => {
        console.log("resetUser");
        setSelectedAdminUser('');
        setSelectedNormalUser('');
        setDepartmentAdminUsers([]);
        setDepartmentNormalUsers([]);
    }

    const submitDepartmentUser = (newUser) => {
        console.log("newUser",newUser);
        setLoading(true)
        axiosInstance.post(`/add-department-user`, newUser, {
            headers: {
                Authorization: `Bearer ${userToken}`,
            }
        })
            .then(async (response) => {
                resetUser();
                setLoading(false)
                if (response.status === 200) {
                    getDepartmentUsers(id)
                    toast.success("Successfully Updated department")
                } else {
                    throw new Error(response?.error);
                }

            }).catch(err => {
                console.log(err)
                setLoading(false)
                toast.error("Failed to Update department")
            });
    }

    const handleDelete = async (uId, name) => {
        const result = await Swal.fire({
            title: `Are you sure you want to remove ${name} from this department`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .post(process.env.REACT_APP_API_BASEURL + "/department-user/" + uId, {
                    userId: uId,
                    departmentId: id
                })
                .then(function (response) {
                    resetUser();
                    getDepartmentUsers(id);
                    toast.success("User deleted successfully");
                })
                .catch(function (error) {
                    toast.error(error?.response?.data?.message || "Unable to delete user");
                });
        }
    };

    const handleArchive = async()=>{
        const result = await Swal.fire({
            title: `Are you sure you want to archive this department`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, archive it!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .post(process.env.REACT_APP_API_BASEURL + "/archive-department/",{
                    id
                }, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    }
                })
                .then(function (response) {
                    resetUser();
                    getDepartmentUsers(id);
                    toast.success("Depratment Archived successfully");
                })
                .catch(function (error) {
                    toast.error(error?.response?.data?.message || "Unable to archive department");
                });
        }
    }

    return (
        <div class="middle_page">
            <Header title={title} steps={[{ name: "Home", path: "/" }, { name: "Department List", path: "/departments" }, { name: "View Department" }]} />
            <div class="main_container">
                {/* <p class="legal">(In Contemplation of Legal Proceedings)</p> */}
                <div className='d-flex w-100 view_user_content justify-content-between flex-wrap' id="grid-view">
                    <div>
                        <div class="accident mt-0">
                            <div class="accident_text">
                                <h5>Details</h5>
                            </div>
                        </div>
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <th scope="row" style={{ width: "30%" }}>Name:</th>
                                    <td>{data?.name}</td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: "30%" }}>Site:</th>
                                    <td>{data?.siteName}</td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: "30%" }}>Description:</th>
                                    <td>{data?.description}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='w-100 d-flex justify-content-between px-2'>
                            <button className='view_site_button text-white submit' onClick={() => {
                                history.push("/edit-department/" + id)
                            }}>Edit</button>
                            {((user.role=="admin")||(user.role=="superadmin"))&&(data.archived!="1")&&<button className='view_site_button text-dark submit' style={{ background: "#e1eefa" }} onClick={handleArchive}>Archive</button>}
                        </div>
                        <div class="signature">
                            <div class="sign_btns">
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="accident mt-0">
                            <div class="accident_text">
                                <h5>Statistics</h5>
                            </div>
                        </div>
                        <table class="table table-striped d-flex">
                            <tbody className='statistic-table'>
                                <tr>
                                    <th scope="row" width="75%">Users:</th>
                                    <td width='5%'>{departmentUsers.length}</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">Accident Notifications:</th>
                                    <td width='5%'>{stats?.accidentNotifications ? stats?.accidentNotifications : 0}</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">NCR CAR Reports:</th>
                                    <td width='5%'>0</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">Safety Desciplinary Logs:</th>
                                    <td width='5%'>{stats?.safetyDisciplinaryLogs ? stats?.safetyDisciplinaryLogs : 0 }</td>
                                </tr>
                            </tbody>
                            <tbody className='statistic-table'>
                                <tr>
                                    <th scope="row" width="75%">Accident Reports:</th>
                                    <td width='5%'>{stats?.accidentreports ? stats?.accidentreports : 0}</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">PPE Issue:</th>
                                    <td width='5%'>{stats?.ppeIssueReports ? stats?.ppeIssueReports : stats?.ppeIssueReports}</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">Safety Observations:</th>
                                    <td width='5%'>{stats?.safetyobservations ? stats?.safetyobservations : 0}</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">Safety Checklists:</th>
                                    <td width='5%'>{stats?.safetychecklists ? stats?.safetychecklists : 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <div class="accident mt-0">
                            <div class="accident_text">
                                <h5>Department Admins</h5>
                            </div>
                        </div>
                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div className='form_inner p-2'>
                                    {/* <label for="">Users</label> */}
                                    <div id="wrapper" style={{ maxHeight: "600px" }}>
                                        <table class="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="5%">View <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Full Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Email <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    {/* <th scope="col" width="8%">Company Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th> */}
                                                    {/* <th scope="col" width="8%">Role <img src={require("../../images/up-down-arrow.png")} alt="" /></th> */}
                                                    <th scope="col" width="2%">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? <TableLoading colSpan={4} />
                                                    : departmentAdminUsers.filter((v, i) => (((pageNumber - 1) * pageSize) <= i) && (pageNumber * pageSize) > i).map(dau => (
                                                    <tr>
                                                        <th scope="row" className='cursor-pointer' onClick={() => {
                                                            history.push("/view-user/" + dau.id)
                                                        }}>{dau.id}</th>
                                                        <td>{dau.fullName}</td>
                                                        <td>{dau.email}</td>
                                                        {/* <td>{u.companyName}</td> */}
                                                        {/* <td>{u.role?.split(",").map(v => roles.filter(r => r.id == v).length ? roles.filter(r => r.id == v)[0].name : v)}</td> */}
                                                        
                                                        
                                                        <td>
                                                        <img src={Delete} alt="" onClick={() => { handleDelete(dau.fduId, dau.fullName)}}/>
                                                        </td>
                                                    </tr>))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pagination-content mt-3">
                                        <div className="page-entries">
                                            <nav aria-label="...">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <a className="page-link">Show </a>
                                                    </li>
                                                    <li className="page-item">
                                                        {/* <a className="page-link" href="#">1 &nbsp; <img src="../../images/down-arrow.png" alt=""/></a> */}
                                                        <select className='page-link' style={{
                                                            borderRadius: 0,
                                                            padding: "6px 12px",
                                                        }} value={pageSize} onChange={e => {
                                                            setPageSize(e.target.value)
                                                        }}>
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                        </select>

                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Entries </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <p>Showing {((pageNumber - 1) * pageSize) + 1} to {pageNumber * pageSize} from {total} Entries</p>
                                        </div>
                                        <nav aria-label="...">
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <a className="page-link" onClick={e => {
                                                        e.preventDefault()
                                                        if (pageNumber > 1) {
                                                            setPage(p => p - 1)
                                                        }
                                                    }}>Previous</a>
                                                </li>
                                                {Array.from({ length: Math.ceil(total / pageSize) }).map((v, i) => (<li onClick={()=>setPage(i+1)} className={`page-item ${pageNumber == (i + 1) ? "active" : ""}`} aria-current="page">
                                                    <a className="page-link" href="#">{i + 1}</a>
                                                </li>))}
                                                <li className="page-item">
                                                    <a className="page-link" href="#" onClick={e => {
                                                        e.preventDefault()
                                                        if ((total / pageSize) > pageNumber) {
                                                            setPage(p => p + 1)
                                                        }
                                                    }}>Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='viewBoxWrapper'>
                            <label >Add Department Admin</label>
                            <Autocomplete
                                value={selectedAdminUser}
                                onChange={(event, newValue) => {
                                    console.log("NEW-Val", newValue);
                                    setSelectedAdminUser(newValue);
                                }}
                                name="siteAdmin"
                                disablePortal
                                id="combo-box-demo"
                                options={selectedCompanyUsers}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Select Admin User" />}
                            />
                            <button disabled={!selectedAdminUser} className='view_site_button text-white submit' onClick={!selectedAdminUser ? () => { } : () => { addDepartmentUser('admin') }}>
                                Add
                            </button>
                        </div>
                    </div>

                    <div>
                        <div class="accident mt-0">
                            <div class="accident_text">
                                <h5>Department Users</h5>
                            </div>
                        </div>
                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div className='form_inner p-2'>
                                    {/* <label for="">Users</label> */}
                                    <div id="wrapper" style={{ maxHeight: "600px" }}>
                                        <table class="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="5%">View <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Full Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Email <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    {/* <th scope="col" width="8%">Company Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Role <img src={require("../../images/up-down-arrow.png")} alt="" /></th> */}
                                                    <th scope="col" width="2%">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? <TableLoading colSpan={4} />
                                                    : departmentNormalUsers.filter((v, i) => (((pageNumber2 - 1) * pageSize2) <= i) && (pageNumber2 * pageSize2) > i).map(du => (
                                                    <tr>
                                                        <th scope="row" className='cursor-pointer' onClick={() => {
                                                            history.push("/view-user/" + du.id)
                                                        }}>{du.id}</th>
                                                        <td>{du.fullName}</td>
                                                        <td>{du.email}</td>
                                                        {/* <td>{d.companyName}</td>
                                                        <td>{d.role?.split(",").map(v => roles.filter(r => r.id == v).length ? roles.filter(r => r.id == v)[0].name : v)}</td> */}
                                                        {/* {user.role.includes("admin") && <td><img src={require("../../images/table-cross.png")} alt="" onClick={() => {
                                                            handleDelete(d.id, d.fullName)
                                                        }} /></td>} */}
                                                        <td>
                                                         

                                                            <img src={Delete} alt="" onClick={() => { handleDelete(du.fduId, du.fullName) }}/>

                                                            
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pagination-content mt-3">
                                        <div className="page-entries">
                                            <nav aria-label="...">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <a className="page-link">Show </a>
                                                    </li>
                                                    <li className="page-item">
                                                        {/* <a className="page-link" href="#">1 &nbsp; <img src="../../images/down-arrow.png" alt=""/></a> */}
                                                        <select className='page-link' style={{
                                                            borderRadius: 0,
                                                            padding: "6px 12px",
                                                        }} value={pageSize2} onChange={e => {
                                                            setPageSize2(e.target.value)
                                                        }}>
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                        </select>

                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Entries </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <p>Showing {((pageNumber2 - 1) * pageSize2) + 1} to {pageNumber2 * pageSize2} from {total2} Entries</p>
                                        </div>
                                        <nav aria-label="...">
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <a className="page-link" onClick={e => {
                                                        e.preventDefault()
                                                        if (pageNumber2 > 1) {
                                                            setPage2(p => p - 1)
                                                        }
                                                    }}>Previous</a>
                                                </li>
                                                {Array.from({ length: Math.ceil(total2 / pageSize2) }).map((v, i) => (<li onClick={()=>setPage2(i+1)} className={`page-item ${pageNumber2 == (i + 1) ? "active" : ""}`} aria-current="page">
                                                    <a className="page-link" href="#">{i + 1}</a>
                                                </li>))}
                                                <li className="page-item">
                                                    <a className="page-link" href="#" onClick={e => {
                                                        e.preventDefault()
                                                        if ((total2 / pageSize2) > pageNumber2) {
                                                            setPage2(p => p + 1)
                                                        }
                                                    }}>Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                {/* <div class="form_inner p-2">
                                        <label for="">Add User</label>
                                        <div class="input_icons">
                                            <div class="input_field">
                                                <select name="role" value={newUser} onChange={e => setUser(e.target.value)} className="w-100" >
                                                    <option value="">Select User</option>
                                                    {users.filter(v => !departmentUsers.map(v => v.id).includes(v.id)).map(u => (
                                                        <option value={u.id}>{u.fullName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                        <div className='viewBoxWrapper'>
                            <label >Add User</label>
                            <Autocomplete
                                value={selectedNormalUser}
                                onChange={(event, newValue) => {
                                    console.log("NEW-Val", newValue);
                                    setSelectedNormalUser(newValue);
                                }}
                                name="siteUser"
                                disablePortal
                                id="combo-box-demo"
                                options={selectedCompanyUsers}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Select User" />}
                            />
                            <button disabled={!selectedNormalUser} className='view_site_button text-white submit' onClick={!selectedNormalUser ? () => { } : () => { addDepartmentUser('user') }}>
                                Add
                            </button>
                        </div>
                        <div class="signature">
                            <div class="sign_btns">
                                <div>
                                    {/* <button class="submit" disabled={loading && !data?.id} onClick={handleSubmit}>Submit</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewDepartment