import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { GoogleOAuthProvider } from "@moeindana/google-oauth";
import "./styles/bootstrap.css"
import "./styles/main.css"
import App from './App';
import Login from './components/login/Login';
import PasswordReset from './components/verify/PasswordReset';
import PasswordCreate from './components/verify/PasswordCreate';
import { Stripe } from './components/Stripe/Stripe';
import Home from "./components/home/Home";
import Contact from './components/contact/Contact';
import AppStateProvider, { useAppState } from './state';
import SignUp from './components/signup/SignUp'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function Main() {
  const { isLoggedIn, user } = useAppState();
  console.log('isLoggedIn KJ' ,isLoggedIn)
  console.log('user && !user.subscription_id && !user.trial' ,user )
  if (isLoggedIn) {
    if (user && !user.subscription_id && !user.trial) return <Stripe />;
    else
      return <App />;
  }
  return <>
    <ToastContainer />
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route path="/registered">
        <Login />
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>
      <Route path="/passwordreset">
        <PasswordReset />
      </Route>
      <Route path="/passwordcreate">
        <PasswordCreate />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/plans">
        <Stripe />
      </Route>
      <Route path="">
        <Login customMessage="You must be logged in to view this content." />
      </Route>
    </Switch>
  </>
    ;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId="709900591742-vi8jgeg2vggclq119lc8kvhpd2aseldp.apps.googleusercontent.com">
    <Router>
      <AppStateProvider>
        <Main />
      </AppStateProvider>
    </Router>
  </GoogleOAuthProvider>
);