import React from "react";
import { useParams } from "react-router-dom";
import "./safetyObservationView.css"
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import { commonService } from "../common/common.service";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import { useHistory } from "react-router-dom";
import fileIcon from "../../images/openfolderwhite.svg";
import { getBase64 } from "../common/imageHelper";
import {
  PDFDownloadLink,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import PDFRenderer from "../common/pdfRenderer/pdfRenderer";
import PDFFiles from "../common/pdfRenderer/files";
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from "sweetalert2";


export default function ViewSafetyObservations({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, user, userPermissions } = useAppState();
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [siteUsers, setSiteUsers] = useState([]);
  const [activities, setActivities] = useState([]);
  const [popup, setPopup] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [reassignPopup, setReassignPopup] = useState(true);
  const [reassignData, setReassignData] = useState({});

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    approvedComment: "",
    deniedComment: "",
    signature: null,
    actionTaken: false,
    actionStatus: false,
    actionFiles: false,
  });

  const [files, setFiles] = useState([]);

  const statusOptions = [
    { value: 'Open', label: 'Open', name: 'actionStatus', },
    { value: 'In Progress', label: 'In Progress', name: 'actionStatus', },
    { value: 'Closed', label: 'Closed', name: 'actionStatus', },
  ];

  const reassignStatuses = [
    { value: "Counter charge", label: "Counter charge", name: "actionStatus" },
    { value: "Open", label: "Open", name: "actionStatus" },
    { value: "Reassign", label: "Reassign", name: "actionStatus" },
    { value: "In Progress", label: "In Progress", name: "actionStatus" },
  ];

  const fetchSafetyObservationDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/details`,
        {
          safetyObservationId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data[0]);
        setLoading(false);
        getSiteUsers(res.data[0].siteId);
        getUserEmail(res.data[0].addedByInCompany);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
        console.log("errr", err);
      });
  };

  const fetchActivities = () => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations-activities/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        if (Array.isArray(res.data)) {
          setActivities(res.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true);
    try {
      const siteUsersResponse = await axiosInstance.post(
        `/site-users/${siteId}`
      );
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false);
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSafetyObservationDetails();
      fetchActivities();
    }
  }, [id]);

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file);
        });
      }
    });
  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      // let signature
      // if(!fieldData.denied){
      //   signature = await handleSaveVerifySign()
      // }
      handleSubmitAfterSave();
    } catch (error) {
      toast.error("Failed to save signature");
    }
  };

  const handleFileChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "newfiles") {
      Promise.all(Array.from(e.target.files).map((file) => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        actionFiles: files,
      });
    }
  };

  const handleSubmitAfterSave = () => {
    const form = new FormData();
    form.append("actionRequired", data.actionRequired)
    form.append("deniedComment", fieldData.deniedComment);
    form.append("approvedComment", fieldData.approvedComment);
    if (fieldData.approved) {
      form.append("approved", fieldData.approved);
    }
    if (fieldData.completed) {
      form.append("completed", fieldData.completed);
    }

    if (fieldData.actionTaken) {

      if ( fieldData.actionStatus == '' ) {
        toast.error('Please select action status');
        return false;
      }

      if ( fieldData.actionRootCause == '' ) {
        toast.error('Enter a root cause');
        return false;
      }

      form.append("actionTaken", fieldData.actionTaken);
      form.append("actionStatus", fieldData.actionStatus);
      form.append("actionComment", fieldData.actionComment);
      form.append("actionRootCause", fieldData.actionRootCause);

      Object.entries(fieldData?.actionFiles)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
    }

    // form.append("signature", signature);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);
    form.append("id", id);
    setLoading(true);
    axiosInstance
      .post(
        fieldData.resubmitted || fieldData.completed
          ? `${process.env.REACT_APP_API_BASEURL}/resubmit/safety-observation`
          : fieldData.actionTaken
          ? `${process.env.REACT_APP_API_BASEURL}/safety-observation/action-taken`
          : `${process.env.REACT_APP_API_BASEURL}/update/safety-observation`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setFieldData({
          deniedComment: "",
          approvedComment: "",
          approved: false,
          resubmitted: false,
          completed: false,
          denied: false,
          signature: null,
          actionComment: "",
          actionTaken: false,
          actionStatus: "",
          actionComment: "",
          actionRootCause: "",
        });

        setPopup(true);

        fetchSafetyObservationDetails();
        fetchActivities();
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  };

  const reassignToNewUser = async() => {
    const form = new FormData();
    if(id && reassignData.assignTo && reassignData.status && reassignData.comments) {
      form.append("id", id);
      form.append("assignTo", reassignData.assignTo);
      form.append("status", reassignData.status);
      form.append("comments", reassignData.comments);
      form.append("observationDate", data.observationDate);
      form.append("observationDetails", data.observationDetails);
      form.append("flow", reassignData.flow);
      
       siteUsers.filter((user) => {
        if (user.id == reassignData.assignTo) {
          form.append("assignToName", user.fullName);
          form.append("assignToEmail", user.fullName);
        }
      });
      axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/${id}/reassign`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setReassignData({});
        setReassignPopup(true);

        fetchSafetyObservationDetails();
        fetchActivities();
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
    }
  }

  const pdfStyles = StyleSheet.create({
    body: {
      padding: "20px 16px 30px",
      fontSize: "13px",
      fontFamily: "Helvetica",
      color: "#000",
    },
    logos: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },
    halfWidthRightAlign: {
      width: "50%",
      textAlign: "right",
    },
    halfWidthLeftAlign: {
      width: "50%",
      textAlign: "left",
    },
    detailsWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      fontSize: "10px",
      border: "1px solid #ddd",
      borderBottom: "none",
    },
    detailsCell3n1: {
      width: "33.3333333%",
      flexBasis: "33.3333333%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    detailsCell5in1: {
      width: "20%",
      flexBasis: "20%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    detailsCell: {
      width: "50%",
      flexBasis: "50%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    detailsCellFullWidth: {
      width: "100%",
      flexBasis: "100%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    }
  });

  const MyDoc = () => {
    const filesBlocks = [
      {
        name: "Uploaded Files",
        files: data.files,
      }
    ];

    if(data.actionFiles) {
      filesBlocks.push(
        {
          name: "Action Files",
          files: data.actionFiles,
        }
      );      
    }

    return (
      <PDFRenderer name={"Safety Observations #" + id} user={userDetails}>
        <View>
          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              General Details
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Site : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.siteName ? data.siteName : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Department : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.departmentName ? data.departmentName : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Contractor :</Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.contractorName
                      ? data.contractorName
                      : data.companyName}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Observation Date :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.createdAt
                      ? commonService.formatDate(
                          data.observationDate,
                          "Do MMM YYYY"
                        ) == "Invalid date"
                        ? data.observationDate
                        : commonService.formatDate(
                            data.observationDate,
                            "Do MMM YYYY"
                          )
                      : "-"}{" "}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Action Required :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.actionRequired ? data.actionRequired : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Submitted By :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.submittedBy ? data.submittedBy : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Assigned To :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.originalAssignTo
                      ? data.originalAssignedToName
                      : data.assignedToName
                      ? data.assignedToName
                      : "-"}
                  </Text>
                </Text>
              </View>

              {data && data.reassignComment && (
                <View style={pdfStyles.detailsCell}>
                  <Text>
                    <Text style={{ fontFamily: "Helvetica" }}>
                      Reassigned To :{" "}
                    </Text>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>
                      {data.originalAssignTo != "" ? data.assignedToName : "-"}
                    </Text>
                  </Text>
                </View>
              )}
            </View>
          </View>

          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Observation Details
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Details : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.observationDetails ? data.observationDetails : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Status : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.observationStatus
                      ? data.observationStatus
                      : data.isActionRequired != 1
                      ? "Closed"
                      : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Type : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.observationType ? data.observationType : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Estimated Completion Date :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.completionDate
                      ? commonService.formatDate(
                          data.completionDate,
                          "Do MMM YYYY"
                        )
                      : "-"}{" "}
                  </Text>
                </Text>
              </View>

              {data.actionTaken == 1 && (
                <>
                  <View style={pdfStyles.detailsCell}>
                    <Text>
                      <Text style={{ fontFamily: "Helvetica" }}>
                        Action Comment :{" "}
                      </Text>
                      <Text style={{ fontFamily: "Helvetica-Bold" }}>
                        {data.actionComment ? data.actionComment : "-"}
                      </Text>
                    </Text>
                  </View>

                  <View style={pdfStyles.detailsCell}>
                    <Text>
                      <Text style={{ fontFamily: "Helvetica" }}>
                        Action Root Cause :{" "}
                      </Text>
                      <Text style={{ fontFamily: "Helvetica-Bold" }}>
                        {data.actionRootCause ? data.actionRootCause : "-"}
                      </Text>
                    </Text>
                  </View>
                </>
              )}
            </View>
          </View>

          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Activity
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              {activities.map((activity) => (
                <View style={pdfStyles.detailsCellFullWidth}>
                  <Text>
                    <Text style={{ fontFamily: "Helvetica" }}>
                      {activity.action} :{" "}
                    </Text>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>
                      {activity.datetime}
                    </Text>
                  </Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    {" "}
                    {activity.comment ? <p>{activity.comment}</p> : null}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </View>

        {filesBlocks.map((block) => (
          <PDFFiles block={block} />
        ))}
      </PDFRenderer>
    );
  };

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/safety-observation/" + deleteId)
        .then(function (response) {
          toast.success("Safety Observation archived successfully");
          window.location = '/safety-observations';
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Safety Observation"
          );
        });
    }
  };
  
  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Safety Observations List", path: "/safety-observations/" },
          { name: "Safety Observation", path: `/safety-observations/${id}` },
        ]}
      />

      <CustomPopup
        trigger={!reassignPopup}
        setTrigger={() => {
          setReassignPopup(true);
          setReassignData({});
        }}
      >
        <div className="col-12 form_inner p-2">
          <div className="input_icons">
            <div className="input_field">
              <div className="w-100 p-2 text-left">
                <label htmlFor="">
                  <div className="require-label">
                    Assign To <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={(e) => {
                        setReassignData({
                          ...reassignData,
                          assignTo: e.value,
                        });
                      }}
                      placeholder="Select User"
                      options={siteUsers.filter((usr) => usr.id != data.assignTo).map((v) => ({
                        label:
                          v.fullName +
                          " - " +
                          (v.contractorName
                            ? v.contractorName
                            : v.companyName) +
                          " - " +
                          (v.roleName ? v.roleName : v.role),
                        value: `${v.userid}`,
                        name: "assignTo",
                      }))}
                      value={siteUsers
                        .filter((v) => `${v.userid}` == reassignData?.assignTo)
                        .map((v) => ({
                          label:
                            v.fullName +
                            " - " +
                            (v.contractorName
                              ? v.contractorName
                              : v.companyName) +
                            " - " +
                            (v.roleName ? v.roleName : v.role),
                          value: `${v.userid}`,
                          name: "assignTo",
                        }))}
                    />
                  </div>
                </div>
              </div>

              {reassignData.status && !["Counter charge", "Open"].includes(reassignData.status) && 
                <div className="w-100 p-2 text-left">
                  <label htmlFor="">
                    <div className="require-label">
                      Status <span className="text-danger">*</span>
                    </div>
                  </label>
                  
                    <div className="input_icons">
                    <div className="w-100">
                      <Select
                        placeholder="Select a status"
                        options={reassignStatuses}
                        value={reassignData.status}
                        onChange={(e) => {
                          setReassignData({
                            ...reassignData,
                            status: e.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
            }

              <div className="w-100 p-2 text-left">
                <label htmlFor="">
                  <div className="require-label">
                    Comments <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <textarea
                      name="comments"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      rows="5"
                      cols="100"
                      onChange={(e) => {
                        setReassignData({
                          ...reassignData,
                          comments: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>
              <div className="sign_save_reset_btn">
              <button
                className="btn btn-success"
                disabled={
                  !reassignData?.assignTo ||
                  !reassignData?.status ||
                  !reassignData?.comments
                }
                onClick={reassignToNewUser}
              >
                Reassign
              </button>
            </div>
            </div>
          </div>
        </div>
      </CustomPopup>

      <CustomPopup
        trigger={!popup}
        setTrigger={() => {
          setPopup(true);
          setFieldData({
            approved: false,
            resubmitted: false,
            completed: false,
            denied: false,
            actionTaken: false,
            approvedComment: "",
            deniedComment: "",
            actionComment: "",
            actionRootCause: "",
            actionStatus: "",
            signature: null,
          });
        }}
      >
        {false &&
          fieldData.actionTaken &&
          data.observationCategory == "Housekeeping" && (
            <>
              <div className="popup-warning-notice">
                <p>
                  Please Note:
                  <br />
                  If the area highlighted in the Observation is not cleaned to
                  an acceptable standard and closed out within the estimated
                  time and date given, you will be contra charged in your next
                  schedule of payments!
                </p>
              </div>
            </>
          )}
        {!fieldData.completed && (
          <div className="col-12 form_inner p-2">
            <label htmlFor="">
              {fieldData.resubmitted
                ? "Resubmit Comment"
                : fieldData.approved
                ? "Approval Comment"
                : fieldData.actionTaken
                ? "Action Taken"
                : "Reason for Denial"}{" "}
              <span className="text-danger">*</span>
            </label>
            <div className="input_icons">
              <div className="input_field">
                <textarea
                  name="approvedComment"
                  maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                  rows="5"
                  cols="100"
                  onChange={(e) => {
                    if (fieldData.resubmitted) {
                      setFieldData({
                        ...fieldData,
                        resubmitComment: e.target.value,
                      });
                    } else if (fieldData.approved) {
                      setFieldData({
                        ...fieldData,
                        approvedComment: e.target.value,
                      });
                    } else if (fieldData.actionTaken) {
                      setFieldData({
                        ...fieldData,
                        actionComment: e.target.value,
                      });
                    } else {
                      setFieldData({
                        ...fieldData,
                        deniedComment: e.target.value,
                      });
                    }
                  }}
                ></textarea>

                {fieldData.actionTaken && (
                  <>
                    <div className="w-100 p-2 location_ladder text-left">
                      <label htmlFor="">
                        <div className="require-label">
                          {" "}
                          Root Cause <span className="text-danger">*</span>
                        </div>
                      </label>
                      <div className="input_icons">
                        <div className="input_field">
                          <input
                            type="text"
                            name="actionRootCause"
                            placeholder="Enter a root Cause"
                            onChange={(e) => {
                              setFieldData({
                                ...fieldData,
                                actionRootCause: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-100 p-2 text-left">
                      <label htmlFor="">
                        <div className="require-label">
                          Status <span className="text-danger">*</span>
                        </div>
                      </label>
                      <div className="input_icons">
                        <div className="w-100">
                          <Select
                            placeholder="Select a status"
                            options={statusOptions}
                            onChange={(e) => {
                              setFieldData({
                                ...fieldData,
                                actionStatus: e.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex preview-upload-files">
                      {!!files.length &&
                        files.map((imgPreviewUrl) => (
                          <div
                            id="profile_pic_1_preview"
                            className="image-fixed m-2"
                          >
                            <img
                              className="obj_fit_img obj-fit-cover rounded"
                              src={imgPreviewUrl}
                              alt=""
                              height="150"
                              width="150"
                            />
                          </div>
                        ))}
                    </div>

                    <div className="select_files">
                      <input
                        name="newfiles"
                        type="file"
                        multiple
                        id="upload"
                        hidden
                        onChange={handleFileChange}
                      />
                      <label htmlFor="upload">
                        <img src={fileIcon} alt="Icon" />
                        Select files...
                      </label>
                    </div>
                    <div className="select_all_files">
                      Select all files you wish to upload in one go and NOT
                      individually.
                    </div>
                    <div className="hold_down">
                      Hold down CTRL (CMD on Mac) to select multiple files.
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            {...(fieldData.approved
              ? {
                  disabled: fieldData.approved
                    ? false
                    : !fieldData.deniedComment,
                }
              : null)}
            {...(fieldData.actionTaken
              ? {
                  disabled: !fieldData.actionComment,
                }
              : null)}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>

      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">

            {data.isActionRequired == 1 && data.observationCategory == 'Housekeeping' && <>
              <div className="row">
                <div className="col-12">
                  <div className="popup-warning-notice">
                    <p>Please Note:<br />If the area highlighted in the Observation is not cleaned to an acceptable standard and closed out within the estimated time and date given, you will be contra charged in your next schedule of payments!</p>
                  </div>
                </div>
              </div>
            </>}

              <div className="row">
                <div className="col-12 col-lg-5 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">General Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          {/* <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Approved : </div>
                            <div className="col-6 dd-access">
                              {data.isApproved == 1
                                ? "Yes"
                                : data.isApproved == 0
                                  ? "No"
                                  : "Pending"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Completed : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {(data.isCompleted && data.isCompleted == 1) ? "Yes" : "No"}
                            </div>
                          </div> */}
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Site : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.siteName ? data.siteName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Department : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.departmentName ? data.departmentName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Contractor : </div>
                            <div className="col-6 dd-access">
                              {data.contractorName
                                ? data.contractorName
                                : data.companyName}{" "}
                            </div>
                          </div>
                  
                  
                     

                     
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Observation Date :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {data.createdAt
                                ? commonService.formatDate(
                                    data.observationDate,
                                    "Do MMM YYYY"
                                  ) == "Invalid date"
                                  ? data.observationDate
                                  : commonService.formatDate(
                                      data.observationDate,
                                      "Do MMM YYYY"
                                    )
                                : "-"}{" "}
                            </div>
                          </div>

                          {data.isActionRequired == 1 && (
                            <div className="action-required-block">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-6 dd-access">
                                  Action Required :{" "}
                                </div>
                                <div className="col-6 dd-access">
                                  {data.actionRequired
                                    ? data.actionRequired
                                    : ""}
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Submitted By :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.submittedBy ? data.submittedBy : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Assigned To :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              { (data.originalAssignTo) ? data.originalAssignedToName : (data.assignedToName)? data.assignedToName : "-"}
                            </div>
                          </div>

                          {/* only show if reassigned */}
                          {data && data.reassignComment &&
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Reassigned To :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.originalAssignTo != '' ? data.assignedToName : "-"}
                            </div>
                          </div>
                          }

                          {!data.isApproved && (
                            <div className="mb-1 d-flex dl-access alert alert-danger">
                              <div className="col-6 dd-access">
                                Reason Denied :{" "}
                              </div>
                              <div className="col-6 dd-access">
                                {data.denialComment ? data.denialComment : "-"}{" "}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {false &&
                      siteUsers.filter(
                        (user) =>
                          user.type == "admin" && user.userid == userDetails.id
                      ).length &&
                      data.isApproved == 2 &&
                      data.isCompleted != 1 ? (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              setFieldData({
                                ...fieldData,
                                approved: true,
                              });
                              setPopup(false);
                            }}
                          >
                            Approve
                          </button>
                          <button
                            className="btn btn-danger ms-2"
                            onClick={() => {
                              setFieldData({
                                ...fieldData,
                                denied: true,
                              });
                              setPopup(false);
                            }}
                          >
                            Deny
                          </button>
                        </>
                      ) : null}

                      {false &&
                      data.isApproved == 0 &&
                      data.isCompleted != 1 ? (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              setFieldData({
                                ...fieldData,
                                resubmitted: true,
                              });
                              setPopup(false);
                            }}
                          >
                            Resubmit
                          </button>
                        </>
                      ) : null}

                      {userPermissions.includes('submitsafetyObservation') ? (
                        <>
                          <button
                            className="btn btn-outline-success ms-1 me-2"
                            onClick={() => {
                              history.push(
                                `/edit-safety-observation/${data.id}`
                              );
                            }}
                          >
                            Edit
                          </button>
                        </>
                      ) : null}

                      {false &&
                      data.isApproved == 1 &&
                      data.isCompleted != 1 ? (
                        <>
                          <button
                            className="btn btn-success me-2"
                            onClick={() => {
                              setFieldData({
                                ...fieldData,
                                completed: true,
                              });
                              setPopup(false);
                            }}
                          >
                            Complete
                          </button>
                        </>
                      ) : null}


                    {userPermissions.includes('submitsafetyObservation') && (
                      <div className="inline-dropdown">
                        <Dropdown>
                          <Dropdown.Toggle variant="outline-success" id="dropdown-basic"> Actions</Dropdown.Toggle>

                          <Dropdown.Menu>
                            {data && ["Open", "Closed", "Counter charge", "In Progress"].includes(data.observationStatus) && ( 
                                <Dropdown.Item onClick={() => {
                                  setReassignData({
                                    ...reassignData,
                                    status: "Reassign",
                                    flow: "Reassigned"
                                  });
                                  setReassignPopup(false);
                                }}>Reassign</Dropdown.Item>
                            )}
                            {data && ["Closed"].includes(data.observationStatus) && ( 
                              <Dropdown.Item onClick={() => {
                                setReassignData({
                                  ...reassignData,
                                  status: "Open",
                                  flow: "Reopened"
                                });
                                setReassignPopup(false);
                              }}>Reopen</Dropdown.Item>
                            )}
                            <Dropdown.Item onClick={() => {
                                      setReassignData({
                                        ...reassignData,
                                        status: "Counter charge",
                                        flow: "Counter charged"
                                      });
                                      setReassignPopup(false);
                                    }}>Counter Charge</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    </div>
                   )}

                      {id && userPermissions.includes('submitsafetyObservation') && data.isDeleted != 1 ? <>
                        <button className="btn btn-outline-danger ms-1 mt-2" onClick={() => handleArchive(id)}>
                          Archive
                        </button>
                      </> : ''}
                  
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-7 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">
                        Observation Details
                      </div>

                      <div className="p-2">
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Details : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.observationDetails
                              ? data.observationDetails
                              : "-"}
                          </div>
                        </div>

                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Status : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.observationStatus
                              ? data.observationStatus
                              : ( data.isActionRequired != 1 ? 'Closed' : '-' ) }
                          </div>
                        </div>

                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Type : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.observationType
                              ? data.observationType
                              : "-"}
                          </div>
                        </div>

                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Category : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.observationCategory
                              ? data.observationCategory
                              : "-"}
                          </div>
                        </div>

                        {data.isActionRequired == 1 && <>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Estimated Completion Date :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.completionDate
                                ? commonService.formatDate(
                                    data.completionDate,
                                    "Do MMM YYYY"
                                  )
                                : "-"}{" "}
                            </div>
                          </div>
                        </>}

                        {data.actionTaken == 1 && (
                          <>
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">
                                Action Comment :{" "}
                              </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.actionComment ? data.actionComment : "-"}
                              </div>
                            </div>
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">
                                Action Root Cause :{" "}
                              </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.actionRootCause
                                  ? data.actionRootCause
                                  : "-"}
                              </div>
                            </div>
                          </>
                        )}

                        {data.isActionRequired == 1 && (data.actionTaken != 1 || data.observationStatus != 'Closed' || data.actionStatus != 'Closed') && (
                          <>
                            <button
                              className="btn btn-danger"
                              style={{ height: "35px" }}
                              onClick={() => {
                                setFieldData({
                                  ...fieldData,
                                  actionTaken: true,
                                });
                                setPopup(false);
                              }}
                            >
                              Take Action
                            </button>
                          </>
                        )}
                      </div>
                    </div>

                    <div
                      className="mb-3"
                      style={{
                        border: "1px solid #e2e6ea",
                        borderRadius: "8px",
                        overflow: "auto",
                        maxHeight: "670px",
                      }}
                    >
                      <div
                        className="header-access-view position-sticky"
                        style={{ top: 0 }}
                      >
                        Activity
                      </div>
                      {activities.map((activity) => (
                        <div className="m-2">
                          <div className="p-2 signature-div-inner">
                            <div className="d-flex justify-content-between mb-2">
                              <span className="rst-title me-2">
                                {activity.action} :{" "}
                              </span>
                              <span className="rst-title me-2 text-secondary">
                                {activity.datetime}
                              </span>
                            </div>
                            {activity.comment ? (
                              <p>{activity.comment}</p>
                            ) : null}
                            {activity.signature ? (
                              <img
                                onError={(e) => {
                                  e.target.src = DefaultImage;
                                }}
                                className="obj-fit-cover"
                                height="250"
                                width="250"
                                src={
                                  activity.signature
                                    ? process.env.REACT_APP_API_BASEURL +
                                      activity.signature
                                    : DefaultImage
                                }
                              ></img>
                            ) : null}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="header-access-view"> Uploaded Files</div>
                  <div className="d-flex">
                    {data.files &&
                      data.files.split(",").map((imgPreviewUrl) => (
                        <div
                          id="profile_pic_1_preview"
                          className="image-fixed m-2"
                        >
                          {commonService.outputImageTile(imgPreviewUrl)}
                        </div>
                      ))}
                    {data.actionFiles &&
                      data.actionFiles.split(",").map((imgPreviewUrl) => (
                        <div
                          id="profile_pic_1_preview"
                          className="image-fixed m-2"
                        >
                          {commonService.outputImageTile(imgPreviewUrl)}
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <PDFDownloadLink
                    document={<MyDoc />}
                    className="mt-2 btn btn-primary pdf-download-btn"
                    fileName={"safety-observations-" + id + ".pdf"}
                  >
                    {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download PDF"
                    }
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
