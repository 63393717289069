import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import "./Riser.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import { FormHelperText } from "@mui/material";
import { commonService } from "../common/common.service";

const AddRiser = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany } = useAppState();
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    siteId: null,
    departmentId: null,
    contractorId: user.contractorId && !id ? user.contractorId : null,
    descriptionOfWorks: null,
    responsiblePerson: null,
    drawingSpecsCalc: null,
    safeAccess: null,
    edgeProtection: null,
    barriersPasserBy: null,
    grpInstalled: null,
    areasBarrieredOff: null,
    confirmedRiser: null,
    signageFitted: null,
    approvedSop: null,
    grpModified: null,
    openingAccordance: null,
    operatorSupplied: null,
    ppeRequired: null,
    specialRequirements: null,
    contactPhone: null,
    locationOfWorks: null,
    date: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
    startTime: (new Date()).toLocaleString('en-GB').substring(12, 17),
    finishTime: (new Date()).toLocaleString('en-GB').substring(12, 17),
    verifySignature: null,
    companyId: companyId,
    addedBy: companyId,
    files: "",
    resubmitComment: null
  };
  const [fieldData, setFieldData] = useState(fieldValues);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({ ...fieldValues, contractorId: null, date: null, startTime: null, finishTime: null });
  const [contractorData, setContractorData] = useState([]);
  const [siteUsers, setSiteUsers] = useState([])

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(
            userCompanyId
          );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchRiserDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/riser/permit/details`,
        {
          riserId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0], existingFiles: res.data[0].files });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        console.log("errr", err);
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchRiserDetails()
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {
    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else if ((name == "startTime") || (name == "finishTime")) {
      let val = value
      if (fieldData.date == (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")) {
        const localTime = (new Date()).toLocaleString('en-GB').substring(12, 17)
        if (parseInt(e.target.value.split(":")[0]) <= parseInt(localTime.split(":")[0])) {
          if (parseInt(e.target.value.split(":")[1]) <= parseInt(localTime.split(":")[1])) {
            val = localTime
            toast.error("Selecting a time in the past is not possible")
          }
        }
      }
      setFieldData({
        ...fieldData,
        [name]: val,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value)
      }
    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      case "addedBy":
        if (!value) {
          message = stateObj[name] = "Added by is required.";
        }
        break;
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;
      case "responsiblePerson":
        if (!value) {
          message = stateObj[name] = "Responsible person is required.";
        }
        break;
      case "descriptionOfWorks":
        if (!value) {
          message = stateObj[name] = "Description of Works is required.";
        }
        break;
      case "drawingSpecsCalc":
        if (!value) {
          message = stateObj[name] = "Drawing, specifications and calculations references is required";
        }
        break;
      case "safeAccess":
        if (!value) {
          message = stateObj[name] = "Is there safe access available? is required";
        }
        break;
      case "edgeProtection":
        if (!value) {
          message = stateObj[name] = "Is edge protection required? is required";
        }
        break;
      case "barriersPasserBy":
        if (!value) {
          message = stateObj[name] = "Are barriers to protect passers by required? is required";
        }
        break;
      case "grpInstalled":
        if (!value) {
          message = stateObj[name] = "Has GRP been installed? is required";
        }
        break;
      case "areasBarrieredOff":
        if (!value) {
          message = stateObj[name] = "Are all areas below the works barriered off/locked ? is required";
        }
        break;
      case "confirmedRiser":
        if (!value) {
          message = stateObj[name] = "Confirm Shaft/Riser is occupied below is required";
        }
        break;
      case "signageFitted":
        if (!value) {
          message = stateObj[name] = "Is signage fitter below and above to warn others of work? is required";
        }
        break;
      case "approvedSop":
        if (!value) {
          message = stateObj[name] = "Is there an approved SOP/RAMS for the task (attached to back)? is required";
        }
        break;
      case "grpModified":
        if (!value) {
          message = stateObj[name] = "Does GRP need to be modified? is required";
        }
        break;
      case "openingAccordance":
        if (!value) {
          message = stateObj[name] = "Is opening in accordance with initial model that the GRP was designed for? is required";
        }
        break;
      case "operatorSupplied":
        if (!value) {
          message = stateObj[name] = "Is operator supplied with required PPE? is required";
        }
        break;
      case "ppeRequired":
        if (!value) {
          message = stateObj[name] = "PPE Required field is required.";
        }
        break;
      case "contactPhone":
        if (!value) {
          message = stateObj[name] = "Contact Phone is required.";
        }
        break;
      case "locationOfWorks":
        if (!value) {
          message = stateObj[name] = "Location of works is required.";
        }
        break;
      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Contractor Id is required.";
        }
        break;
      case "date":
        if (!value) {
          message = stateObj[name] = "date is required.";
        }
        break;
      case "startTime":
        if (!value) {
          message = stateObj[name] = "Start time is required.";
        }
        break;
      case "finishTime":
        if (!value) {
          message = stateObj[name] = "Finish Time is required.";
        }
        break;
      case "verifySignature":
        if (!value) {
          message = stateObj[name] = "Upload signature.";
        }
        break;
      case "files":
        if (!value) {
          message = stateObj[name] = "You must select at least 1 file to upload.";
        }
        break;
      
      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      const [verifySignature] = await Promise.all([handleSaveVerifySign()])
      handleSubmitAfterSave(verifySignature)
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (verifySignature) => {
    let errorShow = {
      companyId: fieldData.companyId,
      addedBy: fieldData.addedBy,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      responsiblePerson: fieldData.responsiblePerson,
      descriptionOfWorks: fieldData.descriptionOfWorks,
      drawingSpecsCalc: fieldData.drawingSpecsCalc,
      safeAccess: fieldData.safeAccess,
      edgeProtection: fieldData.edgeProtection,
      barriersPasserBy: fieldData.barriersPasserBy,
      grpInstalled: fieldData.grpInstalled,
      areasBarrieredOff: fieldData.areasBarrieredOff,
      confirmedRiser: fieldData.confirmedRiser,
      signageFitted: fieldData.signageFitted,
      approvedSop: fieldData.approvedSop,
      grpModified: fieldData.grpModified,
      openingAccordance: fieldData.openingAccordance,
      operatorSupplied: fieldData.operatorSupplied,
      ppeRequired: fieldData.ppeRequired,
      contactPhone: fieldData.contactPhone,
      locationOfWorks: fieldData.locationOfWorks,
      contractorId: user.contractorId||user.role=="admin"?fieldData.contractorId:true,
      date: fieldData.date,
      startTime: fieldData.startTime,
      finishTime: fieldData.finishTime,
      verifySignature: verifySignature,
      files: (fieldData.drawingSpecsCalc == 1) ? files.length !== 0 : true,
    };
    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.companyId !== null &&
      fieldData.addedBy !== null &&
      fieldData.siteId !== null &&
      fieldData.departmentId !== null &&
      fieldData.responsiblePerson !== null &&
      fieldData.descriptionOfWorks !== null &&
      fieldData.drawingSpecsCalc !== null &&
      fieldData.safeAccess !== null &&
      fieldData.edgeProtection !== null &&
      fieldData.barriersPasserBy !== null &&
      fieldData.grpInstalled !== null &&
      fieldData.areasBarrieredOff !== null &&
      fieldData.confirmedRiser !== null &&
      fieldData.signageFitted !== null &&
      fieldData.approvedSop !== null &&
      fieldData.grpModified !== null &&
      fieldData.openingAccordance !== null &&
      fieldData.operatorSupplied !== null &&
      fieldData.ppeRequired !== null &&
      fieldData.contactPhone !== null &&
      fieldData.locationOfWorks !== null &&
      (user.contractorId||user.role=="admin"?fieldData.contractorId !== null:true) &&
      fieldData.date !== null &&
      fieldData.startTime !== null &&
      fieldData.finishTime !== null &&
      ((fieldData.drawingSpecsCalc == 1) ? files.length !== 0 : true) &&
      verifySignature !== null
    ) {
      const form = new FormData();
      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("riserPermits") : v.permits.includes("riserPermitsreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      form.append("departmentId", fieldData.departmentId == null ? null : fieldData.departmentId);
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      form.append("responsiblePerson", fieldData.responsiblePerson);
      form.append("descriptionOfWorks", fieldData.descriptionOfWorks);
      form.append("locationOfWorks", fieldData.locationOfWorks);
      form.append("drawingSpecsCalc", fieldData.drawingSpecsCalc);
      form.append("safeAccess", fieldData.safeAccess);
      form.append("edgeProtection", fieldData.edgeProtection);
      form.append("barriersPasserBy", fieldData.barriersPasserBy);
      form.append("grpInstalled", fieldData.grpInstalled);
      form.append("areasBarrieredOff", fieldData.areasBarrieredOff);
      form.append("confirmedRiser", fieldData.confirmedRiser);
      form.append("signageFitted", fieldData.signageFitted);
      form.append("approvedSop", fieldData.approvedSop);
      form.append("grpModified", fieldData.grpModified);
      form.append("openingAccordance", fieldData.openingAccordance);
      form.append("operatorSupplied", fieldData.operatorSupplied);
      form.append("ppeRequired", fieldData.ppeRequired);
      form.append("specialRequirements", fieldData.specialRequirements);
      form.append("contactPhone", fieldData.contactPhone);
      form.append("contractorId", fieldData.contractorId);
      form.append("date", fieldData.date);
      form.append("startTime", fieldData.startTime);
      form.append("finishTime", fieldData.finishTime);
      form.append("verifySignature", verifySignature);
      form.append("companyId", companyId);
      form.append("addedBy", companyId);
      form.append("addedByInCompany", user.id);
      form.append("approvedByName", user.fullName);
      if (id) {
        form.append("resubmitComment", fieldData.resubmitComment);
      }
      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }
      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
      setLoading(true);
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update-details/riser-permit/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/riser-permit`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/view-own/riser-permits')
        setFieldData(fieldData);
        handleClearVerifySign();
      })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const signatureVerifyRef = useRef();

  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRef.current.isEmpty()) {
        res(null)
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file)
        });
      }
    })
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }))

  const departmentOptions = allDepartment.filter(v => v.siteId == fieldData.siteId).map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentId",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">
        <p className="redText mb-1">This Permit is valid for one shift only. </p>
        <p className="redText">You must ensure areas below you works are closed to prevent others access below your works. </p>
        <div className="addriser_first_container">
          <div className="general_details">General Details</div>
          <div className="general_details_fields justify-content-start flex-wrap">
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Site <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Sites"
                    options={siteOptions}
                    {...(id ? {
                      value: siteOptions.filter(v => v.value == fieldData.siteId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.siteId && <span className="err">{error.siteId}</span>}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Department <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Department"
                    options={departmentOptions}
                    {...(id ? {
                      value: departmentOptions.filter(v => v.value == fieldData.departmentId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.departmentId && (
                <span className="err">{error.departmentId}</span>
              )}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Contractor <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  {user.role.includes("admin") ? <Select
                    onChange={handleChange}
                    placeholder="Select Contractor"
                    value={fieldData.contractorId ? contractorData.filter(v => v.id == fieldData.contractorId).map(d => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    })) : null}
                    options={contractorData.map((d) => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    }))}
                  /> : fieldData.contractorId && contractorData.filter(v => v.id == fieldData.contractorId).length ? contractorData.filter(v => v.id == fieldData.contractorId)[0].name : user.companyName}
                </div>
              </div>
              {error.contractorId && (
                <span className="err">{error.contractorId}</span>
              )}
            </div>
            <div className="col-3  p-2 location_riser">
              <label for="">
                <div className="require-label">
                  {" "}
                  Exact Location of works <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="locationOfWorks"
                    value={fieldData.locationOfWorks}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.locationOfWorks && (
                <span className="err">{error.locationOfWorks}</span>
              )}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Date <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.date} disabled={!!id} min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")} type="date" onChange={handleChange} name="date" />
                </div>
              </div>
              {error.date && <span className="err">{error.date}</span>}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Start Time <span className="text-danger">*</span>{" "}
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.startTime} disabled={!!id} type="time" pattern="hh:mm" onChange={handleChange} name="startTime" />
                </div>
              </div>
              {error.startTime && <span className="err">{error.startTime}</span>}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Finish Time <span className="text-danger">*</span>{" "}
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.finishTime} disabled={!!id} type="time" pattern="hh:mm" onChange={handleChange} name="finishTime" />
                </div>
              </div>
              {error.finishTime && <span className="err">{error.finishTime}</span>}
            </div>
            <div className="col-3  p-2 location_riser">
              <label for="">
                <div className="require-label">
                  {" "}
                  Responsible Person <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="responsiblePerson"
                    value={fieldData.responsiblePerson}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.responsiblePerson && (
                <span className="err">{error.responsiblePerson}</span>
              )}
            </div>
            <div className="col-3  p-2 location_riser">
              <label for="">
                <div className="require-label">
                  {" "}
                  Contact Phone <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="contactPhone"
                    value={fieldData.contactPhone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.contactPhone && (
                <span className="err">{error.contactPhone}</span>
              )}
            </div>
            <div className="col-3  p-2 location_riser"></div>
            {/* <p className="redText ms-2">Fire Watch : The person required to maintain a careful watch for during Hot works / Break Times and a minimum of 1 hour following the completion of the hot works.</p> */}
            <p className="redText ms-2 mt-3 mb-1">Responsible Person : This Person is charged with the safe execution of all aspects of this permit.</p>
          </div>
        </div>
        <div className="addriser_second_container">
          <div className="general_details">Work Details</div>
          <div>
            <div className="col-8 form_inner p-2">
              <label for="">
                Brief description of works <span className="text-danger">*</span>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="descriptionOfWorks"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="5"
                    cols="100"
                    value={fieldData.descriptionOfWorks}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              {error.descriptionOfWorks && (
                <span className="err">{error.descriptionOfWorks}</span>
              )}
            </div>
          </div>
          <div>
            <div className="col-8 location_riser p-2">
              <div className="inspected_radio_btn m-0">
                <div>
                  <FormControl>
                    <FormLabel class="inspected_label">
                      Drawing, specifications and calculations references
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="drawingSpecsCalc"
                      onChange={handleChange}
                    >
                      <FormControlLabel checked={fieldData.drawingSpecsCalc == 1} value={1} control={<Radio />} label="Yes" />
                      <FormControlLabel checked={fieldData.drawingSpecsCalc == 0} value={0} control={<Radio />} label="No" />
                      <FormControlLabel checked={fieldData.drawingSpecsCalc == 2} value={2} control={<Radio />} label="N/A" />
                    </RadioGroup>
                    <FormHelperText className="m-0">If 'Yes' is selected please upload the files below</FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="addriser_fourth_container">
          <div className="general_details">Hazards / Control Measures</div>

          <div className="inspected_radio_btn">
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Is there safe access available?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="safeAccess"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.safeAccess == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.safeAccess == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.safeAccess == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Is edge protection required?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="edgeProtection"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.edgeProtection == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.edgeProtection == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.edgeProtection == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Are barriers to protect passers by required?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="barriersPasserBy"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.barriersPasserBy == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.barriersPasserBy == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.barriersPasserBy == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Has GRP been installed?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="grpInstalled"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.grpInstalled == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.grpInstalled == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.grpInstalled == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Are all areas below the works barriered off/locked ?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="areasBarrieredOff"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.areasBarrieredOff == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.areasBarrieredOff == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.areasBarrieredOff == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Confirm Shaft/Riser is occupied below
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="confirmedRiser"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.confirmedRiser == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.confirmedRiser == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.confirmedRiser == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Is signage fitter below and above to warn others of work?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="signageFitted"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.signageFitted == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.signageFitted == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.signageFitted == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Is there an approved SOP/RAMS for the task (attached to back)?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="approvedSop"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.approvedSop == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.approvedSop == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.approvedSop == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Does GRP need to be modified?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="grpModified"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.grpModified == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.grpModified == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.grpModified == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Is opening in accordance with initial model that the GRP was designed for?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="openingAccordance"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.openingAccordance == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.openingAccordance == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.openingAccordance == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Is operator supplied with required PPE?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="operatorSupplied"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.operatorSupplied == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.operatorSupplied == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.operatorSupplied == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="d-flex">
            <div className="textArea">
              <label for="">
                <div className="require-label">
                  {" "}
                  List what PPE is required for task
                  <span className="text-danger"> *</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="ppeRequired"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="3"
                    cols="100"
                    onChange={handleChange}
                    value={fieldData.ppeRequired}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              {error.ppeRequired && <span className="err">{error.ppeRequired}</span>}
            </div>
            <div className="textArea">
              <label for="">
                <div className="require-label">
                  {" "}
                  Any other special requirements?
                  {/* <span className="text-danger"> *</span> */}
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="specialRequirements"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="3"
                    cols="100"
                    onChange={handleChange}
                    value={fieldData.specialRequirements}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              {error.specialRequirements && <span className="err">{error.specialRequirements}</span>}
            </div>
          </div>
        </div>
        <div className="addriser_fifth_container">
          <div className="general_details">Upload Files</div>
          <div className="d-flex">
            {!!files.length && files.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
              <img
                className="obj_fit_img obj-fit-cover rounded"
                src={imgPreviewUrl}
                alt=""
                height="150"
                width="150"
              />
            </div>))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
          </div>
        </div>
        <div className="addriser_sixth_container">
          <div className="general_details">Signature(s)</div>
          <div className="p-2">
            <p className="hold_down">
              I have read and understood my employers Risk Assessment and Method Statement and the requirements of this permit.
            </p>
            <div className="sign_image">
              <SignatureCanvas
                ref={signatureVerifyRef}
                canvasProps={{ width: 263, height: 270 }}
              />
              <span
                className="cancel-custom cursor-pointer"
                onClick={handleClearVerifySign}
              >
                <i
                  className="clear_btn  text-danger fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            {error.verifySignature && (
              <span className="err">{error.verifySignature}</span>
            )}
            {!!id && <div className="d-flex">
              <div className="textArea mx-0">
                <p className="mb-0 text-dark">
                  <div className="require-label">
                    {" "}
                    Resubmit Comment
                  </div>
                </p>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="resubmitComment"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      rows="3"
                      cols="100"
                      onChange={handleChange}
                      value={fieldData.resubmitComment}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>
            </div>}
            <p className="redText">
              <strong>Please Note:</strong> Work cannot be started until the permit is approved.
            </p>
          </div>
        </div>
        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Resubmit" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn">cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddRiser;
