import React, { useEffect } from 'react'
import Header from '../Header/Header'
import { useState } from 'react'
import axiosInstance from '../../helpers/request'
import { useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import Delete from '../../images/circle-cross.svg';
import Select from 'react-select'
import { permissions } from '../../helpers/permissions'
import { useHistory } from 'react-router-dom'
import { useAppState } from '../../state'
import { Autocomplete, TextField } from '@mui/material'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { commonService } from "../common/common.service";
import CustomPopup from "../Popup/CustomPopup";
import SiteService from '../../services/site.service'
import statisticsService from "../../services/statistics.service";
import { onError } from "../../libs/errorLib";



function ViewUser({ title }) {
    const cookies = new Cookies();
    const { user: userData,userPermissions } = useAppState()
    const userToken = localStorage.getItem("cs_token");
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        emailAddress: "",
        role: "",
    })
    const { id } = useParams()
    const history = useHistory()
    const [roles, setRoles] = useState([])
    const [loading, setLoading] = useState(false)
    const [companyId, setUserID] = useState("")
    const [contractors, setContractors] = useState([])
    const [sites, setSites] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [userDepartments, setUserDepartments] = useState([])
    const [userSites, setUserSites] = useState([])
    const [user, setUser] = useState("")
    const [selectedSite, setSelectedSite] = useState("")
    const [selectedDepartment, setSelectedDepartment] = useState("")
    const [emailPreference, setEmailPref] = useState(permissions.map(v => v.children).flat().map(v => ({ name: v.name, value: false })))
    const [selectedRole, setSelectedRole] = useState([])
    const [reassignPopup, setReassignPopup] = useState(true);
    const [reassignData, setReassignData] = useState({});
    const [siteUsers, setSiteUsers] = useState([])
    const [stats, setStats] = useState(null);

    const siteRoles = [
        {label: 'Admin', value: 'admin'},
        {label: 'User', value: 'user'}
    ];
    const [selectedDRole, setSelectedDRole] = useState([])
    const siteDRoles = [
        {label: 'Admin', value: 'admin'},
        {label: 'User', value: 'user'}
    ];
    useEffect(() => {
        userDetail()
        if (id) {
            getUser();
            getUserStats();
        }
    }, [])

    useEffect(() => {
        if (user && user.companyId) {
            getContractors(user.companyId)
            getSites(user.companyId)
        }
    }, [user])
    useEffect(() => {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-roles`, {
                companyId: companyId,
                status: ""
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setRoles(res.data);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }, [companyId])

    const getUserStats = async() => {
        try {
          const response = await statisticsService.getStatistics({userId: id});
          if (response) {
            setStats(response);
          }
        } catch (e) {
          onError(e);
        }
      }

    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setUser({ ...res.data[0], companyId: ((res.data[0].role !== "admin") && res.data[0].added_by) ? res.data[0].added_by : res.data[0].id });
                    setUserID(res.data[0].added_by ? res.data[0].added_by : res.data[0].id);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }


    function getUser() {
        axiosInstance
            .get(`${process.env.REACT_APP_API_BASEURL}/user/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.id) {
                    setData({
                        ...res.data,
                        contractor: res.data.contractorId ? res.data.contractorId : "",
                    });
                    if (!res.data.role.includes('admin')) {
                        axiosInstance.get(`${process.env.REACT_APP_API_BASEURL}/permissions/${res.data.role}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${userToken}`,
                                },
                            }
                        ).then((res1) => {
                            if (res1.data.length > 0) {
                                let allPermits = res1.data
                                    .map((v) => v.permissions)
                                    .join(",")
                                    .split(",");
                                console.log("allPermits", allPermits, res.data.emailPreference)
                                if (res.data.emailPreference) {
                                    setEmailPref(
                                        permissions
                                            .map((v) => v.children)
                                            .flat()
                                            .filter(v => allPermits.includes(v.name))
                                            .map(v => ({ name: v.name, value: res.data.emailPreference.split(",").includes(v.name) }))
                                    )
                                } else {
                                    setEmailPref(
                                        permissions
                                            .map((v) => v.children)
                                            .flat()
                                            .filter(v => allPermits.includes(v.name))
                                            .map(v => ({ name: v.name, value: true }))
                                    );
                                }
                            }
                        }).catch((err) => {
                            console.log("errr", err);
                        });
                    } else {
                        setEmailPref(
                            permissions
                                .map((v) => v.children)
                                .flat()
                                .map(v => ({ name: v.name, value: res.data.emailPreference ? res.data.emailPreference.split(",").includes(v.name) : true }))
                        )
                    }
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
        getUsersSites()
        getUsersDepartments()
    }

    const getUsersSites = () => {
        axiosInstance
            .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length) {
                    setUserSites(res.data)
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const getUsersDepartments = () => {
        axiosInstance
            .get(`${process.env.REACT_APP_API_BASEURL}/users-department/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length) {
                    setUserDepartments(res.data)
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const handlePasswordReset = async (id, name, email) => {
        const result = await Swal.fire({
            title: `Do you really want to send password reset for:`,
            text: `${name}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, send email!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .post(process.env.REACT_APP_API_BASEURL + "/verify-email/", {
                    email: email
                })
                .then(function (response) {
                    toast.success("Email sent successfully");
                })
                .catch(function (error) {
                    toast.error(error?.response?.data?.message || "Unable to send email user");
                });
        }
    };

    const getContractors = (companyId) => {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-contractors`, {
                companyId: companyId,
                status: 1
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setContractors(res.data);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const getSites = (companyId) => {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-sites`, {
                companyId: companyId,
                status: ""
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    let allSites = res.data.filter((v, i) => res.data.findIndex(v1 => v1.id == v.id) == i).map(v => ({ ...v, label: v.name }))
                    setSites(allSites);
                    getDepartments(companyId, allSites)
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const getDepartments = (companyId, allSites) => {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-departments`, {
                companyId: companyId,
                status: ""
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setDepartments(res.data.filter((v, i) => res.data.findIndex(v1 => v1.id == v.id) == i).map(v => ({ ...v, label: `${v.name} - ${allSites.filter(s => s.id == v.siteId)[0]?.label}` })));
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }


    const addSiteUser = () => {
        let newUser = {
            userid: id,
        };
        if (selectedRole?.value && selectedSite) {
            newUser.type = selectedRole.value;
            newUser.siteid = selectedSite.id
            submitSite(newUser)
        } else {
            toast.error("Please select site and role")
        }
    }

    const addDepartmentUser = () => {
        let newUser = {
            userid: id
        };
        if (selectedDRole?.value && selectedDepartment) {
            newUser.type = selectedDRole.value;
            newUser.departmentid = selectedDepartment.id
            submitDepartment(newUser)
        } else {
            toast.error("Please select department and role")
        }
    }


    const submitSite = (newUser) => {
        setLoading(true)
        axiosInstance.post(`/add-site-user`, newUser)
            .then(async (response) => {
                setLoading(false)
                setSelectedSite("");
                if (response.status === 200) {
                    getUsersSites();
                    toast.success("Successfully Added to Site")
                } else {
                    throw new Error(response?.error);
                }
            }).catch(err => {
                setLoading(false)
                console.log(err)
                toast.error("Failed to Add to Site")
            });
    }

    const submitDepartment = (newUser) => {
        setLoading(true)
        axiosInstance.post(`/add-department-user`, newUser, {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        }).then(async (response) => {
                setLoading(false)
                setSelectedDepartment("");
                if (response.status === 200) {
                    getUsersDepartments();
                    toast.success("Successfully Added to Department")
                } else {
                    throw new Error(response?.error);
                }
            }).catch(err => {
                setLoading(false)
                console.log(err)
                toast.error("Failed to Add to Department")
            });
    }

    const getReassignmentUser = async (uId, name) => {
        // show reassign popup
        if( ["string", "number"].includes(typeof uId)) {
            const siteUsers = await SiteService.getSiteUsers(uId);
            setSiteUsers(siteUsers.filter((item) => item.userid != id));
        }
        if(!reassignData.assignTo) {
            setReassignPopup(false);
        } else {
            setReassignPopup(true);
            handleDeleteSite(reassignData.siteId, reassignData.name);
        }
    }

    const handleDeleteSite = async (siteId, departmentName) => {

        setReassignData({
            ...reassignData,
            siteId,
            name: departmentName
        })

        if(reassignData.assignTo) {

            const result = await Swal.fire({
                title: `Are you sure you want to remove ${data.firstName} ${data.lastName} from ${departmentName}`,
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });

            if (result.isConfirmed) {
                axiosInstance
                    .post(process.env.REACT_APP_API_BASEURL + "/site-user/", {
                        siteId,
                        userId: id,
                        assignTo: reassignData.assignTo
                    })
                    .then(function (response) {
                        getUsersSites();
                        setReassignData({});
                        toast.success("User deleted user from site");
                    })
                    .catch(function (error) {
                        setReassignData({});
                        toast.error(error?.response?.data?.message || "Unable to delete user from site");
                    });
            }
            setReassignData({});
        } else {
            getReassignmentUser(siteId, departmentName);
        }
    };

    const handleDeleteDepartment = async (departmentId, departmentName) => {
        const result = await Swal.fire({
            title: `Are you sure you want to remove ${data.firstName} ${data.lastName} from ${departmentName}`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .delete(process.env.REACT_APP_API_BASEURL + "/department-user", {
                    data: {
                        departmentId,
                        userId: id
                    }
                })
                .then(function (response) {
                    getUsersDepartments();
                    toast.success("User deleted user from department");
                })
                .catch(function (error) {
                    toast.error(error?.response?.data?.message || "Unable to delete user from department");
                });
        }
    };


    return (
        <div class="middle_page">
            <CustomPopup
                trigger={!reassignPopup}
                setTrigger={() => {
                setReassignPopup(true);
                }}
            >
                <div className="col-12 form_inner p-2">
                    <div className="input_icons">
                        <div className="input_field">
                            <div className="w-100 p-2 text-left">
                                <label for="">
                                    <div className="require-label">
                                        Assign To <span className="text-danger">*</span>
                                    </div>
                                </label>
                                <div className="input_icons">
                                    <div className="w-100">
                                        <Select
                                        onChange={(e) => {
                                            setReassignData({
                                            ...reassignData,
                                            assignTo: e.value,
                                            });
                                        }}
                                        placeholder="Select User"
                                        options={siteUsers.map((v) => ({
                                            label:
                                            v.fullName +
                                            " - " +
                                            (v.contractorName
                                                ? v.contractorName
                                                : v.companyName) +
                                            " - " +
                                            (v.roleName ? v.roleName : v.role),
                                            value: `${v.userid}`,
                                            name: "assignTo",
                                        }))}
                                        value={siteUsers
                                            .filter((v) => `${v.userid}` == reassignData?.assignTo)
                                            .map((v) => ({
                                            label:
                                                v.fullName +
                                                " - " +
                                                (v.contractorName
                                                ? v.contractorName
                                                : v.companyName) +
                                                " - " +
                                                (v.roleName ? v.roleName : v.role),
                                            value: `${v.userid}`,
                                            name: "assignTo",
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sign_save_reset_btn">
                                <button
                                    className="btn btn-success"
                                    disabled={
                                    !reassignData?.assignTo
                                    }
                                    onClick={getReassignmentUser}
                                >
                                    Reassign
                                </button>
                            </div>

                            <div className="w-100 p-2 text-left">
                                <div className="caption">
                                    This user may have open action items, please choose another user to reassign these open items to
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomPopup>
            <Header title={title} steps={[{ name: "Home", path: "/" }, { name: "Users List", path: "/users" }, { name: "View User" }]} />
            <div class="main_container">
                <div>
                    <div class="accident">
                        <div class="accident_text">
                            <h5>View User</h5>
                        </div>
                    </div>
                    <form>
                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div class="form_inner p-2">
                                    <label for="">Full Name </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={data.fullName} name="firstName" disabled placeholder="Full Name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Company Name </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={(data.contractorId && (data.role != "admin")) ? contractors.filter(v => v.id == data.contractorId).map(v => v.name).join("") : data.companyName} name="lastName" disabled placeholder="Last Name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Email Address </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={data.email} name="email" disabled placeholder="Email Address" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Role </label>
                                    <div class="input_icons">
                                        <div class="w-100">
                                            <Select isMulti options={roles.map(d => ({ label: d.name, value: d.id }))} isDisabled={true} value={data.role == "admin" ? [{ label: "Admin", value: "admin" }] : roles.filter(v => data.role.includes(v.id)).map(v => ({ label: v.name, value: v.id }))} placeholder="Select Roles" onChange={v => {
                                                setData({
                                                    ...data,
                                                    role: v && v.length ? v : []
                                                })
                                            }} />
                                        </div>
                                    </div>
                                </div>

                                { userData.role.includes('admin') && id && ! loading ?
                                    <div className='form_inner p-2'>
                                        <button
                                            className="btn btn-primary"
                                            onClick={ (e) => {
                                                e.preventDefault();
                                                handlePasswordReset(data.id, data.fullName, data.email);
                                                }
                                            }>
                                                Send Password Reset Email
                                        </button>
                                    </div>
                                : ''}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="main_container mt-4">
                <div>
                    <div class="accident">
                        <div class="accident_text">
                            <h5>User's Sites</h5>
                        </div>
                    </div>
                    <div id="wrapper" style={{ maxHeight: "300px" }}>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col" width="5%">View</th>
                                    <th scope="col" width="8%">Name</th>
                                    <th scope="col" width="8%">Description</th>
                                    {userData.role.includes("admin") && <th scope="col" width="8%">Status</th>}
                                    {(userData.role.includes("admin") || userPermissions.includes('editusers')) && <th scope="col" width="8%">Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {userSites.map(d => (<tr>
                                    <th scope="row" className='cursor-pointer' style={{ color: '#005CB9' }} onClick={() => {
                                        history.push("/view-site/" + d.id)
                                    }}>{d.id}</th>
                                    <td>{d.name}</td>
                                    <td>{d.description}</td>
                                    {userData.role.includes("admin") && <td>  <div className="form-check form-switch">
                                        <input
                                            className="sub-permission-check form-check-input form-check-input-color d-block"
                                            style={{ width: "45px", height: "22px" }}
                                            type="checkbox"
                                            role={`switch-${d.id}`}
                                            id={`flexSwitchCheckChecked-${d.id}`}
                                            checked={d.is_active ? true : false}
                                            disabled />
                                    </div></td>}
                                    {(userData.role.includes("admin") || userPermissions.includes('editusers')) && <td>
                                        <img src={Delete} alt="" onClick={() => {
                                            handleDeleteSite(d.id, d.name)
                                        }} />
                                    </td>}
                                </tr>))}

                            </tbody>
                        </table>
                    </div>
                    <div >
                        <label >Add to Site</label>
                        <div className='row'>
                            <div className="col-md-6">
                                <Autocomplete
                                    value={selectedSite}
                                    onChange={(event, newValue) => {
                                        setSelectedSite(newValue);
                                    }}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={sites.filter(v => !userSites.map(v => v.id).includes(v.id))}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => <TextField {...params} label="Select Site" />}
                                />
                            </div>

                            <div className="col-md-6">
                                <Autocomplete
                                    value={selectedRole}
                                    onChange={(event, newValue) => {
                                        setSelectedRole(newValue);
                                    }}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={siteRoles}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => <TextField {...params} label="Select Role" />}
                                />
                            </div>
                        </div>
                        <button disabled={!selectedSite} className='view_site_button text-white submit mt-2' onClick={!selectedSite ? () => { } : () => addSiteUser()}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div class="main_container mt-4">
                <div>
                    <div class="accident">
                        <div class="accident_text">
                            <h5>User's Departments</h5>
                        </div>
                    </div>
                    <div id="wrapper" style={{ maxHeight: "300px" }}>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col" width="5%">View</th>
                                    <th scope="col" width="8%">Name</th>
                                    <th scope="col" width="8%">Description</th>
                                    {userData.role.includes("admin") && <th scope="col" width="8%">Status</th>}
                                    {(userData.role.includes("admin") || userPermissions.includes('editusers')) && <th scope="col" width="8%">Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {userDepartments.map(d => (<tr>
                                    <th scope="row" className='cursor-pointer' style={{ color: '#005CB9' }} onClick={() => {
                                        history.push("/view-department/" + d.id)
                                    }}>{d.id}</th>
                                    <td>{d.name}</td>
                                    <td>{d.description}</td>
                                    {userData.role.includes("admin") && <td>  <div className="form-check form-switch">
                                        <input
                                            className="sub-permission-check form-check-input form-check-input-color d-block"
                                            style={{ width: "45px", height: "22px" }}
                                            type="checkbox"
                                            role={`switch-${d.id}`}
                                            id={`flexSwitchCheckChecked-${d.id}`}
                                            checked={d.is_active ? true : false}
                                            disabled />
                                    </div></td>}
                                    {(userData.role.includes("admin") || userPermissions.includes('editusers')) && <td>
                                        <img src={Delete} alt="" onClick={() => {
                                            handleDeleteDepartment(d.id, d.name)
                                        }} />
                                    </td>}
                                </tr>))}

                            </tbody>
                        </table>
                    </div>
                    <div className=''>
                        <label>Add to Department</label>
                        <div className='row'>
                            <div className="col-md-6">
                                <Autocomplete
                                    value={selectedSite}
                                    onChange={(event, newValue) => {
                                        setSelectedDepartment(newValue);
                                    }}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={departments.filter(v => !userDepartments.map(v => v.id).includes(v.id))}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => <TextField {...params} label="Select Department" />}
                                />
                            </div>
                            <div className="col-md-6">
                                <Autocomplete
                                    value={selectedDRole}
                                    onChange={(event, newValue) => {
                                        setSelectedDRole(newValue);
                                    }}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={siteDRoles}
                                    sx={{ width: "100%" }}
                                    renderInput={(params) => <TextField {...params} label="Select Role" />}
                                />  
                            </div>
                        </div>
                        <button disabled={!selectedDepartment} className='view_site_button text-white submit mt-2' onClick={!selectedDepartment ? () => { } : () => addDepartmentUser()}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
            
            <div class="main_container mt-4">
                <div>
                    <div class="accident">
                        <div class="accident_text">
                            <h5>Email Preferences</h5>
                        </div>
                    </div>
                    <form>
                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div class="form_inner p-2">
                                    <div className='row w-100'>
                                        <div className='row mt-4'>
                                            <div className='col-12 mb-4'>
                                                <div className='d-flex justify-content-between'>
                                                    <label className='p-2' for=""> Permissions</label>
                                                </div>
                                            </div>

                                        </div>
                                        {permissions.map((v, i) => (
                                            <div className='row mt-4'>
                                                <h5 className='mb-4'>{v.name}</h5>
                                                {v.children.filter(v => emailPreference.map(v => v.name).includes(v.name)).map(v => (<div className='col-md-6 col-xl-4 col-sm-6  mb-4'>
                                                    <div className='d-flex justify-content-between'>
                                                        <label for={`flexSwitchCheckChecked-${i}`}>{v.label} </label>
                                                        <div class="form-check form-switch">
                                                            <input
                                                                class="sub-permission-check form-check-input form-check-input-color d-block"
                                                                style={{ width: "50px" }}
                                                                type="checkbox"
                                                                role={`switch-${i}`}
                                                                id={`flexSwitchCheckChecked-${i}`}
                                                                disabled
                                                                checked={emailPreference.filter(p => p.name === v.name)[0].value}
                                                            // onChange={e => {
                                                            //     setEmailPref(d => (
                                                            //         d.map(p => {
                                                            //             if (p.name === v.name) {
                                                            //                 return { ...p, value: e.target.checked }
                                                            //             } else {
                                                            //                 return p
                                                            //             }
                                                            //         })
                                                            //     ))
                                                            // }} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>))}
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


            {userData.role.includes("admin") && 
                <div class="main_container mt-4">
                    <div>
                        <div class="accident">
                            <div class="accident_text">
                                <h5>Statistics</h5>
                            </div>
                        </div>
                    </div>
                    <div class="p-2">
                        <div class="main-details">
                            <div class="mb-1 d-flex dl-ladder">
                                <div class="col-6 dd-ladder">Last login : </div>
                                <div class="col-6 dd-ladder"> 
                                    {data && (
                                        <>
                                            {commonService.formatDate(data.lastLogin, "Do MMM YYYY  HH:mm:ss")}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div class="mb-1 d-flex dl-ladder">
                                <div class="col-6 dd-ladder">Safety Observations : </div>
                                <div class="col-6 dd-ladder">
                                    {data && (
                                        <>
                                        <span className='roundPill yellow-bg'> {data.observations} </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div class="mb-1 d-flex dl-ladder">
                                <div class="col-6 dd-ladder">Safety Checklists submitted: </div>
                                <div class="col-6 dd-ladder">
                                    <span className='roundPill blue-bg'> {stats?.safetyobservations ? stats.safetyobservations : 0} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ViewUser