import React from "react";
import { useAppState } from "../../state";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Tick from "../../images/tick.svg";
const cookies = new Cookies();

export const PaymentCard = ({ item }) => {
  const { user } = useAppState();
  const history = useHistory();
  let expireDate;
  const currentDate = new Date();
  let str2;
  let subscription_id;
  if (user?.trial?.split("-")) {[, str2] = user?.trial?.split("-")};
  if (user) subscription_id = user?.subscription_id;
  if (item.validity === "yearly") {
    expireDate = new Date(
      currentDate.setFullYear(currentDate.getFullYear() + 1)
    );
  } else {
    expireDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
  }
  const expiryTimestamp = Math.floor(expireDate.getTime() / 1000);
  const Submit = async (type) => {
    if (type !== "START TRIAL") {
      const data = await fetch(
        process.env.REACT_APP_API_BASEURL + "/stripeSession",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email: user?.email,
            priceId: item.priceId,
            trial: `${expiryTimestamp}-${item.priceId}`,
          }),
        }
      );
      const response = await data.json();
      if (response) {
        console.log(
          "window.location.href",
          (window.location.href = response.url)
        );
        // history.push(response.url);
      }
    } else {
      cookies.set(
        "cs_subscribe",
        JSON.stringify({
          trial: `${expiryTimestamp}-${item.priceId}`,
        })
      );

      localStorage.setItem(
        "cs_subscribe",
        JSON.stringify({
          trial: `${expiryTimestamp}-${item.priceId}`,
        })
      );

      
      history.push("/signup");
    }
  };
  return (
    <div className={`card ${str2 === item.priceId ? "selected-plan-card" : ""}`} style={str2 === item.priceId ? {
      borderWidth: "4px",
      borderTopWidth: "25px",
      width: "106%",
      height: "106%",
      transform: "translate(-3%,-3%)"
    } : {}}>
      <div className="card__header">
      <h2 className="card__heading">{item.name + (str2 === item.priceId ? "( Current Plan )" : "")}</h2>
        {/* <p style={{ backgroundColor: item?.discountColor }}>
          Save {item.discount}%
        </p> */}
      </div>
      <div className="card__body">
        <div className="card__body__price">
          <p className="card__body__price__heading">€{item.price}</p>
          <div className="">
            <p className="card__body__price__amount">
              .99<sup>*</sup>
            </p>
          
          </div>

          <p className="card__body__price__duration">
          {item.validity === "monthly" ? "/Month" : "/Annually"}
            </p>


        </div>
        <p className="card__body__discription__heading">{item.description}</p>
      </div>
    
      <div className="card__footer">
      {user && subscription_id === item.priceId ? (
          <button
            className="card__footer__button"
            onClick={() => Submit("UPGRADE")}
            style={{opacity:"0.5"}}
            disabled
            >
            Upgrade
          </button>
        ) : (
          <button
            className="card__footer__button"
            onClick={() => Submit(!user?.trial ? "START TRIAL" : "UPGRADE")}>
            {!user?.trial ? "START TRIAL" : "UPGRADE"}
          </button>
        )}
      </div>
      <p className="include__heading">Whats Includeed:</p>
      {/* <div className="include">
        <p className="include__heading">Include:</p>
        {item.includes.map((val) => {
          return (
            <li className="include_list_item">
              <CheckCircleIcon fontSize="10px" /> <span>{val}</span>
            </li>
          );
        })}
      </div> */}
      <div className="features">
        {/* <p className="features__heading">Features:</p> */}
        {item.features.map((val) => {
          return (
            <li className="features_list_item">
              <img src={Tick} className="tick" /> <span>{val}</span>
            </li>
          );
        })}
      </div>
    
    </div>
  );
};
