import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import ImgInput from "../../images/default/no_image.png";
import Table from "@mui/material/Table";
import Cookies from "universal-cookie";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import axiosInstance from "../../helpers/request";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import PageLoader from "../common/form/loaders/PageLoader";
import { monthlyPaymentArray } from "../../helpers/paymentArray";

function createData(name) {
  return { name };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const rows = [
  createData("Bennett Construction"),
  createData("Address"),
  createData("Phone Number"),
  createData("Website"),
];

const TabFirst = [
  createData("Name:"),
  createData("Cost:"),
  createData("Renewal Date:"),
];

const TabSecond = [createData("Space Allotted:"), createData("Space Used:")];

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function ComOverview({ title }) {
  const cookies = new Cookies();
  const history = useHistory();
  const userToken = localStorage.getItem("cs_token");

  const [value, setValue] = useState(0);
  const [fetchLoading, setFetchLoading] = useState(true);

  const [data, setData] = useState({});

  const subscribe = localStorage.getItem("cs_subscribe")
    ? JSON.parse(localStorage.getItem("cs_subscribe"))
    : null;
  console.log("subscribe", subscribe);
  let priceId = null;
  let paymentObject = {};

  if (subscribe) {
    let trial = subscribe.trial;
    if (trial.split("-").length) {
      if (typeof trial.split("-")[1] !== "undefined") {
        priceId = trial.split("-")[1];
        let paymentArray = monthlyPaymentArray.filter(
          (itm) => itm.priceId == priceId
        );
        paymentObject = paymentArray.length ? paymentArray[0] : {};
      }
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          console.log("res.data", res.data);
          setData({
            ...res.data[0],
          });
          setFetchLoading(false);
        } else {
          toast.error("No user found.");
          setFetchLoading(false);
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  return (
    <div class="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          { name: "Company Overview ", path: "/company/overview" },
        ]}
      />
      <div class="company_ovrviw">
        {!fetchLoading ? (
          Object.keys(data).length ? (
            <>
              {console.log("data121", data)}
              <div class="company_container">
                <img
                  class="company_image p-2"
                  src={
                    data.companyLogo
                      ? process.env.REACT_APP_API_BASEURL + data.companyLogo
                      : ImgInput
                  }
                />
                <div class="company_table">
                  <TableContainer elevation={0} component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        <TableRow sx={{ height: 50 }}>
                          <TableCell component="th" scope="row">
                            {data.companyName}
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                        <TableRow sx={{ height: 50 }}>
                          <TableCell component="th" scope="row">
                            Address
                          </TableCell>
                          <TableCell align="right">
                            {data.companyaddress ? data.companyaddress : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ height: 50 }}>
                          <TableCell component="th" scope="row">
                            Phone Number
                          </TableCell>
                          <TableCell align="right">
                            {data.companytelephonenumber
                              ? data.companytelephonenumber
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ height: 50 }}>
                          <TableCell component="th" scope="row">
                            Website
                          </TableCell>
                          <TableCell align="right">
                            {data.website ? data.website : "-"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Button
                    type="button"
                    onClick={() => {
                      history.push("/company/details");
                    }}
                    sx={{ width: "100%", marginTop: 2 }}
                    variant="contained"
                  >
                    Edit Details
                  </Button>
                </div>
              </div>
              <div class="company_container_tab">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="nav tabs example"
                >
                  <Tab label="Price Plan Details" {...a11yProps(0)} />
                  <Tab label="Disk Space Overview" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <TableRow sx={{ height: 50 }}>
                    <TableCell component="th" scope="row">
                      Name:
                    </TableCell>
                    <TableCell align="right">
                      {Object.keys(paymentObject).length
                        ? paymentObject.name
                          ? paymentObject.name
                          : "-"
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ height: 50 }}>
                    <TableCell component="th" scope="row">
                      Cost:
                    </TableCell>
                    <TableCell align="right">
                      {Object.keys(paymentObject).length
                        ? paymentObject.price
                          ? paymentObject.price
                          : "-"
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ height: 50 }}>
                    <TableCell component="th" scope="row">
                      Renewal Date:
                    </TableCell>
                    <TableCell align="right">-</TableCell>
                  </TableRow>

                  <Button
                    sx={{ backgroundColor: "green", marginTop: 2 }}
                    variant="contained"
                  >
                    Change Price Plan
                  </Button>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {TabSecond.map((row) => (
                    <TableRow key={row.name} sx={{ height: 50 }}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">-</TableCell>
                    </TableRow>
                  ))}
                </TabPanel>
              </div>{" "}
            </>
          ) : (
            "No data found"
          )
        ) : (
          <PageLoader />
        )}
      </div>
    </div>
  );
}

export default ComOverview;
