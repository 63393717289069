import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { commonService } from "../common/common.service";
import fileIcon from "../../images/openfolderwhite.svg";
import "./SafetyDisciplinaryLogs.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";

const AddSafetyDisciplinaryLog = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany } = useAppState()
  const history = useHistory();
  const location = useLocation()
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldValues = {
    siteId: null,
    departmentId: null,
    companyId: companyId,
    contractorId: null,
    addedBy: companyId,

    operative: companyId,
    warningType: null,
    isSuspended: null,
    suspendedDate: null,
    issueRaised: null,
  };

  const [fieldData, setFieldData] = useState(fieldValues);
  const [error, setError] = useState({ ...fieldValues });
  const [siteUsers, setSiteUsers] = useState([]);
  const [contractorData, setContractorData] = useState([]);
  
  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID( userCompanyId );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
            contractorId: ( user.role.includes("admin") ? fieldData.contractorId : res.data[0].contractorId )
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchSafetyDisciplinaryLogDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/safety-disciplinary-logs/details`,
        {
          safetyDisciplinaryLogId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0] });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchSafetyDisciplinaryLogDetails()
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {
    
    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setFieldData({
      ...fieldData,
      [name]: value,
    });
    if (name == "siteId") {
      getSiteUsers(value)
    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      
      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;

      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Company is required.";
        }
        break;
      
      case "operative":
        if (!value) {
          message = stateObj[name] = "Operative is required.";
        }
        break;
      
      case "warningType":
        if (!value) {
          message = stateObj[name] = "Warning is required.";
        }
        break;
      
      case "isSuspended":
        if (!value) {
          message = stateObj[name] = "Suspended is required.";
        }
        break;
      
      case "issueRaised":
        if (!value) {
          message = stateObj[name] = "Issue raised is required.";
        }
        break;
      
      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      handleSubmitAfterSave()
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = () => {
    let errorShow = {
      companyId: fieldData.companyId,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      
      operative: fieldData.operative,
      warningType: fieldData.warningType,
      isSuspended: fieldData.isSuspended,
      issueRaised: fieldData.issueRaised,
      
      contractorId: fieldData.contractorId,
    };

    if ( fieldData.isSuspended ) {
      errorShow.suspendedDate = fieldData.suspendedDate;
    } else {
      errorShow.isExpelled = fieldData.isExpelled;
    }

    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.companyId != null &&
      fieldData.siteId != null &&
      fieldData.operative != null &&
      fieldData.warningType != null &&
      fieldData.isSuspended != null &&
      fieldData.issueRaised != null &&
      fieldData.contractorId != null &&
      ( fieldData.suspendedDate != null || fieldData.isSuspended == 0 ) &&
      ( fieldData.isExpelled != null || fieldData.isSuspended == 1 )
    ) {
      
      const form = new FormData();

      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("safetyDisciplinaryLogs") : v.permits.includes("safetyDisciplinaryLogsreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      
      form.append("departmentId", fieldData.departmentId == null ? null : fieldData.departmentId);
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      form.append("companyId", companyId);
      form.append("addedByInCompany", user.id);
      form.append("contractorId", fieldData.contractorId);
      
      form.append("operative", fieldData.operative);
      form.append("warningType", fieldData.warningType);
      form.append("isSuspended", fieldData.isSuspended);
      form.append("suspendedDate", commonService.formatDate(
        fieldData.suspendedDate,
        "YYYY-MM-DD"
      ));
      
      form.append("isExpelled", typeof fieldData.isExpelled == 'undefined' ? '0' : fieldData.isExpelled );

      form.append("issueRaised", fieldData.issueRaised);
      
      
      setLoading(true);
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update/safety-disciplinary-log/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/safety-disciplinary-log`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })

      apiCall.then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        
        id ? history.push('/safety-disciplinary-logs/' + id) : history.push('/view-own/safety-disciplinary-logs');

        setFieldData(fieldData);
      })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }))

  const departmentOptions = allDepartment.filter(v => v.siteId == fieldData.siteId).map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentId",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
        ]}
      />
      <div className="main_container">
        <div className="addaccess_first_container">
          <div className="general_details">Details</div>

          <div className="d-flex flex-wrap">
            
            <div className="col-md-4">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Site <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Sites"
                      options={siteOptions}
                      {...(id ? {
                        value: siteOptions.filter(v => v.value == fieldData.siteId),
                        isDisabled: true
                      } : {})}
                    />
                  </div>
                </div>
                {error.siteId && <span className="err">{error.siteId}</span>}
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Department <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Department"
                      options={departmentOptions}
                      {...(id ? {
                        value: departmentOptions.filter(v => v.value == fieldData.departmentId),
                        isDisabled: true
                      } : {})}
                    />
                  </div>
                </div>
                {error.departmentId && (
                  <span className="err">{error.departmentId}</span>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2 location_ladder">
                <label for="">
                  <div className="require-label">
                    Operative <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      type="text"
                      name="operative"
                      {...(id ? {
                        value: fieldData.operative,
                      } : {})}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {error.operative && (
                  <span className="err">{error.operative}</span>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Company <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleChange}
                      placeholder="Select Contractor"
                      value={fieldData.contractorId ? contractorData.filter(v => v.id == fieldData.contractorId).map(d => ({
                        label: d.name,
                        value: d.id,
                        name: "contractorId",
                      })) : null}
                      options={contractorData.map((d) => ({
                        label: d.name,
                        value: d.id,
                        name: "contractorId",
                      }))}
                    />
                  </div>
                </div>
                {error.contractorId && (
                  <span className="err">{error.contractorId}</span>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="w-100 p-2">
                <FormControl>
                  <FormLabel class="inspected_label">
                    Warning <span className="text-danger">*</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="warningType"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.warningType == 1} value={1} control={<Radio />} label="Verbal" />
                    <FormControlLabel checked={fieldData.warningType == 2} value={2} control={<Radio />} label="Written" />
                  </RadioGroup>
                </FormControl>
              </div>

            </div>

            <div className="col-md-4">
              <div className="w-100 p-2">
                <FormControl>
                  <FormLabel class="inspected_label">
                    Suspended <span className="text-danger">*</span>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="isSuspended"
                    onChange={handleChange}
                  >
                    <FormControlLabel checked={fieldData.isSuspended == 1} value={1} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={fieldData.isSuspended == 0} value={0} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>

            </div>

            { (!fieldData.isSuspended == null || fieldData.isSuspended == 0) && <>
              <div className="col-md-4">
                <div className="w-100 p-2">
                  <FormControl>
                    <FormLabel class="inspected_label">
                      Expelled <span className="text-danger">*</span>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="isExpelled"
                      onChange={handleChange}
                    >
                      <FormControlLabel checked={fieldData.isExpelled == 1} value={1} control={<Radio />} label="Yes" />
                      <FormControlLabel checked={fieldData.isExpelled == 0} value={0} control={<Radio />} label="No" />
                    </RadioGroup>
                  </FormControl>
                </div>

              </div>
            </>}

            {(fieldData.isSuspended != 0 && fieldData.isSuspended == 1 ) && <>            
              <div className="col-md-4">
                <div className="w-100 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Suspended Until <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                      min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")}
                      type="date"
                      onChange={handleChange}
                      {...(id ? {
                        value: ( fieldData.suspendedDate ? new Date(fieldData.suspendedDate).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-") : ""),
                      } : {})}
                      name="suspendedDate" />
                    </div>
                  </div>
                  {error.suspendedDate && <span className="err">{error.suspendedDate}</span>}
                </div>
              </div>
            </>}

            <div className="col-md-8">
              <div className="form_inner p-2 w-100">
                <label for="">
                Issue Raised & Action Taken <span className="text-danger">*</span>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="issueRaised"
                      rows="5"
                      className="w-100"
                      cols="100"
                      value={fieldData.issueRaised}
                      onChange={handleChange}
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
                {error.issueRaised && (
                  <span className="err">{error.issueRaised}</span>
                )}
              </div>
            </div>

          </div>

        </div>

        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Update Data" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn" onClick={() => history.push("/safety-disciplinary-logs/")}>cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddSafetyDisciplinaryLog;
