import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import { commonService } from "../common/common.service";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import fileIcon from "../../images/openfolderwhite.svg";
import tickIcon from "../../images/green-tick.svg";
import riskIcon from "../../images/risk-icon.svg";
import { getBase64 } from "../common/imageHelper";
import Swal from "sweetalert2";
import riskAssessmentService from "../../services/riskAssessment.service";
import { onError } from "../../libs/errorLib";
import Delete from "../../images/circle-cross.svg";

import "./ViewRiskAssessments.css";

import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";

export default function ViewRiskAssessments({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, user, userPermissions, companyId, selectedCompany } = useAppState();
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    overallScore: 0,
    closedIssues: null,
    issues: null,
    carryOverIssues: null,
  });
  const [scoreData, setScoreData] = useState({
    overallScore: 0,
  });
  const [siteUsers, setSiteUsers] = useState([]);
  const [newIssuePopup, setPopup] = useState(true);
  const [closeIssuePopup, setClosePopup] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [housekeepingShown, setHouseKeepingShown] = useState(false);
  const [matrixPopup, setMatrixPopup] = useState(false);
  const [housekeepingPopup, setHousekeepingPopup] = useState("");

  const [completeAssessmentPopup, setCompleteAssessmentPopup] = useState(false);
  const [reassignPopup, setReassignPopup] = useState(true);
  const [reassignData, setReassignData] = useState({});
  const [hasHousekeeping, setHasHouseKeeping] = useState(false);

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    approvedComment: "",
    deniedComment: "",
    signature: null,
    actionTaken: false,
    actionStatus: false,
    actionFiles: false,
    closedIssues: null,
    issues: null,
    carryOverIssues: null,
  });

  const [newIssueData, setNewIssueData] = useState({
    departmentId: null,
    siteId: null,
    companyId: null,
    requiredAction: "",
    assignedTo: "",
    dateToCompleteBy: "",
    status: "",
    comment: "",
    riskAssessmentIssueId: "",
    revise: false,
    
    files: [],
  });

  const [closeIssueData, setCloseIssueData] = useState({
    issueId: 0,
    closedBy: "",
    dateClosed: "",
    ehs: "",
    closureDetails: "",
    files: [],
  });

  const reassignStatuses = [
    { value: "Counter charge", label: "Counter charge", name: "actionStatus" },
    { value: "Open", label: "Open", name: "actionStatus" },
    { value: "Reassign", label: "Reassign", name: "actionStatus" },
    { value: "In Progress", label: "In Progress", name: "actionStatus" },
  ];

  const riskOptions = [
    { value: 1, label: "Insignificant = no injuries / no damage" },
    { value: 2, label: "minor = first aid injury / minor damage" },
    { value: 3, label: "moderate = medical injury / <10k damage" },
    { value: 4, label: "Major = Hospital / <25K damage" },
    { value: 5, label: "Catasphroic = Fatality / <50k damage" },
  ];
  
  const revisedLikelihood = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];
  
  const controlsOptions = [
    {label: "Elimination of hazard/risk" },
    {label: "Substitution" },
    {label: "Housekeeping" },
    {label: "Isolation" },
    {label: "Environmental" },
    {label: "Ventilation" },
    {label: "Safety Awareness" },
    {label: "Training" },
    {label: "Supervision" },
    {label: "PPE" },
    {label: "No Controls" },
  ];

  const [files, setFiles] = useState([]);

  const fetchRiskAssessment = async (calculateScores) => {
    setLoading(true);

    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASEURL}/risk-assessment/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((resp) => {
        const response = resp.data;

        if (response) {
          setData(response[0]);
          getSiteUsers(response[0].siteId);
          getUserEmail(response[0].addedByInCompany);
  
          setNewIssueData({
            ...newIssueData,
            siteId: response[0].siteId,
            departmentId: response[0].departmentId,
            companyId: response[0].companyId,
  
            requiredAction: "",
            ehs: "",
            riskFactor: "",
            response: "",
            assignedTo: "",
            dateToCompleteBy: "",
            riskAssessmentIssueId: "",
            status: "",
            comment: "",
            revise: false,
            files: [],
          });
  
          setFiles([]); // Reset Files.

          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
      });
  };

  const getRiskFactorScoring = (riskFactor) => {
    return {
      "Good Practice": 5,
      "Low Risk/ Needs Improvement": 4,
      "Poor Practice": 3,
      "Breach of Company Procedure": 0,
      "Breach Of Regulation": 0,
      "Foreseeable Risk Of Injury": 0,
      "Near Miss": 0,
      "Dangerous Occurrences": 0,
      "ISO 45001": 0,
    };
  };

  const recalculateScores = () => {
    let overallScore = 100;
    let totalNeagatives = 0;
    let totalPositives = 0;
    let totalIssues = 0;
    let possibleScore = 0;
    let housekeepingFound = 0;

    overallScore = 0;

    const riskFactorScore = getRiskFactorScoring();

    // loop through open issues
    if (data?.issues?.length) {
      totalIssues += data.issues.length;
      data.issues.forEach((issue) => {

        if ( issue.ehs == 'Housekeeping' ) {
          housekeepingFound = true;
        }

        if (typeof riskFactorScore[issue.riskFactor] !== "undefined") {
          overallScore += riskFactorScore[issue.riskFactor];

          if (riskFactorScore[issue.riskFactor] < 0) {
            totalNeagatives += 1;
          } else {
            totalPositives += 1;
          }
        }
      });
    }

    if (data?.closedIssues?.length) {
      totalIssues += data.closedIssues.length;
      data.closedIssues.forEach((issue) => {

        if ( issue.ehs == 'Housekeeping' ) {
          housekeepingFound = true;
        }

        if (typeof riskFactorScore[issue.riskFactor] !== "undefined") {
          overallScore += riskFactorScore[issue.riskFactor];

          if (riskFactorScore[issue.riskFactor] < 0) {
            totalNeagatives += 1;
          } else {
            totalPositives += 1;
          }
        }
      });
    }

    if (data?.carryOverIssues?.length) {
      totalIssues += data.carryOverIssues.length;
      data.carryOverIssues.forEach((issue) => {

        if ( issue.ehs == 'Housekeeping' ) {
          housekeepingFound = true;
        }

        if (typeof riskFactorScore[issue.riskFactor] !== "undefined") {
          overallScore += riskFactorScore[issue.riskFactor];

          if (riskFactorScore[issue.riskFactor] < 0) {
            totalNeagatives += 1;
          } else {
            totalPositives += 1;
          }
        }
      });
    }

    if ( housekeepingFound ) {
      setHasHouseKeeping(true);
    }

    possibleScore = totalIssues * 5;

    if ( possibleScore == 0 ) {
      return; // Do nothing
    }

    overallScore = ( overallScore / possibleScore ) * 100;
    
    overallScore = overallScore.toFixed(2).replace(".00", "");

    if (overallScore > 100) {
      overallScore = 100;
    }

    if (overallScore != scoreData.overallScore) {
      setScoreData({ ...scoreData, overallScore: overallScore });

      // update the score in database.
      axiosInstance
        .post(
          process.env.REACT_APP_API_BASEURL + "/inspection/update-score/",
          {
            inspectionId: id,
            overallScore: overallScore,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then(function (response) {})
        .catch(function (error) {});
    }
  };

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true);
    try {
      const siteUsersResponse = await axiosInstance.post(
        `/site-users/${siteId}`
      );
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false);
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchRiskAssessment();
    }
  }, [id]);

  useEffect(() => {
    recalculateScores();
  }, [data]);

  const signatureRef = useRef();
  const secondarySignatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file);
        });
      }
    });
  };

  const handleSecondarySaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (secondarySignatureRef.current.isEmpty()) {
        res(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = secondarySignatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          res(file);
        });
      }
    });
  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleClearSecondarySignature = () => {
    secondarySignatureRef.current?.clear();
  };

  const userCanSeeIssue = (issue) => {
    if (
      user.role == "admin" ||
      user.id == data.addedByInCompany ||
      userPermissions.includes("submitriskAssessments") ||
      issue.assignedTo == user.id
    ) {
      return true;
    } else {
      return issue.createdBy == user.id;
    }
  };

  const handleComplete = async () => {
    try {
      let verifySignature = '';
      let verifySignatureTwo = '';
      
      [verifySignature, verifySignatureTwo] = await Promise.all( [ handleSaveVerifySign(), handleSecondarySaveVerifySign() ] )
      
      const form = new FormData();
      
      form.append("signature", verifySignature);
      form.append("secondarySignature", verifySignatureTwo);

      axiosInstance
        .post(process.env.REACT_APP_API_BASEURL + "/complete-risk-assessment/" + id, form, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(function (response) {
          toast.success("Risk Assessment completed successfully");
          setCompleteAssessmentPopup(false);
          fetchRiskAssessment(true);
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to complete Risk Assessment"
          );
        });
    } catch (error) {
      toast.error("Failed to save signature")
    }
    
  };

  const handleSubmit = async () => {
    try {
      // let signature
      // if(!fieldData.denied){
      //   signature = await handleSaveVerifySign()
      // }
      handleSubmitAfterSave();
    } catch (error) {
      toast.error("Failed to save signature");
    }
  };

  const handleNewIssueSubmit = async () => {
    try {

      if ( newIssueData.revise ) {
        if (
          newIssueData.requiredAction !== "" &&
          newIssueData.likelihood !== "" &&
          newIssueData.potential_risks !== ""
        ) {
          const form = new FormData();
  
          const mailUpdates = siteUsers
            .filter((v) => v.type == "admin")
            .map((v) => ({
              email: v.email,
              userid: v.userid,
              fullName: v.fullName,
              emailPref: v.emailPreference
                ? v.emailPreference.includes("accidentNotification")
                : v.permits.includes("accidentNotificationreceiveEmails"),
              isAdminWithNoPref: !v.emailPreference && v.role.includes("admin"),
            }))
            .filter((v) => v.emailPref || v.isAdminWithNoPref);
  
          form.append("assessmentId", id);
          form.append("issueId", newIssueData.riskAssessmentIssueId);
  
          form.append(
            "mailUpdates",
            mailUpdates.map((v) => `${v.email}--${v.fullName}`).join(",")
          );
          form.append(
            "notiticationUpdates",
            siteUsers
              .filter((v) => v.type == "admin")
              .filter((v) => v.userid)
              .map((v) => v.userid)
              .join(",")
          );
  
          form.append("recommendedControls", newIssueData.requiredAction);
          form.append("revisedLikelihood", newIssueData.likelihood);  
          form.append("revisedRR", newIssueData.likelihood * newIssueData.potential_risks);  
          form.append("revisedComments", newIssueData.comment);
  
          Object.entries(newIssueData?.files)?.map(([key, value]) => {
            form.append(`files${[key]}`, value);
          });

          if ( data.issues.filter((v) => v.id == newIssueData.riskAssessmentIssueId)[0].revisedFiles ) {
            form.append("existingFiles", data.issues.filter((v) => v.id == newIssueData.riskAssessmentIssueId)[0].revisedFiles);
          }
  
          setLoading(true);
          const apiCall = axiosInstance.post(
            `${process.env.REACT_APP_API_BASEURL}/risk-assessment-action/revise`,
            form,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
  
          apiCall
            .then((res) => {
              toast.success(res.data.message);
              setLoading(false);
              setPopup(true);
              fetchRiskAssessment(true);
            })
            .catch((err) => {
              setPopup(false);
              toast.error(
                err?.response?.data?.message || "Unable to process this request"
              );
              setLoading(false);
            });
        } else {
          toast.error("Please fill in all required fields.");
        }
      } else {
        if (
          newIssueData.requiredAction !== "" &&
          newIssueData.assignedTo !== "" &&
          newIssueData.dateToCompleteBy !== "" &&
          newIssueData.status !== ""
        ) {
          const form = new FormData();
  
          const mailUpdates = siteUsers
            .filter((v) => v.type == "admin")
            .map((v) => ({
              email: v.email,
              userid: v.userid,
              fullName: v.fullName,
              emailPref: v.emailPreference
                ? v.emailPreference.includes("accidentNotification")
                : v.permits.includes("accidentNotificationreceiveEmails"),
              isAdminWithNoPref: !v.emailPreference && v.role.includes("admin"),
            }))
            .filter((v) => v.emailPref || v.isAdminWithNoPref);
  
          form.append("assessmentId", id);
          form.append("riskAssessmentIssueId", newIssueData.riskAssessmentIssueId);
  
          form.append(
            "mailUpdates",
            mailUpdates.map((v) => `${v.email}--${v.fullName}`).join(",")
          );
          form.append(
            "notiticationUpdates",
            siteUsers
              .filter((v) => v.type == "admin")
              .filter((v) => v.userid)
              .map((v) => v.userid)
              .join(",")
          );
  
          form.append("requiredAction", newIssueData.requiredAction);
          form.append("dateToCompleteBy", commonService.formatDate(newIssueData.dateToCompleteBy, "YYYY-MM-DD"));
          form.append("status", newIssueData.status);

          if ( data.issues.filter((v) => v.id == newIssueData.riskAssessmentIssueId)[0].actionFiles ) {
            form.append("existingFiles", data.issues.filter((v) => v.id == newIssueData.riskAssessmentIssueId)[0].actionFiles);
          }
  
          form.append("assignedTo", newIssueData.assignedTo);
          siteUsers.filter((d) => {
            if (d.id == newIssueData.assignedTo) {
              form.append("assignToName", d.fullName);
              form.append("assignToEmail", d.email);
            }
          });
  
          form.append("comment", newIssueData.comment);
  
          Object.entries(newIssueData?.files)?.map(([key, value]) => {
            form.append(`files${[key]}`, value);
          });
  
          setLoading(true);
          const apiCall = axiosInstance.post(
            `${process.env.REACT_APP_API_BASEURL}/risk-assessment-action/add`,
            form,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
  
          apiCall
            .then((res) => {
              toast.success(res.data.message);
              setLoading(false);
              setPopup(true);
              fetchRiskAssessment(true);
            })
            .catch((err) => {
              setPopup(false);
              toast.error(
                err?.response?.data?.message || "Unable to process this request"
              );
              setLoading(false);
            });
        } else {
          toast.error("Please fill in all required fields.");
        }
      }

    } catch (error) {
      toast.error("Failed to create issue");
    }
  };

  const handleCloseIssueSubmit = async () => {

    let signature = '';
    try {
      signature = await handleSaveVerifySign()
    } catch (error) {
      toast.error("Signature is required");
      return;
    }

    try {
      if (
        closeIssueData.closedBy !== "" &&
        closeIssueData.dateClosed !== "" &&
        closeIssueData.closureDetails !== ""
      ) {
        const form = new FormData();

        const mailUpdates = siteUsers
          .filter((v) => v.type == "admin")
          .map((v) => ({
            email: v.email,
            userid: v.userid,
            fullName: v.fullName,
            emailPref: v.emailPreference
              ? v.emailPreference.includes("accidentNotification")
              : v.permits.includes("accidentNotificationreceiveEmails"),
            isAdminWithNoPref: !v.emailPreference && v.role.includes("admin"),
          }))
          .filter((v) => v.emailPref || v.isAdminWithNoPref);

        form.append("riskAssessmentId", id);
        form.append("issueId", closeIssueData.issueId);
        form.append("signature", signature);

        form.append(
          "mailUpdates",
          mailUpdates.map((v) => `${v.email}--${v.fullName}`).join(",")
        );
        form.append(
          "notiticationUpdates",
          siteUsers
            .filter((v) => v.type == "admin")
            .filter((v) => v.userid)
            .map((v) => v.userid)
            .join(",")
        );

        form.append("closedBy", closeIssueData.closedBy);
        form.append("dateClosed", closeIssueData.dateClosed);
        form.append("closureDetails", closeIssueData.closureDetails);

        Object.entries(closeIssueData?.files)?.map(([key, value]) => {
          form.append(`files${[key]}`, value);
        });

        setLoading(true);

        const apiCall = axiosInstance.post(
          `${process.env.REACT_APP_API_BASEURL}/risk-assessment-action/close`,
          form,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        apiCall
          .then((res) => {
            toast.success(res.data.message);
            setLoading(false);
            setClosePopup(true);
            setCloseIssueData({
              issueId: 0,
              closedBy: "",
              dateClosed: "",
              closureDetails: "",
              files: [],
            });
            fetchRiskAssessment();
          })
          .catch((err) => {
            setClosePopup(false);
            toast.error(
              err?.response?.data?.message || "Unable to process this request"
            );
            setLoading(false);
          });
      } else {
        toast.error("Please fill in all required fields.");
      }
    } catch (error) {
      toast.error("Failed to close issue");
    }
  };

  const handleFileChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "newfiles") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }

      Promise.all(Array.from(modifiedFiles.files).map((file) => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setNewIssueData({
        ...newIssueData,
        [name]: modifiedFiles.filesList,
      });
    } else if (name == "newfilesCloseIssue") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      Promise.all(Array.from(modifiedFiles.files).map((file) => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setCloseIssueData({
        ...closeIssueData,
        [name]: modifiedFiles.filesList,
      });
    }
  };

  const handleNewIssueChange = (e) => {
    const { name, value, files } = e.target || e;
    setNewIssueData({
      ...newIssueData,
      [name]: value,
    });
  };

  const handleCloseIssueChange = (e) => {
    const { name, value, files } = e.target || e;
    setCloseIssueData({
      ...closeIssueData,
      [name]: value,
    });
  };

  const toggleIssue = (issue_id) => {
    // toggle class on id: issue_id
    let element = document.getElementById("inspection-" + issue_id);
    if (element.classList.contains("active")) {
      element.classList.remove("active");
    } else {
      element.classList.add("active");
    }
  };

  const handleClosePopup = async () => {
    const result = await Swal.fire({
      title: `Are you sure you want to close this assessment`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .post(process.env.REACT_APP_API_BASEURL + "/risk-assessment/close", {
          riskAssessmentId: id,
        }, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(function (response) {
          fetchRiskAssessment();
          toast.success("Risk Assessment closed.");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to close Risk Assessment."
          );
        });
    }
  };

  const handleIssueDelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${name}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/assessment-issue/" + id)
        .then(function (response) {
          fetchRiskAssessment();
          toast.success("Issue deleted successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to delete Issue"
          );
        });
    }
  };

  const handleSubmitAfterSave = () => {
    const form = new FormData();
    form.append("deniedComment", fieldData.deniedComment);
    form.append("approvedComment", fieldData.approvedComment);
    if (fieldData.approved) {
      form.append("approved", fieldData.approved);
    }
    if (fieldData.completed) {
      form.append("completed", fieldData.completed);
    }

    if (fieldData.actionTaken) {
      form.append("actionTaken", fieldData.actionTaken);
      form.append("actionStatus", fieldData.actionStatus);
      form.append("actionComment", fieldData.actionComment);
      form.append("actionRootCause", fieldData.actionRootCause);

      Object.entries(fieldData?.actionFiles)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
    }

    // form.append("signature", signature);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);
    form.append("id", id);
    setLoading(true);
    axiosInstance
      .post(
        fieldData.resubmitted || fieldData.completed
          ? `${process.env.REACT_APP_API_BASEURL}/resubmit/safety-observation`
          : fieldData.actionTaken
          ? `${process.env.REACT_APP_API_BASEURL}/safety-observation/action-taken`
          : `${process.env.REACT_APP_API_BASEURL}/update/safety-observation`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setFieldData({
          deniedComment: "",
          approvedComment: "",
          approved: false,
          resubmitted: false,
          completed: false,
          denied: false,
          signature: null,
          actionComment: "",
          actionTaken: false,
          actionStatus: "",
          actionRootCause: "",
        });

        setPopup(true);

        fetchRiskAssessment();
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  };

  const issueScore = (issue, type) => {
    let score = 0;
    if (type == "carryover") {
      if (issue.riskFactor == "Good Practice") {
        score = 1;
      } else {
        score = -3;
      }
    } else {
      const riskFactorScore = getRiskFactorScoring();

      if (typeof riskFactorScore[issue.riskFactor] !== "undefined") {
        score = riskFactorScore[issue.riskFactor];
      }
    }

    return <></>;
    // return <strong className={score < 1 ? 'red-text' : 'green-text'}>{score > 0 ? '+' : ''}{score}</strong>
  };

  const matrixScoreClass = (score) => {
    if ( score < 4 ) {
      return 'matrix-score-white';
    } else if ( score >= 4 && score < 10 ) {
      return 'matrix-score-green';
    } else if ( score >= 10 && score <= 12 ) {
      return 'matrix-score-yellow';
    } else {
      return 'matrix-score-red';
    }
  }

  const IssueLikelihood = (issue) => {
    const controls = issue.currentControls;

    if ( ! controls ) {
      return (<span></span>);
    }
    
    let totalScore = 0;

    controls.map((control) => {
      if ( control == 'Elimination of hazard/risk' ) {
        totalScore += 5;
      } else if ( control == 'PPE' ) {
        totalScore += 0.5;
      } else if ( control == 'No Controls' ) {
        totalScore += 0;
      } else {
        totalScore += 0.75;
      }
    });

    if ( totalScore > 5 ) {
      totalScore = 5;
    }

    totalScore = 5 - totalScore;

    return (
      <span className={matrixScoreClass(totalScore)}>
        {totalScore}
      </span>
    );
  };

  const pdfStyles = StyleSheet.create({
    body: {
      padding: "20px 16px 30px",
      fontSize: "13px",
      fontFamily: "Helvetica",
      color: "#000",
    },
    logos: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },
    halfWidthRightAlign: {
      width: "50%",
      textAlign: "right",
    },
    halfWidthLeftAlign: {
      width: "50%",
      textAlign: "left",
    },
    detailsWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      fontSize: "10px",
      border: "1px solid #ddd",
      borderBottom: "none",
    },
    detailsCell: {
      width: "33.3%",
      flexBasis: "33.3%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    issueWrapper: {
      marginBottom: "16px",
      marginTop: "10px",
    },
    issueDetails: {
      fontSize: "10px",
    },
    riskFactor: {
      width: "50%",
      padding: "3px 5px",
      fontSize: "10px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
    },
  });

  const issueRiskColor = (score) => {
    if ( score < 4 ) {
      return '#fff';
    } else if ( score >= 4 && score < 10 ) {
      return '#198754';
    } else if ( score >= 10 && score <= 12 ) {
      return '#f59e0b';
    } else {
      return '#dc3644';
    }
  };

  const MyDoc = () => {
    return (
      <Document>
        <Page style={pdfStyles.body}>
          <View style={pdfStyles.logos}>
            <View style={pdfStyles.halfWidthLeftAlign}>
              <Image
                src={{
                  uri:
                    process.env.REACT_APP_API_BASEURL +
                    "/uploads/foresight-logo.png",
                }}
                style={{ width: "100px" }}
              />
            </View>

            <View style={pdfStyles.halfWidthRightAlign}>
              <Image
                src={{
                  uri:
                    process.env.REACT_APP_API_BASEURL +
                    "/pdf-image?skipHeightResize=1&path=" +
                    (user.companyLogo || user.contractorLogo
                      ? user.contractorLogo
                        ? user.contractorLogo
                        : user.companyLogo
                      : "/uploads/foresight-logo.png"),
                }}
                style={{ marginLeft: "auto", marginRight: "0", width: "100px" }}
              />
            </View>
          </View>

          <View>
            <Text
              style={{
                fontSize: "20px",
                fontFamily: "Helvetica-Bold",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Risk Assessment #{data.id}
            </Text>
          </View>

          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              General Details
            </Text>
          </View>

          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              {data.task}
            </Text>
          </View>

          <View style={pdfStyles.detailsWrapper}>
            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>Asessed By: </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.assessedByName}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>Site: </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.siteName}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>Company: </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.contractorName}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>Department: </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.departmentName}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>Number of days to review: </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.numberOfDays}
                </Text>
              </Text>
            </View>

            <View style={pdfStyles.detailsCell}>
              <Text>
                <Text style={{ fontFamily: "Helvetica" }}>
                  Date:{" "}
                </Text>
                <Text style={{ fontFamily: "Helvetica-Bold" }}>
                  {data.assessmentDate
                    ? commonService.formatDate(
                        data.assessmentDate,
                        "Do MMM YYYY"
                      ) == "Invalid date"
                      ? data.assessmentDate
                      : commonService.formatDate(
                          data.assessmentDate,
                          "Do MMM YYYY"
                        )
                    : "-"}
                </Text>
              </Text>
            </View>
            
          </View>

          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
                marginTop: "20px",
              }}
            >
              Risk Matrix
            </Text>

            <Image
                src={{
                  uri:
                    process.env.REACT_APP_API_BASEURL +
                    "/uploads/risk-matrix.png",
                }}
                style={{ width: "60%" }}
              />
          </View>

          <View style={{...pdfStyles.detailsWrapper, border: "none"}}>
            {data.completeSignature ? (
              <View style={{ margin: "10px 0 0", fontSize: "10px",width: "50%",flexBasis: "50%",padding: "5px",borderRight: "0 solid #ddd",borderBottom: "0 solid #ddd"}}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Helvetica",
                    textAlign: "center",
                  }}
                >
                  Signature
                </Text>

                <Image
                  style={{ marginLeft: "auto", marginRight: "auto", width: "100px", marginTop: "10px", padding: "5px", border: "1px solid #ddd" }}
                  src={{
                    uri:
                      process.env.REACT_APP_API_BASEURL +
                      data.completeSignature,
                  }}
                />
              </View>
            ) : ''}

            {data.completeSecondarySignature ? (
              <View style={{ margin: "10px 0 0", fontSize: "10px",width: "50%",flexBasis: "50%", padding: "5px",borderRight: "0 solid #ddd",borderBottom: "0 solid #ddd"}}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Helvetica",
                    textAlign: "center",
                  }}
                >
                  Secondary Signature
                </Text>

                <Image
                  style={{ marginLeft: "auto", marginRight: "auto", width: "100px", marginTop: "10px", padding: "5px", border: "1px solid #ddd" }}
                  src={{
                    uri:
                      process.env.REACT_APP_API_BASEURL +
                      data.completeSecondarySignature,
                  }}
                />
              </View>
            ) : ''}
          </View>

          <View style={{ marginTop: "40px" }}>
            <Text
              style={{
                fontSize: "10px",
                fontFamily: "Helvetica",
                textAlign: "center",
              }}
            >
              (See Next Page)
            </Text>
          </View>
        </Page>

        <Page style={pdfStyles.body}>
          <View
            style={{
              fontSize: "16px",
              fontFamily: "Helvetica-Bold",
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            <Text style={{ fontFamily: "Helvetica" }}>Issues</Text>
          </View>

          {pdfIssues(data.issues)}
        </Page>
      </Document>
    );
  };

  const pdfIssues = (issues) => {
    if (issues && issues.length > 0) {
      return (
        <View>
          {issues.map((issue, index) => (
            <View style={pdfStyles.issueWrapper} key={index}>
              <View wrap={false}>
                <View style={{ fontFamily: "Helvetica-Bold" }}>
                  <Text
                    style={{
                      fontSize: "12px",
                      fontFamily: "Helvetica-Bold",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                  >
                    Issue / Hazard #{issue.id}:
                  </Text>
                </View>

                <View style={{ margin: "10px 0", fontSize: "10px" }}>
                  <View
                    style={{
                      fontFamily: "Helvetica-Bold",
                      marginBottom: "10px",
                    }}
                  >
                    <Text>Hazard:</Text>
                  </View>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    {issue.hazard ? issue.hazard : " - "}
                  </Text>
                </View>

                <View style={pdfStyles.detailsWrapper}>
                  
                  <View style={pdfStyles.detailsCell}>
                    <Text style={{ fontFamily: "Helvetica" }}>Likelihood:</Text>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>{issue.likelihood}</Text>
                  </View>

                  <View style={pdfStyles.detailsCell}>
                    <Text style={{ fontFamily: "Helvetica" }}>Severity:</Text>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>{issue.severity}</Text>
                  </View>

                  <View style={{...pdfStyles.detailsCell, backgroundColor: issueRiskColor(issue.rr), color: issue.rr > 4 ? "#fff" : "#000"}}>
                    <Text style={{ fontFamily: "Helvetica" }}>RR:</Text>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>{issue.rr}</Text>
                  </View>

                </View>
                
                <View style={{...pdfStyles.detailsWrapper,marginTop: "10px",border:"0"}}>
                  <View style={{ margin: "10px 0 0", fontSize: "10px",width: "50%",flexBasis: "50%",padding: "5px",borderRight: "0 solid #ddd",borderBottom: "0 solid #ddd"}}>
                    <View style={{ fontFamily: "Helvetica-Bold", marginBottom: "10px",}}>
                      <Text>Potential Risks:</Text>
                    </View>
                    <Text style={{ fontFamily: "Helvetica" }}>
                      {riskOptions.filter((v) => v.value == issue.potential_risks)[0].label}
                    </Text>
                  </View>

                  <View style={{ margin: "10px 0 0", fontSize: "10px",width: "50%",flexBasis: "50%",padding: "5px",borderRight: "0 solid #ddd",borderBottom: "0 solid #ddd"}}>
                    <View style={{ fontFamily: "Helvetica-Bold", marginBottom: "10px",}}>
                      <Text>Current Controls in Place:</Text>
                    </View>
                    <Text style={{ fontFamily: "Helvetica" }}>
                      {issue.current_controls_in_place}
                    </Text>
                  </View>
                </View>
                
                <View style={{ margin: "10px 0", fontSize: "10px" }}>
                  <View
                    style={{
                      fontFamily: "Helvetica-Bold",
                      marginBottom: "10px",
                    }}
                  >
                    <Text>Comment:</Text>
                  </View>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    {issue.comments ? issue.comments : " - "}
                  </Text>
                </View>

              </View>
                
              {issue.assignedTo > 0 && <>
                <View style={{ margin: "10px 0", fontSize: "10px" }}>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    Images added when issue opened
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      fontFamily: "Helvetica",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {issue.actionFiles &&
                      issue.actionFiles.split(",").map((imgPreviewUrl) => (
                        <View
                          style={{
                            margin: "5px",
                            width: "100px",
                            border: "1px solid #ddd",
                          }}
                        >
                          <Link
                            src={
                              process.env.REACT_APP_API_BASEURL + imgPreviewUrl
                            }
                          >
                            <Image
                              src={{
                                uri:
                                  process.env.REACT_APP_API_BASEURL +
                                  "/pdf-image?path=" +
                                  imgPreviewUrl,
                              }}
                            />
                          </Link>
                        </View>
                      ))}
                    {!issue.actionFiles && (
                      <>
                        <Text style={{ fontFamily: "Helvetica" }}>
                          No images added
                        </Text>
                      </>
                    )}
                  </View>
                </View>

                {/* Print Response, Action, Clouse Out Date, ensure wrap is false on the wrapper */}
                <View
                  wrap={false}
                  style={{
                    margin: "10px 0",
                    fontSize: "10px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    backgroundColor: "#f3f3f3",
                    border: "1px solid #ddd",
                  }}
                >
                  <View
                    style={{
                      width: "50%",
                      padding: "8px",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    <View
                      style={{
                        dispay: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ fontFamily: "Helvetica" }}>
                        Required Action:{" "}
                      </Text>
                      <Text>
                        {issue.requiredAction}
                      </Text>
                    </View>
                  </View>

                </View>

                <View 
                  wrap={false}
                  style={{
                    margin: "10px 0",
                    fontSize: "10px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    backgroundColor: "#f3f3f3",
                    border: "1px solid #ddd",
                  }}>
                    <View
                      style={{
                        width: "50%",
                        padding: "8px",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      <Text>
                        <Text style={{ fontFamily: "Helvetica" }}>Action By: </Text>
                        <Text style={{ fontFamily: "Helvetica-Bold" }}>
                          {issue.assignedToName}
                        </Text>
                      </Text>
                    </View>

                    <View style={{ width: "50%", padding: "8px" }}>
                      <Text style={{ fontFamily: "Helvetica" }}>
                        <Text style={{ fontFamily: "Helvetica" }}>
                          Close Out By:{" "}
                        </Text>
                        <Text style={{ fontFamily: "Helvetica-Bold" }}>
                          {issue.dateToCompleteBy
                            ? commonService.formatDate(
                                issue.dateToCompleteBy,
                                "Do MMM YYYY"
                              )
                            : ""}
                        </Text>
                      </Text>
                    </View>
                    
                    {issue.actionComment && <>
                      <View
                        style={{
                          width: "50%",
                          padding: "8px",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        <Text>
                          <Text style={{ fontFamily: "Helvetica" }}>Comment: </Text>
                          <Text style={{ fontFamily: "Helvetica-Bold" }}>
                            {issue.actionComment}
                          </Text>
                        </Text>
                      </View>
                    </>}

                </View>

                {issue.status == "Closed" && (
                  <>
                    <View
                      wrap={false}
                      style={{
                        margin: "10px 0",
                        fontSize: "10px",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        backgroundColor: "#f3f3f3",
                        border: "1px solid #ddd",
                      }}
                    >
                      <View
                        style={{
                          width: "50%",
                          padding: "8px",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        <Text style={{ fontFamily: "Helvetica" }}>
                          <Text style={{ fontFamily: "Helvetica" }}>
                            Closure Details:{" "}
                          </Text>
                          <Text style={{ fontFamily: "Helvetica-Bold" }}>
                            {issue.closureDetails}
                          </Text>
                        </Text>
                      </View>

                      <View style={{ width: "50%", padding: "8px" }}>
                        <Text style={{ fontFamily: "Helvetica" }}>
                          <Text style={{ fontFamily: "Helvetica" }}>
                            Date Closed:{" "}
                          </Text>
                          <Text style={{ fontFamily: "Helvetica-Bold" }}>
                            {issue.dateClosed
                              ? commonService.formatDate(
                                  issue.dateClosed,
                                  "Do MMM YYYY"
                                )
                              : ""}
                          </Text>
                        </Text>
                      </View>
                    </View>

                    <View style={{ margin: "10px 0", fontSize: "10px" }}>
                      <Text style={{ fontFamily: "Helvetica-Bold" }}>
                        Images added when issue closed
                      </Text>
                      <View
                        style={{
                          fontFamily: "Helvetica",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {issue.closureFiles &&
                          issue.closureFiles.split(",").map((imgPreviewUrl) => (
                            <View
                              style={{
                                margin: "5px",
                                width: "100px",
                                border: "1px solid #ddd",
                              }}
                            >
                              <Link
                                src={
                                  process.env.REACT_APP_API_BASEURL +
                                  imgPreviewUrl
                                }
                              >
                                <Image
                                  src={{
                                    uri:
                                      process.env.REACT_APP_API_BASEURL +
                                      "/pdf-image?path=" +
                                      imgPreviewUrl,
                                  }}
                                />
                              </Link>
                            </View>
                          ))}
                        {!issue.closureFiles && (
                          <>
                            <Text style={{ fontFamily: "Helvetica" }}>
                              No images added
                            </Text>
                          </>
                        )}
                      </View>
                    </View>
                  </>
                )}
              </>}
            </View>
          ))}
        </View>
      );
    } else {
      return (
        <View style={pdfStyles.issueDetails}>
          <Text style={{ fontFamily: "Helvetica" }}>No issues.</Text>
        </View>
      );
    }
  };

  const statusOptions = [
    { value: "Open", label: "Open", name: "status" },
    { value: "Closed", label: "Closed", name: "status" },
  ];

  const reassignToNewUser = async() => {
    if(id && reassignData.id && reassignData.assignTo && reassignData.status && reassignData.comments) {
      const form = new FormData();
      form.append("id", reassignData.id);
      form.append("assignTo", reassignData.assignTo);
      form.append("status", reassignData.status);
      form.append("comments", reassignData.comments);
      form.append("inspectionId", id);
      
      siteUsers.filter((user) => {
        if (user.id == reassignData.assignTo) {
          form.append("assignToName", user.fullName);
          form.append("assignToEmail", user.fullName);
        }
      });
      axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/inspection-issues/${reassignData.id}/reassign`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setReassignData({});
        setReassignPopup(true);

        fetchRiskAssessment();
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
    }
  }

  const addNewAssessmentRow = () => {
    // Create a new row and refetch assessments
    const form = new FormData();  
    
    form.append("assessmentId", id);
    form.append("addedBy", userDetails.id);
    
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/new-assessment-issue`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        fetchRiskAssessment( true );
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
      });
  }

  const saveIssue = (issue) => {
      const form = new FormData();
      
      form.append("assessmentId", id);
      form.append("hazard", issue.hazard);
      form.append("potential_risks", issue.potentialRisk);
      form.append("current_controls_in_place", issue.currentControls);
      form.append("likelihood", issue.likelihoodScore);
      form.append("severity", issue.potentialRisk);
      form.append("rr", (issue.likelihoodScore * issue.potentialRisk));
      form.append("comments", issue.comments);
      form.append("addedBy", userDetails.id);
      form.append("id", issue.id);

      axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/save-assessment-issue`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        
        fetchRiskAssessment( true );
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
      });
  }

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/risk-assessment/" + deleteId)
        .then(function (response) {
          toast.success("Risk Assessment archived successfully");
          window.location = '/risk-assessments';
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Risk Assessment"
          );
        });
    }
  };

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Risk Assessments List", path: "/risk-assessments/" },
          { name: "Risk Assessment", path: `/risk-assessments/${id}` },
        ]}
      />


      <CustomPopup
        trigger={completeAssessmentPopup}
        setTrigger={() => {
          setCompleteAssessmentPopup(false);
        }}
      >
        <div className="col-12 form_inner p-2">
          <h2>Complete Risk Assessment</h2>
          <p>To complete this assessment you must sign below.</p>

          <div className="col-md-12">
            <div className="w-100 p-2 location_ladder">
              <label>Signature <span className="text-danger">*</span></label>
            
              <div className="sign_image" style={{marginTop: '10px'}}>
                <SignatureCanvas
                  ref={signatureRef}
                  canvasProps={{ width: 263, height: 270 }}
                  />
                <span
                  className="cancel-custom cursor-pointer"
                  onClick={handleClearSignature}
                  >
                  <i
                    className="clear_btn  text-danger fa fa-times-circle"
                    aria-hidden="true"
                    ></i>
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="w-100 p-2 location_ladder">
              <label>Secondary Signature  <span className="text-danger">(optional)</span></label>
            
              <div className="sign_image" style={{marginTop: '10px'}}>
                <SignatureCanvas
                  ref={secondarySignatureRef}
                  canvasProps={{ width: 263, height: 270 }}
                  />
                <span
                  className="cancel-custom cursor-pointer"
                  onClick={handleClearSecondarySignature}
                  >
                  <i
                    className="clear_btn  text-danger fa fa-times-circle"
                    aria-hidden="true"
                    ></i>
                </span>
              </div>
            </div>
          </div>

          <button
            className="btn btn-success"
            onClick={() => {
              handleComplete();
            }}
          >
            Complete Assessment
          </button>

        </div>
      </CustomPopup>

      <CustomPopup
        trigger={!reassignPopup}
        setTrigger={() => {
          setReassignPopup(true);
          setReassignData({});
        }}
      >
        <div className="col-12 form_inner p-2">
          <div className="input_icons">
            <div className="input_field">
              <div className="w-100 p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Assign To <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={(e) => {
                        setReassignData({
                          ...reassignData,
                          assignTo: e.value,
                        });
                      }}
                      placeholder="Select User"
                      options={siteUsers.map((v) => ({
                        label:
                          v.fullName +
                          " - " +
                          (v.contractorName
                            ? v.contractorName
                            : v.companyName) +
                          " - " +
                          (v.roleName ? v.roleName : v.role),
                        value: `${v.userid}`,
                        name: "assignTo",
                      }))}
                      value={siteUsers
                        .filter((v) => `${v.userid}` == reassignData?.assignTo)
                        .map((v) => ({
                          label:
                            v.fullName +
                            " - " +
                            (v.contractorName
                              ? v.contractorName
                              : v.companyName) +
                            " - " +
                            (v.roleName ? v.roleName : v.role),
                          value: `${v.userid}`,
                          name: "assignTo",
                        }))}
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Status <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      placeholder="Select a status"
                      options={reassignStatuses}
                      onChange={(e) => {
                        setReassignData({
                          ...reassignData,
                          status: e.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Comments <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <textarea
                      name="comments"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      rows="5"
                      cols="100"
                      onChange={(e) => {
                        setReassignData({
                          ...reassignData,
                          comments: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>
              <div className="sign_save_reset_btn">
              <button
                className="btn btn-success"
                disabled={
                  !reassignData?.assignTo ||
                  !reassignData?.status ||
                  !reassignData?.comments
                }
                onClick={reassignToNewUser}
              >
                Reassign
              </button>
            </div>
            </div>
          </div>
        </div>
      </CustomPopup>

      <CustomPopup
        trigger={!newIssuePopup}
        setTrigger={() => {
          setPopup(true);
          setNewIssueData({
            ...newIssueData,
            requiredAction: "",
            ehs: "",
            riskFactor: "",
            response: "",
            assignedTo: "",
            dateToCompleteBy: "",
            status: "",
            comment: "",
            revise: false,
            likelihood: "",
            files: [],
          });
        }}
      >
        <div className="col-12 form_inner add-issue-popup">
          <h3>{newIssueData.revise ? "Revise Risk Rating" : "Assign Action"}</h3>

          <div className="d-flex flex-wrap">
            <div className="col-md-12">
              <div className="w-100 p-2 location_ladder">
                <label for="required-action">
                  <div className="require-label">
                    {newIssueData.revise ? 'Recommended Controls' : 'Required Action'} <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      id="required-action"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      type="text"
                      value={newIssueData.requiredAction}
                      name="requiredAction"
                      placeholder=""
                      onChange={handleNewIssueChange}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>
            </div>

            { ! newIssueData.revise ? <>
              <div className="col-md-12">
                <div className="w-100 p-2">
                  <label for="">
                    <div className="require-label">
                      Assign To <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="w-100">
                      <Select
                        onChange={handleNewIssueChange}
                        placeholder="Select User"
                        options={siteUsers.map((v) => ({
                          label:
                            v.fullName +
                            " - " +
                            (v.contractorName ? v.contractorName : v.companyName) +
                            " - " +
                            (v.roleName ? v.roleName : v.role),
                          value: `${v.userid}`,
                          name: "assignedTo",
                        }))}
                        value={siteUsers
                          .filter(
                            (v) => `${v.userid}` == newIssueData?.assignedTo
                          )
                          .map((v) => ({
                            label:
                              v.fullName +
                              " - " +
                              (v.contractorName ? v.contractorName : v.companyName) +
                              " - " +
                              (v.roleName ? v.roleName : v.role),
                            value: `${v.userid}`,
                            name: "assignedTo",
                          }))}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="w-100 p-2 location_access">
                  <label for="dateToCompleteBy">
                    <div className="require-label">
                      Close Out By <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        value={newIssueData.dateToCompleteBy}
                        id="dateToCompleteBy"
                        min={new Date()
                          .toLocaleString("en-GB")
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                        type="date"
                        onChange={handleNewIssueChange}
                        name="dateToCompleteBy"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="w-100 p-2 location_ladder">
                  <label for="response">
                    <div className="require-label">
                      Status <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <Select
                        onChange={handleNewIssueChange}
                        placeholder="-- Select an option --"
                        options={statusOptions}
                        {...(id
                          ? {
                              value: statusOptions.filter(
                                (v) => v.value == newIssueData.status
                              ),
                            }
                          : {})}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </> : <>
              <div className="col-md-12">
                <div className="w-100 p-2 location_ladder">
                  <label for="response">
                    <div className="require-label">
                      Revised Likelihood <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <Select
                        onChange={handleNewIssueChange}
                        placeholder="-- Select --"
                        options={riskOptions.map((v) => ({
                          label: v.label,
                          value: `${v.value}`,
                          name: "likelihood",
                        }))}
                        {...(newIssueData.likelihood
                          ? {
                              value: riskOptions.filter(
                                (v) => v.value == newIssueData.likelihood
                              ),
                            }
                          : {})}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                <div className="w-100 p-2 location_ladder">
                <label for="response">
                    <div className="require-label">
                      Severity <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        value={newIssueData.potential_risks} 
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="w-100 p-2">
                  <label>
                    RR
                  </label>

                  <div className="revised-rr">
                    <span className={matrixScoreClass( newIssueData.likelihood ? parseInt( newIssueData.likelihood ) * newIssueData.potential_risks : 0 )}>{newIssueData.likelihood ? parseInt( newIssueData.likelihood ) * newIssueData.potential_risks : ''}</span>
                  </div>
                </div>
              </div>

            </> }


            <div className="col-md-12">
              <div className="w-100 p-2 location_ladder">
                <label for="comment">
                  <div className="require-label">{newIssueData.revise ? 'Revised Comments' : 'Comment'}</div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      id="comment"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      type="text"
                      name="comment"
                      placeholder=""
                      value={newIssueData.comment}
                      onChange={handleNewIssueChange}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>
            </div>

            <div className="d-flex preview-upload-files w-100">
              {!!files.length &&
                files.map((imgPreviewUrl) => (
                  <div id="profile_pic_1_preview" className="image-fixed m-2">
                    <img
                      className="obj_fit_img obj-fit-cover rounded"
                      src={imgPreviewUrl}
                      alt=""
                      height="150"
                      width="150"
                    />
                  </div>
                ))}
            </div>

            <div className="select_files">
              <input
                name="newfiles"
                type="file"
                multiple
                id="upload"
                hidden
                onChange={handleFileChange}
              />
              <label for="upload">
                <img src={fileIcon} alt="Icon" />
                Select files...
              </label>
            </div>
            <div className="select_all_files">
              Select all files you wish to upload in one go and NOT
              individually.
            </div>
            <div className="hold_down">
              Hold down CTRL (CMD on Mac) to select multiple files.
            </div>
          </div>
        </div>
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            onClick={() => {
              handleNewIssueSubmit();
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>

      <CustomPopup
        trigger={matrixPopup}
        setTrigger={() => {
          setMatrixPopup(false);
        }}
      >
        <div className="risk-matrix-table">
          <h3>Risk Matrix</h3>
          <table style={{width: "100%"}}>
            <tr>
              <td></td>
              <td colSpan="5">
                Severity
              </td>
            </tr>
            <tr>
              <td>Likelihood</td>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
            </tr>

            <tr>
              <td>1</td>
              <td className="risk-negligible"><strong>Negligible</strong><span>1</span></td>
              <td className="risk-negligible"><strong>Negligible</strong><span>2</span></td>
              <td className="risk-negligible"><strong>Negligible</strong><span>3</span></td>
              <td className="risk-tolerable"><strong>Tolerable</strong><span>4</span></td>
              <td className="risk-tolerable"><strong>Tolerable</strong><span>5</span></td>
            </tr>

            <tr>
              <td>2</td>
              <td className="risk-negligible"><strong>Negligible</strong><span>2</span></td>
              <td className="risk-tolerable"><strong>Tolerable</strong><span>4</span></td>
              <td className="risk-tolerable"><strong>Tolerable</strong><span>6</span></td>
              <td className="risk-tolerable"><strong>Tolerable</strong><span>8</span></td>
              <td className="risk-undesirable"><strong>Undesirable</strong><span>10</span></td>
            </tr>

            <tr>
              <td>3</td>
              <td className="risk-negligible"><strong>Negligible</strong><span>3</span></td>
              <td className="risk-tolerable"><strong>Tolerable</strong><span>6</span></td>
              <td className="risk-tolerable"><strong>Tolerable</strong><span>9</span></td>
              <td className="risk-undesirable"><strong>Undesirable</strong><span>12</span></td>
              <td className="risk-intolerable"><strong>Intolerable</strong><span>15</span></td>
            </tr>

            <tr>
              <td>4</td>
              <td className="risk-tolerable"><strong>Tolerable</strong><span>4</span></td>
              <td className="risk-tolerable"><strong>Tolerable</strong><span>8</span></td>
              <td className="risk-undesirable"><strong>Undesirable</strong><span>12</span></td>
              <td className="risk-intolerable"><strong>Intolerable</strong><span>16</span></td>
              <td className="risk-intolerable"><strong>Intolerable</strong><span>20</span></td>
            </tr>
          
            <tr>
              <td>5</td>
              <td className="risk-tolerable"><strong>Tolerable</strong><span>5</span></td>
              <td className="risk-undesirable"><strong>Undesirable</strong><span>10</span></td>
              <td className="risk-intolerable"><strong>Intolerable</strong><span>15</span></td>
              <td className="risk-intolerable"><strong>Intolerable</strong><span>20</span></td>
              <td className="risk-intolerable"><strong>Intolerable</strong><span>25</span></td>
            </tr>
            

          </table>
        </div>
      </CustomPopup>

      <CustomPopup
        trigger={!closeIssuePopup}
        setTrigger={() => {
          setClosePopup(true);
          setCloseIssueData({
            issueId: 0,
            closedBy: "",
            dateClosed: "",
            ehs: "",
            closureDetails: "",
            files: [],
          });
        }}
      >
        <div className="col-12 form_inner">
          <h3>Close Issue #{closeIssueData.issueId}</h3>

          <div className="d-flex flex-wrap">
            <div className="col-md-6">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Closed By <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={handleCloseIssueChange}
                      placeholder="Select User"
                      options={
                        user.role == "admin" ||
                        user.id == data.addedByInCompany ||
                        userPermissions.includes(
                          "submitriskAssessments"
                        )
                          ? siteUsers.map((v) => ({
                              label:
                                v.fullName +
                                " - " +
                                (v.contractorName
                                  ? v.contractorName
                                  : v.companyName) +
                                " - " +
                                (v.roleName ? v.roleName : v.role),
                              value: `${v.userid}`,
                              name: "closedBy",
                            }))
                          : siteUsers
                              .filter((v) => v.userid == user.id)
                              .map((v) => ({
                                label:
                                  v.fullName +
                                  " - " +
                                  (v.contractorName
                                    ? v.contractorName
                                    : v.companyName) +
                                  " - " +
                                  (v.roleName ? v.roleName : v.role),
                                value: `${v.userid}`,
                                name: "closedBy",
                              }))
                      }
                      value={siteUsers
                        .filter(
                          (v) => `${v.userid}` == closeIssueData?.closedBy
                        )
                        .map((v) => ({
                          label:
                            v.fullName +
                            " - " +
                            v.companyName +
                            " - " +
                            (v.roleName ? v.roleName : v.role),
                          value: `${v.userid}`,
                          name: "closedBy",
                        }))}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="w-100 p-2 location_access">
                <label for="dateClosed">
                  <div className="require-label">
                    Date Closed <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      value={closeIssueData.dateClosed}
                      id="dateClosed"
                      min={new Date()
                        .toLocaleString("en-GB")
                        .substring(0, 10)
                        .split("/")
                        .reverse()
                        .join("-")}
                      type="date"
                      onChange={handleCloseIssueChange}
                      name="dateClosed"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="w-100 p-2 location_ladder">
                <label for="closureDetails">
                  <div className="require-label">
                    Closure Details <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      id="closureDetails"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      type="text"
                      name="closureDetails"
                      placeholder=""
                      onChange={handleCloseIssueChange}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>
            </div>

            <div className="d-flex preview-upload-files w-100">
              {!!files.length &&
                files.map((imgPreviewUrl) => (
                  <div id="profile_pic_1_preview" className="image-fixed m-2">
                    <img
                      className="obj_fit_img obj-fit-cover rounded"
                      src={imgPreviewUrl}
                      alt=""
                      height="150"
                      width="150"
                    />
                  </div>
                ))}
            </div>

            <div className="select_files">
              <input
                name="newfilesCloseIssue"
                type="file"
                multiple
                id="upload"
                hidden
                onChange={handleFileChange}
              />
              <label for="upload">
                <img src={fileIcon} alt="Icon" />
                Select files...
              </label>
            </div>
            <div className="select_all_files">
              Select all files you wish to upload in one go and NOT
              individually.
            </div>
            <div className="hold_down">
              Hold down CTRL (CMD on Mac) to select multiple files.
            </div>
              
              
            <div className="col-md-12">
              <div className="w-100 p-2 location_ladder">
                <label>Signature <span className="text-danger">*</span></label>
              
                <div className="sign_image" style={{marginTop: '10px'}}>
                  <SignatureCanvas
                    ref={signatureRef}
                    canvasProps={{ width: 263, height: 270 }}
                    />
                  <span
                    className="cancel-custom cursor-pointer"
                    onClick={handleClearSignature}
                    >
                    <i
                      className="clear_btn  text-danger fa fa-times-circle"
                      aria-hidden="true"
                      ></i>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            onClick={() => {
              handleCloseIssueSubmit();
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>

      <div className="main_container assessments-main-container inspections-main-container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">

              <div className="row">
                <div className="col-12 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">General Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          <div className="flex flex-wrap">
                           <div className="col-md-12 d-flex dl-access">
                              <div className="col-6 dd-access">Task : </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.task ? data.task : "-"}
                              </div>
                            </div>
                            <div className="col-md-6 d-flex dl-access">
                              <div className="col-6 dd-access">Site : </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.siteName ? data.siteName : "-"}
                              </div>
                            </div>
                            
                            <div className="col-md-6 d-flex dl-access">
                              <div className="col-6 dd-access">Department : </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.departmentName ? data.departmentName : "-"}
                              </div>
                            </div>
                            
                            <div className="col-md-6 d-flex dl-access">
                              <div className="col-6 dd-access">Company : </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.contractorName ? data.contractorName : "-"}
                              </div>
                            </div>

                            <div className="col-md-6 d-flex dl-access">
                              <div className="col-6 dd-access">
                                Date:{" "}
                              </div>
                              <div className="col-6 dd-access">
                              {data.assessmentDate
                                  ? ( commonService.formatDate(
                                    data.assessmentDate,
                                    "Do MMM YYYY"
                                  ) == 'Invalid date' ? data.assessmentDate : commonService.formatDate(
                                    data.assessmentDate,
                                    "Do MMM YYYY"
                                  ))
                                  : "-"}{" "}
                              </div>
                            </div>
                            
                            <div className="col-md-6 d-flex dl-access">
                              <div className="col-6 dd-access">Assessed By : </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.assessedByName ? data.assessedByName : "-"}
                              </div>
                            </div>
                            
                            <div className="col-md-6 d-flex dl-access">
                              <div className="col-6 dd-access">Number of days to review (0-365)</div>
                              <div className="col-6 dd-access">
                                {data.numberOfDays ? data.numberOfDays : ''}
                              </div>
                            </div>
                          </div>
                        </div>

                        {userPermissions.includes('submitriskAssessments') && (data.isCompleted == 0) ? <>
                          <button className="btn btn-outline-success ms-1" onClick={() => {
                            history.push(`/edit-risk-assessment/${data.id}`)
                          }}>Edit</button>
                        </>
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div>
                  {id && userPermissions.includes('submitriskAssessments') && data.isDeleted != 1 ? <>
                    <button className="btn btn-outline-danger ms-1 mt-2" onClick={() => handleArchive(id)}>
                      Archive
                    </button>
                  </> : ''}
                  <div className="p-2"></div>
                </div>

                <div className="col-12 mb-2">
                  <div className="mb-3">
                    <div className="header-access-view">Assessments</div>
                    <div className="p-2">
                      <div className="main-details">
                        {data.issues && data.issues.length > 0
                          ? (
                            <div className="inspection-issues">
                              <div className="inspection-issue-row inspection-issue-row-header">
                                <div className="inspection-issue-number">#</div>
                                <div className="inspection-issue-hazard">Hazard</div>
                                <div className="inspection-issue-resp-required">Potential Risks</div>
                                <div className="inspection-issue-risk-factor">Current controls in place<br/><small>(select multiple)</small></div>
                                <div className="assessment-row-likelihood">L</div>
                                <div className="assessment-row-severity">S</div>
                                <div className="assessment-row-rr">RR</div>
                                <div className="assessment-row-comments">Comments</div>
                                <div className="assessment-row-save">Save/Revise</div>
                                <div className="assessment-row-actions">Actions</div>
                              </div>

                              {data.issues.map(
                                (issue, index) =>
                                  userCanSeeIssue(issue) && (
                                    <div
                                      className="assessment-item-row inspection-issue-item"
                                      key={issue.id}
                                      id={"inspection-" + issue.id}
                                    >
                                      <div className="inspection-issue-row">
                                        <div className="inspection-issue-number">
                                          {issue.id}{" "}
                                          {issue.isSaved == 1 && ( issue.recommendedControls || issue.assignTo > 0 ) && <>
                                            <button
                                              href="#"
                                              className="inspection-issue-toggle"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                toggleIssue(issue.id);
                                              }}
                                            >
                                              <span></span>
                                            </button>
                                          </>}
                                        </div>
                                        <div className="inspection-issue-hazard location_ladder">
                                          {issue.isSaved == 1 ? issue.hazard : (
                                            <input
                                              name="hazard"
                                              type="text"
                                              default={issue.hazard}
                                              onChange={(e) => {
                                                issue.hazard = e.target.value;
                                                setData({ ...data });
                                              }}
                                            />
                                          )}
                                        </div>
                                        <div className="inspection-issue-resp-required">
                                          {issue.isSaved == 1 ? riskOptions.filter((v) => v.value == issue.potential_risks)[0].label : (
                                            <Select
                                              placeholder="Select Risk"
                                              options={riskOptions.map((v) => ({
                                                label: v.label,
                                                value: `${v.value}`,
                                                name: "potentialRisk",
                                              }))}
                                              onChange={(e) => {
                                                issue.potentialRisk = e.value;
                                                setData({ ...data });
                                              }}
                                            />
                                          )}
                                        </div>
                                        <div className="inspection-issue-risk-factor">
                                          {issue.isSaved == 1 ? issue.current_controls_in_place : (
                                            <Select
                                              isMulti
                                              placeholder="Select controls"
                                              options={controlsOptions.map((v) => ({
                                                label: v.label,
                                                value: `${v.label}`,
                                                name: "currentControls",
                                              }))}
                                              onChange={(e) => {
                                                if (e ) {
                                                  issue.currentControls = e.map((v) => v.value);
                                                } else {
                                                  issue.currentControls = [];
                                                }

                                                let totalScore = 0;

                                                issue.currentControls.map((control) => {
                                                  if ( control == 'Elimination of hazard/risk' ) {
                                                    totalScore += 5;
                                                  } else if ( control == 'PPE' ) {
                                                    totalScore += 0.5;
                                                  } else if ( control == 'No Controls' ) {
                                                    totalScore += 0;
                                                  } else {
                                                    totalScore += 0.75;
                                                  }
                                                });

                                                if ( totalScore > 5 ) {
                                                  totalScore = 5;
                                                }

                                                issue.likelihoodScore = parseInt( 5 - totalScore );

                                                if ( typeof issue.likelihoodScore !== 'undefined' && issue.potentialRisk ) {
                                                  issue.rr = issue.likelihoodScore * issue.potentialRisk
                                                }
                                                
                                                setData({ ...data });
                                              }}
                                            />
                                          )}
                                        </div>

                                        <div className="assessment-row-likelihood">
                                          <span>{issue.isSaved == 1 ? issue.likelihood : typeof issue.likelihoodScore !== 'undefined' ?issue.likelihoodScore:''}</span>
                                        </div>

                                        <div className="assessment-row-severity">
                                          <span>{issue.isSaved == 1 ? issue.potential_risks : issue.potentialRisk?issue.potentialRisk:''}</span>
                                        </div>

                                        <div className="assessment-row-rr">
                                          {issue.isSaved == 1 ? <span className={matrixScoreClass(issue.rr)}>{issue.rr}</span> : <>
                                            { typeof issue.likelihoodScore !== 'undefined' && issue.potentialRisk ? (
                                              <span className={matrixScoreClass(issue.likelihoodScore * issue.potentialRisk)}>{ issue.likelihoodScore * issue.potentialRisk }</span>
                                            ) : <span></span> }
                                          </>}
                                        </div>
                                        
                                        <div className="assessment-row-comments location_ladder">
                                          {issue.isSaved == 1 ? issue.comments : <>
                                            <input
                                              name="comments"
                                              type="text"
                                              default={issue.comments}
                                              onChange={(e) => {
                                                issue.comments = e.target.value;
                                                setData({ ...data });
                                              }}
                                            />
                                          </>}
                                        </div>
                                        
                                        <div className="assessment-row-save">
                                          {issue.isSaved != 1 ? 
                                            issue.hazard && issue.potentialRisk && issue.currentControls && <>
                                              <button
                                                onClick={() => {
                                                  saveIssue(issue);
                                                }}
                                              >
                                                <img
                                                  src={tickIcon}
                                                  alt="tick"
                                                />
                                              </button>
                                              </>
                                            : <>
                                              { issue.rr >= 10 ? <>
                                                <button
                                                  className={ "risk-revise-btn" + ( issue.revisedRR ? " revised" : "") }
                                                  onClick={() => {
                                                    if ( issue.revisedRR ) {
                                                      return;
                                                    }
                                                    setNewIssueData({
                                                      ...newIssueData,
                                                      riskAssessmentIssueId: issue.id,
                                                      revise: true,
                                                      potential_risks: issue.potential_risks,
                                                    })
                                                    setPopup(false);
                                                  }}
                                                >
                                                  <img
                                                    src={riskIcon}
                                                    alt="Risk" 
                                                  />
                                                </button>
                                              </> : <>
                                                <img
                                                  src={tickIcon}
                                                  alt="tick"
                                                />
                                              </> }
                                            </>}
                                        </div>

                                        <div className="inspection-issue-actions">
                                          
                                          {issue.status == null && issue.isSaved == 1 && issue.rr >= 10 && <>
                                            {(user.role.includes("admin") ||
                                              userPermissions.includes(
                                                "submitriskAssessments"
                                              )) && (
                                              <>
                                                <button
                                                  className="btn btn-sm btn-add-action"
                                                  onClick={() => {
                                                    setNewIssueData({
                                                      ...newIssueData,
                                                      riskAssessmentIssueId: issue.id,
                                                    })
                                                    setPopup(false);
                                                  }}
                                                >
                                                  Action
                                                </button>
                                              </>
                                            )}
                                          </>}

                                          {issue.status == "Open" && userCanSeeIssue(issue) && (
                                            <>
                                              <button
                                                className="btn btn-sm take-action"
                                                style={{ height: "35px", marginRight: "5px" }}
                                                onClick={() => {
                                                  setCloseIssueData({
                                                    ...closeIssueData,
                                                    issueId: issue.id,
                                                  });
                                                  setClosePopup(false);
                                                }}
                                              >
                                                Take Action
                                              </button>
                                            </>
                                          )}

                                          {issue.status == 'Closed' && (
                                            <button className="btn btn-sm take-action btn-disabled">Closed</button>
                                          )}

                                          {(user.role.includes("admin") ||
                                            userPermissions.includes(
                                              "submitriskAssessments"
                                            )) && (
                                            <>
                                              <img
                                                src={Delete}
                                                alt=""
                                                onClick={() => {
                                                  handleIssueDelete(issue.id, "this issue");
                                                }}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </div>

                                      <div className="inspection-issue-details">

                                        {issue.recommendedControls && <>
                                          <div className="d-flex flex-wrap inspection-issue-details-basic inspection-issue-revised-details">
                                            
                                            <div className="col-md-5 inspection-issue-details-item">
                                              <strong>Recommended Controls:</strong>
                                              <span>{issue.recommendedControls}</span>
                                            </div>
                                            
                                            <div className="col-md-2 inspection-issue-details-item">
                                              <strong>Revised Likelihood:</strong>
                                              <span>{riskOptions.filter((v) => v.value == issue.revisedLikelihood)[0].label}</span>
                                            </div>
                                            
                                            <div className="col-md-2 inspection-issue-details-item">
                                              <strong>Revised RR:</strong>
                                              <span className="revised-rr"><span className={matrixScoreClass(issue.revisedRR)}>{issue.revisedRR}</span></span>
                                            </div>
                                            
                                            { issue.revisedComments && <div className="col-md-3 inspection-issue-details-item">
                                              <strong>Revised Comments:</strong>
                                              <span>{issue.revisedComments}</span>
                                            </div> }

                                            <div className="d-flex inspection-issue-details-files">
                                              <div className="col-md-6">
                                                <strong>Images added when revised</strong>
                                                <div className="inspection-issue-details-files-grid">
                                                  <div className="d-flex">
                                                    {issue.revisedFiles &&
                                                      issue.revisedFiles.split(",").map((imgPreviewUrl) => (
                                                        <div
                                                          id="profile_pic_1_preview"
                                                          className="image-fixed m-2"
                                                        >
                                                          {commonService.outputImageTile(imgPreviewUrl)}
                                                        </div>
                                                      ))}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            
                                            { data.isCompleted != 1 && (user.role == "admin" ||
                                              user.id == data.addedByInCompany ||
                                              userPermissions.includes(
                                                "submitriskAssessments"
                                              )) && 
                                              <button
                                                className="btn btn-outline-success btn-edit-action"
                                                onClick={() => {
                                                  setPopup(false);
                                                  setNewIssueData({
                                                    ...newIssueData,
                                                    riskAssessmentIssueId: issue.id,
                                                    revise: true,
                                                    potential_risks: issue.potential_risks,
                                                    requiredAction: issue.recommendedControls,
                                                    likelihood: issue.revisedLikelihood,
                                                    comment: issue.revisedComments
                                                  })
                                                }}
                                              >
                                                Edit Revise
                                              </button>
                                            }

                                          </div>
                                        </>}

                                        {issue.assignedTo > 0 && <>
                                          <div className="d-flex flex-wrap inspection-issue-details-basic">
                                          <div className="col-md-4 inspection-issue-details-item">
                                            <strong>Action By:</strong>
                                            <span>{issue.assignedToName}</span>
                                          </div>

                                          <div className="col-md-4 inspection-issue-details-item">
                                            <strong>Required Action:</strong>
                                            <span>{issue.requiredAction}</span>
                                          </div>

                                          <div className="col-md-4 inspection-issue-details-item">
                                            <strong>Close Out By:</strong>
                                            <span>
                                              {issue.dateToCompleteBy
                                                ? commonService.formatDate(
                                                    issue.dateToCompleteBy,
                                                    "Do MMM YYYY"
                                                  )
                                                : ""}
                                            </span>
                                          </div>

                                          {issue.actionComment && (
                                            <div className="col-md-12 inspection-issue-details-item">
                                              <strong>Comment:</strong>
                                              <span>{issue.actionComment}</span>
                                            </div>
                                          )}

                                          {issue.dateClosed && (
                                            <div className="col-md-12 inspection-issue-details-item">
                                              <strong>Date Closed:</strong>
                                              <span>
                                                {issue.dateClosed
                                                  ? commonService.formatDate(
                                                      issue.dateClosed,
                                                      "Do MMM YYYY"
                                                    )
                                                  : ""}
                                              </span>
                                            </div>
                                          )}

                                          {issue.closureDetails && (
                                            <div className="col-md-12 inspection-issue-details-item">
                                              <strong>Closure Details:</strong>
                                              <span>{issue.closureDetails}</span>
                                            </div>
                                          )}

                                          <div className="d-flex inspection-issue-details-files">
                                            <div className="col-md-6">
                                              <strong>Images added when opened</strong>
                                              <div className="inspection-issue-details-files-grid">
                                                <div className="d-flex">
                                                  {issue.actionFiles &&
                                                    issue.actionFiles.split(",").map((imgPreviewUrl) => (
                                                      <div
                                                        id="profile_pic_1_preview"
                                                        className="image-fixed m-2"
                                                      >
                                                        {commonService.outputImageTile(imgPreviewUrl)}
                                                      </div>
                                                    ))}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              <strong>Images added when closed</strong>
                                              <div className="inspection-issue-details-files-grid">
                                                <div className="d-flex">
                                                  {issue.closureFiles &&
                                                    issue.closureFiles
                                                      .split(",")
                                                      .map((imgPreviewUrl) => (
                                                        <div
                                                          id="profile_pic_1_preview"
                                                          className="image-fixed m-2"
                                                        >
                                                          {commonService.outputImageTile(
                                                            imgPreviewUrl
                                                          )}
                                                        </div>
                                                      ))}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {issue.closureSignature ?
                                          <div className="assessment-issue-closure-signature">
                                            <strong>Closure Signature</strong><br />
                                            <img
                                              className="obj-fit-cover"
                                              height="250"
                                              width="250"
                                              alt="Signature"
                                              src={
                                                issue.closureSignature
                                                  ? process.env.REACT_APP_API_BASEURL +
                                                  issue.closureSignature
                                                  : ''
                                              }
                                              />
                                            </div>
                                            : ''}
                                          
                                          { data.isCompleted != 1 && (user.role == "admin" ||
                                              user.id == data.addedByInCompany ||
                                              userPermissions.includes(
                                                "submitriskAssessments"
                                              )) && 
                                              <button
                                                className="btn btn-outline-success btn-edit-action"
                                                onClick={() => {
                                                  setPopup(false);
                                                  setNewIssueData({
                                                    ...newIssueData,
                                                    riskAssessmentIssueId: issue.id,
                                                    revise: false,
                                                    dateToCompleteBy: issue.dateToCompleteBy ? commonService.formatDate(issue.dateToCompleteBy, "YYYY-MM-DD") : "",
                                                    requiredAction: issue.requiredAction,
                                                    assignedTo: issue.assignedTo,
                                                    comment: issue.actionComment,
                                                    status: issue.status
                                                  })
                                                }}
                                              >
                                                Edit Issue
                                              </button>
                                            }

                                        </div>
                                        </>}
                                      </div>

                                    </div>
                                  )
                              )}
                            </div>
                          )
                          : "No rows added yet."}

                        {(user.role.includes("admin") ||
                          userPermissions.includes(
                            "submitriskAssessments"
                          )) &&
                          data.isCompleted != 1 && data.isClosed != 1 && (
                            <>
                              <div className="add-new-issue-button">
                                <button
                                  className="btn btn-danger"
                                  onClick={addNewAssessmentRow}
                                >
                                  Add New Row
                                </button>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="col-12 d-flex flex-spaced">
                  <button
                    className="btn btn-outline-success ms-1"
                    onClick={() => setMatrixPopup(true)}
                  >
                    Risk Matrix Table
                  </button>
                  
                  {data.isCompleted == 1 &&
                    (user.role.includes("admin") ||
                      (
                        siteUsers
                        .filter((v) => v.userid == user.id).length && siteUsers
                        .filter((v) => v.userid == user.id)[0].type == 'admin'
                      ) ||
                      user.id == data.addedByInCompany) && (
                      <>
                        <PDFDownloadLink
                          document={<MyDoc />}
                          className="btn btn-primary pdf-download-btn"
                          fileName={"inspection-" + data.id + ".pdf"}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : "Download PDF"
                          }
                        </PDFDownloadLink>
                      </>
                    )}

                  { data.isCompleted != 1 && data.isClosed != 1 && data.issues.length == data.issues.filter(issue => issue.isSaved ).length && ( 0 < data.issues.filter(issue => ( (issue.rr >= 10) && issue.status != 'Closed') ).length ) && <>
                    <div class="complete-inspection">
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          handleClosePopup();
                        }}
                      >
                        Close Assessment
                      </button>
                    </div>
                  </>}

                  {data.isCompleted != 1 && data.isClosed == 1 && ! ( data.issues.length == data.issues.filter(issue => issue.isSaved && ( (issue.status == null && issue.rr < 10) || issue.status == 'Closed') ).length ) && <>
                    <div class="complete-inspection">
                      <button
                        className="btn btn-danger">
                        Assessment Closed
                      </button>
                    </div>
                  </>}

                  {data.isCompleted != 1 && ( data.issues.length == data.issues.filter(issue => issue.isSaved && ( (issue.status == null && issue.rr < 10) || issue.status == 'Closed') ).length ) &&
                    (user.role.includes("admin") ||
                      user.id == data.addedByInCompany) && (
                      <>
                        <div class="complete-inspection">
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              setCompleteAssessmentPopup(true);
                              // handleComplete(data.id, "this assessment");
                            }}
                          >
                            Complete Assessment
                          </button>
                        </div>
                      </>
                    )}
                </div>

              </div>
            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
