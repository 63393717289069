import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import "./ga1RoTEPermitView.css";
import { commonService } from "../common/common.service";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import {QRCodeCanvas} from 'qrcode.react';
import Logo from '../../images/logo.svg'


export default function ViewGA1RoTEPermitQR({ title }) {
  
    let { id } = useParams();
  
    const history = useHistory();
    const { user: userDetails } = useAppState()
    const userToken = localStorage.getItem("cs_token");
    const [loading, setLoading] = useState(true);
    const [qrpopup, setQRLoading] = useState(true);
    const [data, setData] = useState({});
    const [siteUsers, setSiteUsers] = useState([])
    const [activities, setActivities] = useState([])
    const [popup, setPopup] = useState(true);
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");

    const [fieldData, setFieldData] = useState({
        ga1Id: id,
    });

    const fetchGA1RoteQRDetails = () => {
        setLoading(true);
        axiosInstance
          .post(
            `${process.env.REACT_APP_API_BASEURL}/ga1rote/qr/details`,
            {
              ga1roteId: id,
              companyId: ( userDetails.role.includes("admin") ? userDetails.id : userDetails.added_by ),
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
          .then((res) => {
            setPopup(false);
            setLoading(false);

            setData( res.data );
          })
          .catch((err) => {

            // check if code is 403
            if (err?.response?.status === 403) {
              
              toast.error(
                err?.response?.data?.message || "Unable to process request"
              );
              
              // go to /
              history.push("/");

              return;
            }

            setLoading(false);
            toast.error(
              err?.response?.data?.message || "Unable to process request"
            );
            console.log("errr", err);
          });
      };

    useEffect(() => {
        if (id) {
          fetchGA1RoteQRDetails();
        }
      }, [id]);

    return (
        <div className="middle_page">
          <Header
            title={title + " #" + id}
            steps={[
              { name: "Home", path: "/" },
              { name: "GA1 RoTE List", path: "/ga1rote-permits" },
              { name: "GA1 RoTE", path: `/ga1rote-permit/${id}` },
            ]}
          />
          <CustomPopup
            trigger={!popup}
            showClose={false}
            setTrigger={() => {
              setPopup(true)
              setFieldData({
                ga1Id: data?.ga1Id,
              })
            }}
          >
            
            <h4 className="mb-20">You opened this link from a QR Code.<br/>Choose an action below:</h4>

            {data.serialNumber && <>
                <h4 className="mb-20">(Serial Number: {data.serialNumber})</h4>
            </>}

            <div className="d-flex justify-center flex-wrap qr-code-popup-actions">
                
                {data.ga1Id && <>
                    <button className="btn btn-success" onClick={() => history.push(`/ga1rote-permit/${data.ga1Id}`)}>Open GA1</button>
                </>}
                
                {data.serialNumber && <>
                    <button className="btn btn-success" onClick={() => history.push(`/ga2s-permits-by-serial-number/${data.serialNumber}`)}>Open Related GA2s</button>
                </>}
                
                <button className="btn btn-success" onClick={() => history.push("/")}>Dashboard</button>
            </div>
            
          </CustomPopup>

        { loading ? <>
            <div>Fetching details..</div>
        </> : <>
            
        </> }

        </div>
      );

};