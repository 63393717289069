import React from 'react'
import Header from '../Header/Header'
import { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../helpers/request';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppState } from '../../state';
import Swal from "sweetalert2";
import SubmitLoader from '../common/SubmitLoader';

function CreateDepartment({ title }) {
    const { user } = useAppState()
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()
    const isEdit = location.pathname.includes("edit-department")
    const userToken = localStorage.getItem("cs_token");

    const [data, setData] = useState({
        name: "",
        description: "",
        siteId: "",
        addedByInCompany:""
    })
    const [loading, setLoading] = useState(false)
    const [companyId, setUserID] = useState("")
    const [departmentUsers, setDepartmentUsers] = useState([])
    const [roles, setRoles] = useState([]);
    const [sites, setSites] = useState([]);
    const [newUser, setUser] = useState(null);

    useEffect(() => {
        userDetail()
        if (isEdit && id) {
            getDepartment()
        }
    }, [])

    useEffect(() => {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-sites`, {
                companyId: companyId,
                status: ""
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setSites(res.data.filter((v,i)=>res.data.findIndex(v1=>v1.id==v.id) == i));
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
        // getUsers()
    }, [companyId])

    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setUserID(((res.data[0].role!=="admin")&&res.data[0].added_by) ? res.data[0].added_by : res.data[0].id);
                    if(!isEdit){
                        setData({
                            ...data,
                            addedByInCompany:res.data[0].id
                        })
                    }
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    // const getUsers = () => {
    //     axiosInstance
    //         .post(`${process.env.REACT_APP_API_BASEURL}/all-users`, {
    //             companyId: companyId,
    //             status: ""
    //         }, {
    //             headers: {
    //                 Authorization: `Bearer ${userToken}`,
    //             },
    //         })
    //         .then((res) => {
    //             if (res.data.length > 0) {
    //                 setUsers(res.data);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("errr", err);
    //         });
    // }

    function getDepartment() {
        axiosInstance
            .get(`${process.env.REACT_APP_API_BASEURL}/department/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length) {
                    setData(res.data[0]);
                    // if (res.data.map(v => v.userid).filter(v => v).length) {
                    //     getDepartmentUsers(res.data.map(v => v.userid).filter(v => v))
                    // } else {
                    //     setDepartmentUsers([])
                    // }
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    function getDepartmentUsers(userIds) {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/get-all-department-users`, userIds.map(v => parseInt(v)).sort(), {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length) {
                    setDepartmentUsers(res.data);
                    // setUniqueRoles(res.data.map(v => v.role.split(",")).flat().filter((u, i, arr) => i == arr.findIndex(x => x === u)))
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = () => {
        if (data.name && data.siteId) {
            setLoading(true)
            if (isEdit) {
                axiosInstance.put(`/department/${id}`, {
                    ...data,
                }).then(async (response) => {
                    if (response.status === 200) {
                        // setData(response.data)
                        toast.success("Successfully Updated department")
                        history.push("/departments")
                    } else {
                        throw new Error(response?.error);
                    }
                    setLoading(false)
                }).catch(err => {
                    console.log(err)
                    setLoading(false)
                    toast.error("Failed to Update department")
                });
            } else {
                axiosInstance.post('/add-department', {
                    ...data,
                    companyId,
                }, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }).then(async (response) => {
                    if (response.status === 200) {
                        // setData(response.data)
                        toast.success("Successfully Added department")
                        history.push("/departments")
                    } else {
                        throw new Error(response?.error);
                    }
                    setLoading(false)
                }).catch(err => {
                    console.log(err)
                    setLoading(false)
                    toast.error("Failed to add department")
                });
            }
        } else {
            toast.error("Name is a mandatory field.")
        }
    }

    const handleDelete = async (id, name) => {

        const result = await Swal.fire({
            title: `Are you sure to remove ${name} from this department`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .post(process.env.REACT_APP_API_BASEURL + "/department-user/" + id)
                .then(function (response) {
                    getDepartment();
                    toast.success("User deleted successfully");
                })
                .catch(function (error) {
                    toast.error(error?.response?.data?.message || "Unable to delete user");
                });
        }
    };


    return (
        <div class="middle_page">
            <Header title={title} steps={[{ name: "Home", path: "/" }, {name: "Department List", path: "/departments"}, { name: !isEdit ? "Add Department" : "Edit Department", path: "/create-department" }]} />
            <div class="main_container">
                {/* <p class="legal">(In Contemplation of Legal Proceedings)</p> */}
                <div>
                    <div class="accident">
                        <div class="accident_text">
                            <h5>{!isEdit ? "Add Department" : "Edit Department"}</h5>
                        </div>
                        {/* <div class="cross_icon"><img src={require("../../images/minus.png")} alt="" /></div> */}
                    </div>
                    <form>
                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div class="form_inner p-2">
                                    <label for="">Choose the site this department will be for <span className='text-danger'>*</span></label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <select name="siteId" value={data.siteId} onChange={handleChange} className="w-100" >
                                                <option value="">Select Site</option>
                                                {sites.map(s => (
                                                    <option value={s.id}>{s.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Name of the Department <span className='text-danger'>*</span></label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={data.name} name="name" onChange={handleChange} placeholder="Name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Description </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={data.description} name="description" onChange={handleChange} placeholder="Descriptions" />
                                        </div>
                                    </div>
                                </div>

                                {departmentUsers.length > 0 && <div className='form_inner p-2'>
                                    <label for="">Users</label>
                                    <div id="wrapper" style={{ maxHeight: "600px" }}>
                                        <table class="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="5%">View <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Full Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Email <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Company Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Role <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {departmentUsers.map(d => (<tr key={d.id}>
                                                    <th scope="row" className='cursor-pointer' onClick={() => {
                                                        history.push("/view-user/" + d.id)
                                                    }}>{d.id}</th>
                                                    <td>{d.fullName}</td>
                                                    <td>{d.email}</td>
                                                    <td>{d.companyName}</td>
                                                    <td>{d.role?.split(",").map(v => roles.filter(r => r.id == v).length ? roles.filter(r => r.id == v)[0].name : v)}</td>
                                                    {user.role.includes("admin") && <td><img src={require("../../images/table-cross.png")} alt="" onClick={() => {
                                                        handleDelete(d.id, d.fullName)
                                                    }} /></td>}
                                                </tr>))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>}

                                {/* <div class="form_inner p-2">
                                    <label for="">Add User</label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <select name="role" value={newUser} onChange={e => setUser(e.target.value)} className="w-100" >
                                                <option value="">Select User</option>
                                                {users.filter(v => !departmentUsers.map(v => v.id).includes(v.id)).map(u => (
                                                    <option value={u.id}>{u.fullName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </form>
                    <div class="signature">
                        <div class="sign_btns">
                            <div>
                                {!loading ? <button class="submit" disabled={loading} onClick={handleSubmit}>{!isEdit ? "Create" : "Update"}</button> : <SubmitLoader />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateDepartment