export const permissions = [
  {
    name: "Main Navigation",
    children: [
      {
        name: "accidentNotification",
        label: "Accident Notifications",
        icon: "notification.svg",
        cardIcon: "1-27.svg",
        children: [
          {
            name: "viewaccidentNotification",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/accident-notifications",
          },
          {
            name: "viewAllaccidentNotification",
            label: "View All",
            icon: "eye.svg",
            path: "/accident-notifications",
          },
          {
            name: "deletedaccidentNotification",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/accident-notifications",
          },
          {
            name: "submitaccidentNotification",
            label: "Create New",
            icon: "plus.svg",
            path: "/accident-notifications/add-notification",
          },
          {
            name: "accidentNotificationreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "accidentReports",
        label: "Accident Reports",
        icon: "nav-report.svg",
        cardIcon: "1-28.svg",
        children: [
          {
            name: "viewaccidentReports",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/view-accident-reports",
          },
          {
            name: "viewAllaccidentReports",
            label: "View All",
            icon: "eye.svg",
            path: "/view-accident-reports",
          },
          {
            name: "deletedaccidentReports",
            label: "Deleted",
            adminOnly:true,
            icon:'eye.svg',
            path: "/deleted/view-accident-reports"
          },
          {
            name: "submitaccidentReport",
            label: "Create New",
            icon: "plus.svg",
            path: "/create-accident-report",
          },
          // {
          //   name: "archiveaccidentReports",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "submitaccidentReports",
          //   label: "Create New",
          //   icon: "plus.svg",
          //   path: "/",
          // },
          {
            name: "accidentReportsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "ppeIssueReports",
        label: "PPE Issue Reports",
        icon: "nav-ppe.svg",
        cardIcon: "1-24.svg",
        children: [
          {
            name: "viewppeIssueReports",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/ppe-issue-reports",
          },
          {
            name: "viewAllppeIssueReports",
            label: "View All",
            icon: "eye.svg",
            path: "/ppe-issue-reports",
          },
          {
            name: "editppeIssueReports",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedppeIssueReports",
            label: "Deleted",
            icon:'eye.svg',
            path: "/deleted/ppe-issue-reports"
          },
          // {
          //   name: "archiveppeIssueReports",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitppeIssueReports",
            label: "Create New",
            icon: "plus.svg",
            path: "/ppe-issue-report/add-ppe-issue-report",
          },
          {
            name: "ppeIssueReportsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "groupSafetyVisits",
        label: "Group Safety Manager Visits",
        icon: "nav-group.svg",
        cardIcon: "1-13.svg",
        children: [
          {
            name: "viewgroupSafetyVisits",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/groupinspections",
          },
          {
            name: "viewAllgroupSafetyVisits",
            label: "View All",
            icon: "eye.svg",
            path: "/groupinspections",
          },
          {
            name: "editgroupSafetyVisits",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/groupinspections/add-groupinspection",
          },
          {
            name: "deletedgroupSafetyVisits",
            label: "Deleted",
            icon:'eye.svg',
            path: "/deleted/groupinspections"
          },
          // {
          //   name: "archivegroupSafetyVisits",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitgroupSafetyVisits",
            label: "Create New",
            icon: "plus.svg",
            path: "/groupinspections/add-groupinspection",
          },
          {
            name: "groupSafetyVisitsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "safetyDisciplinaryLogs",
        label: "Safety Disciplinary Logs",
        icon: "nav-logs.svg",
        cardIcon: "1-14.svg",
        children: [
          {
            name: "viewsafetyDisciplinaryLogs",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/safety-disciplinary-logs",
          },
          {
            name: "viewAllsafetyDisciplinaryLogs",
            label: "View All",
            icon: "eye.svg",
            path: "/safety-disciplinary-logs",
          },
          {
            name: "editsafetyDisciplinaryLogs",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedsafetyDisciplinaryLogs",
            label: "Deleted",
            icon:'eye.svg',
            path: "/deleted/safety-disciplinary-logs"
          },
          // {
          //   name: "archivesafetyDisciplinaryLogs",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitsafetyDisciplinaryLogs",
            label: "Create New",
            icon: "plus.svg",
            path: "/safety-disciplinary-log/add-disciplinary-log",
          },
          {
            name: "safetyDisciplinaryLogsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "safetyHarnessIssues",
        label: "Safety Harness Issues",
        icon: "nav-logs.svg",
        cardIcon: "1-14.svg",
        children: [
          {
            name: "viewsafetyHarnessIssues",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/safety-harness-issues",
          },
          {
            name: "viewAllsafetyHarnessIssues",
            label: "View All",
            icon: "eye.svg",
            path: "/safety-harness-issues",
          },
          {
            name: "editsafetyHarnessIssues",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedsafetyHarnessIssues",
            label: "Deleted",
            icon:'eye.svg',
            path: "/deleted/safety-harness-issues"
          },
          // {
          //   name: "archivesafetyDisciplinaryLogs",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitsafetyHarnessIssues",
            label: "Create New",
            icon: "plus.svg",
            path: "/safety-harness-issues/add",
          },
          {
            name: "safetyHarnessIssuesreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      // {
      //   name: "safetyDisciplinaryIssues",
      //   label: "Safety Disciplinary Issues",
      //   icon: "nav-issue.svg",
      //   cardIcon: "card-ga2.svg",
      //   path: "/",
      //   children: [
      //     {
      //       name: "viewsafetyDisciplinaryIssues",
      //       label: "View Own",
      //       icon: 'eye.svg',
      //       path: "/"
      //     },
      //     {
      //       name: "viewAllsafetyDisciplinaryIssues",
      //       label: "View All",
      //       icon: 'eye.svg',
      //       path: "/"
      //     },
      //     {
      //       name: "editsafetyDisciplinaryIssues",
      //       label: "Edit",
      //       type: "non-sidebar",
      //       icon: 'eye.svg',
      //       path: "/"
      //     },
      //     // {
      //     //   name: "deletesafetyDisciplinaryIssues",
      //     //   label: "Delete",
      //     //   adminOnly:true,
      //     //   type:"non-sidebar",
      //     //   icon:'eye.svg',
      //     //   path: "/"
      //     // },
      //     // {
      //     //   name: "archivesafetyDisciplinaryIssues",
      //     //   label: "Archive",
      //     //   adminOnly:true,
      //     //   type:"non-sidebar",
      //     //   icon:'eye.svg',
      //     //   path: "/"
      //     // },
      //     {
      //       name: "submitsafetyDisciplinaryIssues",
      //       label: "Create New",
      //       icon: 'plus.svg',
      //       path: "/"
      //     },
      //     {
      //       name: "safetyDisciplinaryIssuesreceiveEmails",
      //       label: "Receive Emails for this section",
      //       email: true,
      //       type: "non-sidebar",
      //       icon: 'plus.svg',
      //       path: "/"
      //     },
      //   ]
      // },
      {
        name: "safetyObservation",
        label: "Safety Observation",
        icon: "nav-observation.svg",
        cardIcon: "1-09.svg",
        children: [
          {
            name: "viewsafetyObservation",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/safety-observations",
          },
          {
            name: "viewAllsafetyObservation",
            label: "View All",
            icon: "eye.svg",
            path: "/safety-observations",
          },
          {
            name: "editsafetyObservation",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedsafetyObservation",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/safety-observations",
          },
          {
            name: "submitsafetyObservation",
            label: "Create New",
            icon: "plus.svg",
            path: "/safety-observation/add-observation",
          },
          {
            name: "safetyObservationreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        label: "Safety Officer Inspections",
        name: "safetyOfficerInspections",
        icon: "nav-officer.svg",
        cardIcon: "1-26.svg",
        children: [
          {
            name: "viewsafetyOfficerInspections",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/inspections",
          },
          {
            name: "viewAllsafetyOfficerInspections",
            label: "View All",
            icon: "eye.svg",
            path: "/inspections",
          },
          {
            name: "editsafetyOfficerInspections",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedsafetyOfficerInspections",
            label: "Deleted",
            icon:'eye.svg',
            path: "/deleted/inspections"
          },
          // {
          //   name: "archivesafetyOfficerInspections",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitsafetyOfficerInspections",
            label: "Create New",
            icon: "plus.svg",
            path: "/inspections/add-inspection",
          },
          {
            name: "safetyOfficerInspectionsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        label: "Safety Checklists",
        name: "safetyChecklists",
        icon: "nav-checklist.svg",
        cardIcon: "1-25.svg",
        children: [
          {
            name: "viewsafetyChecklists",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/safety-checklists",
          },
          {
            name: "viewAllsafetyChecklists",
            label: "View All",
            icon: "eye.svg",
            path: "/safety-checklists",
          },
          {
            name: "editsafetyChecklists",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedsafetyChecklists",
            label: "Deleted",
            icon:'eye.svg',
            path: "/deleted/safety-checklists"
          },
          // {
          //   name: "archivesafetyChecklists",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitsafetyChecklists",
            label: "Create New",
            icon: "plus.svg",
            path: "/safety-checklists/add-safety-checklist",
          },
          {
            name: "safetyChecklistsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        label: "Risk Assessments",
        name: "riskAssessments",
        icon: "nav-checklist.svg",
        cardIcon: "1-25.svg",
        path: "/risk-assessments",
        children: [
          {
            name: "viewriskAssessments",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/risk-assessments",
          },
          {
            name: "viewAllriskAssessments",
            label: "View All",
            icon: "eye.svg",
            path: "/risk-assessments",
          },
          {
            name: "editriskAssessment",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedriskAssessment",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/risk-assessments",
          },
          {
            name: "submitriskAssessments",
            label: "Create New",
            icon: "plus.svg",
            path: "/risk-assessments/add-risk-assessment",
          },
          {
            name: "riskAssessmentsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        label: "Trainings",
        name: "trainings",
        icon: "nav-checklist.svg",
        cardIcon: "1-25.svg",
        children: [
          {
            name: "viewTrainings",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/trainings",
          },
          {
            name: "viewAllTrainings",
            label: "View All",
            icon: "eye.svg",
            path: "/trainings",
          },
          {
            name: "viewRequiredTrainings",
            label: "Required Trainings",
            icon: "eye.svg",
            path: "/required-trainings",
          },
          {
            name: "viewCompletedTrainings",
            label: "Completed Trainings",
            icon: "eye.svg",
            path: "/completed-trainings",
          },
          {
            name: "editTraining",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedTraining",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/trainings",
          },
          {
            name: "submitTrainings",
            label: "Create New",
            icon: "plus.svg",
            path: "/trainings/add",
          },
          {
            name: "trainingsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
    ],
  },
  {
    name: "Permits",
    children: [
      {
        name: "ladderPermits",
        label: "Ladder Permits",
        icon: "nav-ladder.svg",
        cardIcon: "1-02.svg",
        children: [
          // {
          //   name: "viewaccidentNotification",
          //   label: "Add Ladder Permit",
          //   icon:'eye.svg',
          //   path: "/ladder/add-permit"
          // },
          {
            name: "viewladderPermits",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/ladder-permits",
          },
          {
            name: "viewAllladderPermits",
            label: "View All",
            icon: "eye.svg",
            path: "/ladder-permits",
          },
          {
            name: "deletedladderPermits",
            label: "Deleted Permits",
            icon: "eye.svg",
            path: "/deleted/ladder-permits",
          },
          {
            name: "submitladderPermits",
            label: "Create New",
            icon: "plus.svg",
            path: "/ladder/add-permit",
          },
          {
            name: "ladderPermitsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "hotWorksPermits",
        label: "Hot Works Permits",
        icon: "nav-hot.svg",
        cardIcon: "1-01.svg",
        children: [
          {
            name: "viewhotworksPermits",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/hotworks-permits",
          },
          {
            name: "viewAllhotworksPermits",
            label: "View All",
            icon: "eye.svg",
            path: "/hotworks-permits",
          },
          {
            name: "deletedhotworksPermits",
            label: "Deleted Permits",
            icon: "eye.svg",
            path: "/deleted/hotworks-permits",
          },
          {
            name: "submithotworksPermits",
            label: "Create New",
            icon: "plus.svg",
            path: "/hotworks/add-permit",
          },
          {
            name: "hotWorksPermitsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "tempWorksPermits",
        label: "Temporary Works Permits",
        icon: "nav-hot.svg",
        cardIcon: "1-04.svg",
        children: [
          {
            name: "viewtempWorksPermits",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/temporary-permits",
          },
          {
            name: "viewAlltempWorksPermits",
            label: "View All",
            icon: "eye.svg",
            path: "/temporary-permits",
          },
          {
            name: "edittempWorksPermits",
            label: "Deleted Permits",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/deleted/temporary-permits",
          },
          // {
          //   name: "archivetempWorksPermits",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submittempWorksPermits",
            label: "Create New",
            icon: "plus.svg",
            path: "/temporary/add-permit",
          },
          {
            name: "tempWorksPermitsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "accessPermits",
        label: "Access Permits",
        icon: "nav-access.svg",
        cardIcon: "1-16.svg",
        children: [
          {
            name: "viewaccessPermits",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/access-permits",
          },
          {
            name: "viewAllaccessPermits",
            label: "View All",
            icon: "eye.svg",
            path: "/access-permits",
          },
          {
            name: "deletedaccessPermits",
            label: "Deleted Permits",
            icon: "eye.svg",
            path: "/deleted/access-permits",
          },
          {
            name: "submitaccessPermits",
            label: "Create New",
            icon: "plus.svg",
            path: "/access/add-permit",
          },
          {
            name: "accessPermitsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "riserPermits",
        label: "Riser Permits",
        icon: "nav-riser.svg",
        cardIcon: "1-03.svg",
        children: [
          {
            name: "viewriserPermits",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/riser-permits",
          },
          {
            name: "viewAllriserPermits",
            label: "View All",
            icon: "eye.svg",
            path: "/riser-permits",
          },
          {
            name: "editriserPermits",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/deleted/riser-permits",
          },
          {
            name: "deletedriserPermits",
            label: "Deleted",
            icon:'eye.svg',
            path: "/deleted/riser-permits"
          },
          // {
          //   name: "archiveriserPermits",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitriserPermits",
            label: "Create New",
            icon: "plus.svg",
            path: "/riser/add-permit",
          },
          {
            name: "riserPermitsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "digPermits",
        label: "Dig Permits",
        icon: "nav-dig.svg",
        cardIcon: "1-15.svg",
        children: [
          {
            name: "viewdigPermits",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/dig-permits",
          },
          {
            name: "viewAlldigPermits",
            label: "View All",
            icon: "eye.svg",
            path: "/dig-permits",
          },
          {
            name: "editdigPermits",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deleteddigPermits",
            label: "Deleted",
            icon:'eye.svg',
            path: "/deleted/dig-permits"
          },
          // {
          //   name: "archivedigPermits",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitdigPermits",
            label: "Create New",
            icon: "plus.svg",
            path: "/dig/add-permit",
          },
          {
            name: "digPermitsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
    ],
  },
  {
    name: "Statutory Forms",
    children: [
      {
        name: "ga4workequipmentspermits",
        label: "GA4 Work Equipments",
        icon: "notification.svg",
        cardIcon: "1-19.svg",
        children: [
          {
            name: "viewga4workequipments",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/ga4workequipments-permits",
          },
          {
            name: "viewAllga4workequipments",
            label: "View All",
            icon: "eye.svg",
            path: "/ga4workequipments-permits",
          },
          {
            name: "editga4workequipments",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedga4workequipments",
            label: "Deleted",
            icon:'eye.svg',
            path: "/deleted/ga4workequipments-permits"
          },
          // {
          //   name: "archivega3workingatheights",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitga4workequipments",
            label: "Create New",
            icon: "plus.svg",
            path: "/ga4workequipments/add-permit",
          },
          {
            name: "ga4workequipmentsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "ga3workingatheightspermits",
        label: "GA3 Working at Heights",
        icon: "nav-ga3.svg",
        cardIcon: "1-06.svg",
        children: [
          {
            name: "viewga3workingatheights",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/ga3workingatheights-permits",
          },
          {
            name: "viewAllga3workingatheights",
            label: "View All",
            icon: "eye.svg",
            path: "/ga3workingatheights-permits",
          },
          {
            name: "editga3workingatheights",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/deleted/ga3workingatheights-permits",
          },
          {
            name: "deletedga3workingatheights",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/ga3workingatheights-permits",
          },
          // {
          //   name: "deletega3workingatheights",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archivega3workingatheights",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitga3workingatheights",
            label: "Create New",
            icon: "plus.svg",
            path: "/ga3workingatheights/add-permit",
          },
          {
            name: "ga3workingatheightsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "ga3cantideckinspectionPermits",
        label: "GA3 CantiDeck",
        icon: "nav-ga3.svg",
        cardIcon: "1-20.svg",
        children: [
          {
            name: "viewga3cantideckinspection",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/ga3cantideckinspection-permits",
          },
          {
            name: "viewAllga3cantideckinspection",
            label: "View All",
            icon: "eye.svg",
            path: "/ga3cantideckinspection-permits",
          },
          {
            name: "editga3cantideckinspection",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/deleted/ga3cantideckinspection-permits",
          },
          {
            name: "deletedga3cantideckinspection",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/ga3cantideckinspection-permits",
          },
          // {
          //   name: "deletega3workingatheights",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archivega3workingatheights",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitga3cantideckinspection",
            label: "Create New",
            icon: "plus.svg",
            path: "/ga3cantideckinspection/add-permit",
          },
          {
            name: "ga3cantideckinspectionreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "ga2plantonly",
        label: "GA2 Plant Only",
        icon: "notification.svg",
        cardIcon: "1-18.svg",
        children: [
          {
            name: "viewga2plantonly",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/ga2-permits",
          },
          {
            name: "viewAllga2plantonly",
            label: "View All",
            icon: "eye.svg",
            path: "/ga2-permits",
          },
          {
            name: "editga2plantonly",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedga2plantonly",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/ga2-permits",
          },
          // {
          //   name: "deleteaccidentType",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archiveaccidentType",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitga2plantonly",
            label: "Create New",
            icon: "plus.svg",
            path: "/ga2/add-permit",
          },
          {
            name: "ga2plantonlyreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "ga2liftingequipmentonlypermits",
        label: "GA2 Lifting Equipment",
        icon: "notification.svg",
        cardIcon: "1-05.svg",
        children: [
          {
            name: "viewga2liftingequipmentonly",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/ga2liftingequipmentonly-permits",
          },
          {
            name: "viewAllga2liftingequipmentonly",
            label: "View All",
            icon: "eye.svg",
            path: "/ga2liftingequipmentonly-permits",
          },
          {
            name: "editga2liftingequipmentonly",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedga2liftingequipmentonly",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/ga2liftingequipmentonly-permits",
          },
          // {
          //   name: "deletega3workingatheights",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archivega3workingatheights",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitga2liftingequipmentonly",
            label: "Create New",
            icon: "plus.svg",
            path: "/ga2liftingequipmentonly/add-permit",
          },
          {
            name: "ga2liftingequipmentonlyreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },

      {
        name: "ga1rote",
        label: "GA1 Thorough Examination",
        icon: "nav-ga1.svg",
        cardIcon: "1-17.svg",
        children: [
          {
            name: "viewga1rote",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/ga1rote-permits",
          },
          {
            name: "viewAllga1rote",
            label: "View All",
            icon: "eye.svg",
            path: "/ga1rote-permits",
          },
          {
            name: "editga1rote",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedga1rote",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/ga1rote-permits",
          },
          
          // {
          //   name: "deletega1rote",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archivega1rote",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitga1rote",
            label: "Create New",
            icon: "plus.svg",
            path: "/ga1rote/add-permit",
          },
          {
            name: "ga1rotereceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "fireChecklist",
        label: "Fire Checklists",
        icon: "nav-fire.svg",
        cardIcon: "1-12.svg",
        children: [
          {
            name: "viewfireChecklist",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/firecheck-permits",
          },
          {
            name: "viewAllfireChecklist",
            label: "View All",
            icon: "eye.svg",
            path: "/firecheck-permits",
          },
          {
            name: "editfireChecklist",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedfireChecklist",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/firecheck-permits",
          },
          // {
          //   name: "deletefireChecklist",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archivefireChecklist",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitfireChecklist",
            label: "Create New",
            icon: "plus.svg",
            path: "/firecheck/add-permit",
          },
          {
            name: "fireChecklistreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "hoistChecklists",
        label: "Hoist Checklists",
        icon: "nav-hoist.svg",
        cardIcon: "1-21.svg",
        children: [
          {
            name: "viewhoistChecklists",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/hoistchecks-permits",
          },
          {
            name: "viewAllhoistChecklists",
            label: "View All",
            icon: "eye.svg",
            path: "/hoistchecks-permits",
          },
          {
            name: "edithoistChecklists",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedhoistChecklists",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/hoistchecks-permits",
          },
          // {
          //   name: "deletehoistChecklists",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archivehoistChecklists",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submithoistChecklists",
            label: "Create New",
            icon: "plus.svg",
            path: "/hoistchecks/add-permit",
          },
          {
            name: "hoistChecklistsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "towerCraneChecklists",
        label: "Tower Crane Checklists",
        icon: "nav-tower.svg",
        cardIcon: "1-22.svg",
        children: [
          {
            name: "viewtowerCraneChecklists",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/towercrane-permits",
          },
          {
            name: "viewAlltowerCraneChecklists",
            label: "View All",
            icon: "eye.svg",
            path: "/towercrane-permits",
          },
          {
            name: "edittowerCraneChecklists",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedtowerCraneChecklists",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/towercrane-permits",
          },
          // {
          //   name: "deletetowerCraneChecklists",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archivetowerCraneChecklists",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submittowerCraneChecklists",
            label: "Create New",
            icon: "plus.svg",
            path: "/towercrane/add-permit",
          },
          {
            name: "towerCraneChecklistsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        label: "Excavation Examinations",
        name: "excavationExaminations",
        icon: "nav-evacuation.svg",
        cardIcon: "1-08.svg",
        children: [
          {
            name: "viewexcavationExaminations",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/excavationinspection-permits",
          },
          {
            name: "viewAllexcavationExaminations",
            label: "View All",
            icon: "eye.svg",
            path: "/excavationinspection-permits",
          },
          {
            name: "editexcavationExaminations",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedexcavationExaminations",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/excavationinspection-permits",
          },
          // {
          //   name: "deleteexcavationExaminations",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archiveexcavationExaminations",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitexcavationExaminations",
            label: "Create New",
            icon: "plus.svg",
            path: "/excavationinspection/add-permit",
          },
          {
            name: "excavationExaminationsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "scaffoldingPermits",
        label: "GA3 Scaffolding Inspection",
        icon: "nav-ga3.svg",
        cardIcon: "1-07.svg",
        children: [
          {
            name: "viewscaffolding",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/scaffolding-permits",
          },
          {
            name: "viewAllscaffolding",
            label: "View All",
            icon: "eye.svg",
            path: "/scaffolding-permits",
          },
          {
            name: "editscaffolding",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "deletedscaffolding",
            label: "Deleted",
            icon: "eye.svg",
            path: "/deleted/scaffolding-permits",
          },
          // {
          //   name: "deletega3workingatheights",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archivega3workingatheights",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitscaffolding",
            label: "Create New",
            icon: "plus.svg",
            path: "/scaffolding/add-permit",
          },
          {
            name: "scaffoldingreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
    ],
  },
  {
    name: "Reports",
    children: [
      {
        name: "reportsAccidentNotification",
        label: "Accident Reports",
        icon: "nav-report.svg",
        cardIcon: "1-28.svg",
        children: [
          {
            name: "viewreportsAccidentNotification",
            label: "View Own",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "viewAllreportsAccidentNotification",
            label: "View All",
            icon: "eye.svg",
            path: "/",
          },
          // {
          //   name: "editreportsAccidentNotification",
          //   label: "Edit",
          //   type: "non-sidebar",
          //   icon: "eye.svg",
          //   path: "/",
          // },
          // {
          //   name: "deletereportsAccidentNotification",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archivereportsAccidentNotification",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitreportsAccidentNotification",
            label: "Create New",
            icon: "plus.svg",
            path: "/",
          },
          {
            name: "reportsAccidentNotificationreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "reportsSafetyObservation",
        label: "Safety Observation Reports",
        icon: "nav-observation.svg",
        cardIcon: "1-09.svg",
        children: [
          {
            name: "viewreportsSafetyObservation",
            label: "View Own",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "viewAllreportsSafetyObservation",
            label: "View All",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "editreportsSafetyObservation",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          // {
          //   name: "deletereportsSafetyObservation",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archivereportsSafetyObservation",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitreportsSafetyObservation",
            label: "Create New",
            icon: "plus.svg",
            path: "/",
          },
          {
            name: "reportsSafetyObservationreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "siteReports",
        label: "Site Reports",
        icon: "nav-report.svg",
        cardIcon: "1-28.svg",
        children: [
          {
            name: "viewsiteReports",
            label: "View Own",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "viewAllsiteReports",
            label: "View All",
            icon: "eye.svg",
            path: "/",
          },
          {
            name: "editsiteReports",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          // {
          //   name: "deletesiteReports",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archivesiteReports",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "submitsiteReports",
            label: "Create New",
            icon: "plus.svg",
            path: "/",
          },
          {
            name: "siteReportsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "viewActions",
        label: "View Actions",
        icon: "nav-view.svg",
        cardIcon: "card-view.svg",
        children: [
          {
            name: "viewviewActions",
            label: "View Own",
            icon: "eye.svg",
            path: "/view-own/view-actions-reports",
          },
          {
            name: "viewAllviewActions",
            label: "View All",
            icon: "eye.svg",
            path: "/view-actions-reports",
          },
          {
            name: "editviewActions",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          // {
          //   name: "deleteviewActions",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archiveviewActions",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "submitviewActions",
          //   label: "Create New",
          //   icon: 'plus.svg',
          //   path: "/"
          // },
          {
            name: "viewActionsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
    ],
  },
  {
    name: "Manage",
    children: [
      {
        name: "companies",
        requiresSuperAdmin: true,
        label: "Clients",
        icon: "notification.svg",
        cardIcon: "card-ga2.svg",
        children: [
          {
            name: "companies",
            label: "List",
            path: "/companies",
            icon: "eye.svg",
          },
          {
            name: "submitCompanies",
            label: "Create New",
            icon: "plus.svg",
            path: "/companies/add",
          }
        ],
      },
      {
        name: "company",
        label: "Company",
        icon: "notification.svg",
        cardIcon: "card-ga2.svg",
        children: [
          {
            name: "company-overview",
            label: "Overview",
            path: "/company/overview",
            icon: "eye.svg",
          },
          {
            name: "edit-details",
            admin: true,
            label: "Edit Details",
            path: "/company/details",
            icon: "eye.svg",
          },
          {
            name: "change-price-plan",
            admin: true,
            label: "Change Price Plan",
            path: "/company/change-price-plan",
            icon: "eye.svg",
          },
            {
            name: "manageSafetyObservations",
            label: "Configure Safety Observations",
            adminOnly: true,
            icon: "eye.svg",
            path: "/manage/safety-observations",
          },
        ],
      },
      // {
      //   name: "documents",
      //   label: "Documents",
      //   icon: "notification.svg",
      //   cardIcon: "card-ga2.svg",
      //   children: [
      //     {
      //       name: "document-overview",
      //       label: "Document Overview",
      //       path: "/documents/overview",
      //       icon: "eye.svg"
      //     },{
      //       name: "createFolder",
      //       admin: true,
      //       label: "Add Folder",
      //       path: "/create-folder",
      //       icon: "plus.svg"
      //     },
      //     {
      //       name: "upload-documents",
      //       admin: true,
      //       label: "Upload",
      //       path: "/documents/uploads",
      //       icon: "plus.svg"
      //     },
      //   ],
      // },
      {
        name: "sites",
        label: "Sites",
        icon: "notification.svg",
        cardIcon: "card-ga2.svg",
        children: [
          {
            name: "viewSites",
            label: "View Sites",
            path: "/sites",
            icon: "eye.svg",
          },
          {
            name: "viewSites",
            label: "View Own Sites",
            path: "/my-sites",
            icon: "eye.svg",
          },
          {
            name: "createSite",
            admin: true,
            label: "Create New",
            path: "/create-site",
            icon: "plus.svg",
          },
          {
            name: "editsites",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          // {
          //   name: "deleteaccidentType",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "archivesites",
            label: "Archive",
            adminOnly: true,
            // type: "non-sidebar",
            icon: "eye.svg",
            path: "/archived-sites",
          },
          {
            name: "sitesreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "contractors",
        label: "Contractors",
        icon: "notification.svg",
        cardIcon: "card-ga2.svg",
        children: [
          {
            name: "viewContractor",
            label: "View Contractors",
            path: "/contractors",
            icon: "eye.svg",
          },
          {
            name: "viewContractor",
            label: "View Own Contractors",
            path: "/my-contractors",
            icon: "eye.svg",
          },
          {
            name: "viewsitesContractor",
            label: "View Sites Contractors",
            adminOnly: true,
            type: "on-site-select",
            path: "/sites-contractors",
            icon: "eye.svg",
          },
          {
            name: "createcontractor",
            admin: true,
            label: "Add Contractor",
            path: "/create-contractor",
            icon: "plus.svg",
          },
          {
            name: "editcontractor",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          // {
          //   name: "deleteaccidentType",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },

          {
            name: "archivecontractor",
            label: "Archive",
            adminOnly: true,
            // type: "non-sidebar",
            icon: "eye.svg",
            path: "/archived-contractors",
          },
          {
            name: "contractorsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "users",
        label: "Users",
        icon: "notification.svg",
        cardIcon: "card-ga2.svg",
        children: [
          {
            name: "viewUsers",
            label: "View Users",
            path: "/users",
            icon: "eye.svg",
          },
          {
            name: "viewUsers",
            label: "View Own Users",
            path: "/my-users",
            icon: "eye.svg",
          },
          {
            name: "viewSitesUsers",
            label: "View Sites Users",
            adminOnly: true,
            type: "on-site-select",
            path: "/sites-users",
            icon: "eye.svg",
          },
          {
            name: "createUser",
            admin: true,
            label: "Add User",
            path: "/create-user",
            icon: "plus.svg",
          },
          {
            name: "editusers",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          // {
          //   name: "deleteaccidentType",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archiveaccidentType",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "usersreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "departments",
        label: "Departments",
        icon: "notification.svg",
        cardIcon: "card-ga2.svg",
        children: [
          {
            name: "viewdepartments",
            label: "View Departments",
            path: "/departments",
            icon: "eye.svg",
          },
          {
            name: "viewdepartments",
            label: "View Own Departments",
            path: "/my-departments",
            icon: "eye.svg",
          },
          {
            name: "viewSitesdepartments",
            label: "View Sites Departments",
            adminOnly: true,
            type: "on-site-select",
            path: "/sites-departments",
            icon: "eye.svg",
          },
          {
            name: "createdepartment",
            admin: true,
            label: "Add Department",
            path: "/create-department",
            icon: "plus.svg",
          },
          {
            name: "editdepartments",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          // {
          //   name: "deleteaccidentType",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "archivedepartments",
            label: "Archive",
            adminOnly: true,
            // type: "non-sidebar",
            icon: "eye.svg",
            path: "/archived-departments",
          },
          {
            name: "departmentsreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "roles",
        label: "Roles",
        superAdmin: true,
        icon: "notification.svg",
        cardIcon: "card-ga2.svg",
        children: [
          {
            name: "viewroles",
            label: "View Roles",
            path: "/roles",
            icon: "eye.svg",
          },
          {
            name: "viewroles",
            label: "View Own Roles",
            path: "/my-roles",
            icon: "eye.svg",
          },
          {
            name: "createRole",
            label: "Add Role",
            admin: true,
            path: "/create-role",
            icon: "plus.svg",
          },
          {
            name: "editRoles",
            label: "Edit",
            type: "non-sidebar",
            icon: "eye.svg",
            path: "/",
          },
          // {
          //   name: "deleteaccidentType",
          //   label: "Delete",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          // {
          //   name: "archiveaccidentType",
          //   label: "Archive",
          //   adminOnly:true,
          //   type:"non-sidebar",
          //   icon:'eye.svg',
          //   path: "/"
          // },
          {
            name: "rolesreceiveEmails",
            label: "Receive Emails for this section",
            email: true,
            type: "non-sidebar",
            icon: "plus.svg",
            path: "/",
          },
        ],
      },
      {
        name: "safetyChecklistTemplates",
        label: "Safety Checklist Templates",
        icon: "notification.svg",
        cardIcon: "card-ga2.svg",
        children: [
          {
            name: "safety-checklist-templates",
            admin: true,
            label: "ViewTemplates",
            path: "/safety/templates",
            icon: "eye.svg",
          },
          {
            name: "safety-checklist-template-create",
            admin: true,
            label: "Add template",
            path: "/safety/create-safety-template",
            icon: "plus.svg",
          },
        ],
      },
      {
        name: "courses",
        label: "Courses",
        icon: "notification.svg",
        cardIcon: "card-ga2.svg",
        children: [
          {
            name: "courses",
            admin: true,
            label: "View Courses",
            path: "/courses/list",
            icon: "eye.svg",
          },
          {
            name: "courses-create",
            admin: true,
            label: "Add Course",
            path: "/courses/create",
            icon: "plus.svg",
          },
        ],
      },
    ],
  },
];
