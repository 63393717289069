import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import { commonService } from "../common/common.service";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import fileIcon from "../../images/openfolderwhite.svg";
import { getBase64 } from "../common/imageHelper";
import Swal from "sweetalert2";
import Delete from "../../images/circle-cross.svg";

export default function ViewTrainingsDetails({ title }) {
  let { id } = useParams();
  let id_parts = id.split('-');

  let traineeId = id_parts[1];
  id = id_parts[0]; // Reset the ID to correct value

  const history = useHistory();
  const { user: userDetails, userPermissions } = useAppState()
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [siteUsers, setSiteUsers] = useState([])

  const [traineePopup, setTraineePopup] = useState(false);
  const [newTrainees, setNewTrainees] = useState([]);
  const [existingTrainees, setExistingTrainees] = useState([]);
  const [questionPopup, setQuestionPopup] = useState(false);
  const [popupData, setPopupData] = useState({
    question: "",
    options: [
      "",
      "",
      "",
    ],
    correctAnswer: "",
  });

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    approvedComment: "",
    deniedComment: "",
    signature: null,
    actionTaken: false,
    actionStatus: false,
    actionFiles: false,
  });

  const [returnData, setReturnData] = useState({
    signature: false,
    date: false,
    interpreterRequired: null
  });

  const [files, setFiles] = useState([]);
  const [answers, setAnswers] = useState({});
  const [myAnswers, setMyAnswers] = useState(null);
  const [takeAction, setTakeAction] = useState(false);
  const [actionData, setActionData] = useState({});

  const statusOptions = [
    { value: 'Open', label: 'Open', name: 'actionStatus', },
    { value: 'In Progress', label: 'In Progress', name: 'actionStatus', },
    { value: 'Closed', label: 'Closed', name: 'actionStatus', },
  ];

  const fetchTrainingDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/training/details`,
        {
          trainingId: id,
          userId: traineeId
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
        
        if (res.data) {
          var existingTraineesList = [];
          res.data.trainees.map((item) => {
            existingTraineesList.push(item.userId);
            return item;
          });

          setExistingTrainees(existingTraineesList);
        }

        getSiteUsers(res.data.siteId);

        if ( res.data.trainees.filter(v => v.userId == traineeId && v.status == 'Completed' ) ) {
          fetchMyAnswers();
        }

      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
      });
  };

  const fetchMyAnswers = async () => {
    setLoading(true)
    try {
      const myAnswersResponse = await axiosInstance.post(`/training/my-answers/${traineeId}`);
      const myAnswersResponseData = myAnswersResponse.data;
      setLoading(false)
      if (myAnswersResponseData.length) {
        setMyAnswers(myAnswersResponseData);
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      fetchTrainingDetails();
    }
  }, [id]);

  const addQuestionHandler = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/training/add-question`,
        {
          trainingId: id,
          addedBy: traineeId,
          ...popupData
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        fetchTrainingDetails();
        setQuestionPopup(false);
        setPopupData({
          question: "",
          options: [
            "",
            "",
            "",
          ],
          correctAnswer: "",
        });

        toast.success(res.data.message);
      })
      .catch((err) => {
      });
  };

  const addTraineesHandler = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/training/add-trainees`,
        {
          trainingId: id,
          trainees: newTrainees,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        fetchTrainingDetails();
        setTraineePopup(false);
        setNewTrainees([]);

        toast.success(res.data.message);
      })
      .catch((err) => {
      });
  };

  const deleteQuestionHandler = async (questionId) => {

    const result = await Swal.fire({
      title: `Are you sure you want to delete this question?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if ( result.isConfirmed ) {
      axiosInstance
        .delete(
          `${process.env.REACT_APP_API_BASEURL}/training/delete-question/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then((res) => {
          fetchTrainingDetails();
          toast.success(res.data.message);
        })
        .catch((err) => {
        });
    }
  };

  const setAnswer = (questionId, answer) => {
    setAnswers({
      ...answers,
      [questionId]: answer
    });
  }

  const handleSubmitAnswers = async () => {
    const result = await Swal.fire({
      title: `Do you want to submit answers?`,
      text: "You can only submit answers once",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Submit",
    });

    if ( result.isConfirmed ) {
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_BASEURL}/training/submit-answers`,
          {
            trainingId: id,
            answers: answers,
            userId: traineeId
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((err) => {
        });
    }
  }

  useEffect(() => {
    console.log(answers);
  }, [answers]);

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file)
        });
      }
    })

  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      let signature
      if (!fieldData.denied && !fieldData.actionTaken) {
        signature = await handleSaveVerifySign()
      }
      handleSubmitAfterSave(signature)
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (signature) => {
    const form = new FormData();
    
    form.append("actionComment", actionData.actionComment);
    form.append("signature", signature);
    form.append("trainingId", id);
    form.append("userId", traineeId);

    setLoading(true);
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/training-action-taken`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        handleClearSignature();
        fetchTrainingDetails();
        setTakeAction(false);
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Trainings", path: "/required-trainings/" },
          { name: "Training", path: `/required-training/${id}` },
        ]}
      />
   
      <div className="main_container">

      <CustomPopup
        trigger={takeAction}
        setTrigger={() => {
          setTakeAction(false)
          setFieldData({
            
          })
        }}
      >
        <div className="form_inner">
          <label>Signature:</label>
        </div>
        <div className="sign_image">
          <SignatureCanvas
            ref={signatureRef}
            canvasProps={{ width: 263, height: 270 }}
          />
          <span
            className="cancel-custom cursor-pointer"
            onClick={handleClearSignature}
          >
            <i
              className="clear_btn  text-danger fa fa-times-circle"
              aria-hidden="true"
            ></i>
          </span>
        </div>

        <div className="col-12 form_inner p-2">
          <label for="">
            Action Taken <span className="text-danger">*</span>
          </label>
          <div className="input_icons">
            <div className="input_field">
              <textarea
                name="actionComment"
                rows="5"
                cols="100"
                onChange={(e) => {
                  setActionData({
                    ...actionData,
                    actionComment: e.target.value
                  })
                }}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            onClick={() => {
              handleSubmit()
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>
      
      <CustomPopup
        trigger={traineePopup}
        setTrigger={() => {
          setTraineePopup(false);
          setNewTrainees([]);
        }}
      >
        <div className="col-md-12">
          <h3>Add Trainees to Training</h3>
          <p>You can add multiple trainees to this training.</p>
          <div className="w-100 p-2" style={{minHeight: "300px"}}>
            <label for="">
              <div className="require-label">
                Trainee(s) <span className="text-danger">*</span>
              </div>
            </label>
            <div className="input_icons">
              <div className="w-100">
                <Select
                  onChange={(e) => {
                    let value = [];
                    if (e ) {
                      value = e.map((v) => v.value);
                    }

                    setNewTrainees(value);
                  }}
                  isMulti
                  placeholder="Select User"
                  options={siteUsers.filter((v) => ! existingTrainees?.includes( parseInt( v.userid) )).map((v) => ({
                    label:
                      v.fullName +
                      " - " +
                      (v.contractorName ? v.contractorName : v.companyName) +
                      " - " +
                      (v.roleName ? v.roleName : v.role),
                    value: `${v.userid}`,
                    name: "trainees",
                  }))}
                  value={siteUsers
                    .filter((v) => newTrainees?.includes(`${v.userid}`))
                    .map((v) => ({
                      label:
                        v.fullName +
                        " - " +
                        (v.contractorName ? v.contractorName : v.companyName) +
                        " - " +
                        (v.roleName ? v.roleName : v.role),
                      value: `${v.userid}`,
                      name: "trainees",
                    }))}
                />
              </div>
            </div>
          </div>

          <div className="sign_save_reset_btn">
            <button
              className="btn btn-success"
              onClick={addTraineesHandler}
            >
              Add Trainees
            </button>
          </div>
        </div>
      </CustomPopup>
      
      <CustomPopup
        trigger={questionPopup}
        setTrigger={() => {
          setQuestionPopup(false);
          setPopupData({
            question: "",
            options: [
              "",
              "",
              "",
            ],
            correctAnswer: "",
          });
        }}
      >
        <div className="col-12 form_inner p-2">
          <div className="input_icons">
            <div className="input_field">
              
              <div className="w-100 location_ladder p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Question <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <input
                      value={popupData.question}
                      type="text"
                      onChange={(e) => {
                        setPopupData({
                          ...popupData,
                          question: e.target.value,
                        });
                      }}
                      name="Question"
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 location_ladder p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Answers <span className="text-danger">*</span>
                  </div>
                </label>

                <div className="answers-choices-list">
                  {popupData?.options?.map((option, index) => (
                    <div className="answers-choice-item">
                      <label className="answers-choice-correct">
                        <input
                          type="radio"
                          name="correct_answer"
                          value={index}
                          onChange={(e) => {
                            setPopupData({
                              ...popupData,
                              correctAnswer: e.target.value,
                            });
                          }}
                        />
                        <span></span>
                      </label>
                      <div className="answers-choice-text">
                        <input
                          type="text"
                          value={popupData.options[index]}
                          placeholder={`Option ${index + 1}`}
                          onChange={(e) => {
                            let options = popupData.options;
                            options[index] = e.target.value;
                            setPopupData({
                              ...popupData,
                              options: options,
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="sign_save_reset_btn">
                <button
                  className="btn btn-success"
                  disabled={
                    !popupData?.question ||
                    !popupData?.options ||
                    !popupData?.correctAnswer
                  }
                  onClick={addQuestionHandler}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomPopup>

        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">

              <div className="row">
                <div className="col-12 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">

                      { data.amIEnrolled && <>
                        {data.myStatus == 'Enrolled' ? <>
                          <div className="training-enrollment-status" data-type="Enrolled">
                            Enrolled
                          </div>
                        </> : <>
                          <div className="training-enrollment-status" data-type="Completed">
                            Completed
                          </div>
                        </>}
                      </> }

                      <div className="header-access-view">Details</div>
                      <div className="p-2">
                        <div className="main-details">

                            {data.trainingType == 2 ? <>
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-6 dd-access">Training Name : </div>
                                <div className="col-6 dd-access">
                                  {" "}
                                  {data.trainingName ? data.trainingName : "-"}
                                </div>
                              </div>
                            </> : <>
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-6 dd-access">Course Name : </div>
                                <div className="col-6 dd-access">
                                  {" "}
                                  {data.courseName ? data.courseName : "-"}
                                </div>
                              </div>
                            </>}

                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Training Type : </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.trainingType == 1 ? 'Required' : 'Induction' }
                              </div>
                            </div>
                            
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Site : </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.siteName ? data.siteName : ( data.siteId == -1 ? 'All Sites' : "-")}
                              </div>
                            </div>

                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Department : </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.departmentName ? data.departmentName : (data.departmentId == -1 ? 'All Departments' : "-")}
                              </div>
                            </div>
                            
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Company : </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.contractorName ? data.contractorName : "-"}
                              </div>
                            </div>

                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">
                                Training Date :{" "}
                              </div>
                              <div className="col-6 dd-access">
                              {data.trainingDate
                                  ? ( commonService.formatDate(
                                    data.trainingDate,
                                    "Do MMM YYYY"
                                  ) == 'Invalid date' ? data.trainingDate : commonService.formatDate(
                                    data.trainingDate,
                                    "Do MMM YYYY"
                                  ))
                                  : "-"}{" "}
                              </div>
                            </div>


                        </div>
                      </div>
                    </div>
                    
                    {data.trainees.filter(v => v.userId == traineeId && v.status != 'Completed' ).length > 0 && <>
                      {data.trainingType == 2 && <>
                        <div className="mb-3">
                          <div className="header-access-view">Answer the Following Questions:</div>
                          <div className="p-2">
                            <div className="main-details">
                              {data.questions.map((question, index) => (
                                <div className="training-question" key={index}>
                                  <div className="training-questions-header d-flex flex-spaced">
                                    <div className="training-questions-header-label">
                                      {index + 1 }. {question.question}
                                    </div>
                                  </div>
                                  <div className="training-questions-answers">
                                    
                                      <div className={"training-questions-answer"}>
                                        <div className="input_icons">
                                          <div className="w-100">
                                            <FormControl
                                              className="w-100"
                                            >
                                              <RadioGroup
                                                row
                                                className="w-100"
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name={"qIndex-" + question.id}
                                                onChange={(e) => {
                                                  setAnswer(question.id, e.target.value);
                                                }}
                                              >
                                                {JSON.parse(question.choices).map((option, qIndex) => (
                                                <FormControlLabel
                                                  className="w-100"
                                                  checked={answers[question.id] == qIndex}
                                                  value={qIndex}
                                                  control={<Radio />}
                                                  label={option}
                                                />
                                              ))}
                                              </RadioGroup>
                                            </FormControl>
                                          </div>
                                        </div>
                                      </div>
                                    
                                  </div>
                                </div>
                              ))}


                              {Object.keys(answers).length == data.questions.length && <>
                                <div className="d-flex justify-content-end">
                                  <button className="btn btn-primary" onClick={() => {
                                    handleSubmitAnswers();
                                  }
                                  }>
                                    Submit Answers
                                  </button>
                                </div>
                              </>}
                            </div>
                          </div>
                        </div>
                      </>}
                    </>}

                    {data.trainees.filter(v => v.userId == traineeId && v.status == 'Completed' ).length > 0 && <>
                      {data.trainingType == 2 && <>
                        <div className="mb-3">

                          <div className="header-access-view">Result</div>
                            <div className="p-2">
                              <div className="main-details">
                                <div className="training-stats">
                                  <div className="training-stat">
                                    <strong>Total Questions</strong>
                                    <span>{data.questions.length}</span>
                                  </div>
                                  <div className="training-stat">
                                    <strong>Total Correct Answers</strong>
                                    <span>{data.myAnswers.filter(v => v.isCorrect == 1).length}</span>
                                  </div>
                                  <div className="training-stat">
                                    <strong>Percentage</strong>
                                    <span>{(data.myAnswers.filter(v => v.isCorrect == 1).length / data.questions.length * 100).toFixed(2).replace('.00', '')}%</span>
                                  </div>
                                  <div className="training-stat">
                                    <strong>Status</strong>
                                    <span>{data.trainees.filter(v => v.userId == traineeId)[0].scoreStatus}</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                          <div className="header-access-view">Trainee has taken the questionnaire:</div>
                          <div className="p-2">
                            <div className="main-details">
                              {data.questions.map((question, index) => (
                                <div className="training-question" key={index}>
                                  <div className="training-questions-header d-flex flex-spaced">
                                    <div className="training-questions-header-label">
                                      {index + 1 }. {question.question}
                                    </div>
                                  </div>
                                  <div className="training-questions-answers">
                                    
                                      <div className={"training-questions-answer"}>
                                        <div className="input_icons">
                                          <div className="w-100">
                                            <FormControl
                                              className="w-100"
                                            >
                                              <RadioGroup
                                                row
                                                className="w-100"
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name={"qIndex-" + question.id}
                                                onChange={(e) => {
                                                  setAnswer(question.id, e.target.value);
                                                }}
                                              >
                                                {JSON.parse(question.choices).map((option, qIndex) => (
                                                <FormControlLabel
                                                  className={"w-100" + ( data.myAnswers.find(v => v.questionId == question.id).chosenAnswer == qIndex 
                                                    ? ' is-answer-checked' : '' ) + ( data.myAnswers.find(v => v.questionId == question.id).chosenAnswer == qIndex ? ( data.myAnswers.find(v => v.questionId == question.id).isCorrect == 1 && data.myAnswers.find(v => v.questionId == question.id).chosenAnswer == qIndex ? ' is-answer-correct' : ' is-answer-incorrect' ) : '' ) }
                                                  checked={answers[question.id] == qIndex}
                                                  value={qIndex}
                                                  disabled
                                                  {...(data.myAnswers.find(v => v.questionId == question.id).chosenAnswer == qIndex 
                                                    ? {
                                                        checked: true,
                                                      }
                                                    : {})}
                                                  control={<Radio />}
                                                  label={option}
                                                />
                                              ))}
                                              </RadioGroup>
                                            </FormControl>
                                          </div>
                                        </div>
                                      </div>
                                    
                                  </div>
                                </div>
                              ))}

                            </div>
                          </div>
                        </div>
                      </>}
                    </>}
                    

                    <div className="row">
                      <div className="col-12">
                        <div className="header-access-view"> Uploaded Files</div>
                        <div className="d-flex">
                          {data.files &&
                            data.files.split(",").map((imgPreviewUrl) => (
                              <div
                                id="profile_pic_1_preview"
                                className="image-fixed m-2"
                              >
                                {commonService.outputImageTile(imgPreviewUrl)}
                              </div>
                            ))}
                          {data.actionFiles &&
                            data.actionFiles.split(",").map((imgPreviewUrl) => (
                              <div
                                id="profile_pic_1_preview"
                                className="image-fixed m-2"
                              >
                                {commonService.outputImageTile(imgPreviewUrl)}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>

                    { data.amIEnrolled && data.myStatus.status && <>
                      { data.myStatus.status == 'Enrolled' ? <>
                        
                      </> : <>
                        <div className="training-action-taken">
                            {data.myStatus.actionComment && <>
                              <div className="training-action-comment">
                                <strong>Comments:</strong>
                                <div>{data.myStatus.actionComment}</div>
                              </div>

                              <div className="training-action-signature">
                                <strong>Signature:</strong>
                                  <img
                                    onError={(e) => {
                                      e.target.src = DefaultImage;
                                    }}
                                    className="obj-fit-cover"
                                    height="250"
                                    width="250"
                                    alt="Signature"
                                    src={
                                      data.myStatus.signatureFile
                                        ? process.env.REACT_APP_API_BASEURL +
                                        data.myStatus.signatureFile
                                        : DefaultImage
                                    }
                                  />
                              </div>
                            </>}
                        </div>
                      </> }
                    </> }

                  </div>
                </div>
              </div>
            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
