import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ImgInput from "../../images/default/no_image.png";
import fileIcon from "../../images/openfolderwhite.svg";
import "./Ladder.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import SubmitLoader from "../common/SubmitLoader";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import { commonService } from "../common/common.service";

const AddLadder = ({ title }) => {
  const { id } = useParams()
  const { user, selectedCompany } = useAppState()
  const userToken = localStorage.getItem("cs_token");

  const history = useHistory();

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    label: "Select Status",
    value: "",
  });

  const fieldValues = {
    siteId: null,
    departmentId: null,
    contractorId: user.contractorId ? user.contractorId : null,
    operatorName: null,
    rstFloorSpace: null,
    rstFloorSpaceQuestion: "Restricted FLoor Space",
    obsOverheadQuestion: "Overhead Obstruction",
    rstHeightQuestion: "Restricted height from Floor to ceiling",
    obsMEQuestion: "M & E Obstruction",
    restrictedAccessQuestion: "Restricted Access?",
    obsOverhead: null,
    rstHeight: null,
    obsME: null,
    restrictedAccess: null,
    restrictedAccessText: null,
    otherRestriction: null,
    ladderLocation: null,
    date: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
    time: (new Date()).toLocaleString('en-GB').substring(12, 17),
    isDefectFound: null,
    detailsOfDefect: null,
    actionTaken: null,
    detailsActionTaken: null,
    inspectorName: null,
    companyInformation: null,
    ladderTypeQuestion: "Type of Ladder",
    ladderVisuallnspectedQuestion: "Has a visual inspection of the ladder been carried out",
    identifyNumberInspectedQuestion: "Is ladder inspected on a GA 3 and identification number visual and up to date",
    ladderWorkingOrderInspectedQuestion: "Are all ladder components fully intact, free form damage and in full working order",
    ladderProperInspectedQuestion: "Is the ladder appropriate for the task required",
    cutsInspectedQuestion: "Are there any cracks or cuts",
    ladderType: null,
    ladderVisualInspected: null,
    identifyNumberInspected: null,
    ladderWorkingOrderInspected: null,
    ladderProperInspected: null,
    cutsInspected: null,
    companyId: companyId,
    addedBy: companyId,
    idMark: null,
    inspectorSignature: null,
    verifySignature: null,
    files: "",
  };
  const [fieldData, setFieldData] = useState(fieldValues);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({ ...fieldValues, contractorId: null, date: null, time: null });
  const [contractorData, setContractorData] = useState([]);

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID( userCompanyId );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          })
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };


  const fetchLadderDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/ladder/permit/details`,
        {
          ladderId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setFieldData({ ...res.data[0], existingFiles: res.data[0].files });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        console.log("errr", err);
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: status.value,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        // if (res.data.length > 0) {
        // setDepartmentData(
        // res.data.filter(
        //   (department, index, arr) =>
        //     res.data.findIndex((item) => item.id == department.id) == index
        // )
        // );
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        )
        // }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchLadderDetails()
    }
    getSites();
    getDepartments();
    getContractor();
  }, [companyId, status]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const getSites = () => {
    
    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getContractor = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-contractors`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: status.value,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setContractorData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );
        // }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {
      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }
      
      // getBase64(e.target.files[0])
      Promise.all(Array.from(modifiedFiles.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else if (name == "time") {
      let val = value
      if (fieldData.date == (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")) {
        const localTime = (new Date()).toLocaleString('en-GB').substring(12, 17)
        if (parseInt(e.target.value.split(":")[0]) <= parseInt(localTime.split(":")[0])) {
          if (parseInt(e.target.value.split(":")[1]) <= parseInt(localTime.split(":")[1])) {
            val = localTime
            toast.error("Selecting a time in the past is not possible")
          }
        }
      }
      setFieldData({
        ...fieldData,
        [name]: val,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value)
        setDepartmentData(allDepartment.filter(v => v.siteId == value))
      }
      const targetValue = e.target ? e.target.value : e.value;
      const targetName = e.target ? e.target.name : e.name;
      // isValidData(targetValue, targetName);
    }
  };

  const isValidData = (value, name) => {
    let message = ""
    const stateObj = { ...error, [name]: "" };
    switch (name) {
      case "companyId":
        if (!value) {
          message = stateObj[name] = "Company Id is required.";
        }
        break;
      case "addedBy":
        if (!value) {
          message = stateObj[name] = "Added by is required.";
        }
        break;
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;

      case "departmentId":
        if (!value) {
          message = stateObj[name] = "Department Id is required.";
        }
        break;

      case "operatorName":
        if (!value) {
          message = stateObj[name] = "Operator name is required.";
        }
        break;
      case "contractorId":
        if (!value) {
          message = stateObj[name] = "Contractor Id is required.";
        }
        break;
      case "restrictedAccess":
        if (!value) {
          message = stateObj[name] = "Choose option for restricted access";
        }
        break;
      case "restrictedAccessText":
        if (!value) {
          message = stateObj[name] = "Add Restricted Access details";
        }
        break;
      case "rstFloorSpaceQuestion":
        if (!value) {
          message = stateObj[name] = "Add Floor space question";
        }
        break;
      case "obsOverheadQuestion":
        if (!value) {
          message = stateObj[name] = "Add overhead obstruction question";
        }
        break;
      case "rstHeightQuestion":
        if (!value) {
          message = stateObj[name] = "Add restricted height question";
        }
        break;
      case "obsMEQuestion":
        if (!value) {
          message = stateObj[name] = "Add M & E obstruction question";
        }
        break;
      case "restrictedAccessQuestion":
        if (!value) {
          message = stateObj[name] = "Add Restricted access question";
        }
        break;
      case "ladderLocation":
        if (!value) {
          message = stateObj[name] = "Location of ladder is required.";
        }
        break;
      case "date":
        if (!value) {
          message = stateObj[name] = "date is required.";
        }
        break;
      case "time":
        if (!value) {
          message = stateObj[name] = "time is required.";
        }
        break;
      case "isDefectFound":
        if (!value) {
          message = stateObj[name] = "Choose option for where any defects fond.";
        }
        break;
      case "detailsOfDefect":
        if (!value) {
          message = stateObj[name] = "Detail of defects is required";
        }
        break;
      case "actionTaken":
        if (!value || (value == 0)) {
          message = stateObj[name] = "Corrective actions must be taken before you can submit this permit.";
        }
        break;
      case "detailsActionTaken":
        if (!value) {
          message = stateObj[name] = "Add Details of Corrective actions taken.";
        }
        break;
      case "inspectorName":
        if (!value) {
          message = stateObj[name] = "Name of person making inspection is required.";
        }
        break;
      case "inspectorSignature":
        if (!value) {
          message = stateObj[name] = "Upload signature of person making inspection.";
        }
        break;
      case "idMark":
        if (!value) {
          message = stateObj[name] = "Labelled / ID Mark is required.";
        }
        break;
      case "ladderType":
        if (!value) {
          message = stateObj[name] = "Select type of Ladder.";
        }
        break;
      case "ladderTypeQuestion":
        if (!value) {
          message = stateObj[name] = "Ladder type question";
        }
        break;
      case "ladderVisuallnspectedQuestion":
        if (!value) {
          message = stateObj[name] = "Ladder visual inspected question";
        }
        break;
      case "identifyNumberInspectedQuestion":
        if (!value) {
          message = stateObj[name] = "Ladder identification number question";
        }
        break;
      case "ladderWorkingOrderInspectedQuestion":
        if (!value) {
          message = stateObj[name] = "Ladder working order question";
        }
        break;
      case "ladderProperInspectedQuestion":
        if (!value) {
          message = stateObj[name] = "Ladder proper inspection question";
        }
        break;
      case "cutsInspectedQuestion":
        if (!value) {
          message = stateObj[name] = "Ladder cuts inspection question";
        }
        break;
      case "verifySignature":
        if (!value) {
          message = stateObj[name] = "Upload signature.";
        }
        break;
      
      default:
        break;
    }
    setError(stateObj);
    return message;
  };

  const [siteUsers, setSiteUsers] = useState([])

  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const handleSubmit = async () => {
    try {
      const [inspectorSignature, verifySignature] = await Promise.all([handleSaveSignature(), handleSaveVerifySign()])
      handleSubmitAfterSave(inspectorSignature, verifySignature)
    } catch (error) {
      console.log(error)
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (inspectorSignature, verifySignature) => {
    let errorShow = {
      companyId: fieldData.companyId,
      addedBy: fieldData.addedBy,
      siteId: fieldData.siteId,
      departmentId: fieldData.departmentId,
      operatorName: fieldData.operatorName,
      contractorId: user.contractorId || user.role == "admin" ? fieldData.contractorId : true,
      restrictedAccess: fieldData.restrictedAccess !== null,
      ...(fieldData.restrictedAccess == 1 ? {
        restrictedAccessText: fieldData.restrictedAccessText,
      } : {}),
      rstFloorSpaceQuestion: fieldData.rstFloorSpaceQuestion,
      obsOverheadQuestion: fieldData.obsOverheadQuestion,
      rstHeightQuestion: fieldData.rstHeightQuestion,
      obsMEQuestion: fieldData.obsMEQuestion,
      restrictedAccessQuestion: fieldData.restrictedAccessQuestion,
      ladderLocation: fieldData.ladderLocation,
      date: fieldData.date,
      time: fieldData.time,
      isDefectFound: fieldData.isDefectFound !== null,
      ...(fieldData.isDefectFound == 1 ? {
        detailsOfDefect: fieldData.detailsOfDefect,
        actionTaken: fieldData.actionTaken,
        ...(fieldData.actionTaken == 1 ? {
          detailsActionTaken: fieldData.detailsActionTaken,
        } : {}),
      } : {}),
      inspectorName: fieldData.inspectorName,
      idMark: fieldData.idMark,
      ladderType: fieldData.ladderType,
      ladderTypeQuestion: fieldData.ladderTypeQuestion,
      ladderVisuallnspectedQuestion: fieldData.ladderVisuallnspectedQuestion,
      identifyNumberInspectedQuestion: fieldData.identifyNumberInspectedQuestion,
      ladderWorkingOrderInspectedQuestion: fieldData.ladderWorkingOrderInspectedQuestion,
      ladderProperInspectedQuestion: fieldData.ladderProperInspectedQuestion,
      cutsInspectedQuestion: fieldData.cutsInspectedQuestion,
      inspectorSignature: id?true:inspectorSignature,
      verifySignature: verifySignature,
    };
    let trigger = true
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message)
        trigger = false
      }
    });

    if (
      fieldData.companyId !== null &&
      fieldData.addedBy !== null &&
      fieldData.siteId !== null &&
      fieldData.departmentId !== null &&
      fieldData.operatorName !== null &&
      (user.contractorId || user.role == "admin" ? fieldData.contractorId !== null : true) &&
      fieldData.restrictedAccess !== null &&
      (fieldData.restrictedAccess == 1 ? fieldData.restrictedAccessText : true) &&
      fieldData.rstFloorSpaceQuestion !== null &&
      fieldData.obsOverheadQuestion !== null &&
      fieldData.rstHeightQuestion !== null &&
      fieldData.obsMEQuestion !== null &&
      fieldData.restrictedAccessQuestion !== null &&
      fieldData.ladderLocation !== null &&
      fieldData.date !== null &&
      fieldData.time !== null &&
      fieldData.isDefectFound !== null &&
      (fieldData.isDefectFound == 1 ? fieldData.detailsOfDefect && fieldData.actionTaken : true) &&
      (fieldData.actionTaken == 1 ? fieldData.detailsActionTaken : true) &&
      fieldData.inspectorName !== null &&
      fieldData.idMark !== null &&
      fieldData.ladderType !== null &&
      fieldData.ladderTypeQuestion !== null &&
      fieldData.ladderVisuallnspectedQuestion !== null &&
      fieldData.identifyNumberInspectedQuestion !== null &&
      fieldData.ladderWorkingOrderInspectedQuestion !== null &&
      fieldData.ladderProperInspectedQuestion !== null &&
      fieldData.cutsInspectedQuestion !== null &&
      verifySignature !== null &&
      (!id?inspectorSignature !== null:true)
    ) {
      const form = new FormData();
      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("ladderPermits") : v.permits.includes("ladderPermitsreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      form.append("companyInformation", fieldData.companyInformation);
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
      form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
      form.append(
        "departmentId",
        fieldData.departmentId == null ? null : fieldData.departmentId
      );
      form.append("operatorName", fieldData.operatorName);
      form.append("contractorId", fieldData.contractorId);
      form.append("rstFloorSpace", fieldData.rstFloorSpace == null ? null : fieldData.rstFloorSpace);
      form.append("obsOverhead", fieldData.obsOverhead == null ? null : fieldData.obsOverhead);
      form.append("rstHeight", fieldData.rstHeight == null ? null : fieldData.rstHeight);
      form.append("obsME", fieldData.obsME == null ? null : fieldData.obsME);
      form.append("restrictedAccess", fieldData.restrictedAccess);
      form.append("rstFloorSpaceQuestion", fieldData.rstFloorSpaceQuestion);
      form.append("obsOverheadQuestion", fieldData.obsOverheadQuestion);
      form.append("rstHeightQuestion", fieldData.rstHeightQuestion);
      form.append("obsMEQuestion", fieldData.obsMEQuestion);
      form.append("restrictedAccessQuestion", fieldData.restrictedAccessQuestion);
      form.append("restrictedAccessText", fieldData.restrictedAccessText);
      form.append("otherRestriction", fieldData.otherRestriction);
      form.append("ladderLocation", fieldData.ladderLocation);
      form.append("date", fieldData.date);
      form.append("time", fieldData.time);
      form.append(
        "isDefectFound",
        fieldData.isDefectFound == null ? null : fieldData.isDefectFound
      );
      form.append("detailsOfDefect", fieldData.detailsOfDefect);
      form.append("actionTaken", fieldData.actionTaken);
      form.append("detailsActionTaken", fieldData.detailsActionTaken);
      form.append("inspectorName", fieldData.inspectorName);
      form.append("companyInformation", fieldData.companyInformation);
      form.append("ladderType", fieldData.ladderType);
      form.append("ladderTypeQuestion", fieldData.ladderTypeQuestion);
      form.append("ladderVisuallnspectedQuestion", fieldData.ladderVisuallnspectedQuestion);
      form.append("identifyNumberInspectedQuestion", fieldData.identifyNumberInspectedQuestion);
      form.append("ladderWorkingOrderInspectedQuestion", fieldData.ladderWorkingOrderInspectedQuestion);
      form.append("ladderProperInspectedQuestion", fieldData.ladderProperInspectedQuestion);
      form.append("cutsInspectedQuestion", fieldData.cutsInspectedQuestion);
      form.append("ladderVisualInspected", fieldData.ladderVisualInspected);
      form.append(
        "identifyNumberInspected",
        fieldData.identifyNumberInspected == null
          ? null
          : fieldData.identifyNumberInspected
      );

      form.append(
        "ladderWorkingOrderInspected",
        fieldData.ladderWorkingOrderInspected == null
          ? null
          : fieldData.ladderWorkingOrderInspected
      );
      form.append(
        "ladderProperInspected",
        fieldData.ladderProperInspected == null
          ? null
          : fieldData.ladderProperInspected
      );
      form.append(
        "cutsInspected",
        fieldData.cutsInspected == null ? null : fieldData.cutsInspected
      );
      form.append("companyId", companyId);
      form.append("addedByInCompany", user.id);
      form.append("approvedByName", user.fullName);
      if (id) {
        form.append("resubmitComment", fieldData.resubmitComment);
      }
      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }
      form.append("addedBy", companyId);
      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      form.append("inspectorSignature", inspectorSignature);
      form.append("verifySignature", verifySignature);
      form.append("idMark", fieldData.idMark);
      
      form.append( "createdAt", (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-") );

      Object.entries(fieldData?.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
      setLoading(true);
      const apiCall =
        id ?
          axiosInstance
            .put(`${process.env.REACT_APP_API_BASEURL}/update-details/ladder-permit/${id}`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }) :
          axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/add/ladder-permit`, form, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            })
      apiCall
        .then((res) => {
          toast.success(res.data.message);
          setLoading(false);
          history.push('/view-own/ladder-permits')
          setFieldData({
            siteId: null,
            departmentId: null,
            operatorName: null,
            contractorId: 0,
            rstFloorSpace: null,
            obsOverhead: null,
            rstHeight: null,
            obsME: null,
            restrictedAccess: null,
            rstFloorSpaceQuestion: "Restricted FLoor Space",
            obsOverheadQuestion: "Overhead Obstruction",
            rstHeightQuestion: "Restricted height from Floor to ceiling",
            obsMEQuestion: "M & E Obstruction",
            restrictedAccessQuestion: "Restricted Access?",
            restrictedAccessText: null,
            otherRestriction: null,
            ladderLocation: null,
            date: (new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-"),
            time: (new Date()).toLocaleString('en-GB').substring(12, 17),
            inspectorName: null,
            companyInformation: null,
            isDefectFound: null,
            detailsOfDefect: null,
            actionTaken: null,
            detailsActionTaken: null,
            ladderTypeQuestion: "Type of Ladder",
            ladderVisuallnspectedQuestion: "Has a visual inspection of the ladder been carried out",
            identifyNumberInspectedQuestion: "Is ladder inspected on a GA 3 and identification number visual and up to date",
            ladderWorkingOrderInspectedQuestion: "Are all ladder components fully intact, free form damage and in full working order",
            ladderProperInspectedQuestion: "Is the ladder appropriate for the task required",
            cutsInspectedQuestion: "Are there any cracks or cuts",
            ladderType: null,
            ladderVisualInspected: null,
            identifyNumberInspected: null,
            ladderWorkingOrderInspected: null,
            ladderProperInspected: null,
            cutsInspected: null,
            companyId: companyId,
            addedBy: companyId,
            idMark: null,
            inspectorSignature: null,
            verifySignature: null,
            files: "",
          });
          handleClearVerifySign();
          handleClearSignature();
        })
        .catch((err) => {
          console.log(err)
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const signatureRef = useRef();
  const signatureVerifyRef = useRef();

  const handleSaveSignature = () => {
    return new Promise((res, rej) => {
      if (signatureRef?.current) {
        if (signatureRef.current.isEmpty()) {
          res(null)
        } else {
          const canvas = signatureRef.current.getCanvas();
          const timestamp = Date.now(); // Get the current timestamp
          const randomString = Math.random().toString(36).substring(2);
  
          canvas.toBlob((blob) => {
            const file = new File(
              [blob],
              `signature_${timestamp}_${randomString}.png`,
              { type: "image/png" }
            );
            setFieldData((prevFieldData) => {
              return {
                ...prevFieldData,
                inspectorSignature: file,
              };
            });
            res(file)
          });
        }
      }else{
        res(null)
      }
    })
    // toast.success("Successfully Save Signature");
  };

  const handleClearSignature = () => {
    signatureRef?.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        inspectorSignature: null,
      };
    });
  };

  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRef.current.isEmpty()) {
        res(null)
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file)
        });
      }
    })
    // toast.success("Successfully Save Signature");
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };


  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }))

  const departmentOptions = allDepartment.filter(v => v.siteId == fieldData.siteId).map((d) => ({
    label: d.name,
    value: d.id,
    name: "departmentId",
  }))

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          //   { name: "Company Details ", path: "/company/edit-details" },
        ]}
      />
      <div className="main_container">
        <p className="redText">Use of "A" frame ladders is only allowed if a Podium Step, Alloy Tower or MEWP can't be used; I confirm that following a risk assessment for working at heights, the works to be carried out with this permit can't be attained by other means due to the following: </p>
        <div className="addladder_first_container">

          <div className="general_details">General Details</div>
          <div className="general_details_fields">
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Site <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Sites"
                    options={siteOptions}
                    {...(id ? {
                      value: siteOptions.filter(v => v.value == fieldData.siteId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.siteId && <span className="err">{error.siteId}</span>}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Department <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100">
                  <Select
                    onChange={handleChange}
                    placeholder="Select Department"
                    options={departmentOptions}
                    {...(id ? {
                      value: departmentOptions.filter(v => v.value == fieldData.departmentId),
                      isDisabled: true
                    } : {})}
                  />
                </div>
              </div>
              {error.departmentId && (
                <span className="err">{error.departmentId}</span>
              )}
            </div>
            <div className="col-3  p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Operator Name <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    name="operatorName"
                    value={fieldData.operatorName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error.operatorName && (
                <span className="err">{error.operatorName}</span>
              )}
            </div>
            <div className="col-3  p-2">
              <label for="">
                <div className="require-label">
                  Contractor <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="w-100 pt-2">
                  {user.role.includes("admin") ? <Select
                    onChange={handleChange}
                    placeholder="Select Contractor"
                    value={fieldData.contractorId ? contractorData.filter(v => v.id == fieldData.contractorId).map(d => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    })) : null}
                    options={contractorData.map((d) => ({
                      label: d.name,
                      value: d.id,
                      name: "contractorId",
                    }))}
                  /> : fieldData.contractorId && contractorData.filter(v => v.id == fieldData.contractorId).length ? contractorData.filter(v => v.id == fieldData.contractorId)[0].name : user.companyName}
                </div>
              </div>
              {error.contractorId && (
                <span className="err">{error.contractorId}</span>
              )}
            </div>
          </div>
        </div>
        <div className="addladder_second_container">
          <div className="general_details">Restriction or Obstructions</div>
          <div className="restriction_radio_btn">
            <div>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Restricted FLoor Space
                </FormLabel>
                {/* <FormLabel id="demo-row-radio-buttons-group-label" className="location_ladder">
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="rstFloorSpaceQuestion"
                        value={fieldData.rstFloorSpaceQuestion}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="rstFloorSpace"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.rstFloorSpace == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.rstFloorSpace == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.rstFloorSpace == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Overhead Obstruction
                </FormLabel>
                {/* <FormLabel id="demo-row-radio-buttons-group-label" className="location_ladder">
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        value={fieldData.obsOverheadQuestion}
                        name="obsOverheadQuestion"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="obsOverhead"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.obsOverhead == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.obsOverhead == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.obsOverhead == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Restricted height from Floor to ceiling
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="rstHeight"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.rstHeight == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.rstHeight == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.rstHeight == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  M & E Obstruction
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="obsME"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.obsME == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.obsME == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.obsME == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="restriction_radio_btn">
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <div className="require-label">
                  Restricted Access? <span className="text-danger">*</span>
                </div>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="restrictedAccess"
                onChange={handleChange}
              >
                <FormControlLabel checked={fieldData.restrictedAccess == 1} value={1} control={<Radio />} label="Yes" />
                <FormControlLabel checked={fieldData.restrictedAccess == 0} value={0} control={<Radio />} label="No" />
                <FormControlLabel checked={fieldData.restrictedAccess == 2} value={2} control={<Radio />} label="N/A" />
              </RadioGroup>
              {error.restrictedAccess && (
                <span className="err">{error.restrictedAccess}</span>
              )}
            </FormControl>
          </div>
          {(fieldData.restrictedAccess == 1) && <div>
            <div className="col-8 form_inner p-2">
              <label for="">
                Restricted Access details <span className="text-danger">*</span>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="restrictedAccessText"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="5"
                    cols="100"
                    value={fieldData.restrictedAccessText}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              {error.restrictedAccessText && (
                <span className="err">{error.restrictedAccessText}</span>
              )}
            </div>
          </div>}
          <div>
            <div className="col-8 form_inner p-2">
              <label for="">
                Other Restrictions or Obstructions(please explain)
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="otherRestriction"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="5"
                    cols="100"
                    value={fieldData.otherRestriction}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
            </div>
          </div>
        </div>
        <div className="addladder_third_container">
          <div className="general_details">
            Before use, all working at height equipment is to be inspected
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-6 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  Location of Ladder <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input
                    type="text"
                    onChange={handleChange}
                    name="ladderLocation"
                    value={fieldData.ladderLocation}
                  />
                </div>
              </div>
              {error.ladderLocation && (
                <span className="err">{error.ladderLocation}</span>
              )}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Date <span className="text-danger">*</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.date} min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")} type="date" onChange={handleChange} name="date" />
                </div>
              </div>
              {error.date && <span className="err">{error.date}</span>}
            </div>
            <div className="col-3 p-2 location_ladder">
              <label for="">
                <div className="require-label">
                  {" "}
                  Time <span className="text-danger">*</span>{" "}
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <input value={fieldData.time} type="time" pattern="hh:mm" onChange={handleChange} name="time" />
                </div>
              </div>
              {error.time && <span className="err">{error.time}</span>}
            </div>
          </div>
          <div className="defects_found_radio_btn">
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <div className="require-label">
                  {" "}
                  Were any defects found?
                  <span className="text-danger"> *</span>
                </div>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="isDefectFound"
                onChange={handleChange}
              >
                <FormControlLabel checked={fieldData.isDefectFound == 1} value={1} control={<Radio />} label="Yes" />
                <FormControlLabel checked={fieldData.isDefectFound == 0} value={0} control={<Radio />} label="No" />
              </RadioGroup>
              {error.isDefectFound && (
                <span className="err">{error.isDefectFound}</span>
              )}
            </FormControl>
          </div>
          <div className="col-6 p-2 location_ladder">
            {(fieldData.isDefectFound == 1) && <div>
              <div className="col-12 form_inner">
                <label for="">
                  Details of defects found <span className="text-danger">*</span>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="detailsOfDefect"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      rows="5"
                      cols="100"
                      value={fieldData.detailsOfDefect}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
                {error.detailsOfDefect && (
                  <span className="err">{error.detailsOfDefect}</span>
                )}
              </div>
              <div className="col-12 form_inner">
                <div className="defects_found_radio_btn">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <div className="require-label">
                        {" "}
                        Were corrective actions taken?
                        <span className="text-danger"> *</span>
                      </div>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="actionTaken"
                      onChange={handleChange}
                    >
                      <FormControlLabel checked={fieldData.actionTaken == 1} value={1} control={<Radio />} label="Yes" />
                      <FormControlLabel checked={fieldData.actionTaken == 0} value={0} control={<Radio />} label="No" />
                    </RadioGroup>
                    {(fieldData.actionTaken == 0) && (
                      <span className="err alert alert-danger">Corrective actions must be taken before you can submit this permit.</span>
                    )}
                  </FormControl>
                </div>
              </div>

              {(fieldData.actionTaken == 1) && <div>
                <div className="col-12 form_inner">
                  <label for="">
                    Details of Corrective actions  <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <textarea
                        name="detailsActionTaken"
                        maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                        rows="5"
                        cols="100"
                        value={fieldData.detailsActionTaken}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
                  {error.detailsActionTaken && (
                    <span className="err">{error.detailsActionTaken}</span>
                  )}
                </div>
              </div>}
            </div>}
          </div>
          <div className="col-6 p-2 location_ladder">
            <label for="">
              <div className="require-label">
                {" "}
                Name of person making inspection
                <span className="text-danger"> *</span>
              </div>
            </label>
            <div className="input_icons">
              <div className="input_field">
                <input
                  type="text"
                  onChange={handleChange}
                  value={fieldData.inspectorName}
                  name="inspectorName"
                />
              </div>
            </div>
            {error.inspectorName && (
              <span className="err">{error.inspectorName}</span>
            )}
          </div>
          {!id&&<div className="p-2">
            <label for="">
              <div className="require-label">
                Signature of person making inspection
                <span className="text-danger"> *</span>
              </div>
            </label>
            <div className="sign_image">
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{ width: 263, height: 270 }}
              />
              <span
                className="cancel-custom cursor-pointer"
                onClick={handleClearSignature}
              >
                <i
                  className="clear_btn  text-danger fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            {/* <div className="sign_save_reset_btn">
              <button className="btn btn-primary" onClick={handleSaveSignature}>
                Save
              </button>
            </div> */}
            {error.inspectorSignature && (
              <span className="err">{error.inspectorSignature}</span>
            )}
          </div>}
        </div>
        <div className="addladder_fourth_container">
          <div className="general_details">Inspected Items</div>
          <div className="d-flex">
            <div className="textArea">
              <label for="">
                <div className="require-label">
                  {" "}
                  Labelled / Id Mark
                  <span className="text-danger"> *</span>
                </div>
              </label>
              <div className="input_icons">
                <div className="input_field">
                  <textarea
                    name="idMark"
                    maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                    rows="3"
                    cols="100"
                    onChange={handleChange}
                    value={fieldData.idMark}
                  ></textarea>
                </div>
              </div>
              <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              {error.idMark && <span className="err">{error.idMark}</span>}
            </div>
            <div className="type_of_ladder_checkbox">
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  <div className="require-label">
                    {" "}
                    Type of Ladder
                    <span className="text-danger"> *</span>
                  </div>
                </FormLabel>
                {/* <FormLabel id="demo-row-radio-buttons-group-label" className="location_ladder">
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        value={fieldData.ladderTypeQuestion}
                        name="ladderTypeQuestion"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="ladderType"
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="A-frame"
                    checked={fieldData.ladderType == "A-frame"}
                    control={<Radio />}
                    label="A-frame"
                  />
                  <FormControlLabel
                    value="Straight"
                    checked={fieldData.ladderType == "Straight"}
                    control={<Radio />}
                    label="Straight"
                  />
                </RadioGroup>
                {error.ladderType && (
                  <span className="err">{error.ladderType}</span>
                )}
              </FormControl>
            </div>
          </div>
          <div className="inspected_radio_btn">
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Has a visual inspection of the ladder been carried out
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="ladderVisualInspected"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.ladderVisualInspected == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.ladderVisualInspected == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.ladderVisualInspected == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Is ladder inspected on a GA 3 and identification number visual
                  and up to date
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="identifyNumberInspected"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.identifyNumberInspected == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.identifyNumberInspected == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.identifyNumberInspected == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Are all ladder components fully intact, free form damage and
                  in full working order
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="ladderWorkingOrderInspected"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.ladderWorkingOrderInspected == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.ladderWorkingOrderInspected == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.ladderWorkingOrderInspected == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Is the ladder appropriate for the task required
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="ladderProperInspected"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.ladderProperInspected == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.ladderProperInspected == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.ladderProperInspected == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <FormLabel class="inspected_label">
                  Are there any cracks or cuts
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="cutsInspected"
                  onChange={handleChange}
                >
                  <FormControlLabel checked={fieldData.cutsInspected == 1} value={1} control={<Radio />} label="Yes" />
                  <FormControlLabel checked={fieldData.cutsInspected == 0} value={0} control={<Radio />} label="No" />
                  <FormControlLabel checked={fieldData.cutsInspected == 2} value={2} control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="addladder_fifth_container">
          <div className="general_details">Upload Files</div>
          <div className="d-flex">
            {!!files.length && files.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
              <img
                className="obj_fit_img obj-fit-cover rounded"
                src={imgPreviewUrl}
                alt=""
                height="150"
                width="150"
              />
            </div>))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
          </div>
        </div>
        <div className="addladder_sixth_container">
          <div className="general_details">Signature(s)</div>
          <div className="p-2">
            <p className="redText">
              I have read and understood my employers Risk Assessment for working on an "A" frame ladder. I will comply with the risk assessment.
            </p>
            <div className="sign_image">
              <SignatureCanvas
                ref={signatureVerifyRef}
                canvasProps={{ width: 263, height: 270 }}
              />
              <span
                className="cancel-custom cursor-pointer"
                onClick={handleClearVerifySign}
              >
                <i
                  className="clear_btn  text-danger fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            {/* <div className="sign_save_reset_btn">
              <button
                className="btn btn-primary"
                onClick={handleSaveVerifySign}
              >
                Save
              </button>
            </div> */}
            {error.verifySignature && (
              <span className="err">{error.verifySignature}</span>
            )}
            {!!id && <div className="d-flex">
              <div className="textArea mx-0">
                <p className="mb-0 text-dark">
                  <div className="require-label">
                    {" "}
                    Resubmit Comment
                  </div>
                </p>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="resubmitComment"
                      maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                      rows="3"
                      cols="100"
                      onChange={handleChange}
                      value={fieldData.resubmitComment}
                    ></textarea>
                  </div>
                </div>
                <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
              </div>
            </div>}
          </div>
        </div>
        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              {id ? "Resubmit" : "Submit"}
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn">cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AddLadder;
