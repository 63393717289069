import React, { useEffect } from "react";
import { monthlyPaymentArray } from "../../helpers/paymentArray";
import { useAppState } from "../../state";
import { PaymentCard } from "../paymentCard/PaymentCard";
import logo from "../../images/foresighthublogo.svg";
import { Link, useHistory } from "react-router-dom";

// social //
import Facebook from '../../images/fb.svg'
import Insta from '../../images/insta.svg'
import Linkedin from '../../images/linkeding.svg'
import Countdown from "../Countdown/Countdown";
import Cookies from "universal-cookie";
import axios from "axios";
import { toast } from 'react-toastify';


export const Stripe = () => {
  const history = useHistory()
  const { isLoggedIn, user, refreshUserDetails } = useAppState();
  const [active, setActive] = React.useState(false);
  const currentPage = window.location.pathname.split("/")[1];

  useEffect(() => {
    const cookies = new Cookies();
    const userToken = localStorage.getItem("cs_token");
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (isLoggedIn && user && params && params.subscriptionId && params.userId) {
      axios.post(`${process.env.REACT_APP_API_BASEURL}/updateUser`, {
        userId: params.userId,
        subscriptionId: params.subscriptionId,
      }, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then((res) => {
          if (res.data && res.data.message) {
            history.push("/plans")
            refreshUserDetails()
            toast.success(res.data.message)
          } else {
            toast.error("Something went wrong!")
          }
        })
        .catch((err) => {
          console.log("errr", err);
          toast.error(err?.response?.data?.message || "Unable to process this request");
        });
    }
  }, [])
  return (
    <div className={`home-styles homepage`}
    >
      {!isLoggedIn && <header className={`site-header site-header--menu-left dynamic-sticky-bg mt-2 mt-lg-0 menu-block-4 mb-7 mb-lg-0 site-header--absolute site-header--stick`}
      >
        <div className={`containerWS`}
        >
          <nav className={`navbar site-navbar`}
          >
            <div className={`brand-logo`}
            >
              <a href="/">
                <img src={logo} alt="" className={`logomain`}
                 />
              </a>
            </div>
            <div className={`menu-block-wrapper `}
            >
              <div className={`menu-overlay`}
              >
              </div>
              <nav className={`menu-block`}
               id="append-menu-header">
                <div className={`mobile-menu-head`}
                >
                  <div className={`go-back`}
                  >
                    <i className={`fa fa-angle-left`}
                    >
                    </i>
                  </div>
                  <div className={`current-menu-title`}
                  >
                  </div>
                  <div className={`mobile-menu-close`}
                  >&times;</div>
                </div>
                <ul className={`site-menu-main`}
                >
                  <li className={`nav-item`}
                  >
                    <Link to={"/"}>
                      <button className={`actionElementButton`}
                      >
                        <span className={`navactionButtonText active`}
                        >Home</span>
                      </button>
                    </Link>
                  </li>
                  <li className={`nav-item`}
                  >
                    <Link to={"/plans/"}>
                      <button className={`actionElementButton`}
                      >
                        <span className={`navactionButtonText`}
                        >Pricing</span>
                      </button>
                    </Link>
                  </li>
                  <li className={`nav-item`}
                  >
                    <Link to={"/contact/"}>
                      <button className={`actionElementButton`}
                      >

                        <span className={`navactionButtonText`}
                        >Contact</span>
                      </button>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={`header-btns  ms-auto ms-lg-0 d-none d-sm-flex align-items-center heading-default-color`}
            >
              <Link to={"/login/"}>
                <button className={`actionElementButton`}
                >

                  <span className={`navactionButtonText`}
                  >Login</span>
                </button>
              </Link>
              <Link to={"/plans/"}>
                <button className={`actionElementButton`}
                >
                  <span className={`btn btn--xxl h-45 btn-primary text-white rounded-55`}
                  >
                    Start Free Trial
                  </span>
                </button>
              </Link>
            </div>
            <div className={`mobile-menu-trigger`}
            >
              <span>
              </span>
            </div>
          </nav>
        </div>
      </header>}
      <div className={`welcome-area welcome-area--l20 bg-default`}
      >
        <div className={`container`}
        >
          <div className={`row justify-content-center`}
          >
            <div
              className={`paymentBody`}
              style={{
                width: currentPage === "plans" && isLoggedIn && `calc(100% - 65px)`,
                marginLeft: currentPage === "plans" && isLoggedIn && "65px",
                marginTop: currentPage === "plans" && isLoggedIn && "65px",
              }}>
              <div className={`header`}
              >
                <h2 className={`content-texts--l2-3__content`}
                data-aos="fade-left" data-aos-duration="500" data-aos-once="true">
                  Choose a Plan to try Foresight Hub risk free.</h2>
                {user && user.trial && !user.subscription_id && user.role !== "superadmin" &&<h3 className={`content-texts--l2-1__heading2`}
                >
                  <Countdown eta={user.trial.split("-")[0]} />
                </h3>}
                <p className={`section-title--2__description`}
                 data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true">
                  Free 30 days trial, cancel anytime. Low monthly subscription with savings on Annual Plans.</p>
                <p className={`section-title--2__description`}
                 data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true">
                  Contact us for customized solutions</p>
                <div className={`paybuttons`}
                >
                  <span className={`paybutton`}
                  >
                    <p
                      className={!active ? `pays` : `pays1`}
                      onClick={() => setActive(false)}>
                      Pay Monthly
                    </p>
                    <p
                      className={active ? `pays` : `pays1`}
                      onClick={() => setActive(true)}>
                      Pay Annually
                    </p>
                  </span>
                </div>
              </div>
              <div className={`items`}
              >
                {!active
                  ? monthlyPaymentArray.slice(0, 3).map((item) => {
                    return <PaymentCard key={item.id} item={item} />;
                  })
                  : monthlyPaymentArray.slice(3, 6).map((item) => {
                    return <PaymentCard key={item.id} item={item} />;
                  })}
              </div>
            </div>
          </div>
        </div>
        {!isLoggedIn && <footer className={`footer-section footer-section--l4 position-relative bg-default`}
        >
          <div className={`containerWS`}
          >
            <div className={`row justify-content-center`}
            >
              <div className={`col-lg-12`}
              >
                <div className={`footer-top border-bottom border-default-color-3 pb-5`}
                >
                  <div className={`row align-items-center justify-content-center`}
                  >
                    <div className={`col-6 col-xxs-4 col-lg-4 col-md-5 col-xs-4`}
                    >
                      <div className={`footer-brand-block footer-brand-block--l4 mb-md-0`}
                      >
                        <div className={`brand-logo mb-0 text-center text-md-start mx-auto mx-md-0`}
                        >
                          <a href="#">
                            <img src={logo} alt="" className={`footerlogo`}
                             />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className={`col-lg-8 col-md-7`}
                    >
                      <div className={`footer-menu text-center text-md-end`}
                      >
                        <ul className={`list-unstyled`}
                        >
                          <li className={`nav-item`}
                          >
                            <Link to={"/"}>
                              <button className={`actionElementButton`}
                              >

                                <span className={`navactionButtonText active`}
                                >Home</span>
                              </button>
                            </Link>
                          </li>
                          <li className={`nav-item`}
                          >
                            <Link to={"/plans/"}>
                              <button className={`actionElementButton`}
                              >

                                <span className={`navactionButtonText`}
                                >Pricing</span>
                              </button>
                            </Link>
                          </li>
                          <li className={`nav-item`}
                          >
                            <Link to={"/contact/"}>
                              <button className={`actionElementButton`}
                              >

                                <span className={`navactionButtonText`}
                                >Contact</span>
                              </button>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`copyright-block copyright-block--l3`}
                >
                  <div className={`row  text-center align-items-center`}
                  >
                    <div className={`col-xs-6 text-sm-start`}
                    >
                      <p className={`copyright-text--l3 `}
                      >© 2023 by Foresight Hub</p>
                    </div>
                    <div className={`col-xs-6 text-sm-end`}
                    >
                      <ul className={`footer-social-share footer-social-share--rounded`}
                      >
                        <li>
                          <a href="#"
                          >
                            <img src={Facebook} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Insta} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src={Linkedin} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>}
      </div>
    </div>
  );
};
