import React, { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import fileIcon from "../../images/openfolderwhite.svg";
import "./AccidentReportForm.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import accidentService from "../../services/accident.service";
import { onError } from "../../libs/errorLib";
import CustomPopup from "../Popup/CustomPopup";
import Delete from "../../images/circle-cross.svg";
import Swal from "sweetalert2";
import { commonService } from "../common/common.service";

const AccidentReportForm = ({ title }) => {
  const { id, rid } = useParams();
  const { user, selectedCompany } = useAppState();
  const history = useHistory();
  const location = useLocation();
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [sitesData, setSitesData] = useState([]);
  const [allDepartment, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [witnessPopup, setWitnessPopup] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [popupData, setPopupData] = useState({
    fullName: "",
    employer: "",
    trade: "",
  });
  const [witnesses, setWitnesses] = useState([]);

  const fieldValues = {
    ipName: null,
    siteId: null,
    ipGender: null,
    ipDob: null,

    accidentDate: null,
    accidentTime: null,
    lightingCondition: null,
    weatherCondition: null,
    userType: null,

    company: null,
    occupation: null,
    accidentLocation: null,

    ipTask: null,
    ipSupervisor: null,
    ipFamiliar: null,
    ssowFollowed: null,
    witness: null,

    equipmentInvolved: null,
    equipmentCondition: null,
    equipmentRelatedToTask: null,
    equipmentUsage: null,
    ppe: null,

    description: null,

    reportedTo: null,
    removedToHospital: null,
    firstAid: null,
    injuryNature: null,

    detainedFor: null,
    treatment: null,
    hospitalName: null,
    hospitalAddress: null,

    submittedBy: null,
    submittedAt: null,
    observation: null,
    status: null,

    verifySignature: null,
    verifySignatureTwo: null,

    files: "",
  };
  const [fieldData, setFieldData] = useState(fieldValues);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({
    ...fieldValues,
    contractorId: null,
    startDate: null,
  });
  const [siteUsers, setSiteUsers] = useState([]);
  const [accidentNotifications, setAccidentNotifications] = useState(null);

  const userTypes = [
    { value: "Employee", label: "Employee", name: "userType" },
    {
      value: "Sub-Contractor",
      label: "Sub-Contractor",
      name: "userType",
    },
    {
      value: "Visitor to site",
      label: "Visitor to site",
      name: "userType",
    },
    { value: "3rd Party", label: "3rd Party", name: "userType" },
  ];

  const weatherConditions = [
    { value: "Cloudy", label: "Cloudy", name: "weatherCondition" },
    { value: "Cold", label: "Cold", name: "weatherCondition" },
    { value: "Dry", label: "Dry", name: "weatherCondition" },
    { value: "Hot", label: "Hot", name: "weatherCondition" },
    { value: "Rainy", label: "Rainy", name: "weatherCondition" },
    { value: "Stormy", label: "Stormy", name: "weatherCondition" },
    { value: "Sunny", label: "Sunny", name: "weatherCondition" },
    { value: "Wet", label: "Wet", name: "weatherCondition" },
    { value: "Windy", label: "Windy", name: "weatherCondition" },
  ];

  const tradeOptions = [
    { value: "Bricklayer", label: "Bricklayer", name: "trade" },
    { value: "Carpenter", label: "Carpenter", name: "trade" },
    { value: "Carpet Tiler", label: "Carpet Tiler", name: "trade" },
    { value: "Drywall", label: "Drywall", name: "trade" },
    { value: "Electrician", label: "Electrician", name: "trade" },
    { value: "Formworker", label: "Formworker", name: "trade" },
    { value: "G.O.", label: "G.O.", name: "trade" },
    { value: "Glazier", label: "Glazier", name: "trade" },
    { value: "Mechanical", label: "Mechanical", name: "trade" },
    {
      value: "Member Of The Public",
      label: "Member Of The Public",
      name: "trade",
    },
    { value: "Painter", label: "Painter", name: "trade" },
    { value: "Plant Operator", label: "Plant Operator", name: "trade" },
    { value: "Plasterer", label: "Plasterer", name: "trade" },
    { value: "Plumber", label: "Plumber", name: "trade" },
    { value: "Roofer", label: "Roofer", name: "trade" },
    { value: "Scaffolder", label: "Scaffolder", name: "trade" },
    { value: "Site Management", label: "Site Management", name: "trade" },
  ];

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(userCompanyId);
          setUserDetails(res.data[0]);
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
            submittedBy: res.data[0].fullName,
          });
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const fetchAccidentReportDetaills = async () => {
    setLoading(true);
    try {
      if (rid) {
        const response = await accidentService.getAccidentReport(rid);
        if (response) {
          let [hours, minutes] = response?.accidentTime?.split(":");
          delete response.files;
          setFieldData({
            ...response,
            accidentDate: response && response.accidentDate ? commonService.formatDate(response.accidentDate,"YYYY-MM-DD") : '',
            ipDob: response?.ipDob?.split("T")[0],
            accidentTime: `${hours}:${minutes}`,
            submittedBy: response.submittedBy? response.submittedBy : userDetails.fullName,
            submittedAt: fieldData.submittedAt ? new Date(fieldData.submittedAt) : new Date(),
          });
          setWitnesses(JSON.parse(response.witnesses));
        }
      }
    } catch (e) {
      onError(e || "Unable to process request");
      console.log("errr", e);
    } finally {
      setLoading(false);
    }
  };

  const fetchAccidentNotificationsDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/AccidentNotifications/notification/details`,
        {
          notificationId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        if(!rid && res.data[0].reportId) {
          history.push(`/edit-accident-report/${id}/${res.data[0].reportId}`)
        }
        setFieldData({
          ...fieldData,
          siteId: res.data[0].siteId,
          departmentId: res.data[0].departmentId,
          accidentDate: res.data[0].accidentDate,
          accidentTime: res.data[0].accidentTime,
        });
        setLoading(false);
        getSiteUsers(res.data[0].siteId);
        fetchAccidentReportDetaills()
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        console.log("errr", err);
      });
  };

  const getDepartments = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-departments`,
        {
          companyId: ( user.role == 'superadmin' ? selectedCompany : companyId ),
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setDepartments(
          res.data.filter(
            (department, index, arr) =>
              res.data.findIndex((item) => item.id == department.id) == index
          )
        );
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (id) {
      fetchAccidentNotificationsDetails();
      setFieldData({
        ...fieldData,
        accidentNotificationId: id
      })
    }
    getSites();
    getDepartments();
    getAccidentNotifications(companyId);
  }, [companyId]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  useEffect(() => {
    if (rid) {
      fetchAccidentReportDetaills();
    }
  }, [rid]);

  const getAccidentNotifications = async(compnayId) => {
    try {
      if(companyId) {
        const response = await accidentService.getAccidentNotifications({companyId});
        if(response) {
          setAccidentNotifications(response);
        }
      }
    } catch (e) {
        onError(e || "Unable to process request");
        console.log("errr", e);
      } finally {
        setLoading(false);
      }
  }

  const accidentNotificationOptions = accidentNotifications && accidentNotifications.map((d) => ({
    label: `Notification : #${d.id} - ${d.fullName}`,
    value: d.id,
    name: "accidentNotificationId",
  }));

  const getSites = () => {
    
    let userIdForCompany = user.role == "superadmin" ? selectedCompany : user.id;

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/users-site/${userIdForCompany}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setSitesData(res.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSiteUsers = async (siteId) => {
    setLoading(true);
    try {
      const siteUsersResponse = await axiosInstance.post(
        `/site-users/${siteId}`
      );
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false);
      if (siteUsersResponseData.length) {
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "files") {

      let modifiedFiles = commonService.addUploadFilesValidations( files );
      
      if ( modifiedFiles.message !== '' ) {
        toast.error( modifiedFiles.message );
      }

      Promise.all(Array.from(modifiedFiles.files).map((file) => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        [name]: modifiedFiles.filesList,
      });
    } else {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
      if (name == "siteId") {
        getSiteUsers(value);
      }
    }
  };

  const isValidData = (value, name) => {
    let message = "";
    const stateObj = { ...error, [name]: "" };
    // eslint-disable-next-line default-case
    switch (name) {
      case "ipName":
        if (!value) {
          message = stateObj[name] = "Name of inured person is required.";
        }
        break;
      case "ipGender":
        if (!value) {
          message = stateObj[name] = "Gender is required.";
        }
        break;
      case "siteId":
        if (!value) {
          message = stateObj[name] = "Site Id is required.";
        }
        break;
      case "ipDob":
        if (!value) {
          message = stateObj[name] = "Date of Birth is required.";
        }
        break;
      case "accidentDate":
        if (!value) {
          message = stateObj[name] = "Date of accident is required.";
        }
        break;
      case "accidentTime":
        if (!value) {
          message = stateObj[name] = "Time of accident is required.";
        }
        break;
      case "lightingCondition":
        if (!value) {
          message = stateObj[name] =
            "Llighting condition Required is required.";
        }
        break;
      case "weatherCondition":
        if (!value) {
          message = stateObj[name] = "Weather condition is required.";
        }
        break;
      case "userType":
        if (!value) {
          message = stateObj[name] = "Type of User is required.";
        }
        break;
      case "company":
        if (!value) {
          message = stateObj[name] = "Company is required.";
        }
        break;
      case "occupation":
        if (!value) {
          message = stateObj[name] = "Occupation is required.";
        }
        break;
      case "accidentLocation":
        if (!value) {
          message = stateObj[name] =
            "Where did the accident occur is required.";
        }
        break;
      case "ipTask":
        if (!value) {
          message = stateObj[name] = "IP task is required.";
        }
        break;
      case "ipSupervisor":
        if (!value) {
          message = stateObj[name] = "IP Supervisor is required.";
        }
        break;
      case "ipFamiliar":
        if (!value) {
          message = stateObj[name] = "IP Familiar is required.";
        }
        break;
      case "ssowFollowed":
        if (!value) {
          message = stateObj[name] =
            "Was the agreed SSOW / RAMS followed is required.";
        }
        break;
      case "witness":
        if (!value) {
          message = stateObj[name] =
            "Was this a solo effor / any witnesses is required.";
        }
        break;
      case "timeLost":
        if (!value) {
          message = stateObj[name] = "Time lost is required.";
        }
        break;
      case "equipmentInvolved":
        if (!value) {
          message = stateObj[name] =
            "Was there a machine / tool or plant involved is required.";
        }
        break;
      case "equipmentCondition":
        if (!value) {
          message = stateObj[name] = "Equipment Condition is required.";
        }
        break;
      case "equipmentUsage":
        if (!value) {
          message = stateObj[name] =
            "Was it being used correctly for the task is required.";
        }
        break;
      case "equipmentRelatedToTask":
        if (!value) {
          message = stateObj[name] =
            "Was it designed for the task being completed is required.";
        }
        break;
      case "ppe":
        if (!value) {
          message = stateObj[name] =
            "What PPE was required and worn at the time of acciden is required.";
        }
        break;
      case "description":
        if (!value) {
          message = stateObj[name] = "Accident detail description is required.";
        }
        break;
      case "reportedTo":
        if (!value) {
          message = stateObj[name] =
            "Who was the accident first reported is required.";
        }
        break;
      case "firstAid":
        if (commonService.isNullOrUndefined(value)) {
          message = stateObj[name] = "Was first aid administered? is required.";
        }
        break;
      case "removedToHospital":
        if (commonService.isNullOrUndefined(value)) {
          message = stateObj[name] =
            "Was the IP removed to hospital? is required.";
        }
        break;
      case "injuryNature":
        if (!value) {
          message = stateObj[name] =
            "Nature of Injuery and where on the IPs body is required.";
        }
        break;
      case "detainedFor":
        if (!value) {
          message = stateObj[name] =
            "Was the IP detained and for how long (days)? is required.";
        }
        break;
      case "treatment":
        if (!value) {
          message = stateObj[name] = "Treatment given (if known) is required.";
        }
        break;
      case "hospitalName":
        if (!value) {
          message = stateObj[name] = "Name of hospital attended? is required.";
        }
        break;
      case "hospitalAdress":
        if (!value) {
          message = stateObj[name] =
            "Address of Hospital attended is required.";
        }
        break;
      case "submittedBy":
        if (!value) {
          message = stateObj[name] = "Submitted by is required.";
        }
        break;
      case "submittedAt":
        if (!value) {
          message = stateObj[name] = "Date Submitted is required.";
        }
        break;
      case "observation":
        if (!value) {
          message = stateObj[name] = "Observations is required.";
        }
        break;
      case "verifySignature":
        if (!value) {
          message = stateObj[name] = "Upload signature.";
        }
        break;
      case "verifySignatureTwo":
        if (!value) {
          message = stateObj[name] = "Upload signature.";
        }
        break;
    }
    setError(stateObj);
    return message;
  };

  const handleSubmit = async () => {
    try {
      const [verifySignature, verifySignatureTwo] = await Promise.all([
        handleSaveVerifySign(),
        handleSaveVerifySignTwo(),
      ]);
      handleSubmitAfterSave(verifySignature, verifySignatureTwo);
    } catch (error) {
      console.log(error);
      toast.error("Failed to save signature");
    }
  };

  const handleSubmitAfterSave = (verifySignature, verifySignatureTwo) => {
    setFieldData({
      ...fieldData,
      submittedAt: new Date(),
      submittedBy: userDetails.fullName
    });
    let errorShow = {
      ipName: fieldData.ipName,
      siteId: fieldData.siteId,
      ipGender: fieldData.ipGender,
      ipDob: fieldData.ipDob,

      accidentDate: fieldData.accidentDate,
      accidentTime: fieldData.accidentTime,
      lightingCondition: fieldData.lightingCondition,
      weatherCondition: fieldData.weatherCondition,
      userType: fieldData.userType,

      company: fieldData.company,
      occupation: fieldData.occupation,
      accidentLocation: fieldData.accidentLocation,

      ipTask: fieldData.ipTask,
      ipSupervisor: fieldData.ipSupervisor,
      ipFamiliar: fieldData.ipFamiliar,
      ssowFollowed: fieldData.ssowFollowed,
      witness: fieldData.witness,
      timeLost: fieldData.timeLost,

      equipmentInvolved: fieldData.equipmentInvolved,
      equipmentCondition: fieldData.equipmentCondition,
      equipmentRelatedToTask: fieldData.equipmentRelatedToTask,
      equipmentUsage: fieldData.equipmentUsage,
      ppe: fieldData.ppe,

      description: fieldData.description,

      reportedTo: fieldData.reportedTo,
      removedToHospital: fieldData.removedToHospital,
      firstAid: fieldData.firstAid,
      injuryNature: fieldData.injuryNature,

      submittedBy: fieldData.submittedBy,
      submittedAt: fieldData.submittedAt,
      observation: fieldData.observation,

      verifySignature: verifySignature,
      verifySignatureTwo: verifySignatureTwo,
    };

    if (fieldData.removedToHospital && fieldData.removedToHospital == 1) {
      errorShow = {
        ...errorShow,
        detainedFor: fieldData.detainedFor,
        treatment: fieldData.treatment,
        hospitalName: fieldData.hospitalName,
        hospitalAddress: fieldData.hospitalAddress,
      };
    }
    let trigger = true;
    Object.keys(errorShow).forEach((key) => {
      let message = isValidData(errorShow[key], key);
      if (trigger && message) {
        toast.error(message);
        trigger = false;
      }
    });

    if (
      fieldData.ipName !== null &&
      fieldData.siteId !== null &&
      fieldData.ipGender !== null &&
      fieldData.ipDob !== null &&
      fieldData.lightingCondition !== null &&
      fieldData.weatherCondition !== null &&
      fieldData.userType !== null &&
      fieldData.company !== null &&
      fieldData.occupation !== null &&
      fieldData.accidentLocation !== null &&
      fieldData.ipTask !== null &&
      fieldData.ipSupervisor !== null &&
      fieldData.ipFamiliar !== null &&
      fieldData.ssowFollowed !== null &&
      fieldData.witness !== null &&
      fieldData.timeLost !== null &&
      fieldData.equipmentInvolved !== null &&
      fieldData.equipmentRelatedToTask !== null &&
      fieldData.equipmentUsage !== null &&
      fieldData.ppe !== null &&
      fieldData.description !== null &&
      fieldData.reportedTo !== null &&
      fieldData.firstAid !== null &&
      fieldData.removedToHospital !== null &&
      fieldData.injuryNature !== null &&
      fieldData.submittedBy !== null &&
      fieldData.submittedAt !== null &&
      fieldData.observation !== null &&
      fieldData.status !== null &&
      verifySignature !== null &&
      verifySignatureTwo !== null
    ) {
      const form = new FormData();

      const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
        email: v.email,
        userid: v.userid,
        fullName: v.fullName,
        emailPref: v.emailPreference ? v.emailPreference.includes("accidentNotification") : v.permits.includes("accidentNotificationreceiveEmails"),
        isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
      })).filter(v => v.emailPref || v.isAdminWithNoPref)
      form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));

      form.append("siteId", fieldData == null ? null : fieldData.siteId);
      form.append("departmentId", fieldData.departmentId);
      form.append("accidentNotificationId", id);

      form.append("ipName", fieldData.ipName);
      form.append("ipGender", fieldData.ipGender);
      form.append("ipContact", fieldData.ipContact);
      form.append("ipDob", fieldData.ipDob);
      form.append("accidentDate", fieldData.accidentDate);
      form.append("accidentTime", fieldData.accidentTime);
      form.append("lightingCondition", fieldData.lightingCondition);
      form.append("weatherCondition", fieldData.weatherCondition);
      form.append("userType", fieldData.userType);
      form.append("company", fieldData.company);
      form.append("occupation", fieldData.occupation);
      form.append("accidentLocation", fieldData.accidentLocation);
      form.append("ipTask", fieldData.ipTask);
      form.append("ipSupervisor", fieldData.ipSupervisor);
      form.append("ipFamiliar", fieldData.ipFamiliar);
      form.append("ssowFollowed", fieldData.ssowFollowed);
      form.append("witness", fieldData.witness);
      form.append("timeLost", fieldData.timeLost);

      form.append("equipmentInvolved", fieldData.equipmentInvolved);
      form.append("equipmentCondition", fieldData.equipmentCondition);
      form.append("equipmentRelatedToTask", fieldData.equipmentRelatedToTask);
      form.append("equipmentUsage", fieldData.equipmentUsage);
      form.append("ppe", fieldData.ppe);

      form.append("description", fieldData.description);

      form.append("reportedTo", fieldData.reportedTo);
      form.append("firstAid", fieldData.firstAid);
      form.append("removedToHospital", fieldData.removedToHospital);
      form.append("injuryNature", fieldData.injuryNature);

      if (fieldData.firstAid == 1) {
        form.append("firstAidBy", fieldData.firstAidBy);
      }

      if (fieldData.removedToHospital == 1) {
        form.append("detainedFor", fieldData.detainedFor);
        form.append("treatment", fieldData.treatment);
        form.append("hospitalName", fieldData.hospitalName);
        form.append("hospitalAddress", fieldData.hospitalAddress);
      }

      form.append("submittedBy", fieldData.submittedBy);
      form.append("submittedAt", fieldData.submittedAt);
      form.append("observation", fieldData.observation);
      form.append("status", fieldData.status);

      form.append("verifySignature", verifySignature);
      form.append("verifySignatureTwo", verifySignatureTwo);

      if (fieldData.existingFiles) {
        form.append("existingFiles", fieldData.existingFiles);
      }

      form.append("witnesses", JSON.stringify(witnesses));

      if(fieldData.files) {
        Object.entries(fieldData?.files)?.map(([key, value]) => {
          form.append(`selectedFiles${[key]}`, value);
        });
      }

      setLoading(true);
      const apiCall = rid
        ? axiosInstance.put(
            `${process.env.REACT_APP_API_BASEURL}/accident-reports/${rid}`,
            form,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          )
        : axiosInstance.post(
            `${process.env.REACT_APP_API_BASEURL}/accident-reports`,
            form,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

      apiCall
        .then((res) => {
          toast.success(res.data.message);
          setLoading(false);
          history.push("/view-own/view-accident-reports");
          setFieldData(fieldData);
          handleClearVerifySign();
          handleClearVerifySignTwo();
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
    } else {
      console.log("error", fieldData);
    }
  };

  const signatureVerifyRef = useRef();
  const signatureVerifyRefTwo = useRef();

  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRef.current.isEmpty()) {
        res(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignature: file,
            };
          });
          res(file);
        });
      }
    });
  };

  const handleSaveVerifySignTwo = () => {
    return new Promise((res, rej) => {
      if (signatureVerifyRefTwo.current.isEmpty()) {
        res(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureVerifyRefTwo.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              verifySignatureTwo: file,
            };
          });
          res(file);
        });
      }
    });
  };

  const handleClearVerifySign = () => {
    signatureVerifyRef.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignature: null,
      };
    });
  };

  const handleClearVerifySignTwo = () => {
    signatureVerifyRefTwo.current.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        verifySignatureTwo: null,
      };
    });
  };

  const siteOptions = sitesData.map((d) => ({
    label: d.name,
    value: d.id,
    name: "siteId",
  }));

  const addWitnessHandler = () => {
    setWitnesses([...witnesses, popupData]);
    setPopupData({
      fullName: "",
      employer: "",
      trade: "",
    });
    setWitnessPopup(false);
  };

  const handleDeleteWitness = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to remove ${name} as accident witness`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const updatedWitnesses = [
          ...witnesses.slice(0, id),
          ...witnesses.slice(id + 1),
        ];
        setWitnesses(updatedWitnesses);
        toast.success("Witness deleted from accident");
      } catch (e) {
        toast.error(e.message || "Unable to delete wiitness from accident");
      }
    }
  };

  const setAccidentNotificationId = (accidentNotification) => {
    if(accidentNotification.value) {
      history.push(`/create-accident-report/${accidentNotification.value}`)
    }
  }

  return (
    <div className="middle_page">
      <CustomPopup
        trigger={witnessPopup}
        setTrigger={() => {
          setWitnessPopup(false);
          setPopupData({
            fullName: "",
            employer: "",
            trade: "",
          });
        }}
      >
        <div className="col-12 form_inner p-2">
          <div className="input_icons">
            <div className="input_field">
              <div className="w-100 location_ladder p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Full Name <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <input
                      value={popupData.fullName}
                      type="text"
                      onChange={(e) => {
                        setPopupData({
                          ...popupData,
                          fullName: e.target.value,
                        });
                      }}
                      name="fullName"
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 location_ladder p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Employer <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <input
                      value={popupData.employer}
                      type="text"
                      onChange={(e) => {
                        setPopupData({
                          ...popupData,
                          employer: e.target.value,
                        });
                      }}
                      name="employer"
                    />
                  </div>
                </div>
              </div>

              <div className="w-100 location_ladder p-2 text-left">
                <label for="">
                  <div className="require-label">
                    Trade <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <Select
                      onChange={(e) => {
                        setPopupData({
                          ...popupData,
                          trade: e.value,
                        });
                      }}
                      placeholder="Select a trade"
                      options={tradeOptions}
                      value={tradeOptions.filter(
                        (v) => v.value == popupData.trade
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="sign_save_reset_btn">
                <button
                  className="btn btn-success"
                  disabled={
                    !popupData?.fullName ||
                    !popupData?.trade ||
                    !popupData?.employer
                  }
                  onClick={addWitnessHandler}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomPopup>
      <Header title={title} steps={[{ name: "Home", path: "/" }]} />
      <div className="main_container">
        {id && 
          <a
            className="original-notification-link"
            href={`/accident-notifications/${id}`}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/accident-notifications/${id}`);
            }}
          >
            Original accident Notification
          </a>
        }
        <div className="addaccess_first_container">
          <div className="general_details">Section 1 - General Details</div>

        { !id && accidentNotificationOptions &&
            <div className="row p-2">
              <div className="col-md-8">
                <div className="form_inner p-2 w-100">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Accident Notification <span className="text-danger">*</span>{" "}
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <Select
                        onChange={setAccidentNotificationId}
                        placeholder="Select Accident Notification"
                        options={accidentNotificationOptions}
                        {...(!id
                          ? {
                              value: accidentNotificationOptions.filter(
                                (v) => v.value == fieldData.accidentNotificationId
                              )
                            }
                          : {})}
                      />
                    </div>
                  </div>
                  {error.site && <span className="err">{error.site}</span>}
                </div>
              </div>
            </div>
          }

          <div className="row p-2">
            <div className="col-md-8">
              <div className="flex flex-wrap">
                <div className="col-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Name of Injured Person{" "}
                      <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        value={fieldData.ipName}
                        type="text"
                        onChange={handleChange}
                        name="ipName"
                      />
                    </div>
                  </div>
                  {error.ipName && <span className="err">{error.ipName}</span>}
                </div>

                <div className="col-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Site <span className="text-danger">*</span>{" "}
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <Select
                        onChange={handleChange}
                        placeholder="Select Sites"
                        options={siteOptions}
                        {...(id
                          ? {
                              value: siteOptions.filter(
                                (v) => v.value == fieldData.siteId
                              ),
                              isDisabled: true,
                            }
                          : {})}
                      />
                    </div>
                  </div>
                  {error.site && <span className="err">{error.site}</span>}
                </div>
              </div>

              <div className="form_inner p-2 w-100">
                <label for="">Contact Details</label>
                <div className="input_icons">
                  <div className="input_field">
                    <textarea
                      name="ipContact"
                      rows="5"
                      className="w-100"
                      cols="100"
                      value={fieldData.ipContact}
                      onChange={handleChange}
                      placeholder="Enter contact details of inpured person (if available)"
                    ></textarea>
                  </div>
                </div>
                {error.ipContact && (
                  <span className="err">{error.ipContact}</span>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Gender <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="ipGender"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          checked={fieldData.ipGender == "Male"}
                          value={"Male"}
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          checked={fieldData.ipGender == "Female"}
                          value={"Female"}
                          control={<Radio />}
                          label="Female"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                {error.ipGender && (
                  <span className="err">{error.ipGender}</span>
                )}
              </div>

              <div className="w-100 p-2 location_access">
                <label for="">
                  <div className="require-label">
                    {" "}
                    Date of Birth <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input
                      value={fieldData.ipDob}
                      type="date"
                      onChange={handleChange}
                      name="ipDob"
                    />
                  </div>
                </div>
                {error.ipDob && <span className="err">{error.ipDob}</span>}
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-12">
              <div className="flex flex-wrap">
                <div className="col-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Date of Accident <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        value={fieldData.accidentDate}
                        type="date"
                        onChange={handleChange}
                        name="accidentDate"
                      />
                    </div>
                  </div>
                  {error.accidentDate && (
                    <span className="err">{error.accidentDate}</span>
                  )}
                </div>

                <div className="col-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Time of Accident <span className="text-danger">
                        *
                      </span>{" "}
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        value={fieldData.accidentTime}
                        disabled={!!id}
                        type="time"
                        pattern="hh:mm"
                        onChange={handleChange}
                        name="accidentTime"
                      />
                    </div>
                  </div>
                  {error.accidentTime && (
                    <span className="err">{error.accidentTime}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-12">
              <div className="flex flex-wrap">
                <div className="col-md-6 location_access">
                  <div className="w-100 p-2">
                    <label for="">
                      <div className="require-label">
                        Lighting Condition?{" "}
                        <span className="text-danger">*</span>
                      </div>
                    </label>
                    <div className="input_icons">
                      <div className="w-100">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="lightingCondition"
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              checked={fieldData.lightingCondition == "Good"}
                              value={"Good"}
                              control={<Radio />}
                              label="Good"
                            />
                            <FormControlLabel
                              checked={fieldData.lightingCondition == "Bad"}
                              value={"Bad"}
                              control={<Radio />}
                              label="Bad"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    {error.lightingCondition && (
                      <span className="err">{error.lightingCondition}</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6 location_access">
                  <div className="w-100  p-2">
                    <label for="">
                      <div className="require-label">
                        Weather Conditions{" "}
                        <span className="text-danger">*</span>
                      </div>
                    </label>
                    <div className="input_icons">
                      <div className="w-100">
                        <Select
                          onChange={handleChange}
                          placeholder="Select an option"
                          options={weatherConditions}
                          value={weatherConditions.filter(
                            (v) => v.value == fieldData.weatherCondition
                          )}
                        />
                      </div>
                    </div>
                    {error.weatherCondition && (
                      <span className="err">{error.weatherCondition}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-12">
              <div className="flex flex-wrap">
                <div className="col-md-6 location_access">
                  <div className="w-100  p-2">
                    <label for="">
                      <div className="require-label">
                        Type of User <span className="text-danger">*</span>
                      </div>
                    </label>
                    <div className="input_icons">
                      <div className="w-100">
                        <Select
                          onChange={handleChange}
                          placeholder="Select an option"
                          options={userTypes}
                          value={userTypes.filter(
                            (v) => v.value == fieldData.userType
                          )}
                        />
                      </div>
                    </div>
                    {error.userType && (
                      <span className="err">{error.userType}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6 p-2 location_access">
                  <div className="w-100  p-2">
                    <label for="">
                      <div className="require-label">
                        {" "}
                        Company <span className="text-danger">*</span>
                      </div>
                    </label>
                    <div className="input_icons">
                      <div className="input_field">
                        <input
                          type="text"
                          name="company"
                          placeholder="Enter company name"
                          onChange={handleChange}
                          value={fieldData.company}
                        />
                      </div>
                    </div>
                    {error.company && (
                      <span className="err">{error.company}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-12">
              <div className="flex flex-wrap">
                <div className="col-md-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Occupation <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="occupation"
                        onChange={handleChange}
                        value={fieldData.occupation}
                      />
                    </div>
                  </div>
                  {error.occupation && (
                    <span className="err">{error.occupation}</span>
                  )}
                </div>
                <div className="col-md-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Where did the accident occur{" "}
                      <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="accidentLocation"
                        onChange={handleChange}
                        value={fieldData.accidentLocation}
                      />
                    </div>
                  </div>
                  {error.accidentLocation && (
                    <span className="err">{error.accidentLocation}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-12">
              <div className="flex flex-wrap">
                <div className="col-md-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      What task was the IP completing{" "}
                      <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="ipTask"
                        onChange={handleChange}
                        value={fieldData.ipTask}
                      />
                    </div>
                  </div>
                  {error.ipTask && <span className="err">{error.ipTask}</span>}
                </div>
                <div className="col-md-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Who was Supervising / giving instruction to IP{" "}
                      <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="ipSupervisor"
                        onChange={handleChange}
                        value={fieldData.ipSupervisor}
                      />
                    </div>
                  </div>
                  {error.ipSupervisor && (
                    <span className="err">{error.ipSupervisor}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-12">
              <div className="flex flex-wrap">
                <div className="col-md-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Was the IP familiar with the task{" "}
                      <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="ipFamiliar"
                        onChange={handleChange}
                        value={fieldData.ipFamiliar}
                      />
                    </div>
                  </div>
                  {error.ipFamiliar && (
                    <span className="err">{error.ipFamiliar}</span>
                  )}
                </div>
                <div className="col-md-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Was the agreed SSOW / RAMS followed{" "}
                      <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="ssowFollowed"
                        onChange={handleChange}
                        value={fieldData.ssowFollowed}
                      />
                    </div>
                  </div>
                  {error.ssowFollowed && (
                    <span className="err">{error.ssowFollowed}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-12">
              <div className="flex flex-wrap">
                <div className="col-md-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Was this a solo effor / any witnesses{" "}
                      <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="witness"
                        onChange={handleChange}
                        value={fieldData.witness}
                      />
                    </div>
                  </div>
                  {error.witness && (
                    <span className="err">{error.witness}</span>
                  )}
                </div>
              <div className="col-md-6 p-2 location_access">
                <label for="">
                  <div className="require-label">
                    {" "}
                    Lost time <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="input_field">
                    <input value={fieldData.timeLost} type="text" onChange={handleChange} name="timeLost" />
                  </div>
                </div>
                {error.timeLost && <span className="err">{error.timeLost}</span>}
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="addaccess_second_container">
          <div className="general_details">Witnesses</div>
          <div className="row p-2">
            <div className="col-md-12 p-3">
              <div id="wrapper" style={{ maxHeight: "300px" }}>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col" width="5%">
                        Full Name
                      </th>
                      <th scope="col" width="8%">
                        Employer
                      </th>
                      <th scope="col" width="8%">
                        Trade
                      </th>
                      <th scope="col" width="8%">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {witnesses && witnesses.length
                      ? witnesses.map((d, key) => (
                          <tr>
                            <td>{d.fullName}</td>
                            <td>{d.employer}</td>
                            <td>{d.trade}</td>
                            <td>
                              <img
                                src={Delete}
                                alt=""
                                onClick={() => {
                                  handleDeleteWitness(key, d.fulllName);
                                }}
                              />
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
              <div>
                <button
                  className="view_site_button text-white submit mt-2"
                  onClick={() => {
                    setWitnessPopup(true);
                  }}
                >
                  Add Witness
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="addaccess_second_container">
          <div className="general_details">Section 2 - Equipment Details</div>

          <div className="row p-2">
            <div className="col-md-6">
              <div className="flex flex-wrap">
                <div className="form_inner p-2 w-100">
                  <label for="">
                    Was there a machine / tool or plant involved
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <textarea
                        name="equipmentInvolved"
                        rows="5"
                        className="w-100"
                        cols="100"
                        value={fieldData.equipmentInvolved}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  {error.equipmentInvolved && (
                    <span className="err">{error.equipmentInvolved}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-6 p-2 location_access">
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Was it in a good Condition{" "}
                    <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="equipmentCondition"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          checked={fieldData.equipmentCondition == "Yes"}
                          value={"Yes"}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          checked={fieldData.equipmentCondition == "No"}
                          value={"No"}
                          control={<Radio />}
                          label="No"
                        />
                        <FormControlLabel
                          checked={fieldData.equipmentCondition == "N/A"}
                          value={"N/A"}
                          control={<Radio />}
                          label="N/A"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                {error.equipmentCondition && (
                  <span className="err">{error.equipmentCondition}</span>
                )}
              </div>
              <div className="w-100 p-2">
                <label for="">
                  <div className="require-label">
                    Was it designed for the task being completed{" "}
                    <span className="text-danger">*</span>
                  </div>
                </label>
                <div className="input_icons">
                  <div className="w-100">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="equipmentRelatedToTask"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          checked={fieldData.equipmentRelatedToTask == "Yes"}
                          value={"Yes"}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          checked={fieldData.equipmentRelatedToTask == "No"}
                          value={"No"}
                          control={<Radio />}
                          label="No"
                        />
                        <FormControlLabel
                          checked={fieldData.equipmentRelatedToTask == "N/A"}
                          value={"N/A"}
                          control={<Radio />}
                          label="N/A"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                {error.equipmentRelatedToTask && (
                  <span className="err">{error.equipmentRelatedToTask}</span>
                )}
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-12">
              <div className="flex flex-wrap">
                <div className="col-md-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Was it being used correctly for the task{" "}
                      <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="equipmentUsage"
                        onChange={handleChange}
                        value={fieldData.equipmentUsage}
                      />
                    </div>
                  </div>
                  {error.equipmentUsage && (
                    <span className="err">{error.equipmentUsage}</span>
                  )}
                </div>
                <div className="col-md-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      What PPE was required and worn at the time of accident{" "}
                      <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="ppe"
                        onChange={handleChange}
                        value={fieldData.ppe}
                      />
                    </div>
                  </div>
                  {error.ppe && <span className="err">{error.ppe}</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="addaccess_second_container">
          <div className="general_details">Section 3 - Accident Details</div>

          <div className="row p-2">
            <div className="col-md-12">
              <div className="flex flex-wrap">
                <div className="form_inner p-2 w-100">
                  <label for="">
                    Describe clearly how the accident occured
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <textarea
                        name="description"
                        rows="5"
                        className="w-100"
                        cols="100"
                        value={fieldData.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  {error.description && (
                    <span className="err">{error.description}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="addaccess_second_container">
          <div className="general_details">
            Section 4 - First Aid / Hospital Details
          </div>

          <div className="row p-2">
            <div className="col-md-6 p-2 location_access">
              <div className="flex flex-wrap">
                <div className="form_inner p-2 w-100">
                  <label for="">
                    Who was the accident first reported to
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="reportedTo"
                        onChange={handleChange}
                        value={fieldData.reportedTo}
                      />
                    </div>
                  </div>
                  {error.reportedTo && (
                    <span className="err">{error.reportedTo}</span>
                  )}
                </div>

                <div className="form_inner p-2 w-100">
                  <label for="">
                    Was the IP removed to hospital?
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="removedToHospital"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            checked={fieldData.removedToHospital == 1}
                            value={1}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            checked={fieldData.removedToHospital == 0}
                            value={0}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  {error.removedToHospital && (
                    <span className="err">{error.removedToHospital}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-6 p-2 location_access">
              <div className="flex flex-wrap">
                <div className="form_inner p-2 w-100">
                  <label for="">
                    Was first aid administered?
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="firstAid"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            checked={fieldData.firstAid == 1}
                            value={1}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            checked={fieldData.firstAid == 0}
                            value={0}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  {error.firstAid && (
                    <span className="err">{error.firstAid}</span>
                  )}
                </div>
                {fieldData.firstAid == 1 ? (
                  <div className="form_inner p-2 w-100">
                    <label for="">
                      Who administered first aid?
                      <span className="text-danger">*</span>
                    </label>
                    <div className="input_icons">
                      <div className="input_field">
                        <input
                          type="text"
                          name="firstAidBy"
                          onChange={handleChange}
                          value={fieldData.firstAidBy}
                        />
                      </div>
                    </div>
                    {error.firstAidBy && (
                      <span className="err">{error.firstAidBy}</span>
                    )}
                  </div>
                ) : null}

                <div className="form_inner p-2 w-100">
                  <label for="">
                    Nature of Injuery and where on the IPs body
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="injuryNature"
                        onChange={handleChange}
                        value={fieldData.injuryNature}
                      />
                    </div>
                  </div>
                  {error.injuryNature && (
                    <span className="err">{error.injuryNature}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {fieldData.removedToHospital == 1 ? (
            <div className="row p-2">
              <div className="col-md-6 p-2 location_access">
                <div className="form_inner p-2 w-100">
                  <label for="">
                    Was the IP detained and for how long (days)?
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="detainedFor"
                        onChange={handleChange}
                        value={fieldData.detainedFor}
                      />
                    </div>
                  </div>
                  {error.detainedFor && (
                    <span className="err">{error.detainedFor}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 p-2 location_access">
                <div className="form_inner p-2 w-100">
                  <label for="">
                    Treatment given (if known)
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="treatment"
                        onChange={handleChange}
                        value={fieldData.treatment}
                      />
                    </div>
                  </div>
                  {error.treatment && (
                    <span className="err">{error.treatment}</span>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {fieldData.removedToHospital == 1 ? (
            <div className="row p-2">
              <div className="col-md-6 p-2 location_access">
                <div className="form_inner p-2 w-100">
                  <label for="">
                    Name of hospital attended?
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="hospitalName"
                        onChange={handleChange}
                        value={fieldData.hospitalName}
                      />
                    </div>
                  </div>
                  {error.hospitalName && (
                    <span className="err">{error.hospitalName}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 p-2 location_access">
                <div className="form_inner p-2 w-100">
                  <label for="">
                    Address of Hospital attended
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <textarea
                        name="hospitalAddress"
                        rows="5"
                        className="w-100"
                        cols="100"
                        value={fieldData.hospitalAddress}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  {error.hospitalAddress && (
                    <span className="err">{error.hospitalAddress}</span>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="addaccess_second_container">
          <div className="general_details">Section 5 - Other Details</div>

          <div className="row p-2">
            <div className="col-md-12">
              <div className="flex flex-wrap">
                <div className="col-md-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Submitted by <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="submittedBy"
                        onChange={handleChange}
                        value={fieldData.submittedBy}
                        disabled={!!id}
                      />
                    </div>
                  </div>
                  {error.submittedBy && (
                    <span className="err">{error.submittedBy}</span>
                  )}
                </div>
                <div className="col-md-6 p-2 location_access">
                  <label for="">
                    <div className="require-label">
                      {" "}
                      Date Submitted <span className="text-danger">*</span>
                    </div>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <input
                        type="text"
                        name="submittedAt"
                        onChange={handleChange}
                        value={
                          currentDate.toDateString() +
                          " " +
                          currentDate.toLocaleTimeString()
                        }
                        disabled={!!id}
                      />
                    </div>
                  </div>
                  {error.submittedAt && (
                    <span className="err">{error.submittedAt}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-12 p-2">
              <div className="flex flex-wrap">
                <div className="form_inner p-2 w-100">
                  <label for="">
                    Observations
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <textarea
                        name="observation"
                        rows="5"
                        className="w-100"
                        cols="100"
                        value={fieldData.observation}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  {error.observation && (
                    <span className="err">{error.observation}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-12 p-2">
              <div className="flex flex-wrap">
                <div className="form_inner p-2 w-100">
                  <label for="">
                    Status of this report
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_icons">
                    <div className="input_field">
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="status"
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            checked={fieldData.status == "In Progress"}
                            value={"In Progress"}
                            control={<Radio />}
                            label="In Progress"
                          />
                          <FormControlLabel
                            checked={fieldData.status == "Closed"}
                            value={"Closed"}
                            control={<Radio />}
                            label="Closed"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  {error.status && <span className="err">{error.status}</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="addladder_fifth_container">
          <div className="general_details">Further Information Required</div>
          <div className="p-2">
            <div className="w-100">
              In Addition to the above please attach any of the following where
              available:
              <ul className="w-100 p-3">
                <li>
                  Any induction/special training for the task - copy of register
                </li>
                <li>Details of Safe Pass, CSCS, other training cards</li>
                <li>
                  Where certified driver/operator involved - copy of current
                  card or other certificate of training
                </li>
                <li>
                  Where certified pllant/machine/equipment involved - copy of
                  current certs
                </li>
                <li>Copy of signed risk assessment and method statement</li>
                <li>HSA/HSE notification iinformation</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="addladder_fifth_container">
          <div className="general_details">Upload Files</div>
          <div className="d-flex">
            {!!files.length &&
              files.map((imgPreviewUrl) => (
                <div id="profile_pic_1_preview" className="image-fixed m-2">
                  <img
                    className="obj_fit_img obj-fit-cover rounded"
                    src={imgPreviewUrl}
                    alt=""
                    height="150"
                    width="150"
                  />
                </div>
              ))}
          </div>
          <div className="select_files">
            <input
              name="files"
              type="file"
              multiple
              id="upload"
              hidden
              onChange={handleChange}
            />
            <label for="upload">
              <img src={fileIcon} alt="Icon" />
              Select files...
            </label>
          </div>
          <div className="select_all_files">
            Select all files you wish to upload in one go and NOT individually.
          </div>
          <div className="hold_down">
            Hold down CTRL (CMD on Mac) to select multiple files.
          </div>
        </div>
        <div className="addaccess_sixth_container">
          <div className="general_details">Signature(s)</div>
          <div className="row">
            <div className="col-md-4">
              <div className="p-2">
                <strong>Submitter Signature</strong>
                <div className="sign_image submitter_signature">
                  <SignatureCanvas
                    ref={signatureVerifyRef}
                    canvasProps={{ width: 263, height: 270 }}
                  />
                  <span
                    className="cancel-custom cursor-pointer"
                    onClick={handleClearVerifySign}
                  >
                    <i
                      className="clear_btn  text-danger fa fa-times-circle"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
                {error.verifySignature && (
                  <span className="err">{error.verifySignature}</span>
                )}
              </div>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="p-2">
                <strong>Injured Person Signature</strong>
                <div className="sign_image injured_signature">
                  <SignatureCanvas
                    ref={signatureVerifyRefTwo}
                    canvasProps={{ width: 263, height: 270 }}
                  />
                  <span
                    className="cancel-custom cursor-pointer"
                    onClick={handleClearVerifySignTwo}
                  >
                    <i
                      className="clear_btn  text-danger fa fa-times-circle"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
                {error.verifySignatureTwo && (
                  <span className="err">{error.verifySignatureTwo}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              className="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <button className="btn btn-secondary me-2">Reset</button>
          <span>or</span>
          <button className="cancel_btn">cancel</button>
          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>
      </div>
    </div>
  );
};

export default AccidentReportForm;
