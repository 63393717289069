import React from 'react'
import "./CreateSite.css"
import Header from '../Header/Header'
import { useState } from 'react'
import Cookies from 'universal-cookie';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../helpers/request';
import { useEffect } from 'react';
import { useAppState } from '../../state';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import SubmitLoader from '../common/SubmitLoader';

function CreateSite({title}) {
    const { user, userPermissions } = useAppState()
    const cookies = new Cookies();
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()
    const isEdit = location.pathname.includes("edit-site")
    const userToken = localStorage.getItem("cs_token");
    const [data, setData] = useState({
        name: "",
        description: "",
        addedByInCompany:""
    })
    const [roles, setRoles] = useState([])
    const [loading, setLoading] = useState(false)
    const [companyId, setUserID] = useState("")
    const [siteUsers, setSiteUsers] = useState([])
    const [users, setUsers] = useState([])
    const [newUser, setUser] = useState(null)

    useEffect(() => {
        userDetail()
        if (isEdit && id) {
            getSite()
        }
    }, [])

    
    useEffect(() => {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-roles`,{
                companyId : companyId,
                status : ""
            } , {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setRoles(res.data);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
        getUsers()
    }, [companyId])

    const getUsers = () => {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-users`, {
                companyId: companyId,
                status: ""
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setUsers(res.data);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }
    
    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setUserID(((res.data[0].role!=="admin")&&res.data[0].added_by) ? res.data[0].added_by : res.data[0].id);

                    // Check if the user is admin
                    if ( res.data[0].role !== 'superadmin' && res.data[0].role !== 'admin' && ! userPermissions.includes('createSite') ) {
                        history.push('/');
                    }

                    if(!isEdit){
                        setData({
                            ...data,
                            addedByInCompany:res.data[0].id
                        })
                    }
                } else {
                    history.push('/');
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    function getSite() {
        axiosInstance
            .get(`${process.env.REACT_APP_API_BASEURL}/site/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length) {
                    setData(res.data[0]);
                    if (res.data.map(v => v.userid).filter(v => v).length) {
                        getSiteUsers(res.data.map(v => v.userid).filter(v => v))
                    }
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    function getSiteUsers(userIds) {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/get-users`, userIds.map(v => parseInt(v)).sort(), {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length) {
                    setSiteUsers(res.data);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }
    const handleSubmit = () => {
            if (data.name) {
                setLoading(true)
                if (isEdit) {
                    if (newUser) {
                        setLoading(true)
                        axiosInstance.post(`/add-site-user`, {
                            siteid: data.id,
                            userid: newUser
                        }).then(async (response) => {
                            if (response.status === 200) {
                                // setData(response.data)
                                // getSite()
                                // toast.success("Successfully Updated site")
                                // history.push("/sites")
                            } else {
                                throw new Error(response?.error);
                            }
                            setLoading(false)
                        }).catch(err => {
                            console.log(err)
                            setLoading(false)
                            toast.error("Failed to Update site")
                        });
                    }
                    axiosInstance.put(`/site/${id}`, {
                        ...data,
                    }).then(async (response) => {
                        if (response.status === 200) {
                            // setData(response.data)
                            toast.success("Successfully Updated site")
                            history.push("/sites")
                        } else {
                            throw new Error(response?.error);
                        }
                        setLoading(false)
                    }).catch(err => {
                        console.log(err)
                        setLoading(false)
                        toast.error("Failed to Update site")
                    });
                } else {
                    axiosInstance.post('/add-site', {
                        ...data,
                        companyId,
                    }, {
                        headers: {
                          Authorization: `Bearer ${userToken}`,
                        }
                    }).then(async (response) => {
                        if (response.status === 200) {
                            // setData(response.data)
                            toast.success("Successfully Added site")
                            history.push("/sites")
                        } else {
                            throw new Error(response?.error);
                        }
                        setLoading(false)
                    }).catch(err => {
                        console.log(err)
                        setLoading(false)
                        toast.error("Failed to add site")
                    });
                }
            } else {
                toast.error("Name is a mandatory field.")
            }
    }
    const handleDelete = async (id, name) => {
        const result = await Swal.fire({
            title:`Are you sure to remove ${name} from this site`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });
    
        if(result.isConfirmed){
            axiosInstance
                .post(process.env.REACT_APP_API_BASEURL + "/site-user/" + id)
                .then(function (response) {
                    getSite();
                    toast.success("User deleted successfully");
                })
                .catch(function (error) {
                    toast.error(error?.response?.data?.message || "Unable to delete user");
                });
        }
    };

    return (
        <div class="middle_page">
            <Header title={title} steps={[{name:"Home",path:"/"},{name:!isEdit?"Add Site":"Edit Site",path:"/create-site"}]}/>
            <div class="main_container">
                {/* <p class="legal">(In Contemplation of Legal Proceedings)</p> */}
                <div>
                    <div class="accident">
                        <div class="accident_text">
                            <h5>{!isEdit?"Add Site":"Edit Site"}</h5>
                        </div>
                        {/* <div class="cross_icon"><img src={require("../../images/minus.png")} alt="" /></div> */}
                    </div>
                    <form>
                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div class="form_inner p-2">
                                    <label for="">Name <span className='text-danger'>*</span> </label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={data.name} name="name" onChange={handleChange} placeholder="Name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_inner p-2">
                                    <label for="">Description</label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <input type="text" value={data.description} name="description" onChange={handleChange} placeholder="Descriptions" />
                                        </div>
                                    </div>
                                </div>
                                
                                {siteUsers.length > 0 && <div className='form_inner p-2'>
                                    <label for="">Users</label>
                                    <div id="wrapper" style={{ maxHeight: "600px" }}>
                                        <table class="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="5%">View <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Full Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Email <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Company Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Role <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="2%">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {siteUsers.map(d => (<tr>
                                                    <th scope="row" className='cursor-pointer' onClick={() => {
                                                        history.push("/view-user/" + d.id)
                                                    }}>{d.id}</th>
                                                    <td>{d.fullName}</td>
                                                    <td>{d.email}</td>
                                                    <td>{d.companyName}</td>
                                                    <td>{d.role?.split(",").map(v => roles.filter(r => r.id == v).length ? roles.filter(r => r.id == v)[0].name : v)}</td>
                                                    {user.role.includes("admin") && <td><img src={require("../../images/table-cross.png")} alt="" onClick={() => {
                                                        handleDelete(d.id, d.fullName)
                                                    }} /></td>}
                                                </tr>))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                                {/* <div class="form_inner p-2">
                                    <label for="">Add User</label>
                                    <div class="input_icons">
                                        <div class="input_field">
                                            <select name="role" value={newUser} onChange={e => setUser(e.target.value)} className="w-100" >
                                                <option value="">Select User</option>
                                                {users.filter(v => !siteUsers.map(v => v.id).includes(v.id)).map(u => (
                                                    <option value={u.id}>{u.fullName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </form>
                    <div class="ps-2 signature">
                        <div class="sign_btns">
                            <div>
                               {!loading ? <button type='button' class="submit" disabled={loading} onClick={handleSubmit}>{!isEdit ? "Create" : "Update"}</button>
                                : <SubmitLoader/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateSite